import React, { useState, useRef, useEffect } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import PropTypes from 'prop-types'
import './styles.scss'

const BulkActionsDropdown = ({ onActionSelect, disabled, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropdownRef = useRef(null)

  const actions = [
    { id: 'disable', label: __('Disable licenses') },
    { id: 'enable', label: __('Enable licenses') },
    { id: 'delete', label: __('Delete licenses') }
  ]

  useEffect(() => {
    const handleClickOutside = event => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  const handleActionClick = actionId => {
    onActionSelect(actionId)
    setIsOpen(false)
  }

  return (
    <div className='BulkActionsDropdown' ref={dropdownRef} {...props}>
      <button
        className={`BulkActionsDropdown-trigger ${disabled ? 'is-disabled' : ''}`}
        onClick={() => !disabled && setIsOpen(!isOpen)}
        disabled={disabled}
        type='button'
      >
        <span>Bulk Actions</span>
        <IoChevronDown className='BulkActionsDropdown-chevron' />
      </button>

      {isOpen && (
        <div className='BulkActionsDropdown-menu'>
          <div className='BulkActionsDropdown-menuContent' role='menu'>
            {actions.map(action => (
              <button
                key={action.id}
                className='BulkActionsDropdown-menuItem'
                onClick={() => handleActionClick(action.id)}
                role='menuitem'
                type='button'
              >
                {action.label}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

BulkActionsDropdown.propTypes = {
  onActionSelect: PropTypes.func.isRequired,
  disabled: PropTypes.bool
}

BulkActionsDropdown.defaultProps = {
  disabled: false
}

export default BulkActionsDropdown
