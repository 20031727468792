import React from 'react'
import { platformFeatures } from 'shared/constants'
import { isFeatureEnabled } from 'shared/helpers'
import { UserPortalData } from '../components'

const UserPortalPreferences = () => {
  const isUserPortalFeatureEnabled = isFeatureEnabled(platformFeatures.extra_user_portal)
  return (
    <div className='UserPortalPreferences'>
      {isUserPortalFeatureEnabled ? (
        <UserPortalData />
      ) : (
        <div>
          {__('User portal feature is not enabled, upgrade your plan to acces to this feature.')}
        </div>
      )}
    </div>
  )
}

export default UserPortalPreferences
