import React from 'react'
import PropTypes from 'prop-types'
import { ContentLoader } from 'shared/components'
import './styles.scss'

const DescriptionTable = ({ details, loading, noDataText }) => (
  <div className='DescriptionTable'>
    <table>
      <tbody>
        {(!details || !details.length) && (
          <tr>
            <td>{noDataText}</td>
          </tr>
        )}
        {details
          .filter(d => d.label)
          .map(d => {
            const isComponent = typeof d.value === 'function'
            return (
              <tr key={d.label} className={d.className || ''}>
                <td>{d.label}</td>
                <td>{isComponent ? d.value() : d.value}</td>
              </tr>
            )
          })}
      </tbody>
    </table>
    {loading && <ContentLoader />}
  </div>
)

DescriptionTable.propTypes = {
  details: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  noDataText: PropTypes.string
}

DescriptionTable.defaultProps = {
  loading: false,
  noDataText: 'No data'
}

export default DescriptionTable
