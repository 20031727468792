import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { userPermissions } from 'shared/constants'
import { PermissionCheck } from 'shared/components'
import CustomersList from '../CustomersList'
import CustomerAccountsList from '../CustomerAccountsList'
import CustomerAccount from '../CustomerAccount'
import CustomerContainer from '../CustomerContainer'

const CustomersContainer = () => (
  <Switch>
    <Route
      exact
      path='/:companyId/customers'
      component={PermissionCheck(CustomersList, userPermissions.customers_read)}
    />
    <Route
      exact
      path='/:companyId/customers/accounts'
      component={PermissionCheck(CustomerAccountsList, userPermissions.customers_read)}
    />
    <Route
      path='/:companyId/customers/accounts/:accountId'
      component={PermissionCheck(CustomerAccount, userPermissions.customers_read)}
    />
    <Route
      path='/:companyId/customers/:customerId'
      component={PermissionCheck(CustomerContainer, userPermissions.customers_read)}
    />
  </Switch>
)

export default CustomersContainer
