import React from 'react'
import { platformFeatures } from 'shared/constants'
import { isFeatureEnabled } from 'shared/helpers'
import { AirgapPortalData } from '../components'

const AirgapPortalPreferences = () => {
  const isAirgapPortalFeatureEnabled = isFeatureEnabled(platformFeatures.extra_airgap_portal)

  return (
    <div className='AirgapPortalPreferences'>
      {isAirgapPortalFeatureEnabled ? (
        <AirgapPortalData />
      ) : (
        <div>
          {__('Airgap portal feature is not enabled, upgrade your plan to access this feature.')}
        </div>
      )}
    </div>
  )
}

export default AirgapPortalPreferences
