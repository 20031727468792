import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'
import { defaultDisabledFeatureMessage } from 'shared/constants'
import { Notification } from 'shared/components'

const sizes = {
  sm: {
    height: 16,
    width: 36
  },
  md: {
    height: 28,
    width: 56
  },
  lg: {
    height: 36,
    width: 72
  }
}

const Switcher = ({ desc, size, handleChange, isFeatureEnabled, notEnabledMessage, ...rest }) => {
  const [checked, setChecked] = useState(false)

  const handleSwitcherChange = val => {
    if (!isFeatureEnabled) {
      Notification('error', __(defaultDisabledFeatureMessage), __(notEnabledMessage))
      return false
    }
    setChecked(val)
    handleChange(val)
    return true
  }

  return (
    <div className='Switcher'>
      <Switch
        aria-label={desc}
        checked={checked}
        onChange={handleSwitcherChange}
        onColor='#10ac84'
        height={sizes[size].height}
        width={sizes[size].width}
        {...rest}
      />
    </div>
  )
}

Switcher.propTypes = {
  desc: PropTypes.string,
  isFeatureEnabled: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  size: PropTypes.oneOf(['sm', 'md', 'lg'])
}

Switcher.defaultProps = {
  desc: 'switcher label',
  isFeatureEnabled: true,
  size: 'md',
  notEnabledMessage: ''
}

export default Switcher
