import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props} viewBox='0 0 20.211 16'>
    <g transform='translate(-3.526 -8)'>
      <path
        d='M16.789,17.263h5.053v-4.21h2.526L19.316,8l-5.053,5.053h2.526Z'
        transform='translate(-5.684)'
      />
      <path
        d='M20.368,27.684v3.369H6.895V27.684H3.526v6.737H23.737V27.684Z'
        transform='translate(0 -10.421)'
      />
    </g>
  </Base>
)
