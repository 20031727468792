/* eslint-disable no-console */
import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone-uploader'
import './styles.scss'
import 'react-dropzone-uploader/dist/styles.css'

const FileUploader = ({
  disabled,
  label,
  subLabel,
  multipleFiles,
  maxFiles,
  onWrongFileTypeReject,
  onUploadFail,
  onUploadSuccess,
  onUploadStart,
  onItemRemove,
  onSubmit,
  validFileTypes,
  progress,
  ...rest
}) => {
  const handleChangeStatus = ({ meta }, status, allFiles) => {
    // console.log(status);
    // console.log(meta);
    // console.log(allFiles);
    switch (status) {
      case 'started':
        return onUploadStart(meta)
      case 'removed':
        return onItemRemove(meta)
      case 'rejected_file_type':
        return onWrongFileTypeReject(meta)
      case 'headers_received':
      case 'done':
        return onUploadSuccess(meta, allFiles)
      case 'aborted':
        return onUploadFail(meta)
      default:
        return undefined
    }
  }

  const handleSubmit = (files, allFiles) => {
    // console.log(files.map(f => f.meta));
    onSubmit(get(allFiles, '[0]'))
  }

  const inputContent = () => (
    <div className='InputContent' key={label}>
      <div className='title'>{label}</div>
      <div className='description'>
        {subLabel}
        <span>{validFileTypes}</span>
      </div>
    </div>
  )

  return (
    <div className='FileUploader'>
      {!!progress && (
        <div className='progress-bar'>
          <div className='progress-bar-fill' style={{ width: `${Math.max(0, progress)}%` }} />
        </div>
      )}
      <Dropzone
        disabled={disabled}
        onChangeStatus={handleChangeStatus}
        onSubmit={handleSubmit}
        maxFiles={maxFiles}
        multiple={multipleFiles}
        accept={validFileTypes}
        inputWithFilesContent={null}
        inputContent={inputContent}
        submitButtonDisabled={false}
        {...rest}
      />
    </div>
  )
}

FileUploader.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  subLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  maxFiles: PropTypes.number,
  multipleFiles: PropTypes.bool,
  onItemRemove: PropTypes.func,
  onUploadFail: PropTypes.func,
  onUploadStart: PropTypes.func,
  onUploadSuccess: PropTypes.func,
  onSubmit: PropTypes.func,
  onWrongFileTypeReject: PropTypes.func,
  progress: PropTypes.number,
  validFileTypes: PropTypes.string
}

FileUploader.defaultProps = {
  label: 'Drag files or click to browse',
  subLabel: 'File types:',
  disabled: false,
  maxFiles: 1,
  multipleFiles: false,
  onItemRemove: () => {},
  onUploadFail: () => {},
  onUploadSuccess: () => {},
  onUploadStart: () => {},
  onSubmit: () => {},
  onWrongFileTypeReject: () => {},
  progress: null,
  validFileTypes: '.csv'
}

export default FileUploader
