/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { abbreviateNumber } from 'js-abbreviation-number'
import {
  AreaChart,
  Area,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import api from 'shared/api'
import { Button, ChartTooltip, ContentLoader, NoGraphData, Notification } from 'shared/components'
import { APIdateFormat } from 'shared/constants'
import {
  formatAnalyticsData,
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource
} from 'shared/helpers'
import './styles.scss'

const aggregatedData = [
  {
    action: 'consumption_add',
    ts__date: '2022-05-1',
    total_consumptions: 2,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-2',
    total_consumptions: 7,
    consumptions: 5
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-3',
    total_consumptions: 9,
    consumptions: 2
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-4',
    total_consumptions: 16,
    consumptions: 7
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-5',
    total_consumptions: 30,
    consumptions: 14
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-6',
    total_consumptions: 38,
    consumptions: 8
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-7',
    total_consumptions: 53,
    consumptions: 15
  },
  {
    action: 'consumption_reset',
    ts__date: '2022-05-7',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-8',
    total_consumptions: 1,
    consumptions: 1
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-9',
    total_consumptions: 7,
    consumptions: 6
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-10',
    total_consumptions: 19,
    consumptions: 12
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-11',
    total_consumptions: 60,
    consumptions: 41
  },
  {
    action: 'consumption_reset',
    ts__date: '2022-05-12',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-12',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-13',
    total_consumptions: 4,
    consumptions: 4
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-14',
    total_consumptions: 10,
    consumptions: 6
  },
  {
    action: 'consumption_reset',
    ts__date: '2022-05-15',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-15',
    total_consumptions: 25,
    consumptions: 15
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-16',
    total_consumptions: 30,
    consumptions: 5
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-17',
    total_consumptions: 40,
    consumptions: 10
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-18',
    total_consumptions: 49,
    consumptions: 9
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-19',
    total_consumptions: 50,
    consumptions: 1
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-20',
    total_consumptions: 54,
    consumptions: 4
  },
  {
    action: 'consumption_reset',
    ts__date: '2022-05-21',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-21',
    total_consumptions: 59,
    consumptions: 5
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-22',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-23',
    total_consumptions: 12,
    consumptions: 10
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-24',
    total_consumptions: 21,
    consumptions: 9
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-25',
    total_consumptions: 21,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-26',
    total_consumptions: 35,
    consumptions: 7
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-27',
    total_consumptions: 37,
    consumptions: 2
  },
  {
    action: 'consumption_reset',
    ts__date: '2022-05-27',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-28',
    total_consumptions: 0,
    consumptions: 0
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-29',
    total_consumptions: 4,
    consumptions: 4
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-30',
    total_consumptions: 14,
    consumptions: 10
  },
  {
    action: 'consumption_add',
    ts__date: '2022-05-31',
    total_consumptions: 21,
    consumptions: 7
  }
]

// ts__lt, ts__lte, ts__gt, ts__gte, action__in=consumption_add,consumption_reset, order_by=ts, license__in | feature__in, ts__date__gte
const ConsumptionChart = ({
  chartID,
  companyID,
  dateFrom,
  dateTo,
  endpointDaily,
  endpointHourly,
  endpointExport,
  height,
  label,
  params,
  title,
  subtitle,
  maxConsumption,
  maxOverages,
  type
}) => {
  const [isLoading, setLoading] = useState(false)
  const [isExportLoading, setExportLoading] = useState(false)
  const [data, setData] = useState(aggregatedData)
  const [isDaily, setDaily] = useState(true)

  const getEndpointParams = () => {
    const from = dateFrom.format(APIdateFormat)
    const to = dateTo.format(APIdateFormat)
    const duration = moment.duration(dateTo.diff(dateFrom)).asDays()
    const gteParam = `&ts__date__gte=${from}`
    const lteParam = `&ts__date__lte=${to}`
    const objectParam = type === 'license' ? `&license__in=${chartID}` : `&feature__in=${chartID}`

    return {
      from,
      to,
      duration,
      gteParam,
      lteParam,
      objectParam
    }
  }

  const getData = useCallback(() => {
    const { duration, gteParam, lteParam, objectParam } = getEndpointParams()

    const shouldDisplayDaily = duration > 7
    setLoading(true)
    setData([])
    setDaily(shouldDisplayDaily)

    const endpoint = shouldDisplayDaily ? endpointDaily : endpointHourly

    api
      .get(
        `${endpoint}?company=${companyID}${gteParam}${lteParam}&order_by=ts${objectParam}${params}`
      )
      .then(res => {
        const events = get(res, 'data') || []
        setData(events)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [companyID, dateFrom, dateTo])

  // useEffect(() => {
  //   getData();
  // }, [getData]);

  const handleDataExport = () => {
    const { gteParam, lteParam, objectParam } = getEndpointParams()

    setExportLoading(true)
    api
      .get(
        `${endpointExport}?company=${companyID}${gteParam}${lteParam}&order_by=ts${objectParam}${params}`
      )
      .then(response => {
        const filename = filenameFromHeadersData(response.headers)
        downloadResource(response, filename)
        setExportLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot export data', data)
        Notification('error', __('Export data failed'))
        setExportLoading(false)
      })
  }

  if (isLoading) {
    return (
      <div
        className='ConsumptionChart BaseChart loading'
        style={{ height: `${height}px`, maxHeight: `${height}px` }}
      >
        <ContentLoader text={`${label} ${__('loading')}`} />
      </div>
    )
  }

  if (!data || !data.length) {
    return (
      <div className='ConsumptionChart'>
        <NoGraphData
          maxHeight={height}
          minHeight={height}
          text={__('No data')}
          description={__('Data is refreshed every 24 hours')}
          label={label}
          subtitle={subtitle}
        />
      </div>
    )
  }

  const overageLimit = maxConsumption + maxOverages
  const unifiedDateTimeData = formatAnalyticsData(data, isDaily)

  const formattedData = unifiedDateTimeData.map(fd => {
    const diff = fd.total_consumptions - maxConsumption
    const cons = diff > 0 ? maxConsumption : fd.total_consumptions
    const over = diff > 0 ? diff : 0
    return {
      ...fd,
      cons,
      over
    }
  })

  const consumptionAddItems = formattedData.filter(fd => fd.action === 'consumption_add')
  const showOverageLimit = maxOverages !== null && maxOverages !== 0

  return (
    <div className='ConsumptionChart BaseChart'>
      {title && (
        <>
          <div className='ConsumptionChart-heading BaseChart-heading'>
            <div className='title'>{title}</div>
            {subtitle && <div className='subtitle'>{subtitle}</div>}
          </div>
          <div className='ConsumptionChart-actions'>
            {/* Show all raw consumption intems in a sidepanel */}
            <Button size='sm' onClick={() => {}}>
              {__('View consumption events')}
            </Button>
            <Button
              size='sm'
              onClick={handleDataExport}
              loading={isExportLoading}
              disabled={isExportLoading}
            >
              {__('Export')}
            </Button>
          </div>
        </>
      )}
      <div className='ConsumptionChart-chart'>
        <ResponsiveContainer width='100%' height={height}>
          <AreaChart
            data={consumptionAddItems}
            margin={{
              top: 15,
              right: 20,
              bottom: 5,
              left: -20
            }}
          >
            <defs>
              <linearGradient id={`consumptions-fill-${chartID}`} x1='0' y1='0' x2='0' y2='1'>
                <stop offset='15%' stopColor='#13b0fc' stopOpacity={0.6} />
                <stop offset='95%' stopColor='#13b0fc' stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id={`overages-fill-${chartID}`} x1='0' y1='0' x2='0' y2='1'>
                <stop offset='15%' stopColor='#ee5253' stopOpacity={0.6} />
                <stop offset='95%' stopColor='#ee5253' stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              connectNulls
              name={label}
              type='step'
              fillOpacity={1}
              stackId='a'
              dataKey='cons'
              fill={`url(#consumptions-fill-${chartID})`}
            />
            <Area
              connectNulls
              name={label}
              type='step'
              fillOpacity={1}
              stackId='a'
              dataKey='over'
              fill={`url(#overages-fill-${chartID})`}
            />
            <ReferenceLine y={maxConsumption} stroke='#13b0fc' strokeDasharray='3 3'>
              <Label value={__('Max consumptions')} position='insideTopRight' />
            </ReferenceLine>
            {showOverageLimit && (
              <ReferenceLine y={overageLimit} stroke='#ee5253' strokeDasharray='3 3'>
                <Label value={__('Max overage limit')} position='insideTopRight' />
              </ReferenceLine>
            )}
            {formattedData
              .filter(fd => fd.action === 'consumption_reset')
              .map(fd => (
                <ReferenceLine x={fd.ts} label='' stroke='#419a848a' strokeWidth={5}>
                  <Label value={__('Reset')} position='top' />
                </ReferenceLine>
              ))}
            <YAxis
              stroke='#07364c'
              tickFormatter={v => abbreviateNumber(v, 1)}
              tick={{ fontSize: 10 }}
              // domain={['dataMin', 'dataMax']}
              domain={[0, showOverageLimit ? overageLimit : 'dataMax']}
            />
            <XAxis
              stroke='#07364c'
              dataKey='ts'
              type='number'
              domain={['dataMin', 'dataMax']}
              interval='preserveStartEnd'
              scale='time'
              tickFormatter={ts =>
                isDaily ? moment(ts).format('MM/DD/YYYY') : moment(ts).format('MM/DD/YYYY HH:mm')
              }
              tick={{ fontSize: 10 }}
              // padding={{ left: 10, right: 10 }}
            />
            <Tooltip
              content={<ChartTooltip isDaily={isDaily} tooltipLabel={label} isConsumption />}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

ConsumptionChart.propTypes = {
  companyID: PropTypes.number.isRequired,
  maxConsumption: PropTypes.number.isRequired,
  maxOverages: PropTypes.number.isRequired,
  chartID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  endpointDaily: PropTypes.string,
  endpointHourly: PropTypes.string,
  endpointExport: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  params: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.string
}

ConsumptionChart.defaultProps = {
  endpointDaily: '/api/v1/license-consumption-activities/daily/',
  endpointHourly: '/api/v1/license-consumption-activities/hourly/',
  endpointExport: '/api/v1/license-consumption-activities/export/',
  height: 300,
  label: '',
  params: '',
  title: '',
  subtitle: '',
  type: 'license' // license || feature
}

export default ConsumptionChart
