import React from 'react'
import PropTypes from 'prop-types'

const ErrorIcon = ({ text }) => (
  <div className='ImportStatusCell'>
    <svg width='20px' height='20px' fill='#AE0721' viewBox='0 0 100 100'>
      <path d='M50,18A32,32,0,1,0,82,50,32,32,0,0,0,50,18Zm0,50a4,4,0,1,1,4-4A4,4,0,0,1,50,68Zm4-14H46V32h8Z' />
    </svg>
    {text}
  </div>
)

ErrorIcon.propTypes = {
  text: PropTypes.string.isRequired
}

export default ErrorIcon
