/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import api from 'shared/api'
import { AreaChart, LineChart, ContentLoader, NoGraphData } from 'shared/components'
import { APIdateFormat } from 'shared/constants'
import { formatAnalyticsData } from 'shared/helpers'
import './styles.scss'

const BaseChart = ({
  axisColor,
  color,
  companyID,
  chartID,
  dataKey,
  dateFrom,
  dateTo,
  displayTotal,
  endpoint,
  height,
  label,
  params,
  title,
  subtitle,
  type
}) => {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  const [total, setTotal] = useState(0)
  const [isDaily, setDaily] = useState(false)

  const getData = useCallback(() => {
    const from = dateFrom.format(APIdateFormat)
    const to = dateTo.format(APIdateFormat)
    const duration = moment.duration(dateTo.diff(dateFrom)).asDays()
    setLoading(true)
    setData([])
    setTotal(0)
    setDaily(duration > 7)

    const gteParam = `&ts__date__gte=${from}`
    const lteParam = `&ts__date__lte=${to}`
    const dailyParam = duration > 7 ? '&time=daily' : ''
    // ts__lt, ts__lte, ts__gt, ts__gte, action__in=activation,check, order_by=ts, license__in, product__in, ts__date__gte

    api
      .get(
        `${endpoint}?company=${companyID}${gteParam}${lteParam}&order_by=ts${dailyParam}${params}`
      )
      .then(res => {
        const events = get(res, 'data') || []
        const sum = events.map(e => get(e, `${dataKey}`) || 0).reduce((a, b) => a + b)
        setData(events)
        setTotal(sum)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [companyID, dateFrom, dateTo])

  useEffect(() => {
    getData()
  }, [getData])

  if (isLoading) {
    return (
      <div
        className='BaseChart loading'
        style={{ height: `${height}px`, maxHeight: `${height}px` }}
      >
        <ContentLoader text={`${label} ${__('loading')}`} />
      </div>
    )
  }

  if (!total) {
    return (
      <NoGraphData
        maxHeight={height}
        minHeight={height}
        text={__('No data')}
        description={__('Data is refreshed every 24 hours')}
        label={label}
        subtitle={subtitle}
      />
    )
  }

  const formattedData = formatAnalyticsData(data, isDaily)

  return (
    <div className='BaseChart'>
      {title && (
        <div className='BaseChart-heading'>
          <div className='title'>{`${title}${displayTotal ? `: ${total}` : ''}`}</div>
          {subtitle && <div className='subtitle'>{subtitle}</div>}
        </div>
      )}
      {type === 'area' ? (
        <AreaChart
          axisColor={axisColor}
          color={color}
          data={formattedData}
          dataKey={dataKey}
          height={height}
          isDaily={isDaily}
          label={label}
          chartID={chartID}
        />
      ) : type === 'line' ? (
        <LineChart
          axisColor={axisColor}
          color={color}
          data={formattedData}
          dataKey={dataKey}
          height={height}
          isDaily={isDaily}
          label={label}
          chartID={chartID}
        />
      ) : null}
    </div>
  )
}

BaseChart.propTypes = {
  axisColor: PropTypes.string,
  color: PropTypes.string,
  chartID: PropTypes.string,
  companyID: PropTypes.number.isRequired,
  dataKey: PropTypes.string,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  displayTotal: PropTypes.bool,
  endpoint: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  params: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  type: PropTypes.oneOf(['area', 'line'])
}

BaseChart.defaultProps = {
  axisColor: '#07364c',
  color: '#14B0FC',
  dataKey: 'total_call_count',
  displayTotal: true,
  endpoint: '/api/v1/activities-hourly/total_call_count/',
  height: 300,
  label: '',
  params: '',
  title: '',
  subtitle: '',
  type: 'area',
  chartID: 'chart-id'
}

export default BaseChart
