import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  DescriptionTable,
  IconCheckmark,
  IconDuplicate,
  IconDelete,
  IconDisabled,
  IconEdit,
  List,
  SelectedCustomFieldsTags,
  SelectedFeaturesTags,
  TooltipHeader
} from 'shared/components'
import {
  displayValue,
  formatDate,
  capitalizeFirstLetter,
  displayMaxOveragesValue,
  isFeatureEnabled,
  isFeatureAvailable
} from 'shared/helpers'
import { checkCompanyConfigField } from 'shared/companyConfig'
import { defaultDateFormat, platformFeatures } from 'shared/constants'
import {
  showConsumption,
  showLicenseUsers,
  showValidity,
  showValidityDuration,
  hasSelectedProductFeatures,
  hasSelectedCustomFields
} from './helpers'

const SelectedProductsTable = ({
  companyID,
  deleteProductFromList,
  handleProductEdit,
  handleProductDuplicate,
  selectedProducts,
  showActionBtns
}) => {
  const [tableExpanded, setTableExpanded] = useState({})
  const allowsAirGapped =
    isFeatureEnabled(platformFeatures.extra_air_gapped) &&
    isFeatureAvailable(platformFeatures.extra_air_gapped)

  return (
    <div className='SelectedProductsTable'>
      <List
        data={selectedProducts}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => (
          <div className='SubComponent'>
            <DescriptionTable
              details={[
                // show validity and expiration if time-limited or subscription license
                {
                  label: showValidity(get(row, 'original')) ? __('Validity Period') : null,
                  value: displayValue(showValidityDuration(get(row, 'original')))
                },
                {
                  label: get(row, 'original.validity_period')
                    ? TooltipHeader(__('Expiration Date'))
                    : null,
                  value: formatDate(get(row, 'original.validity_period'), defaultDateFormat)
                },
                {
                  label: get(row, 'original.allow_grace_period') ? __('Grace period') : null,
                  value: `${displayValue(get(row, 'original.grace_period'))} ${__('hours')}`
                },
                // show consumption values
                {
                  label: showConsumption(get(row, 'original')) ? __('Max consumptions') : null,
                  value: get(row, 'original.allow_unlimited_consumptions')
                    ? __('Unlimited')
                    : displayValue(get(row, 'original.max_consumptions'))
                },
                {
                  label: showConsumption(get(row, 'original'))
                    ? __('Allow negative consumptions')
                    : null,
                  value: get(row, 'original.allow_negative_consumptions') ? __('Yes') : __('No')
                },
                {
                  label: showConsumption(get(row, 'original')) ? __('Allow overages') : null,
                  value: get(row, 'original.allow_overages') ? __('Yes') : __('No')
                },
                {
                  label: showConsumption(get(row, 'original')) ? __('Max overages') : null,
                  value: displayMaxOveragesValue(get(row, 'original'))
                },
                {
                  label: showConsumption(get(row, 'original')) ? __('Reset consumption') : null,
                  value: get(row, 'original.reset_consumption') ? __('Yes') : __('No')
                },
                {
                  label: showConsumption(get(row, 'original')) ? __('Consumption period') : null,
                  value: displayValue(
                    capitalizeFirstLetter(get(row, 'original.consumption_period'))
                  )
                },
                // show everything else
                {
                  label:
                    get(row, 'original.enable_maintenance_period') &&
                    !get(row, 'original.maintenance_period')
                      ? __('Maintenance duration')
                      : null,
                  value: displayValue(get(row, 'original.maintenance_duration'))
                },
                {
                  label:
                    get(row, 'original.enable_maintenance_period') &&
                    get(row, 'original.maintenance_period')
                      ? __('Maintenance End Date')
                      : null,
                  value: formatDate(get(row, 'original.maintenance_period'), defaultDateFormat)
                },
                {
                  label:
                    get(row, 'original.authorization_method') === 'user' &&
                    get(row, 'original.license_users')
                      ? __('Users')
                      : null,
                  value: showLicenseUsers(get(row, 'original.license_users_emails'))
                },
                {
                  label: __('Prevent virtual machine'),
                  value: get(row, 'original.prevent_vm') ? __('Yes') : __('No')
                },
                {
                  label: get(row, 'original.is_floating') ? __('Offline floating license') : null,
                  value: get(row, 'original.is_floating') ? __('Yes') : __('No')
                },
                {
                  label: get(row, 'original.is_floating_cloud') ? __('Is floating cloud') : null,
                  value: get(row, 'original.is_floating_cloud') ? __('Yes') : __('No')
                },
                {
                  label:
                    get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud')
                      ? __('Max simultaneous license users')
                      : null,
                  value: displayValue(get(row, 'original.floating_users'))
                },
                {
                  label:
                    get(row, 'original.is_floating') || get(row, 'original.is_floating_cloud')
                      ? __('Floating timeout')
                      : null,
                  value: displayValue(get(row, 'original.floating_timeout'))
                },
                {
                  label:
                    get(row, 'original.max_license_users') ||
                    get(row, 'original.unlimited_max_license_users')
                      ? __('Max license users')
                      : null,
                  value:
                    get(row, 'original.unlimited_max_license_users') ||
                    get(row, 'original.max_license_users') === 0
                      ? __('Unlimited')
                      : displayValue(get(row, 'original.max_license_users'))
                },
                {
                  label:
                    checkCompanyConfigField(companyID, 'productFeatures') &&
                    hasSelectedProductFeatures(get(row, 'original'))
                      ? __('Product features')
                      : null,
                  value: <SelectedFeaturesTags features={get(row, 'original.selected_features')} />
                },
                {
                  label: hasSelectedCustomFields(get(row, 'original')) ? __('Custom fields') : null,
                  value: (
                    <SelectedCustomFieldsTags
                      cFields={get(row, 'original.selected_custom_fields')}
                    />
                  )
                },
                {
                  label:
                    checkCompanyConfigField(companyID, 'isTrial') &&
                    get(row, 'original.allow_trial')
                      ? __('Trial validity')
                      : null,
                  value: displayValue(get(row, 'original.trial_validity'))
                },
                {
                  label: get(row, 'original.note') ? __('Note') : null,
                  value: displayValue(get(row, 'original.note'))
                },
                {
                  label: get(row, 'original.start_date') ? __('Start Date') : null,
                  value: formatDate(get(row, 'original.start_date'), defaultDateFormat)
                },
                {
                  label: get(row, 'original.max_transfers') ? __('Device transfer limit') : null,
                  value:
                    get(row, 'original.max_transfers') === -1
                      ? __('Device transfer not allowed')
                      : displayValue(get(row, 'original.max_transfers'))
                },
                {
                  label: get(row, 'original.can_borrow') ? __('Can borrow') : null,
                  value: get(row, 'original.can_borrow') ? __('Yes') : __('No')
                },
                {
                  label: get(row, 'original.can_borrow') ? __('Max borrow time') : null,
                  value: `${displayValue(get(row, 'original.max_borrow_time'))} ${__('hours')}`
                },
                {
                  label: get(row, 'original.is_hardware_key_auth')
                    ? __('Require hardware key')
                    : null,
                  value: get(row, 'original.is_hardware_key_auth') ? __('Yes') : __('No')
                }
              ]}
            />
          </div>
        )}
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'product_name',
            Header: __('Product'),
            className: 'text-center',
            headerClassName: 'text-center'
          },
          {
            accessor: 'default_license_type',
            Header: __('License Type'),
            className: 'text-center',
            headerClassName: 'text-center'
          },
          {
            accessor: 'max_activations',
            Header: __('Max Activations'),
            className: 'text-center',
            headerClassName: 'text-center',
            show: checkCompanyConfigField(companyID, 'maxActivations'),
            Cell: rowData => {
              const isUnlimited = get(rowData, 'original.allow_unlimited_activations')
              if (isUnlimited) {
                return __('Unlimited')
              }
              return displayValue(rowData.value)
            }
          },
          {
            accessor: 'license_num',
            Header: __('Number of keys / users'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => {
              const isUserAuth = get(rowData, 'original.authorization_method') === 'user'
              if (isUserAuth) {
                const maxUsers = get(rowData, 'original.max_license_users')
                const isUnlimited = get(rowData, 'original.unlimited_max_license_users')
                return isUnlimited || maxUsers === 0 ? __('Unlimited') : displayValue(maxUsers)
              }
              return displayValue(rowData.value)
            }
          },
          {
            accessor: 'allow_trial',
            Header: __('Is trial'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData =>
              cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              ),
            width: 100,
            show: checkCompanyConfigField(companyID, 'isTrial')
          },
          {
            accessor: 'is_air_gapped',
            Header: __('Is air-gapped'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData =>
              cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              ),
            width: 120,
            show: allowsAirGapped
          },
          {
            Header: __('Duplicate'),
            headerClassName: 'text-center',
            className: 'text-center',
            Cell: cellData => (
              <button
                className='edit-button'
                onClick={() => handleProductDuplicate(cellData.original)}
                type='button'
              >
                <IconDuplicate height='20px' width='20px' />
              </button>
            ),
            width: 90,
            show: showActionBtns
          },
          {
            className: 'text-center',
            Cell: cellData => (
              <button
                className='edit-button'
                onClick={() => handleProductEdit(cellData.index)}
                type='button'
              >
                <IconEdit height='20px' width='20px' />
              </button>
            ),
            width: 50,
            show: showActionBtns
          },
          {
            className: 'text-center',
            Cell: cellData => (
              <button
                className='delete-button'
                onClick={() => deleteProductFromList(cellData.index)}
                type='button'
              >
                <IconDelete height='20px' width='20px' color='#ee5253' />
              </button>
            ),
            width: 50,
            show: showActionBtns
          }
        ]}
        showPagination={false}
        minRows={2}
        noDataText={__('Add products to the list')}
      />
    </div>
  )
}

SelectedProductsTable.propTypes = {
  companyID: PropTypes.number.isRequired,
  deleteProductFromList: PropTypes.func,
  handleProductEdit: PropTypes.func,
  handleProductDuplicate: PropTypes.func,
  selectedProducts: PropTypes.array,
  showActionBtns: PropTypes.bool
}

SelectedProductsTable.defaultProps = {
  deleteProductFromList: () => {},
  handleProductDuplicate: () => {},
  handleProductEdit: () => {},
  selectedProducts: [],
  showActionBtns: true
}

export default SelectedProductsTable
