import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Breadcrumbs, PageNotice } from 'shared/components'
import './styles.scss'

const Page = ({
  children,
  description,
  showBreadcrumbs,
  customBreadcrumbs,
  title,
  showPageNotice
}) => {
  const sidebarSize = useSelector(state => get(state, 'account.sidebarSize'))

  return (
    <section className={`Page Sidebar-size-${sidebarSize}`}>
      {showPageNotice && <PageNotice />}
      <header className='Page-header'>
        <div className='Page-top-container'>
          {showBreadcrumbs && <Breadcrumbs />}
          {customBreadcrumbs}
        </div>
        {!!title && <h1 className='Page-title'>{title}</h1>}
        <div className='Page-description'>{!!description && description}</div>
      </header>
      <main className='Page-content'>{children}</main>
    </section>
  )
}

Page.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  customBreadcrumbs: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  description: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  showBreadcrumbs: PropTypes.bool,
  showPageNotice: PropTypes.bool
}

Page.defaultProps = {
  children: null,
  description: undefined,
  showBreadcrumbs: true,
  showPageNotice: true,
  customBreadcrumbs: null,
  title: null
}

export default Page
