import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { BaseChart } from 'shared/components'
import { licenseAPIactions } from 'shared/constants'
import { capitalizeFirstLetter } from 'shared/helpers'
import { AnalyticsDatePicker } from 'src/analytics/components'
import LicenseConsumptionChart from '../LicenseConsumptionChart'
import './styles.scss'

const LicenseAnalytics = React.memo(({ license }) => {
  const defaultFrom = moment().startOf('month')
  const defaultTo = moment().endOf('month')

  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'))
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'))

  const companyID = useSelector(state => get(state, 'company.details.id'))

  const dateFrom = from ? moment(from) : defaultFrom
  const dateTo = to ? moment(to) : defaultTo

  const licenseParam = `&license__in=${get(license, 'id')}`
  const isConsumption = get(license, 'license_type') === 'consumption'
  const maxConsumption = get(license, 'max_consumptions') || 0
  const allowUnlimited = get(license, 'allow_unlimited_consumptions') || false
  const allowOverage = get(license, 'allow_overages')
  const maxOverages = get(license, 'max_overages') || 0
  const isFloatingCloud = get(license, 'is_floating_cloud')
  const resetPeriod = get(license, 'reset_consumption') ? get(license, 'consumption_period') : null

  const consumptionChartMaxConsumptionSubtitle = `${maxConsumption} ${__('max consumptions')}`
  const consumptionChartMaxOveragesSubtitle = allowOverage
    ? ` | ${maxOverages === 0 ? __('Unlimited') : maxOverages} ${__('max overages')}`
    : ''
  const consumptionChartResetsSubtitle = resetPeriod
    ? ` | ${capitalizeFirstLetter(resetPeriod)} ${__('reset')}`
    : ''
  const subtitle = allowUnlimited
    ? `${__('Unlimited consumptions')}${consumptionChartResetsSubtitle}`
    : `${consumptionChartMaxConsumptionSubtitle}${consumptionChartMaxOveragesSubtitle}${consumptionChartResetsSubtitle}`

  return (
    <div className='LicenseAnalytics'>
      <AnalyticsDatePicker />
      {isConsumption && (
        <LicenseConsumptionChart
          companyID={companyID}
          dateFrom={dateFrom}
          dateTo={dateTo}
          title={__('Consumption activity')}
          subtitle={subtitle}
          license={license}
        />
      )}
      <BaseChart
        companyID={companyID}
        dateFrom={dateFrom}
        dateTo={dateTo}
        chartID='license_check'
        label={__(get(licenseAPIactions, 'check.label'))}
        title={__(get(licenseAPIactions, 'check.label'))}
        subtitle={get(licenseAPIactions, 'check.endpoint')}
        displayTotal
        params={`&action__in=check${licenseParam}`}
        type='line'
      />
      <div className='individual-actions-charts'>
        {isConsumption && (
          <>
            <div className='individual-chart'>
              <BaseChart
                companyID={companyID}
                dateFrom={dateFrom}
                dateTo={dateTo}
                chartID='license_consumption'
                label={__(get(licenseAPIactions, 'consumption.label'))}
                title={__(get(licenseAPIactions, 'consumption.label'))}
                subtitle={get(licenseAPIactions, 'consumption.endpoint')}
                displayTotal
                params={`&action__in=consumption${licenseParam}`}
                height={200}
                type='line'
              />
            </div>
            <div className='individual-chart'>
              <BaseChart
                companyID={companyID}
                dateFrom={dateFrom}
                dateTo={dateTo}
                chartID='license_f_consumption'
                label={__(get(licenseAPIactions, 'f_consumption.label'))}
                title={__(get(licenseAPIactions, 'f_consumption.label'))}
                subtitle={get(licenseAPIactions, 'f_consumption.endpoint')}
                displayTotal
                params={`&action__in=f_consumption${licenseParam}`}
                height={200}
                type='line'
              />
            </div>
          </>
        )}
        {isFloatingCloud && (
          <div className='individual-chart'>
            <BaseChart
              companyID={companyID}
              dateFrom={dateFrom}
              dateTo={dateTo}
              chartID='floating_release'
              label={__(get(licenseAPIactions, 'floating_release.label'))}
              title={__(get(licenseAPIactions, 'floating_release.label'))}
              subtitle={get(licenseAPIactions, 'floating_release.endpoint')}
              displayTotal
              params={`&action__in=floating_release${licenseParam}`}
              height={200}
              type='line'
            />
          </div>
        )}
        <div className='individual-chart'>
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID='track_device_variables'
            label={__(get(licenseAPIactions, 'track_device_variables.label'))}
            title={__(get(licenseAPIactions, 'track_device_variables.label'))}
            subtitle={get(licenseAPIactions, 'track_device_variables.endpoint')}
            displayTotal
            params={`&action__in=track_device_variables${licenseParam}`}
            height={200}
            type='line'
          />
        </div>
        <div className='individual-chart'>
          <BaseChart
            companyID={companyID}
            dateFrom={dateFrom}
            dateTo={dateTo}
            chartID='get_device_variables'
            label={__(get(licenseAPIactions, 'get_device_variables.label'))}
            title={__(get(licenseAPIactions, 'get_device_variables.label'))}
            subtitle={get(licenseAPIactions, 'get_device_variables.endpoint')}
            displayTotal
            params={`&action__in=get_device_variables${licenseParam}`}
            height={200}
            type='line'
          />
        </div>
      </div>
    </div>
  )
})

LicenseAnalytics.propTypes = {
  license: PropTypes.object.isRequired
}

export default LicenseAnalytics
