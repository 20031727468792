import { displayFormat } from './config'

export const displayDate = (date, initialDate, format = displayFormat) => {
  if (date) {
    return date.format(format)
  }
  if (initialDate) {
    return initialDate.format(format)
  }
  return ''
}

export const calculateNewFormat = (startDate, endDate, format = displayFormat) => {
  const isSameYear = startDate && endDate && startDate.year() === endDate.year()
  const formatDate = format.replace(/Y/g, '').trim()
  return isSameYear ? formatDate : format
}
