import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Button, Subtitle } from 'shared/components'
import icon from '../sunk-ship.svg'
import './styles.scss'

const NotFound = ({ history }) => (
  <div className='NotFound-container'>
    <img src={icon} alt='Not found' height='100px' />
    <Subtitle text={__('Page not found')} />
    <p>{__("We can't seem to find the page you're looking for.")}</p>
    <Button
      onClick={() => {
        history.push('/')
      }}
      theme='info'
    >
      {__('Go to homepage')}
    </Button>
  </div>
)

NotFound.propTypes = {
  history: PropTypes.object.isRequired
}

export default withRouter(NotFound)
