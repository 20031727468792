import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props}>
    <polygon points='33.27 18.66 14.69 7.93 6.42 12.71 25 23.43 33.27 18.66' />
    <polygon points='4.75 37.06 23.73 48.02 23.73 25.63 4.75 14.67 4.75 37.06' />
    <polygon points='43.58 12.71 25 1.98 17.22 6.47 35.76 17.17 35.71 17.25 43.58 12.71' />
    <path d='M26.27,48l19-11V14.67l-19,11ZM32.59,33l7.6-4.39v5.07L32.59,38Z' />
  </Base>
)
