import React from 'react'
import PropTypes from 'prop-types'
import { capitalizeFirstLetter } from 'shared/helpers'
import './styles.scss'

const EventStatus = ({ status, text }) => (
  <div className={`EventStatus ${status}`}>{capitalizeFirstLetter(text || status)}</div>
)

EventStatus.propTypes = {
  status: PropTypes.string.isRequired,
  text: PropTypes.string
}

EventStatus.defaultProps = {
  text: ''
}

export default EventStatus
