import { get } from 'lodash'

export const getReaminingFeatures = (product, licenseFeatures) => {
  const productFeatures = get(product, 'product_features') || []
  const remaining = []

  if (Array.isArray(productFeatures) && Array.isArray(licenseFeatures)) {
    productFeatures.forEach(pf => {
      const usedFeature = licenseFeatures.filter(
        lf => get(lf, 'product_feature.id') === get(pf, 'id')
      )
      if (!usedFeature.length) {
        remaining.push(pf)
      }
    })
  }
  return remaining
}
