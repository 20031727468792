import { get, sortBy } from 'lodash'

export const formatCustomFieldsToTable = (cFields = []) => {
  if (!Array.isArray(cFields)) {
    return []
  }
  const mapedCustomFields = cFields.map(cf => {
    const newF = {
      ...cf,
      name: get(cf, 'label') || get(cf, 'data.name'),
      cf_value_error: ''
    }
    return newF
  })
  const sortedArr = sortBy(mapedCustomFields, 'value')
  return sortedArr
}
