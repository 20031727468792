import React from 'react'
import PropTypes from 'prop-types'
import { IconClipboard, Notification } from 'shared/components'
import { copyText } from 'shared/helpers'
import './styles.scss'

const HiddenPasswordField = ({ value, fallback }) => {
  const handleFieldCopy = () => {
    copyText(value)
    Notification('success', __('Password copied to clipboard'))
  }

  if (!value) {
    return fallback
  }

  return (
    <div className='HiddenPasswordField'>
      <span className='hidden-pass'>********</span>
      <button type='button' className='clipboard-btn' onClick={handleFieldCopy}>
        <IconClipboard width='17.5' height='16' viewBox='0 0 51.5 50' />
      </button>
    </div>
  )
}

HiddenPasswordField.propTypes = {
  fallback: PropTypes.string,
  value: PropTypes.string
}

HiddenPasswordField.defaultProps = {
  fallback: 'N/A',
  value: null
}

export default HiddenPasswordField
