import React from 'react'
import PropTypes from 'prop-types'
import { IconInfo } from 'shared/components'
import './styles.scss'

const CustomFormTab = ({ error, text }) => (
  <div className={`CustomFormTab ${error ? 'error' : ''}`}>
    <span>{text}</span>
    {error && <IconInfo color='#e91a1c' height='14px' width='14px' />}
  </div>
)

CustomFormTab.propTypes = {
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  text: PropTypes.string
}

CustomFormTab.defaultProps = {
  error: '',
  text: ''
}

export default CustomFormTab
