import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  Checkbox,
  Selector
} from 'shared/components'
import { errorMsg } from 'shared/constants'
import { sendErrorReport, mapPermissionTemplatesToSelector } from 'shared/helpers'
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateEmail,
  debouncedValidateEmail
} from 'shared/validation'
import { createUser, getUsers } from 'src/account/actions'
import { setUserPermissionsFromTemplate } from 'src/company/actions'

const AddUserForm = ({ closeCb }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const userPermissionsTemplates = useSelector(state =>
    get(state, 'company.userPermissionsTemplates')
  )
  const templatesOptions = mapPermissionTemplatesToSelector(userPermissionsTemplates)

  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false)
  const [firstname, setFirstname] = useState('')
  const [firstnameError, setFirstnameError] = useState('')
  const [lastname, setLastname] = useState('')
  const [lastnameError, setLastnameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [isAdmin, setIsAdmin] = useState(false)
  const [selectedTemplate, setSelectedTemplate] = useState(templatesOptions[0])

  const validateValue = async (val, cb) => {
    let errors
    try {
      errors = await validateRequiredValue(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new user value', { value: val })
    }
    if (errors) {
      return false
    }
    return true
  }

  const handleEmailValidation = async () => {
    setLoading(true)
    let errors
    try {
      errors = await validateEmail(email)
      setEmailError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new user email', { value: email })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isEmailValid = await handleEmailValidation()
    const isFirstNameValid = await validateValue(firstname, setFirstnameError)
    const isLastNameValid = await validateValue(lastname, setLastnameError)
    return isEmailValid && isFirstNameValid && isLastNameValid
  }

  const handleSucces = userID => {
    dispatch(getUsers(companyID))
    closeCb()
    Notification('success', __('User created'))
    history.push(`/${companyID}/account/users/${userID}`)
  }

  const handlePermissionsFromTemplate = async userID => {
    setLoading(true)
    const selectedTemplateID = get(selectedTemplate, 'value')
    const data = { template_id: selectedTemplateID }

    setUserPermissionsFromTemplate(userID, companyID, data)
      .then(() => {
        handleSucces(userID)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setLoading(true)
    const userData = {
      first_name: firstname,
      last_name: lastname,
      email,
      is_admin: isAdmin
    }

    createUser(userData, companyID)
      .then(res => {
        const userID = get(res, 'data.id')
        if (selectedTemplate && !isAdmin) {
          handlePermissionsFromTemplate(userID)
        } else {
          handleSucces(userID)
        }
      })
      .catch(err => {
        if (get(err, 'response.data[0]') === errorMsg.userDuplicate) {
          Notification('error', __('User is already a member of this company.'))
          closeCb()
        } else {
          sendErrorReport(err, 'Cannot create new user', userData)
          Notification('error', __('User creation failed'))
          setLoading(false)
        }
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Add new User')}
      disabled={isLoading}
      size='sm'
    >
      <div className='row input-cont'>
        <Label inputId='user-firstname' text={__('First name')} />
        <TextInput
          disabled={isLoading}
          id='user-firstname'
          type='text'
          value={firstname}
          error={firstnameError}
          handleChange={val => {
            setDirty(true)
            setFirstname(val)
            debouncedValidateRequiredValue(val).then(err => setFirstnameError(err))
          }}
        />
      </div>
      <div className='row input-cont'>
        <Label inputId='user-lastname' text={__('Last name')} />
        <TextInput
          disabled={isLoading}
          id='user-lastname'
          type='text'
          value={lastname}
          error={lastnameError}
          handleChange={val => {
            setDirty(true)
            setLastname(val)
            debouncedValidateRequiredValue(val).then(err => setLastnameError(err))
          }}
        />
      </div>
      <div>
        <Label text={__('Email')} inputId='user-email-input' />
        <TextInput
          id='user-email-input'
          type='email'
          value={email}
          error={emailError}
          handleChange={val => {
            setDirty(true)
            setEmail(val)
            debouncedValidateEmail(val).then(err => setEmailError(err))
          }}
        />
      </div>
      <div>
        <Label text={__('User Type')} inputId='is-admin-checkbox' />
        <Checkbox
          label={__('Is admin')}
          checked={isAdmin}
          inputId='is-admin-checkbox'
          handleChange={val => {
            setDirty(true)
            setIsAdmin(val)
          }}
        />
      </div>
      <div className='SetUserPermissionTemplate' style={{ maxWidth: '300px' }}>
        <Label inputId='template-select' text={__('Set permissions from template')} />
        <Selector
          options={templatesOptions}
          isDisabled={isLoading || !templatesOptions.length || isAdmin}
          value={get(selectedTemplate, 'value')}
          id='template-select'
          handleChange={val => {
            const selected = templatesOptions.find(option => option.value === val)
            setDirty(true)
            setSelectedTemplate(selected)
          }}
        />
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

AddUserForm.propTypes = {
  closeCb: PropTypes.func.isRequired
}

export default AddUserForm
