import { searchType } from 'shared/constants'

export const searchProductsByName = (productsList, query) => {
  const searchedProducts = productsList.filter(ap => {
    const { product_name: productName } = ap
    if (!productName) {
      return false
    }
    return productName.toLowerCase().indexOf(query.toLowerCase()) !== -1
  })

  return searchedProducts
}

export const searchProductsByCode = (productsList, query) => {
  const searchedProducts = productsList.filter(ap => {
    const { short_code: shortCode } = ap
    if (!shortCode) {
      return false
    }
    return shortCode.toLowerCase().indexOf(query.toLowerCase()) !== -1
  })

  return searchedProducts
}

export const searchProducts = (products, searchBy, query) => {
  if (!products || !Array.isArray(products)) {
    return []
  }
  if (!query) {
    return products
  }
  if (searchBy === searchType.productName) {
    return searchProductsByName(products, query)
  }
  if (searchBy === searchType.productCode) {
    return searchProductsByCode(products, query)
  }
  return products
}
