import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props} viewBox='0 0 24 24' strokeWidth='2'>
    <circle cx='12' cy='12' r='1' />
    <circle cx='12' cy='5' r='1' />
    <circle cx='12' cy='19' r='1' />
  </Base>
)
