import React from 'react'
import { NavLink, Route, Switch } from 'react-router-dom'
import { IconUser, IconUserEdit, IconSecurity, Sidebar } from 'shared/components'
import EditProfileContainer from '../containers/EditProfileContainer'
import Welcome from '../containers/Welcome'
import SecurityContainer from '../containers/SecurityContainer'
import './styles.scss'

const ProfileContainer = () => (
  <div className='ProfileContainer'>
    <Sidebar>
      <div className='CompanySidebar'>
        <NavLink exact className='NavLink ProfileContainer-link' to='/profile'>
          <header>
            <div className='NavLink-icon'>
              <IconUser height='20px' width='20px' color='#777' />
            </div>
            <div className='NavLink-text'>{__('My profile')}</div>
          </header>
        </NavLink>
        <NavLink className='NavLink ProfileContainer-link' to='/profile/edit'>
          <header>
            <div className='NavLink-icon'>
              <IconUserEdit viewBox='0 0 100 105' height='20px' width='20px' color='#777' />
            </div>
            <div className='NavLink-text'>{__('Edit profile')}</div>
          </header>
        </NavLink>
        <NavLink className='NavLink ProfileContainer-link' to='/profile/security'>
          <header>
            <div className='NavLink-icon'>
              <IconSecurity viewBox='0 0 95 110' height='20px' width='20px' color='#777' />
            </div>
            <div className='NavLink-text'>{__('Security')}</div>
          </header>
        </NavLink>
      </div>
    </Sidebar>
    <Switch>
      <Route exact path='/profile' component={Welcome} />
      <Route path='/profile/edit' component={EditProfileContainer} />
      <Route path='/profile/security' component={SecurityContainer} />
    </Switch>
  </div>
)

export default ProfileContainer
