/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import AsyncSelect from 'react-select/lib/Async'
import { sendErrorReport } from 'shared/helpers'
import { planTypes } from 'shared/constants'
import { getUserCompanies } from 'src/account/actions'
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  InputErrorMessage,
  Modal,
  Notification,
  NumberInput,
  Selector,
  TextInput
} from 'shared/components'
import {
  validateRequiredValue,
  debouncedValidateRequiredValue,
  validateRequiredNumber,
  debouncedValidateRequiredNumber,
  validateEmail,
  debouncedValidateEmail,
  validateCompanyName,
  debouncedValidateCompanyName,
  validateCompanyCode,
  debouncedValidateCompanyCode
} from 'shared/validation'
import { registerNewCompany } from 'src/company/actions'
import api from 'shared/api'
import './styles.scss'

const NewCompanyForm = ({ closeCb, companies }) => {
  const history = useHistory()
  const dispatch = useDispatch()

  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplayed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [planType, setPlanType] = useState('business_starter')
  const [firstname, setFirstname] = useState('')
  const [firstnameError, setFirstnameError] = useState('')
  const [lastname, setLastname] = useState('')
  const [lastnameError, setLastnameError] = useState('')
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [companyName, setCompanyName] = useState('')
  const [companyNameError, setCompanyNameError] = useState('')
  const [companyCode, setCompanyCode] = useState('')
  const [companyCodeError, setCompanyCodeError] = useState('')
  const [companyCodeLoading, setCompanyCodeLoading] = useState(false)
  const [trialDays, setTrialDays] = useState(15)
  const [trialDaysError, setTrialDaysError] = useState('')
  const [isDev, setIsDev] = useState(false)
  const [isLinked, setIsLinked] = useState(false)
  const [selectedLinkedCompany, setSelectedLinkedCompany] = useState('')
  const [selectedLinkedCompanyError, setSelectedLinkedCompanyError] = useState('')
  const [initialCompanies, setInitialCompanies] = useState([])

  // Fetch initial companies for dropdown
  useEffect(() => {
    const fetchInitialCompanies = async () => {
      try {
        const response = await api.get('/api/v1/companies/?limit=10&is_active=1')
        const fetchedCompanies = get(response, 'data.results', [])
          .filter(c => !c.main)
          .map(c => ({ value: c.id, label: c.name, data: c }))
        setInitialCompanies(fetchedCompanies)
      } catch (err) {
        console.error('Error fetching initial companies:', err)
        sendErrorReport(err, 'Cannot fetch initial companies for dropdown')
      }
    }

    fetchInitialCompanies()
  }, [])

  // Async load options for the companies dropdown
  const loadCompanyOptions = async inputValue => {
    try {
      const response = await api.get(
        `/api/v1/companies/?name__icontains=${encodeURIComponent(inputValue)}&limit=10&is_active=1`
      )
      return get(response, 'data.results', [])
        .filter(c => !c.main)
        .map(c => ({ value: c.id, label: c.name, data: c }))
    } catch (err) {
      console.error('Error searching companies:', err)
      sendErrorReport(err, 'Cannot fetch companies for dropdown search')
      return []
    }
  }

  const validateValue = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredValue(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate form value', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateCompanyEmail = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateEmail(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new company email', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateNewCompanyName = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateCompanyName(val, companies)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new company name', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateTrialDays = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredNumber(val, true)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new company trial days', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateNewCompanyCode = async (val, cb) => {
    setLoading(true)
    setCompanyCodeLoading(true)
    let errors
    try {
      errors = await validateCompanyCode(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new company code', { value: val })
    }
    setLoading(false)
    setCompanyCodeLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateIsLinked = () => {
    if (!isLinked) return true
    if (!selectedLinkedCompany) {
      setSelectedLinkedCompanyError(__('Select from dropdown'))
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isFirstNameValid = await validateValue(firstname, setFirstnameError)
    const isLastNameValid = await validateValue(lastname, setLastnameError)
    const isEmailValid = await validateCompanyEmail(email, setEmailError)
    const isCompanyNameValid = await validateNewCompanyName(companyName, setCompanyNameError)
    const isCompanyCodeValid = await validateNewCompanyCode(companyCode, setCompanyCodeError)
    const areTrialDaysValid = await validateTrialDays(trialDays, setTrialDaysError)
    const isLinkedValid = validateIsLinked()
    return (
      !!isCompanyNameValid &&
      !!isCompanyCodeValid &&
      isEmailValid &&
      isLastNameValid &&
      isFirstNameValid &&
      !!areTrialDaysValid &&
      isLinkedValid
    )
  }

  const submitForm = async () => {
    const isValid = await isFormValid()
    if (!isValid || loading) {
      return false
    }

    setLoading(true)
    const trial = planType !== 'free' ? Number(trialDays) : undefined

    const data = {
      user_first_name: firstname,
      user_last_name: lastname,
      user_email: email,
      company_name: companyName,
      company_code: companyCode,
      sub_plan_type: planType,
      sub_trial_days: trial,
      company_is_dev: isDev,
      company_main: isLinked ? get(selectedLinkedCompany, 'value') : undefined
    }

    registerNewCompany(data)
      .then(res => {
        Notification('success', __('Changes saved successfully'), __('New company created'))
        dispatch(getUserCompanies())
        history.push(`/${res.data.id}`)
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot add new company', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplayed(true)
  }

  // Styling for the AsyncSelect component
  const customStyles = {
    option: provided => ({
      ...provided,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      outline: 'none'
    }),
    control: () => ({
      display: 'flex',
      height: '38px',
      width: '100%',
      maxWidth: '300px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      outline: 'none'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.8 : 1,
      transition: 'opacity 300ms',
      cursor: 'pointer',
      outline: 'none'
    })
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={submitForm}
      title={__('Add new company')}
      disabled={loading}
    >
      <div className='NewCompanyForm'>
        <div className='form-inner'>
          <div className='left'>
            <div>
              <Label
                text={__('Billing account first name')}
                inputId='company-billing-firstname-input'
              />
              <TextInput
                id='company-billing-firstname-input'
                handleChange={val => {
                  setDirty(true)
                  setFirstname(val)
                  debouncedValidateRequiredValue(val).then(err => setFirstnameError(err))
                }}
                value={firstname}
                error={firstnameError}
                disabled={loading}
              />
            </div>
            <div>
              <Label
                text={__('Billing account last name')}
                inputId='company-billing-lastname-input'
              />
              <TextInput
                id='company-billing-lastname-input'
                handleChange={val => {
                  setDirty(true)
                  setLastname(val)
                  debouncedValidateRequiredValue(val).then(err => setLastnameError(err))
                }}
                value={lastname}
                error={lastnameError}
                disabled={loading}
              />
            </div>
            <div>
              <Label text={__('Billing account email')} inputId='company-billing-email-input' />
              <TextInput
                id='company-billing-email-input'
                handleChange={val => {
                  setDirty(true)
                  setEmail(val)
                  debouncedValidateEmail(val).then(err => setEmailError(err))
                }}
                type='email'
                value={email}
                error={emailError}
                disabled={loading}
              />
            </div>
          </div>
          <div className='divider active' />
          <div className='right'>
            <div>
              <Label text={__('Company name')} inputId='company-name-input' />
              <TextInput
                id='company-name-input'
                handleChange={val => {
                  setDirty(true)
                  setCompanyName(val)
                  debouncedValidateCompanyName(val, companies).then(err => setCompanyNameError(err))
                }}
                value={companyName}
                error={companyNameError}
                disabled={loading}
              />
            </div>
            <div>
              <Label text={__('Company code')} inputId='company-code-input' />
              <TextInput
                id='company-code-input'
                handleChange={val => {
                  setDirty(true)
                  setCompanyCode(val)
                  setCompanyCodeLoading(true)
                  debouncedValidateCompanyCode(val).then(err => {
                    setCompanyCodeError(err)
                    setCompanyCodeLoading(false)
                  })
                }}
                value={companyCode}
                error={companyCodeError}
                loading={companyCodeLoading}
                disabled={loading}
              />
            </div>
            <div>
              <Label text={__('Plan type')} inputId='company-plantype-input' />
              <Selector
                getOptionLabel={option => `${option.label} - ${option.pricing}`}
                handleChange={val => {
                  setDirty(true)
                  setPlanType(val)
                  setIsDev(false)
                }}
                maxWidth='300px'
                options={planTypes}
                value={planType}
                disabled={loading}
              />
            </div>
            {planType !== 'free' && (
              <div>
                <Label text={__('Trial days')} inputId='trial-days-input' />
                <NumberInput
                  id='trial-days-input'
                  value={trialDays}
                  error={trialDaysError}
                  min='1'
                  max='10000'
                  handleChange={val => {
                    setDirty(true)
                    setTrialDays(val)
                    debouncedValidateRequiredNumber(val, true).then(err => setTrialDaysError(err))
                  }}
                  disabled={isDev || loading}
                />
              </div>
            )}
            <div className='dev-checkbox'>
              <Checkbox
                label={__('DEV company')}
                description={__(
                  'This company will be used for development and testing purposes only'
                )}
                handleChange={val => {
                  setDirty(true)
                  setIsDev(val)
                }}
                checked={isDev}
                inputId='is-dev-checkbox'
                disabled={loading}
              />
            </div>
            <div className='linked-acc'>
              <Checkbox
                label={__('Is this a linked account?')}
                handleChange={val => {
                  setDirty(true)
                  setIsLinked(val)
                  setSelectedLinkedCompanyError('')
                }}
                checked={isLinked}
                inputId='is-linked-acc'
                disabled={loading}
              />
              {isLinked && (
                <div>
                  <Label text={__('Select main company account')} inputId='main-company-select' />
                  <div className='company-selector'>
                    <AsyncSelect
                      cacheOptions
                      defaultOptions={initialCompanies}
                      loadOptions={loadCompanyOptions}
                      getOptionLabel={o => o.label}
                      getOptionValue={o => o.value}
                      value={selectedLinkedCompany}
                      className='company-selector'
                      classNamePrefix='company-selector-inner'
                      isDisabled={loading}
                      styles={customStyles}
                      onChange={selected => {
                        setDirty(true)
                        setSelectedLinkedCompany(selected)
                        setSelectedLinkedCompanyError('')
                      }}
                      placeholder={__('Select company')}
                      noOptionsMessage={({ inputValue }) =>
                        inputValue ? __('No companies found') : __('Type to search companies')
                      }
                    />
                  </div>
                  {selectedLinkedCompanyError && (
                    <InputErrorMessage text={selectedLinkedCompanyError} />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplayed(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

NewCompanyForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companies: PropTypes.array
}

NewCompanyForm.defaultProps = {
  companies: []
}

export default NewCompanyForm
