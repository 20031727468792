import React, { useState, useEffect, useCallback, useRef } from 'react'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import { Button, IconCheckmark, IconDisabled, IconSearch, List, TextInput } from 'shared/components'
import {
  displayValue,
  formatDate,
  formatAmountValue,
  capitalizeFirstLetter,
  mapCurrencySymbol,
  getUpcomingInvoiceDate,
  isStaffUser,
  sendErrorReport
} from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import api from 'shared/api'
import NewCompanyForm from '../NewCompanyForm'
import GenerateReportForm from '../GenerateReportForm'

const ActiveCompaniesList = () => {
  const history = useHistory()
  const isStaff = isStaffUser()

  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [showForm, setFormDisplay] = useState(false)
  const [isReportFormDisplayed, setReportFormDisplay] = useState(false)
  const [totalCount, setTotalCount] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const [sortBy, setSortBy] = useState('name')
  const [sortDesc, setSortDesc] = useState(false)

  const fetchIdRef = useRef(0)
  const mounted = useRef(false)

  const getOrderByParam = useCallback((id, desc) => {
    const fieldMap = {
      name: 'name',
      plan_type: 'plan_type',
      subscription_cycle: 'subscription_cycle',
      last_login: 'last_login',
      last_license: 'last_license',
      created_at: 'created_at'
    }

    const field = fieldMap[id] || 'name'

    return desc ? `-${field}` : field
  }, [])

  const fetchCompanies = useCallback(
    async (page = 0, limit = 10) => {
      setLoading(true)

      const fetchId = ++fetchIdRef.current

      try {
        let endpoint = `/api/v1/companies/?limit=${limit}&offset=${page * limit}&is_active=1`

        if (query) {
          endpoint += `&name__icontains=${encodeURIComponent(query)}`
        }

        const orderBy = getOrderByParam(sortBy, sortDesc)
        endpoint += `&order_by=${orderBy}`

        const response = await api.get(endpoint)

        if (fetchId === fetchIdRef.current && mounted.current) {
          setCompanies(get(response, 'data.results', []))
          setTotalCount(get(response, 'data.count', 0))
          setLoading(false)
        }
      } catch (err) {
        if (fetchId === fetchIdRef.current && mounted.current) {
          sendErrorReport(err, 'Failed to fetch companies')
          setLoading(false)
        }
      }
    },
    [query, sortBy, sortDesc, getOrderByParam]
  )

  useEffect(() => {
    mounted.current = true
    fetchCompanies(currentPage, pageSize)

    return () => {
      mounted.current = false
    }
  }, [fetchCompanies, currentPage, pageSize])

  const handleSearch = e => {
    e.preventDefault()
    setCurrentPage(0)
    fetchCompanies(0, pageSize)
  }

  const handlePageChange = pageIndex => {
    setCurrentPage(pageIndex)
    fetchCompanies(pageIndex, pageSize)
  }

  const handlePageSizeChange = (newPageSize, pageIndex) => {
    setPageSize(newPageSize)
    setCurrentPage(pageIndex)
    fetchCompanies(pageIndex, newPageSize)
  }

  const handleSortChange = newSorted => {
    if (newSorted && newSorted.length > 0) {
      const { id, desc } = newSorted[0]
      setSortBy(id)
      setSortDesc(desc)
      setCurrentPage(0)
      fetchCompanies(0, pageSize)
    }
  }

  const clearSearch = () => {
    setQuery('')
    setCurrentPage(0)
    fetchCompanies(0, pageSize)
  }

  return (
    <div className='ActiveCompaniesList'>
      <div className='list-header'>
        <div>
          <form onSubmit={handleSearch}>
            <TextInput
              handleChange={val => setQuery(val)}
              value={query}
              placeholder={__('Search companies')}
            />
            {query && (
              <button type='button' onClick={clearSearch}>
                &times;
              </button>
            )}
            <Button type='submit' theme='info' disabled={loading}>
              <IconSearch fill='#fff' />
            </Button>
          </form>
        </div>
        {isStaff && (
          <div>
            <Button onClick={() => setReportFormDisplay(true)} theme='default'>
              {__('Generate report')}
            </Button>
            <Button onClick={() => setFormDisplay(!showForm)} theme='info'>
              {__('New company')}
            </Button>
          </div>
        )}
      </div>
      <List
        clickable
        loading={loading}
        data={companies}
        pages={Math.ceil(totalCount / pageSize)}
        onPageChange={handlePageChange}
        onPageSizeChange={handlePageSizeChange}
        onSortedChange={handleSortChange}
        page={currentPage}
        minRows={pageSize > 10 ? 10 : 1}
        manual
        showPageJump={false}
        defaultPageSize={pageSize}
        defaultSorted={[{ id: sortBy, desc: sortDesc }]}
        handleClick={rowData => history.push(`/${rowData.original.id}`)}
        columns={[
          {
            accessor: 'name',
            Header: __('Company name'),
            Cell: cellInfo => displayValue(cellInfo.value),
            sortable: true
          },
          {
            accessor: 'plan_type',
            Header: __('Plan type'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100,
            sortable: true
          },
          {
            accessor: 'has_payment_method',
            Header: __('Payment method'),
            Cell: cellData => {
              const isBankTransfer = get(cellData, 'original.is_payment_manual')
              if (isBankTransfer) {
                return __('Bank transfer')
              }
              return cellData.value ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              )
            },
            show: isStaff,
            sortable: false
          },
          {
            accessor: 'subscription_cycle',
            Header: __('Billing cycle'),
            Cell: cellInfo => displayValue(capitalizeFirstLetter(cellInfo.value)),
            show: isStaff,
            sortable: true
          },
          {
            accessor: 'upcoming_invoice.next_payment_attempt',
            Header: __('Next invoice date'),
            Cell: cellData => {
              const date = getUpcomingInvoiceDate(get(cellData, 'original'))
              if (!date) {
                return '-'
              }
              return formatDate(date, defaultDateFormat)
            },
            show: isStaff,
            sortable: false
          },
          {
            accessor: 'upcoming_invoice.total',
            Header: __('Amount'),
            Cell: cellData => {
              const currency = get(cellData, 'original.upcoming_invoice.currency')
              if (!cellData.value) {
                return '-'
              }
              return `${formatAmountValue(cellData.value)} ${mapCurrencySymbol(currency)}`
            },
            maxWidth: 100,
            show: isStaff,
            sortable: false
          },
          {
            accessor: 'last_login',
            Header: __('Last login'),
            Cell: cellInfo => formatDate(cellInfo.value),
            show: isStaff,
            sortable: true
          },
          {
            accessor: 'product_count',
            Header: __('Products'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100,
            sortable: false
          },
          {
            accessor: 'license_count',
            Header: __('Licenses'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100,
            sortable: false
          },
          {
            accessor: 'last_license',
            Header: __('Last license created'),
            Cell: cellInfo => formatDate(cellInfo.value),
            sortable: true
          },
          {
            accessor: 'created_at',
            Header: __('Created at'),
            Cell: cellInfo => formatDate(cellInfo.value),
            sortable: true
          }
        ]}
      />
      {showForm && (
        <NewCompanyForm
          closeCb={() => setFormDisplay(false)}
          history={history}
          companies={companies}
          onCompanyCreated={() => fetchCompanies(currentPage, pageSize)}
        />
      )}
      {isReportFormDisplayed && <GenerateReportForm closeCb={() => setReportFormDisplay(false)} />}
    </div>
  )
}

export default ActiveCompaniesList
