import React from 'react'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import './styles.scss'

const Notification = (type, title, body) => {
  toast(
    <div className='Notification'>
      <div className='Notification-title'>{title}</div>
      <div className='Notification-body'>{body}</div>
    </div>,
    {
      type
    }
  )
}

Notification.propTypes = {
  body: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  type: PropTypes.oneOf(['default', 'success', 'warning', 'info', 'error'])
}

Notification.defaultProps = {
  body: null,
  type: 'info',
  title: ''
}

export default Notification
