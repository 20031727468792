import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import MissingPolicyAlert from './components/MissingPolicyAlert'
import EmailConfiguration from './components/EmailConfiguration'
import './styles.scss'

const Emails = ({ setDisableTabsChange }) => {
  const notificationPolicies = useSelector(state =>
    get(state, 'notifications.notification_policies')
  )

  if (!notificationPolicies || !notificationPolicies.length) {
    return (
      <div className='Emails'>
        <MissingPolicyAlert />
      </div>
    )
  }

  return (
    <div className='Emails'>
      <EmailConfiguration setDisableTabsChange={setDisableTabsChange} />
    </div>
  )
}

Emails.propTypes = {
  setDisableTabsChange: PropTypes.func
}

Emails.defaultProps = {
  setDisableTabsChange: () => {}
}

export default Emails
