import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  Checkbox,
  DateInput,
  IconEdit,
  List,
  NumberInput,
  Notice,
  TextArea,
  Switcher,
  Selector
} from 'shared/components'
import {
  displayValue,
  isValidDate,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  isJSON,
  parseMetadata
} from 'shared/helpers'
import {
  productFeatureTypes,
  consumptionPeriods,
  errorMsg,
  platformFeatures
} from 'shared/constants'
import { formatFeaturesToTable } from './helpers'
import './styles.scss'

const ProductFeaturesEditableTable = ({
  selectedFeatures,
  setErrorState,
  handleFeaturesEdit,
  showExpiryDate
}) => {
  const isMetadataFeatureEnabled = isFeatureEnabled(platformFeatures.extra_metadata)

  const notAvailableExpiryDateMessage = (
    <>
      {__('Time-limited features are unavailable in your current plan.')}
      &nbsp;
      {__('Upgrade to use time-limited features.')}
    </>
  )

  const selectedFeaturesWithExpiry = selectedFeatures
    .filter(sf => !!sf.expiry_date)
    .map(sf => sf.code)

  const [hasErrors, setHasErrors] = useState(false)
  const [features, setFeatures] = useState(formatFeaturesToTable(selectedFeatures))
  const [enabledExpiry, setEnabledExpiry] = useState(selectedFeaturesWithExpiry)

  useEffect(() => {
    const newFeatures = formatFeaturesToTable(selectedFeatures)
    const errors = newFeatures.some(
      f =>
        f.max_consumption_error || f.max_overages_error || f.expiry_date_error || f.metadata_error
    )
    setFeatures(newFeatures)
    setErrorState(errors)
    setHasErrors(errors)
  }, [selectedFeatures])

  const submitChange = newFeatures => {
    setFeatures(newFeatures)
    const erorrs = newFeatures.some(
      f =>
        f.max_consumption_error || f.max_overages_error || f.expiry_date_error || f.metadata_error
    )
    if (erorrs) {
      setHasErrors(true)
      setErrorState(true)
      return false
    }
    setHasErrors(false)
    setErrorState(false)
    handleFeaturesEdit(newFeatures)
    return true
  }

  const handleAllowUnlimitedConsumptionsChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const initialFeature = selectedFeatures.find(f => f.value === featureID)
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const newF = {
          ...f,
          allow_unlimited_consumptions: val,
          max_consumption: Number(get(initialFeature, 'max_consumption') || 1),
          max_consumption_error: ''
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const handleMaxConsumptionChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const isError = !val || Number(val) <= 0 ? 'error' : ''
        const newF = {
          ...f,
          max_consumption: Number(val),
          max_consumption_error: isError
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const renderMaxConsumptionEditable = cellData => {
    const feature = features.find(f => f.value === cellData.original.value)
    const cellValue = get(feature, 'max_consumption')
    const isUnlimited = get(feature, 'allow_unlimited_consumptions')
    return (
      <NumberInput
        min='1'
        max='2147483647'
        defaultValue={cellValue}
        error={cellData.original.max_consumption_error}
        disabled={(hasErrors && !cellData.original.max_consumption_error) || isUnlimited}
        handleChange={() => {}}
        onBlur={e => handleMaxConsumptionChange(cellData, e.target.value)}
      />
    )
  }

  const handleAllowOveragesChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const initialFeature = selectedFeatures.find(f => f.value === featureID)
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const newF = {
          ...f,
          allow_overages: val,
          max_overages: Number(get(initialFeature, 'allow_overage') || 1),
          max_overages_error: ''
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const handleMaxOveragesChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const isError = !val || Number(val) <= 0 ? 'error' : ''
        const newF = {
          ...f,
          max_overages: Number(val),
          max_overages_error: isError
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const handleExpiryDateChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const isValid = isValidDate(val, true)
        const err = isValid ? '' : errorMsg.dateYYYYMMDDformat
        const newF = {
          ...f,
          expiry_date: val,
          expiry_date_error: err
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const handleExpiryDateChangeEnabled = (cellData, val) => {
    const cellCode = get(cellData, 'original.code')
    const featureID = get(cellData, 'original.value')

    if (val) {
      setEnabledExpiry([...enabledExpiry, cellCode])
    } else {
      setEnabledExpiry(enabledExpiry.filter(i => i !== cellCode))
      const updatedFeatures = features.map(f => {
        if (f.value === featureID) {
          const newF = {
            ...f,
            expiry_date: '',
            expiry_date_error: ''
          }
          return newF
        }
        return f
      })
      submitChange(updatedFeatures)
    }
  }

  const handleMetadataChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        let isError = !isJSON(val) ? errorMsg.invalidJson : ''
        if (!val) {
          isError = ''
        }
        const newF = {
          ...f,
          metadata: val,
          metadata_error: isError
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const renderMaxOveragesEditable = cellData => {
    const feature = features.find(f => f.value === cellData.original.value)
    const cellValue = get(feature, 'max_overages')
    return (
      <NumberInput
        min='1'
        max='2147483647'
        defaultValue={cellValue}
        error={cellData.original.max_overages_error}
        disabled={hasErrors && !cellData.original.max_overages_error}
        handleChange={() => {}}
        onBlur={e => handleMaxOveragesChange(cellData, e.target.value)}
      />
    )
  }

  const handleResetConsumptionChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const initialFeature = selectedFeatures.find(f => f.value === featureID)
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const newF = {
          ...f,
          reset_consumption: val,
          consumption_period: get(initialFeature, 'consumption_period') || 'daily'
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const handleConsumptionPeriodChange = (cellData, val) => {
    const featureID = get(cellData, 'original.value')
    const updatedFeatures = features.map(f => {
      if (f.value === featureID) {
        const newF = {
          ...f,
          consumption_period: val
        }
        return newF
      }
      return f
    })
    submitChange(updatedFeatures)
  }

  const renderConsumptionPeriodEditable = cellData => {
    const feature = features.find(f => f.value === cellData.original.value)
    const cellValue = get(feature, 'consumption_period')
    return (
      <Selector
        options={consumptionPeriods}
        value={cellValue}
        disabled={hasErrors}
        handleChange={val => handleConsumptionPeriodChange(cellData, val)}
      />
    )
  }

  const renderExpiryDateEditable = cellData => {
    const cellCode = get(cellData, 'original.code')
    const feature = features.find(f => f.value === get(cellData, 'original.value'))
    const cellValue = get(feature, 'expiry_date')
    const isEnabled = enabledExpiry.includes(cellCode)
    return (
      <div className='expiry-date'>
        <Checkbox
          featureEnabled={isFeatureEnabled(platformFeatures.extra_feature_licensing_consumption)}
          notEnabledMessage={getDisabledMessage()}
          featureAvailable={isFeatureAvailable(
            platformFeatures.extra_feature_licensing_consumption
          )}
          notAvailableMessage={notAvailableExpiryDateMessage}
          label={isEnabled ? '' : __('Add expiry date')}
          checked={isEnabled}
          inputId={`${cellCode}-is-expiry-enabled`}
          handleChange={val => handleExpiryDateChangeEnabled(cellData, val)}
        />
        {isEnabled && (
          <DateInput
            defaultValue={cellValue}
            error={get(cellData, 'original.expiry_date_error')}
            disabled={hasErrors && !get(cellData, 'original.expiry_date_error')}
            handleChange={() => {}}
            handleDateSelect={val => handleExpiryDateChange(cellData, val)}
            onBlur={e => handleExpiryDateChange(cellData, e.target.value)}
          />
        )}
      </div>
    )
  }

  const renderMetadataEditable = cellData => {
    const feature = features.find(f => f.value === cellData.original.value)
    const cellValue = get(feature, 'metadata')

    return (
      <TextArea
        handleChange={() => {}}
        id='metadata-input'
        type='metadata'
        defaultValue={parseMetadata(cellValue)}
        error={cellData.original.metadata_error}
        disabled={(hasErrors && !cellData.original.metadata_error) || !isMetadataFeatureEnabled}
        rows='4'
        onBlur={e => handleMetadataChange(cellData, e.target.value)}
      />
    )
  }

  return (
    <div className='ProductFeaturesEditableTable'>
      {hasErrors && (
        <Notice size='sm' theme='error'>
          {__('Fix erorrs to continue saving the edits')}
        </Notice>
      )}
      <List
        columns={[
          { accessor: 'name', Header: __('Name') },
          { accessor: 'code', Header: __('Code') },
          {
            accessor: 'feature_type',
            Header: __('Type'),
            Cell: cellData => displayValue(cellData.value),
            maxWidth: 100
          },
          {
            accessor: 'allow_unlimited_consumptions',
            className: 'text-center',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Allow unlimited consumptions')}
              </div>
            ),
            Cell: cellData => {
              if (get(cellData, 'original.feature_type') === productFeatureTypes.activation) {
                return __('N/A')
              }
              return (
                <Switcher
                  size='sm'
                  checked={!!cellData.value}
                  handleChange={val => handleAllowUnlimitedConsumptionsChange(cellData, val)}
                />
              )
            }
          },
          {
            accessor: 'max_consumption',
            className: 'text-center',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Max consumption')}
              </div>
            ),
            Cell: cellData => {
              if (get(cellData, 'original.feature_type') === productFeatureTypes.activation) {
                return __('N/A')
              }
              return renderMaxConsumptionEditable(cellData)
            }
          },
          {
            accessor: 'allow_overages',
            className: 'text-center',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Allow overages')}
              </div>
            ),
            Cell: cellData => {
              if (get(cellData, 'original.feature_type') === productFeatureTypes.activation) {
                return __('N/A')
              }
              return (
                <Switcher
                  size='sm'
                  checked={!!cellData.value}
                  handleChange={val => handleAllowOveragesChange(cellData, val)}
                />
              )
            }
          },
          {
            accessor: 'max_overages',
            className: 'text-center',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Max overages')}
              </div>
            ),
            Cell: cellData => {
              const allowOverage = get(cellData, 'original.allow_overages')
              if (!allowOverage) return __('N/A')
              return renderMaxOveragesEditable(cellData)
            }
          },
          {
            accessor: 'reset_consumption',
            className: 'text-center',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Reset consumption')}
              </div>
            ),
            Cell: cellData => {
              if (get(cellData, 'original.feature_type') === productFeatureTypes.activation) {
                return __('N/A')
              }
              return (
                <Switcher
                  size='sm'
                  checked={!!cellData.value}
                  handleChange={val => handleResetConsumptionChange(cellData, val)}
                />
              )
            }
          },
          {
            accessor: 'consumption_period',
            className: 'text-center',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Consumption period')}
              </div>
            ),
            Cell: cellData => {
              const resetConsumption = get(cellData, 'original.reset_consumption')
              if (!resetConsumption) return __('N/A')
              return renderConsumptionPeriodEditable(cellData)
            }
          },
          {
            accessor: 'expiry_date',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Expiry date')}
              </div>
            ),
            Cell: cellData => renderExpiryDateEditable(cellData),
            width: 180,
            show: showExpiryDate
          },
          {
            accessor: 'metadata',
            headerClassName: 'editable-column text-center',
            Header: () => (
              <div className='custom-header'>
                <IconEdit height='12px' width='12px' fill='#909090' />
                {__('Metadata')}
              </div>
            ),
            Cell: cellData => renderMetadataEditable(cellData),
            width: 180
          }
        ]}
        data={features}
        clickable={false}
        showPagination={false}
        minRows={2}
        noDataText=''
      />
    </div>
  )
}

ProductFeaturesEditableTable.propTypes = {
  selectedFeatures: PropTypes.array,
  setErrorState: PropTypes.func.isRequired,
  handleFeaturesEdit: PropTypes.func.isRequired,
  showExpiryDate: PropTypes.bool
}

ProductFeaturesEditableTable.defaultProps = {
  selectedFeatures: [],
  showExpiryDate: true
}

export default ProductFeaturesEditableTable
