import React from 'react'
import PropTypes from 'prop-types'
import { Label, NumberInput, Selector } from 'shared/components'
import './styles.scss'

const TrialOptions = ({ handleTrialDuration, max, min, trialDurationError, trialDuration }) => (
  <div className='TrialOptions'>
    <Label text={__('Trial duration')} inputId='product-trial-duration' />
    <div className='flex-row'>
      <NumberInput
        id='product-trial-duration'
        value={trialDuration}
        error={trialDurationError}
        handleChange={handleTrialDuration}
        min={min}
        max={max}
      />
      <Selector options={[{ label: __('Days'), value: 'd' }]} value='d' handleChange={() => {}} />
    </div>
  </div>
)

TrialOptions.propTypes = {
  handleTrialDuration: PropTypes.func.isRequired,
  max: PropTypes.string,
  min: PropTypes.string,
  trialDuration: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  trialDurationError: PropTypes.string
}

TrialOptions.defaultProps = {
  min: '0',
  max: '1000',
  trialDurationError: ''
}

export default TrialOptions
