import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { UnauthorizedNotice } from 'shared/components'
import { isStaffUser } from 'shared/helpers'
import CompanySettings from './CompanySettings'
import PricingPlanSettings from './PricingPlanSettings'
import DiscountSettings from './DiscountSettings'
import TaxRatesSettings from './TaxRatesSettings'
import './styles.scss'

const BillingConfiguration = () => {
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true'
  const isStaff = isStaffUser()

  if (!isStaff) {
    return <UnauthorizedNotice />
  }

  return (
    <div className='BillingConfiguration'>
      <Switch>
        <Route exact path='/:companyId/billing-configuration' render={() => <CompanySettings />} />
        {!isSelfHosted && (
          <>
            <Route
              exact
              path='/:companyId/billing-configuration/pricing-plans'
              render={() => <PricingPlanSettings />}
            />
            <Route
              exact
              path='/:companyId/billing-configuration/coupons'
              render={() => <DiscountSettings />}
            />
            <Route
              exact
              path='/:companyId/billing-configuration/tax-rates'
              render={() => <TaxRatesSettings />}
            />
          </>
        )}
      </Switch>
    </div>
  )
}

export default BillingConfiguration
