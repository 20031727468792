import React, { useState, useCallback, useEffect, Fragment } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useHistory } from 'react-router-dom'
import {
  sendErrorReport,
  displayValue,
  formatDate,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
  copyText
} from 'shared/helpers'
import { platformFeatures, userPermissionLabels, userPermissions } from 'shared/constants'
import {
  ConfirmationPopup,
  ContentLoader,
  DescriptionTable,
  NotFound,
  Notice,
  Notification,
  PermissionMissingNotificationTitle,
  PermissionDenied,
  Button,
  Page,
  Subtitle,
  LabelTag,
  IconCheckmark,
  IconDisabled,
  IconClipboard
} from 'shared/components'
import {
  getUser,
  patchUser,
  deleteUser,
  resendInvitation,
  getUserLabels,
  setUserLabels,
  getUsers
} from 'src/account/actions'
import { addNewUserLabel, getPlatformUserPermissions } from 'src/company/actions'
import { getUserFromStorage } from 'src/keycloak/authUser'
import {
  UserEditForm,
  UserLabelsForm,
  SetUserPermissionTemplate,
  UserPermissionsForm
} from '../components'
import './styles.scss'

const UserContainer = () => {
  const canManagePlatformUsers = checkUserPermission(userPermissions.platform_users_write)
  const currentUser = getUserFromStorage()
  const currentUserID = get(currentUser, 'profile.platform_user_id')

  const history = useHistory()
  const dispatch = useDispatch()
  const { userId } = useParams()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const companyUserLabels = useSelector(state => get(state, 'company.userLabels'))

  const [isLoading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [user, setUser] = useState(null)
  const [userLabels, setLabels] = useState([])
  const [userPermissionsList, setUserPermissionsList] = useState([])
  const [isPatching, setPatching] = useState(false)
  const [isEnableAccountConfirmationDisplayed, setEnableAccountConfirmationDisplay] = useState(
    false
  )
  const [isHandleAdminConfirmationDisplayed, setHandleAdminConfirmationDisplay] = useState(false)
  const [
    isResendInvitationConfirmationDisplayed,
    setResendInvitationConfirmationDisplay
  ] = useState(false)
  const [isUserEditFormDisplayed, setUserEditFormDisplay] = useState(false)
  const [showDeleteUserConfirmation, setDeleteUserConfirmationDisplay] = useState(false)
  const [deletingUser, setDeletingUser] = useState(false)

  const [showLabelForm, setLabelFormDisplay] = useState(false)
  const [labelToDelete, setLabelToDelete] = useState(null)
  const [showDeleteLabelConfirmation, setDeleteLabelConfirmationDisplay] = useState(false)
  const [deletingLabel, setDeletingLabel] = useState(false)
  const [showAssignFromTemplate, setAssignFromTemplateDisplay] = useState(false)
  const [showEditPermissions, setEditPermissionsDisplay] = useState(false)

  const fetchUser = useCallback(() => {
    getUser(companyID, userId)
      .then(res => {
        setUser(get(res, 'data'))
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch user details')
        setLoading(false)
        setNotFound(true)
      })
  }, [userId])

  const fetchUserLabels = useCallback(() => {
    getUserLabels(companyID, userId)
      .then(res => {
        setLabels(get(res, 'data'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch user labels')
      })
  }, [companyID, userId])

  const fetchUserPermissions = useCallback(() => {
    getPlatformUserPermissions(userId, companyID)
      .then(res => {
        setUserPermissionsList(get(res, 'data'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch user permissions')
      })
  }, [companyID, userId])

  useEffect(() => {
    fetchUser()
    fetchUserLabels()
    fetchUserPermissions()
  }, [fetchUser, fetchUserLabels, fetchUserPermissions])

  const handlePatchUser = userData => {
    setPatching(true)

    patchUser(userId, userData, companyID)
      .then(res => {
        Notification('success', __('Changes saved successfully'), __('User account changed'))
        dispatch(getUsers(companyID))
        setPatching(false)
        setEnableAccountConfirmationDisplay(false)
        setHandleAdminConfirmationDisplay(false)
        setUser(get(res, 'data'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot save user account changes', userData)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        setPatching(false)
      })
  }

  const handleInvitationBtnClick = verified => {
    if (verified) {
      Notification(
        'error',
        __('User has already accepted invitation'),
        __('In case of forgotten password use the password reset option on the login screen')
      )
      return false
    }

    setResendInvitationConfirmationDisplay(true)
    return true
  }

  const handleInvitationResend = () => {
    setPatching(true)
    const userEmail = get(user, 'email')

    resendInvitation(userId, companyID)
      .then(() => {
        Notification('success', __(`Invitation sent to ${userEmail}`))
        setPatching(false)
        setResendInvitationConfirmationDisplay(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot resend invitation to user')
        Notification(
          'error',
          __('Sending invitation failed'),
          __('There was an error while sending the invitation')
        )
        setPatching(false)
      })
  }

  const handleAccountStatusChange = isActive => {
    const userData = { is_active: isActive }
    handlePatchUser(userData)
  }

  const handleAdminStatusChange = isAdmin => {
    const userData = { is_admin: isAdmin }
    handlePatchUser(userData)
  }

  const handleLabelRemove = () => {
    const labelToRemoveID = get(labelToDelete, 'id')
    setDeletingLabel(true)

    const userLabelIDs = userLabels.map(l => Number(l.id))
    const newList = userLabelIDs.filter(id => id !== Number(labelToRemoveID))

    const data = {
      user_role_ids: newList
    }

    setUserLabels(companyID, userId, data)
      .then(() => {
        fetchUserLabels()
        fetchUser()
        setDeletingLabel(false)
        setDeleteLabelConfirmationDisplay(false)
        setLabelToDelete(null)
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot load customer details')
        setDeletingLabel(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleUserDelete = () => {
    setDeletingUser(true)

    deleteUser(userId, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        dispatch(getUsers(companyID))
        history.push(`/${companyID}/account/users`)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete platform user')
        setDeletingUser(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const getUserDescription = () => {
    const isActive = get(user, 'is_active')
    const isAdmin = get(user, 'is_admin')

    if (!isActive) {
      return __('Account not active')
    }
    return isAdmin ? __('Admin') : __('User')
  }

  const handleFieldCopy = (val, desc) => {
    copyText(val)
    Notification('success', `${desc} ${__('copied to clipboard')}`)
  }

  const handleManagePlatformUsersClick = cb => {
    if (!canManagePlatformUsers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.platform_users_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const permissionLabels = userPermissionsList.sort().map(code => userPermissionLabels[code])

  if (isLoading) {
    return (
      <Page>
        <ContentLoader text={__('Getting user details')} />
      </Page>
    )
  }

  if (notFound) {
    return (
      <Page>
        <NotFound />
      </Page>
    )
  }

  const isUserActive = get(user, 'is_active')
  const isAdmin = get(user, 'is_admin')
  const isVerified = get(user, 'email_verified')
  const has2FaSetup = get(user, 'totp')
  const isCurrentUser = Number(userId) === currentUserID

  const createUserTitle = () => {
    const userEmail = get(user, 'email')
    return (
      <div className='user-title-container'>
        <div className='user-title'>
          <span>{userEmail}</span>
          <button
            type='button'
            className='clipboard-btn'
            onClick={() => handleFieldCopy(userEmail, __('User'))}
          >
            <IconClipboard width='21.5' height='20' viewBox='0 0 51.5 50' />
          </button>
        </div>
        <div className='labels'>
          <>
            {userLabels.map(l => (
              <Fragment key={get(l, 'id')}>
                <LabelTag
                  label={l}
                  handleLabelDelete={() =>
                    handleManagePlatformUsersClick(() => {
                      setLabelToDelete(l)
                      setDeleteLabelConfirmationDisplay(true)
                    })
                  }
                />
              </Fragment>
            ))}
          </>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_customer)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_customer)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManagePlatformUsersClick(setLabelFormDisplay)}
            theme='default'
            size='sm'
          >
            {__('Add label')}
          </Button>
        </div>
      </div>
    )
  }

  // TODO
  // isStaff can change everything + 2FA

  return (
    <Page title={createUserTitle()} description={getUserDescription()}>
      <div className='list-header'>
        <div>
          {canManagePlatformUsers && (
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_user)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_user)}
              notEnabledMessage={getDisabledMessage()}
              theme='info'
              size='sm'
              onClick={() => setHandleAdminConfirmationDisplay(true)}
              disabled={isCurrentUser}
            >
              {isAdmin ? __('Revoke admin') : __('Make admin')}
            </Button>
          )}
          {(canManagePlatformUsers || isCurrentUser) && (
            <Button theme='default' size='sm' onClick={() => setUserEditFormDisplay(true)}>
              {__('Edit')}
            </Button>
          )}
        </div>
        <div>
          {canManagePlatformUsers && (
            <>
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_user)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_user)}
                notEnabledMessage={getDisabledMessage()}
                onClick={() => handleInvitationBtnClick(isVerified)}
                theme='info'
                size='sm'
                disabled={isCurrentUser}
              >
                {__('Resend invitation')}
              </Button>
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_user)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_user)}
                notEnabledMessage={getDisabledMessage()}
                theme={isUserActive ? 'error' : 'success'}
                size='sm'
                onClick={() => setEnableAccountConfirmationDisplay(true)}
                disabled={isCurrentUser}
              >
                {isUserActive ? __('Disable account') : __('Enable account')}
              </Button>
              {!isCurrentUser && (
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_user)}
                  featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_user)}
                  notEnabledMessage={getDisabledMessage()}
                  size='sm'
                  onClick={() => setDeleteUserConfirmationDisplay(true)}
                  disabled={deletingUser}
                >
                  {__('Delete user')}
                </Button>
              )}
            </>
          )}
        </div>
      </div>
      <div className='UserContainer'>
        <DescriptionTable
          details={[
            { label: __('First Name'), value: displayValue(get(user, 'first_name')) },
            { label: __('Last Name'), value: displayValue(get(user, 'last_name')) },
            {
              label: __('Is admin'),
              value: isAdmin ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              )
            },
            {
              label: __('Is active'),
              value: isUserActive ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              )
            },
            {
              label: __('Two-factor authentication'),
              value: has2FaSetup ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='#aaa' height='14px' />
              )
            },
            {
              label: __('Last login'),
              value: formatDate(get(user, 'last_login'), undefined, __('Never'))
            }
          ]}
        />
        <div className='UserContainer-permissions'>
          <Subtitle text={__('Permissions')} />
          {canManagePlatformUsers && !isAdmin && (
            <>
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_user)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_user)}
                notEnabledMessage={getDisabledMessage()}
                onClick={() => setEditPermissionsDisplay(true)}
                theme='default'
                size='sm'
                disabled={false}
              >
                {__('Edit permissions')}
              </Button>
              <Button
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_user)}
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_user)}
                notEnabledMessage={getDisabledMessage()}
                onClick={() => setAssignFromTemplateDisplay(true)}
                theme='default'
                size='sm'
                disabled={false}
              >
                {__('Set from template')}
              </Button>
            </>
          )}
          {isAdmin ? (
            <Notice theme='info' title={__('Admin')}>
              {__('Admins have all permissions enabled.')}
            </Notice>
          ) : (
            <ul className='permissions-list'>
              {canManagePlatformUsers || isCurrentUser ? (
                <>
                  {permissionLabels.map(p => (
                    <li className='permission-item' key={p}>
                      {p}
                    </li>
                  ))}
                </>
              ) : (
                <PermissionDenied permission={userPermissions.platform_users_write} />
              )}
            </ul>
          )}
        </div>
      </div>
      {isEnableAccountConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setEnableAccountConfirmationDisplay(false)}
          confirmCb={() => handleAccountStatusChange(!isUserActive)}
          title={__(
            `Are you sure you want to ${isUserActive ? __('disable') : __('enable')} this account?`
          )}
          confirmText={isUserActive ? __('Disable') : __('Enable')}
          theme={isUserActive ? 'error' : 'success'}
          disabled={isPatching}
        />
      )}
      {isHandleAdminConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setHandleAdminConfirmationDisplay(false)}
          confirmCb={() => handleAdminStatusChange(!isAdmin)}
          title={__(
            `Are you sure you want to ${
              isAdmin ? __('disable') : __('enable')
            } admin rights for this account?`
          )}
          confirmText={isAdmin ? __('Revoke admin') : __('Make admin')}
          theme={isAdmin ? 'error' : 'success'}
          disabled={isPatching}
        />
      )}
      {isResendInvitationConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setResendInvitationConfirmationDisplay(false)}
          confirmCb={handleInvitationResend}
          title={__('Are you sure you want to resend the invitation to this account?')}
          confirmText={__('Resend')}
          theme='success'
          disabled={isPatching}
        />
      )}
      {isUserEditFormDisplayed && (
        <UserEditForm
          user={user}
          closeCb={() => setUserEditFormDisplay(false)}
          confirmCb={() => {
            setUserEditFormDisplay(false)
            fetchUser()
          }}
          companyID={companyID}
        />
      )}
      {showLabelForm && (
        <UserLabelsForm
          closeCb={() => setLabelFormDisplay(false)}
          user={user}
          companyLabels={companyUserLabels}
          companyID={companyID}
          refetchUser={() => {
            fetchUser()
            fetchUserLabels()
          }}
          updateCompanyLabels={data => dispatch(addNewUserLabel(data))}
        />
      )}
      {showDeleteLabelConfirmation && (
        <ConfirmationPopup
          closeCb={() => {
            setLabelToDelete(null)
            setDeleteLabelConfirmationDisplay(false)
          }}
          confirmCb={handleLabelRemove}
          title={`${__('Are you sure you want to remove this label from the user')}?`}
          confirmText={__('Remove')}
          theme='error'
          disabled={deletingLabel}
        >
          <strong>{get(labelToDelete, 'label')}</strong>
        </ConfirmationPopup>
      )}
      {showDeleteUserConfirmation && (
        <ConfirmationPopup
          closeCb={() => {
            setDeleteUserConfirmationDisplay(false)
          }}
          confirmCb={handleUserDelete}
          title={`${__('Are you sure you want to delete this platform user')}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={deletingUser}
        >
          <strong>{get(labelToDelete, 'label')}</strong>
        </ConfirmationPopup>
      )}
      {showAssignFromTemplate && (
        <SetUserPermissionTemplate
          user={user}
          closeCb={() => setAssignFromTemplateDisplay(false)}
          confirmCb={() => {
            setAssignFromTemplateDisplay(false)
            fetchUserPermissions()
          }}
        />
      )}
      {showEditPermissions && (
        <UserPermissionsForm
          closeCb={() => setEditPermissionsDisplay(false)}
          confirmCb={() => {
            setEditPermissionsDisplay(false)
            fetchUserPermissions()
          }}
          userPermissionsList={userPermissionsList}
          user={user}
          isOpen={showEditPermissions}
        />
      )}
    </Page>
  )
}

export default UserContainer
