import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { get } from 'lodash'
import { List } from 'shared/components'
import { APIdateFormat } from 'shared/constants'
import { displayValue, formatDate } from 'shared/helpers'
import { getMaxConsumptionsLicensesCount } from 'src/analytics/actions'

const ConsumptionsLicenses = ({ product }) => {
  const history = useHistory()
  const productID = get(product, 'data.id') || undefined
  const defaultFrom = moment().startOf('month')
  const defaultTo = moment().endOf('month')

  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'))
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'))

  const companyID = useSelector(state => get(state, 'company.details.id'))

  const dateFrom = from ? moment(from) : defaultFrom
  const dateTo = to ? moment(to) : defaultTo

  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)

  const getData = useCallback(
    (tablePage = 0, rows = rowsPerPage) => {
      const fromDate = dateFrom.format(APIdateFormat)
      const toDate = dateTo.format(APIdateFormat)

      const gteParam = `&ts__date__gte=${fromDate}`
      const lteParam = `&ts__date__lte=${toDate}`

      setLoading(true)

      getMaxConsumptionsLicensesCount(companyID, gteParam, lteParam, tablePage, rows, productID)
        .then(res => {
          setData(get(res, 'data.results') || [])
          setDataCount(get(res, 'data.count'))
          setLoading(false)
        })
        .catch(() => {
          setLoading(false)
        })
    },
    [companyID, from, to, productID]
  )

  const handlePageChange = newPage => {
    setPage(newPage)
    getData(newPage, rowsPerPage)
  }

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage)
    setRowsPerPage(newPageSize)
    getData(newPage, newPageSize)
  }

  const redirectToItemPage = rowData => {
    const orderID = get(rowData, 'original.order.id')
    const licenseID = get(rowData, 'original.id')
    history.push(`/${companyID}/orders/${orderID}/${licenseID}`)
  }

  useEffect(() => {
    getData()
  }, [getData])

  return (
    <div className='ConsumptionsLicenses'>
      <List
        columns={[
          {
            accessor: 'created_at',
            Header: __('Created on'),
            Cell: rowData => formatDate(rowData.value),
            sortable: false
          },
          {
            accessor: 'key_or_users',
            Header: `${__('License Key')}/${__('User')}`,
            Cell: rowData => displayValue(rowData.value),
            sortable: false
          },
          {
            accessor: 'product.product_name',
            Header: __('Product'),
            Cell: rowData => displayValue(rowData.value),
            sortable: false
          },
          {
            accessor: 'total_call_count',
            Header: __('Total'),
            Cell: rowData => displayValue(rowData.value),
            sortable: false
          }
        ]}
        data={data}
        clickable
        page={page}
        pages={Math.ceil(dataCount / rowsPerPage)}
        loading={isLoading}
        manual
        minRows={get(data, 'length') || 10}
        showPagination={dataCount > 5}
        onPageChange={handlePageChange}
        // onSortedChange={handleSortChange}
        // defaultSorted={currentSort}
        handleClick={rowData => redirectToItemPage(rowData)}
        showPageSizeOptions
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize, pageIndex) => handlePageSizeChange(pageSize, pageIndex)}
      />
    </div>
  )
}

ConsumptionsLicenses.propTypes = {
  product: PropTypes.object.isRequired
}

export default ConsumptionsLicenses
