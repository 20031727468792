/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { IconList, Tooltip } from 'shared/components'
import './styles.scss'

const Option = props => (
  <div>
    <components.Option {...props}>
      <input
        id={`${props.label}-input`}
        className='checkbox-option'
        type='checkbox'
        checked={props.isSelected}
        onChange={() => null}
      />{' '}
      <label>{props.label}</label>
    </components.Option>
  </div>
)

const CheckboxSelector = ({
  disabled,
  options,
  onChangeCallback,
  onMenuClose,
  text,
  notAvailableMessage,
  notEnabledMessage,
  featureAvailable,
  featureEnabled,
  ...otherProps
}) => {
  if (!featureAvailable) {
    return (
      <Tooltip
        active={!featureAvailable}
        content={<div className='feature-unavailable-tooltip'>{notAvailableMessage}</div>}
      >
        <div className='CheckboxSelector Selector'>
          <div className='selector-label'>
            <IconList />
            {text}
          </div>
          <Select
            closeMenuOnSelect={false}
            className='selector-container'
            classNamePrefix='selector-inner'
            placeholder=''
            isMulti
            isSearchable={false}
            isClearable={false}
            isDisabled={disabled || !featureAvailable}
            components={{ Option }}
            options={options}
            hideSelectedOptions={false}
            backspaceRemovesValue={false}
            onMenuClose={onMenuClose}
            onChange={e => onChangeCallback(e)}
            {...otherProps}
          />
        </div>
      </Tooltip>
    )
  }

  return (
    <div className='CheckboxSelector Selector'>
      <div className='selector-label'>
        <IconList />
        {text}
      </div>
      <Select
        closeMenuOnSelect={false}
        className='selector-container'
        classNamePrefix='selector-inner'
        placeholder=''
        isMulti
        isSearchable={false}
        isClearable={false}
        isDisabled={disabled}
        components={{ Option }}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onMenuClose={onMenuClose}
        onChange={e => onChangeCallback(e)}
        {...otherProps}
      />
    </div>
  )
}

CheckboxSelector.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  featureAvailable: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  featureEnabled: PropTypes.bool,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

CheckboxSelector.defaultProps = {
  text: '',
  disabled: false,
  featureAvailable: true,
  notAvailableMessage: 'This feature is not available in your plan type.',
  featureEnabled: true,
  notEnabledMessage: ''
}

export default CheckboxSelector
