import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { generatePass, sendErrorReport } from 'shared/helpers'
import { DirtyFormAlert, Label, Modal, Notification, TextInput } from 'shared/components'
import { validateRequiredValue, debouncedValidateRequiredValue } from 'shared/validation'
import { setLicenseUserPassword } from 'src/license/actions'

const OrderManagerPasswordForm = ({ companyID, closeCb, refetchManagers, manager }) => {
  const managerToEditID = get(manager, 'id')

  const [isLoading, setLoading] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false)
  const [password, setPassword] = useState(generatePass())
  const [passwordError, setPasswordError] = useState('')

  const handlePasswordValidation = async () => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredValue(password)
      setPasswordError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate order manager form new password value', {
        value: password
      })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isPasswordValid = await handlePasswordValidation()
    return isPasswordValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setLoading(true)
    const data = { password }

    setLicenseUserPassword(managerToEditID, companyID, data)
      .then(() => {
        refetchManagers()
        closeCb()
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot change order manager password')
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handleClose = () => {
    if (!isDirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size='sm'
      title={__('Change password')}
    >
      <form className='OrderManagerPasswordForm' onSubmit={handleSubmit}>
        <div className='form-row'>
          <Label text={__('Email')} inputId='email' />
          <div className='TextInput'>
            <input type='text' value={get(manager, 'true_email')} disabled />
          </div>
        </div>
        <div className='form-row'>
          <Label text={__('Password')} inputId='password' />
          <TextInput
            id='password'
            value={password}
            error={passwordError}
            handleChange={val => {
              setDirty(true)
              setPassword(val)
              debouncedValidateRequiredValue(val).then(err => setPasswordError(err))
            }}
          />
        </div>
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

OrderManagerPasswordForm.propTypes = {
  companyID: PropTypes.number.isRequired,
  closeCb: PropTypes.func.isRequired,
  refetchManagers: PropTypes.func.isRequired,
  manager: PropTypes.object.isRequired
}

export default OrderManagerPasswordForm
