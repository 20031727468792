import React, { useState } from 'react'
import { get } from 'lodash'
import { useAuth } from 'react-oidc-context'
import { Button, Notice, Page, Subtitle, Notification } from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { getUserFromStorage, generateUpdatePasswordUrl } from 'src/keycloak/authUser'
import { setupOTP, removeOTP } from '../../actions'
import { ReactComponent as TwofaImage } from './twofa-image.svg'
import './styles.scss'

const SecurityContainer = () => {
  const auth = useAuth()

  const [enableTwofaLoading, setEnableTwofaLoading] = useState(false)

  const user = getUserFromStorage()

  const is2FAEnabled = get(user, 'profile.is_2fa_active')

  const handle2FA = () => {
    if (is2FAEnabled) {
      return
    }
    setEnableTwofaLoading(true)

    setupOTP()
      .then(() => {
        Notification('success', __('Two-factor authentication enabled succesfully'))
        setEnableTwofaLoading(false)
        auth.signoutSilent()
      })
      .catch(err => {
        setEnableTwofaLoading(false)
        sendErrorReport(err, 'Cannot enable 2FA')
        Notification(
          'error',
          __('Error occured'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleRemove2FA = () => {
    setEnableTwofaLoading(true)

    removeOTP()
      .then(() => {
        Notification('success', __('Two-factor authentication disabled succesfully'))
        setEnableTwofaLoading(false)
        auth.signinSilent()
      })
      .catch(err => {
        setEnableTwofaLoading(false)
        sendErrorReport(err, 'Cannot disable 2FA')
        Notification(
          'error',
          __('Error occured'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handlePasswordChange = async () => {
    const url = await generateUpdatePasswordUrl()
    window.location.replace(url)
  }

  return (
    <Page title={__('Security')}>
      <div className='SecurityContainer'>
        <div className='SecurityContainer-password'>
          <div className='section-header'>
            <Subtitle text={__('Password')} />
          </div>
          <div className='section-body'>
            <div className='description'>
              {__('This will redirect you to the password update view.')}
            </div>
            <Button onClick={handlePasswordChange}>{__('Change password')}</Button>
          </div>
        </div>
        <div className='SecurityContainer-twofa'>
          <div className='section-header'>
            <Subtitle text={__('Two-factor authentication')} />
          </div>
          <div className='section-body'>
            <div className='description'>
              {__(
                'Two factor authentication adds an extra layer of security to your account by asking for a verification code when you sign in.'
              )}
              <Notice theme={is2FAEnabled ? 'success' : 'error'} size='sm'>
                {is2FAEnabled
                  ? __('Two-factor authentication is enabled')
                  : __('Two-factor authentication is not enabled')}
              </Notice>
            </div>
            <div className='image-container'>
              <TwofaImage />
            </div>
            <div>
              <ul>
                <li>{__('Log in using your email and password as usual.')}</li>
                <li>
                  {__(
                    'Using a two-factor authentication app, generate a passcode to verify your identity.'
                  )}
                </li>
                <li>
                  {__(
                    'Once you enter the passcode at the prompt, you will be logged in to the platform.'
                  )}
                </li>
              </ul>
            </div>
            {!is2FAEnabled ? (
              <Button theme='success' onClick={handle2FA} disabled={enableTwofaLoading}>
                {__('Setup 2FA authentication')}
              </Button>
            ) : (
              <Button theme='default' onClick={handleRemove2FA} disabled={enableTwofaLoading}>
                {__('Disable 2FA authentication')}
              </Button>
            )}
          </div>
        </div>
      </div>
    </Page>
  )
}

export default SecurityContainer
