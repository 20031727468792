import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport } from 'shared/helpers'
import {
  Button,
  ColorPicker,
  CustomerLabelsSelector,
  DirtyFormAlert,
  Modal,
  Notification,
  Label,
  TextInput
} from 'shared/components'
import { validateRequiredValue, debouncedValidateRequiredValue } from 'shared/validation'
import { addLabelToCustomer, addCompanyCustomerLabel } from 'src/customer/actions'
import { mapCustomerLabelsToSelector, getAvailableLabels } from './helpers'
import './styles.scss'

const CustomerLabelsForm = ({
  customer,
  companyLabels,
  companyID,
  closeCb,
  refetchCustomer,
  updateCompanyLabels,
  isLabelCreateOnly
}) => {
  const availableLabels = getAvailableLabels(companyLabels, customer)

  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState(null)
  const [showLabelCreate, setLabelCreateDisplay] = useState(isLabelCreateOnly)
  const [newLabelName, setNewLabelName] = useState('')
  const [newLabelNameError, setNewLabelNameError] = useState('')
  const [selectedColor, setSelectedColor] = useState('#949494')

  const validateValue = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredValue(val)
      setLoading(false)
      cb(errors)
    } catch (err) {
      setLoading(false)
      sendErrorReport(err, 'Cannot validate new customer label name', { value: val })
    }
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isLabelNameValid = await validateValue(newLabelName, setNewLabelNameError)
    return isLabelNameValid
  }

  const handleLabelSelect = val => {
    setDirty(true)
    const selected = availableLabels.find(label => label.id === val.value)
    setSelectedLabel(selected)
  }

  const addExistingLabelToCustomer = data => {
    setLoading(true)

    addLabelToCustomer(data, companyID)
      .then(() => {
        refetchCustomer()
        closeCb()
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add existing label to customer', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const createAndAddNewLabelToCustomer = async () => {
    const isValid = await isFormValid()
    if (loading || !isValid) {
      return false
    }

    setLoading(true)
    const data = {
      label: newLabelName,
      company: companyID,
      color: selectedColor
    }

    let newCompanyLabel
    try {
      newCompanyLabel = await addCompanyCustomerLabel(companyID, data)
    } catch (err) {
      sendErrorReport(err, 'Cannot create new company label', data)
      setLoading(false)
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
    }

    if (newCompanyLabel) {
      if (isLabelCreateOnly) {
        updateCompanyLabels(get(newCompanyLabel, 'data'))
        closeCb()
      } else {
        const newLabelData = {
          customer: get(customer, 'id'),
          customer_label: get(newCompanyLabel, 'data.id')
        }
        addExistingLabelToCustomer(newLabelData)
        updateCompanyLabels(get(newCompanyLabel, 'data'))
      }
      return true
    }
    return false
  }

  const handleSubmit = () => {
    const data = {
      customer: get(customer, 'id'),
      customer_label: get(selectedLabel, 'id')
    }

    if (!showLabelCreate) {
      addExistingLabelToCustomer(data)
    } else {
      createAndAddNewLabelToCustomer()
    }
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size='sm'
      title={__('Add customer label')}
    >
      <div className='CustomerLabelsForm'>
        {showLabelCreate ? (
          <div className='CustomerLabelsForm-create'>
            <div>
              <Label text={__('New label name')} inputId='new-label-name' />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setNewLabelName(val)
                  debouncedValidateRequiredValue(val).then(err => setNewLabelNameError(err))
                }}
                id='new-label-name'
                value={newLabelName}
                error={newLabelNameError}
              />
            </div>
            <div>
              <Label text={__('Label color')} inputId='new-label-color' />
              <ColorPicker
                initialColor={selectedColor}
                onColorSelect={val => setSelectedColor(val)}
                label={selectedColor}
              />
            </div>
            {!isLabelCreateOnly && (
              <div className='back-label-btn'>
                <Button
                  onClick={() => setLabelCreateDisplay(false)}
                  theme='link'
                  size='sm'
                  disabled={loading}
                >
                  {__('Back')}
                </Button>
              </div>
            )}
          </div>
        ) : (
          <div className='CustomerLabelsForm-add'>
            <div>
              <Label text={__('Select label')} inputId='label-select' />
              <CustomerLabelsSelector
                options={mapCustomerLabelsToSelector(availableLabels)}
                value={selectedLabel}
                onChangeCallback={handleLabelSelect}
                text={__('Available labels')}
              />
            </div>
            <div className='new-label-btn'>
              <Button
                onClick={() => setLabelCreateDisplay(true)}
                theme='link'
                size='sm'
                disabled={loading}
              >
                <div>
                  <span>+</span>
                  {__('Create new label')}
                </div>
              </Button>
            </div>
          </div>
        )}
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

CustomerLabelsForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyLabels: PropTypes.array.isRequired,
  companyID: PropTypes.number.isRequired,
  customer: PropTypes.object.isRequired,
  isLabelCreateOnly: PropTypes.bool,
  refetchCustomer: PropTypes.func.isRequired,
  updateCompanyLabels: PropTypes.func.isRequired
}

CustomerLabelsForm.defaultProps = {
  isLabelCreateOnly: false
}

export default CustomerLabelsForm
