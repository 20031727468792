import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props}>
    <g transform='translate(0,-952.36218)'>
      <path d='m 19,973.36217 c -2.77001,0 -5,2.23 -5,5 l 0,41.00003 72,0 0,-41.00003 c 0,-2.77 -2.22999,-5 -5,-5 l -62,0 z m 20,10 c 0.5135,0 1.0457,0.202 1.4375,0.5938 l 9.5625,9.5624 9.5625,-9.5624 c 0.7835,-0.7834 2.06025,-0.7834 2.84375,0 0.7836,0.7835 0.8109,2.0055 0,2.8124 l -9.5625,9.5938 9.5625,9.56253 c 0.8089,0.8088 0.7836,2.0604 0,2.8437 -0.7835,0.7836 -2.06025,0.7836 -2.84375,0 L 50,999.20597 l -9.5625,9.56243 c -0.7835,0.7834 -2.06025,0.7834 -2.84375,0 -0.7835,-0.7835 -0.7835,-2.0603 0,-2.8437 l 9.5625,-9.56253 -9.5625,-9.5938 c -0.7835,-0.7833 -0.7835,-2.0289 0,-2.8124 0.3918,-0.3918 0.89285,-0.5938 1.40625,-0.5938 z m -31,38.00003 0,4 c 0,3.324 2.676,6 6,6 l 72,0 c 3.324,0 6,-2.676 6,-6 l 0,-4 -84,0 z' />
    </g>
  </Base>
)
