/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { components } from 'react-select'
import AsyncSelect from 'react-select/lib/Async'
import { clearCompanyState } from 'src/company/actions'
import { IconDropdown } from 'shared/components'
import api from 'shared/api'
import './styles.scss'

const DropdownIndicator = props => (
  <components.DropdownIndicator {...props}>
    <IconDropdown stroke='#fff' strokeWidth='2' height='20px' width='20px' />
  </components.DropdownIndicator>
)

const CompanySelector = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const activeCompanyId = useSelector(state => get(state, 'account.activeCompany.id'))
  const companyLoading = useSelector(state => get(state, 'company.loading'))
  const loadingUserPermissions = useSelector(state => get(state, 'company.loadingUserPermissions'))

  const [activeCompany, setActiveCompany] = useState(null)
  const [initialCompanies, setInitialCompanies] = useState([])
  const [isSingleCompany, setIsSingleCompany] = useState(false)

  useEffect(() => {
    const fetchInitialCompanies = async () => {
      try {
        const response = await api.get('/api/v1/companies/?limit=10&is_active=1')
        const companies = get(response, 'data.results', [])
        setInitialCompanies(companies)

        if (companies.length === 1) {
          setIsSingleCompany(true)
        }

        if (activeCompanyId) {
          const currentCompany = companies.find(c => c.id === activeCompanyId)
          if (currentCompany) {
            setActiveCompany(currentCompany)
          } else if (companies.length > 0) {
            try {
              const companyResponse = await api.get(`/api/v1/companies/${activeCompanyId}/`)
              setActiveCompany(get(companyResponse, 'data', null))
            } catch (err) {
              console.error('Error fetching active company:', err)
            }
          }
        }
      } catch (err) {
        console.error('Error fetching initial companies:', err)
      }
    }

    fetchInitialCompanies()
  }, [activeCompanyId])

  const loadOptions = async inputValue => {
    try {
      const response = await api.get(
        `/api/v1/companies/?name__icontains=${inputValue}&limit=10&is_active=1`
      )
      return get(response, 'data.results', [])
    } catch (err) {
      console.error('Error searching companies:', err)
      return []
    }
  }

  const customStyles = {
    option: provided => ({
      ...provided,
      backgroundColor: 'transparent',
      cursor: 'pointer',
      outline: 'none'
    }),
    control: () => ({ width: 250, outline: 'none' }),
    singleValue: (provided, state) => ({
      ...provided,
      opacity: state.isDisabled ? 0.8 : 1,
      transition: 'opacity 300ms',
      cursor: 'pointer',
      outline: 'none'
    })
  }

  const handleCompanyChange = company => {
    if (company) {
      setActiveCompany(company)
      dispatch(clearCompanyState())
      history.push(`/${get(company, 'id')}`)
    }
  }

  return (
    <div className='CompanySelector'>
      <AsyncSelect
        cacheOptions
        defaultOptions={initialCompanies}
        loadOptions={loadOptions}
        getOptionLabel={o => o.name}
        getOptionValue={o => o.id}
        value={activeCompany}
        className='company-selector'
        classNamePrefix='company-selector-inner'
        isDisabled={isSingleCompany || companyLoading || loadingUserPermissions}
        styles={customStyles}
        onChange={handleCompanyChange}
        components={{ DropdownIndicator }}
        placeholder={__('Select company')}
        noOptionsMessage={({ inputValue }) =>
          inputValue ? __('No companies found') : __('Type to search companies')
        }
      />
    </div>
  )
}

export default CompanySelector
