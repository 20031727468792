import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { Dropdown, DatePicker, IconCalendar, InputErrorMessage } from 'shared/components'
import './styles.scss'

const DateInput = ({ handleChange, handleDateSelect, disabled, error, ...rest }) => {
  const pickerRef = useRef()
  const [displayDatePicker, setDatePickerDisplay] = useState(false)

  return (
    <div className='DateInput'>
      <button
        className='DateInput-button'
        onClick={() => {
          if (!disabled) {
            setDatePickerDisplay(!displayDatePicker)
          }
        }}
        type='button'
      >
        <IconCalendar />
      </button>
      <input
        className={error ? 'has-error' : ''}
        type='text'
        disabled={disabled}
        onChange={e => handleChange(e.target.value)}
        onFocus={() => {
          if (displayDatePicker) {
            setDatePickerDisplay(false)
          }
        }}
        placeholder='YYYY-MM-DD'
        {...rest}
      />
      {displayDatePicker && (
        <Dropdown ref={pickerRef} close={() => setDatePickerDisplay(!displayDatePicker)}>
          <div className='DateInput-popup'>
            <DatePicker
              selectDate={val => {
                handleChange(val)
                handleDateSelect(val)
                setDatePickerDisplay(false)
              }}
            />
          </div>
        </Dropdown>
      )}
      <InputErrorMessage text={error} />
    </div>
  )
}

DateInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  handleDateSelect: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string
}

DateInput.defaultProps = {
  error: '',
  disabled: false,
  handleDateSelect: () => {}
}

export default DateInput

// class TextInput extends Component {
//   constructor() {
//     super();
//     this.state = {
//       showModal: false,
//     };
//     this.handleChange = this.handleChange.bind(this);
//   }

//   handleChange(val, fromModal = false) {
//     const { handleChange } = this.props;
//     if (fromModal) {
//       this.setState({ showModal: false });
//     }
//     handleChange(val, fromModal);
//   }

//   render() {
//     const { showModal } = this.state;
//     const { error, disabled, ...rest } = this.props;
//     const { handleChange, ...restProps } = rest;

//     return (
//       <div className="DateInput">
//         <button
//           className="DateInput-button"
//           onClick={() => !disabled
//             && this.setState(prevState => ({ showModal: !prevState.showModal }))
//           }
//           type="button"
//         >
//           <IconCalendar />
//         </button>
//         <input
//           className={error ? 'has-error' : ''}
//           type="text"
//           disabled={disabled}
//           onChange={e => this.handleChange(e.target.value)}
//           placeholder="YYYY-MM-DD"
//           {...restProps}
//         />
//         {
//           showModal &&
//           (
//             <div className="DateInput-popup">
//               <DatePicker
//                 selectDate={val => this.handleChange(val, true)}
//               />
//             </div>
//           )
//         }
//         <InputErrorMessage text={error} />
//       </div>
//     );
//   }
// }

// TextInput.propTypes = {
//   handleChange: PropTypes.func.isRequired,
//   disabled: PropTypes.bool,
//   error: PropTypes.string,
// };

// TextInput.defaultProps = {
//   error: '',
//   disabled: false,
// };

// export default TextInput;
