import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport } from 'shared/helpers'
import {
  DirtyFormAlert,
  FileUploader,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  TextInput,
  Selector
} from 'shared/components'
import { validateRequiredValue, debouncedValidateRequiredValue } from 'shared/validation'
import { ssoProvidersList, errorMsg } from 'shared/constants'
import { addCustomerAccProvider } from 'src/customer/actions'
import './styles.scss'

const isGoogleTypeUsed = list => list.find(l => l.type === 'Google')

const validateList = options => {
  if (isGoogleTypeUsed(options)) {
    return options.filter(o => o.provider_type !== 'Google')
  }
  return options
}

const AddSSOProviderForm = ({ companyID, closeCb, refetchProvider, accountID, redirectUrl }) => {
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const providersList = validateList(ssoProvidersList)
  const [selectedProvider, setSelectedProvider] = useState(get(providersList, '[0]'))
  const [displayName, setDisplaylName] = useState('')
  const [displayNameError, setDisplayNameError] = useState('')
  const [redirectURL, setRedirectURL] = useState(redirectUrl || '')
  const [redirectURLError, setRedirectURLError] = useState('')
  // apple form
  const [serviceID, setServiceID] = useState('')
  const [serviceIDError, setServiceIDError] = useState('')
  const [teamID, setTeamID] = useState('')
  const [teamIDError, setTeamIDError] = useState('')
  const [keyID, setKeyID] = useState('')
  const [keyIDError, setKeyIDError] = useState('')
  const [appleFile, setAppleFile] = useState(null)
  const [appleFileError, setAppleFileError] = useState('')
  // google form
  const [clientID, setClientID] = useState('')
  const [clientIDError, setClientIDError] = useState('')
  const [clientSecret, setClientSecret] = useState('')
  const [clientSecretError, setClientSecretError] = useState('')
  // saml form
  const [samlFile, setSamlFile] = useState(null)
  const [samlFileError, setSamlFileError] = useState('')

  const sendSSOSetupRequest = data => {
    addCustomerAccProvider(accountID, companyID, data)
      .then(() => {
        closeCb()
        refetchProvider()
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer account sso provider', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        setLoading(false)
      })
  }

  const handleAppleSetup = () => {
    const reader = new FileReader()
    reader.onload = e => {
      const readKey = e.target.result
      const data = {
        providerId: 'apple',
        config: {
          displayName,
          clientId: serviceID,
          keyId: keyID,
          teamId: teamID,
          p8Content: readKey
        },
        redirect_uri: redirectURL
      }
      // console.log('data', data);
      sendSSOSetupRequest(data)
    }
    reader.readAsText(appleFile.file)
  }

  const handleSamlSetup = () => {
    const reader = new FileReader()
    reader.onload = e => {
      const readXml = e.target.result

      const formData = new FormData()
      formData.append('providerId', 'saml')
      formData.append('file', new Blob([readXml], { type: 'application/xml' }), 'metadata.xml')
      formData.append('displayName', displayName)
      formData.append('redirect_uri', redirectURL)

      sendSSOSetupRequest(formData)
    }
    reader.readAsText(samlFile.file)
  }

  const handleGoogleSetup = () => {
    const data = {
      providerId: 'google',
      displayName,
      config: {
        clientId: clientID,
        clientSecret
      },
      redirect_uri: redirectURL
    }
    sendSSOSetupRequest(data)
  }

  const validateValue = async (val, cb) => {
    let errors
    try {
      errors = await validateRequiredValue(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new user value', { value: val })
    }
    if (errors) {
      return false
    }
    return true
  }

  const validateFile = (type = 'SAML') => {
    if (type === 'SAML') {
      if (!samlFile) {
        setSamlFileError(errorMsg.requiredFile)
        return false
      }
    }
    if (type === 'SignInWithApple') {
      if (!appleFile) {
        setAppleFileError(errorMsg.requiredFile)
        return false
      }
    }
    return true
  }

  const isSamlDataValid = async () => {
    const isSamlNameValid = await validateValue(displayName, setDisplayNameError)
    const isFileValid = validateFile('SAML')
    const isRedirectValid = await validateValue(redirectURL, setRedirectURLError)

    return isSamlNameValid && isFileValid && isRedirectValid
  }

  const isGoogleDataValid = async () => {
    const isIDValid = await validateValue(clientID, setClientSecretError)
    const isSecretValid = await validateValue(clientSecret, setClientSecretError)
    const isRedirectValid = await validateValue(redirectURL, setRedirectURLError)

    return isIDValid && isSecretValid && isRedirectValid
  }

  const isAppleDataValid = async () => {
    const isServiceIDValid = await validateValue(serviceID, setServiceIDError)
    const isTeamIDValid = await validateValue(teamID, setTeamIDError)
    const isKeyIDValid = await validateValue(keyID, setKeyIDError)
    const isFileValid = validateFile('SignInWithApple')
    const isRedirectValid = await validateValue(redirectURL, setRedirectURLError)

    return isServiceIDValid && isTeamIDValid && isKeyIDValid && isFileValid && isRedirectValid
  }

  const validateData = async selectedProviderType => {
    if (selectedProviderType === 'SAML') {
      const isValid = await isSamlDataValid()
      return isValid
    }
    if (selectedProviderType === 'Google') {
      const isValid = await isGoogleDataValid()
      return isValid
    }
    if (selectedProviderType === 'SignInWithApple') {
      const isValid = await isAppleDataValid()
      return isValid
    }
    return false
  }

  const handleSubmit = async () => {
    const selectedProviderType = get(selectedProvider, 'provider_type') // Google OR SAML
    const isValid = await validateData(selectedProviderType)
    if (loading || !isValid) {
      return false
    }
    setLoading(true)

    if (selectedProviderType === 'SAML') {
      handleSamlSetup()
    }
    if (selectedProviderType === 'Google') {
      handleGoogleSetup()
    }
    if (selectedProviderType === 'SignInWithApple') {
      handleAppleSetup()
    }
    return true
  }

  const handleItemRemove = () => {
    setUploading(false)
    setProgress(0)
  }

  const handleWrongFileType = fileType => {
    Notification(
      'error',
      __('Wrong file type'),
      `${__('Only this file type can be uploaded')}: ${fileType}`
    )
  }

  const getFormContent = () => {
    const selectedProviderType = get(selectedProvider, 'provider_type')
    if (selectedProviderType === 'SignInWithApple') {
      return (
        <div className='google-form'>
          <div className='row'>
            <Label text={__('Service ID')} />
            <TextInput
              value={serviceID}
              error={serviceIDError}
              handleChange={val => {
                setDirty(true)
                setServiceID(val)
                debouncedValidateRequiredValue(val).then(err => setServiceIDError(err))
              }}
            />
          </div>
          <div className='row'>
            <Label text={__('Key ID')} />
            <TextInput
              value={keyID}
              error={keyIDError}
              handleChange={val => {
                setDirty(true)
                setKeyID(val)
                debouncedValidateRequiredValue(val).then(err => setKeyIDError(err))
              }}
            />
          </div>
          <div className='row'>
            <Label text={__('Team ID')} />
            <TextInput
              value={teamID}
              error={teamIDError}
              handleChange={val => {
                setDirty(true)
                setTeamID(val)
                debouncedValidateRequiredValue(val).then(err => setTeamIDError(err))
              }}
            />
          </div>
          <div className='row'>
            <Label text={__('Import your key')} iputId='key-file' />
            <FileUploader
              disabled={uploading}
              progress={progress}
              onUploadSuccess={(meta, allFiles) => {
                setAppleFile(allFiles[0])
              }}
              onWrongFileTypeReject={() => handleWrongFileType('.p8')}
              onItemRemove={handleItemRemove}
              label={__('Drag file or click to browse')}
              subLabel={`${__('Valid file type')}:`}
              validFileTypes='.p8'
              submitButtonDisabled
            />
            <InputErrorMessage text={appleFileError} />
          </div>
        </div>
      )
    }

    if (selectedProviderType === 'Google') {
      return (
        <div className='google-form'>
          <div className='row'>
            <Label text={__('Name')} inputId='display-name' />
            <TextInput
              id='display-name'
              value={displayName}
              error={displayNameError}
              handleChange={val => {
                setDirty(true)
                setDisplaylName(val)
                debouncedValidateRequiredValue(val).then(err => setDisplayNameError(err))
              }}
            />
          </div>
          <div className='row'>
            <Label text={__('Client ID')} />
            <TextInput
              value={clientID}
              error={clientIDError}
              handleChange={val => {
                setDirty(true)
                setClientID(val)
                debouncedValidateRequiredValue(val).then(err => setClientIDError(err))
              }}
            />
          </div>
          <div className='row'>
            <Label text={__('Client secret')} />
            <TextInput
              value={clientSecret}
              error={clientSecretError}
              handleChange={val => {
                setDirty(true)
                setClientSecret(val)
                debouncedValidateRequiredValue(val).then(err => setClientSecretError(err))
              }}
            />
          </div>
        </div>
      )
    }

    // SAML
    return (
      <div className='saml-form'>
        <div className='row'>
          <Label text={__('Name')} inputId='saml-name' />
          <TextInput
            id='display-name'
            value={displayName}
            error={displayNameError}
            handleChange={val => {
              setDirty(true)
              setDisplaylName(val)
              debouncedValidateRequiredValue(val).then(err => setDisplayNameError(err))
            }}
          />
        </div>
        <div className='row'>
          <Label text={__('Provider metadata file')} iputId='metadata-file' />
          <FileUploader
            disabled={uploading}
            progress={progress}
            onUploadSuccess={(meta, allFiles) => {
              setSamlFile(allFiles[0])
            }}
            onWrongFileTypeReject={() => handleWrongFileType('.xml')}
            onItemRemove={handleItemRemove}
            label={__('Drag file or click to browse')}
            subLabel={`${__('Valid file type')}:`}
            validFileTypes='.xml'
            submitButtonDisabled
          />
          <InputErrorMessage text={samlFileError} />
        </div>
      </div>
    )
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      title={__('Add SSO Provider')}
      size='sm'
    >
      <div className='AddSSOProviderForm'>
        <div className='select-container'>
          <Label text={__('Select provider')} inputId='provider-select' />
          <Selector
            handleChange={val => {
              const selected = providersList.find(s => s.value === val)
              setSelectedProvider(selected)
            }}
            options={providersList}
            value={get(selectedProvider, 'value')}
          />
        </div>
        <div className='docs-link'>
          <p>{__('Find Single Sign On documentation')}</p>
          <a
            href='https://docs.licensespring.com/docs/single-sign-on'
            target='_blank'
            rel='noopener noreferrer'
          >
            {__('here')}
          </a>
        </div>
        <div className='provider-form'>{getFormContent()}</div>
        <div className='redirect-row'>
          <Label text={__('Redirect URI')} inputId='redirect-url' />
          <TextInput
            id='redirect-url'
            value={redirectURL}
            error={redirectURLError}
            handleChange={val => {
              setDirty(true)
              setRedirectURL(val)
              debouncedValidateRequiredValue(val).then(err => setRedirectURLError(err))
            }}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

AddSSOProviderForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  refetchProvider: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  redirectUrl: PropTypes.string
}

AddSSOProviderForm.defaultProps = {
  redirectUrl: ''
}

export default AddSSOProviderForm
