import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  DashboardButton,
  IconCustomers,
  IconLicense,
  IconOrder,
  IconProduct,
  IconUser,
  Page
} from 'shared/components'
import { userPermissions } from 'shared/constants'
import { getProductNumber, checkUserPermission } from 'shared/helpers'
import ActivityAnalytics from '../ActivityAnalytics'
import './styles.scss'

const Dashboard = ({ activeCompany, company, name }) => {
  const canReadAnalytics = checkUserPermission(userPermissions.analytics_read)
  const productCount = getProductNumber()
  const customersCount = get(company, 'customers_count') || 0
  const ordersCount = get(company, 'orders_count') || 0
  const activeLicensesCount = get(company, 'licenses_active_count') || 0
  const inactiveLicensesCount = get(company, 'licenses_inactive_count') || 0

  const sidebarSize = useSelector(state => get(state, 'account.sidebarSize'))
  const maxWidth = sidebarSize === 'lg' ? '1050px' : '1440px'

  return (
    <Page
      description={
        <div>
          {__('Welcome back')}
          &nbsp;
          {name && <NavLink to='/profile'>{name}</NavLink>}
        </div>
      }
      showBreadcrumbs={false}
      title={__('Dashboard')}
    >
      <div style={{ maxWidth }} className='Dashboard'>
        <div className='dashboard-navigation'>
          <DashboardButton path={`/${activeCompany}/licenses`}>
            <IconLicense height='30px' width='30px' />
            <h3>{__('Licenses')}</h3>
            <p className='multi'>
              <span>{`${__('Active')}: ${activeLicensesCount}`}</span>
              <span>{`${__('Inactive')}: ${inactiveLicensesCount}`}</span>
            </p>
          </DashboardButton>
          <DashboardButton path={`/${activeCompany}/orders`}>
            <IconOrder height='30px' width='30px' />
            <h3>{__('Orders')}</h3>
            <p>{ordersCount}</p>
          </DashboardButton>
          <DashboardButton path={`/${activeCompany}/customers`}>
            <IconCustomers height='30px' width='30px' />
            <h3>{__('Customers')}</h3>
            <p>{customersCount}</p>
          </DashboardButton>
          <DashboardButton path={`/${activeCompany}/products`}>
            <IconProduct height='30px' width='30px' />
            <h3>{__('Configure Products')}</h3>
            <p>{productCount}</p>
          </DashboardButton>
          <DashboardButton path={`/${activeCompany}/account`}>
            <IconUser height='30px' width='30px' />
            <h3>{__('Account settings')}</h3>
            <p>&nbsp;</p>
          </DashboardButton>
        </div>
        {canReadAnalytics && <ActivityAnalytics activeCompany={activeCompany} />}
      </div>
    </Page>
  )
}

Dashboard.propTypes = {
  activeCompany: PropTypes.number.isRequired,
  company: PropTypes.object.isRequired,
  name: PropTypes.string
}

Dashboard.defaultProps = {
  name: ''
}

export default Dashboard
