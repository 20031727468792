import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import AsyncSelect from 'react-select/lib/Async'
import api from 'shared/api'

export const ExistingCustomerSelector = ({ companyID, filter, handleChange, ...rest }) => {
  const setOrderBy = val => {
    switch (val) {
      case 'name': {
        return 'first_name'
      }
      default: {
        return 'first_name'
      }
    }
  }

  const setPlaceholder = val => {
    switch (val) {
      case 'name': {
        return __('Search by name')
      }
      default: {
        return __('Select')
      }
    }
  }

  const setOptionLabel = (val, option) => {
    switch (val) {
      case 'name': {
        return `${option.first_name} ${option.last_name}`
      }
      default: {
        return `${option.first_name} ${option.last_name}`
      }
    }
  }

  const setOptionValue = (val, option) => {
    switch (val) {
      case 'name': {
        return option.organisation
      }
      default: {
        return option.organisation
      }
    }
  }

  const orderBy = setOrderBy(filter)
  const fetchCustomers = (query = '') =>
    api
      .get(`/api/v1/customers/?company=${companyID}&limit=50&order_by=${orderBy}&name=${query}`)
      .then(res => {
        const results = get(res, 'data.results') || []
        return results
      })
      .catch(() => [])

  return (
    <div className='Selector existing-customer'>
      <AsyncSelect
        defaultOptions
        loadOptions={fetchCustomers}
        className='selector-container'
        classNamePrefix='selector-inner'
        isClearable
        getOptionLabel={o => setOptionLabel(filter, o)}
        getOptionValue={o => setOptionValue(filter, o)}
        onChange={option => handleChange(option)}
        placeholder={setPlaceholder(filter)}
        {...rest}
      />
    </div>
  )
}

ExistingCustomerSelector.propTypes = {
  companyID: PropTypes.number.isRequired,
  filter: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired
}

export default ExistingCustomerSelector
