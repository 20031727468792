import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const Widget = ({ label, value }) => (
  <div className='Widget'>
    <header>{label}</header>
    <main>{value}</main>
  </div>
)

Widget.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

export default Widget
