import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useHistory } from 'react-router-dom'
import {
  AsyncSelector,
  ConfirmationPopup,
  Selector,
  Button,
  Checkbox,
  InputErrorMessage,
  Label,
  Notification,
  RadioBtn,
  TextInput,
  PhoneNumberInput
} from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { errorMsg } from 'shared/constants'
import { getCompanyConfigFieldValue } from 'shared/companyConfig'
import { getOrders } from 'src/order/actions'
import api from 'shared/api'
import ExistingOrderDetails from './ExistingOrderDetails'
import { ExistingCustomerSelector } from './ExistingCustomerSelector'
import * as validation from '../../validation'
import './styles.scss'

const CustomerStep = ({ companyID, formState, handleForward, handleSubmit, orderID, setDirty }) => {
  const history = useHistory()

  const [loading, setLoading] = useState(false)
  const [addCustomer, setAddCustomer] = useState(get(formState, 'add_customer'))
  const [orderId, setOrderID] = useState(get(formState, 'orderId'))
  const [orderReference, setOrderReference] = useState(get(formState, 'orderReference'))
  const [reference, setReference] = useState(get(formState, 'customerReference'))
  const [referenceError, setReferenceError] = useState('')
  const [email, setEmail] = useState(get(formState, 'customerEmail'))
  const [emailError, setEmailError] = useState('')
  const [emailLoading, setEmailLoading] = useState(false)
  const [firstName, setFirstName] = useState(get(formState, 'customerFirstName'))
  const [firstNameError, setFirstNameError] = useState('')
  const [lastName, setLastName] = useState(get(formState, 'customerLastName'))
  const [lastNameError, setLastNameError] = useState('')
  const [address, setAddress] = useState(get(formState, 'customerAddress'))
  const [addressError, setAddressError] = useState('')
  const [city, setCity] = useState(get(formState, 'customerCity'))
  const [cityError, setCityError] = useState('')
  const [stateProvince, setStateProvince] = useState(get(formState, 'customerState'))
  const [stateProvinceError, setStateProvinceError] = useState('')
  const [country, setCountry] = useState(get(formState, 'customerCountry'))
  const [countryError, setCountryError] = useState('')
  const [zipcode, setZipcode] = useState(get(formState, 'customerZipcode'))
  const [zipcodeError, setZipcodeError] = useState('')
  const [phone, setPhone] = useState(get(formState, 'customerPhoneNumber'))
  const [phoneError, setPhoneError] = useState('')
  const [organization, setOrganization] = useState(get(formState, 'customerOrganization'))
  const [organizationError, setOrganizationError] = useState('')
  const [isManager, setIsManager] = useState(get(formState, 'is_manager'))
  const [isManagerError, setManagerError] = useState('')
  const [selectedCustomerType, setSelectedCustomerType] = useState(
    get(formState, 'selectedCustomerType')
  )
  const [existingCustomer, setExistingCustomer] = useState(get(formState, 'existingCustomer'))
  const [existingCustomerError, setExistingCustomerError] = useState('')

  const [searchBy, setSearchBy] = useState('email__icontains')
  const [isExistingOrderPopupDisplayed, setExistingOrderPopupDisplay] = useState(false)
  const [existingOrderID, setExistingOrderID] = useState(null)

  useEffect(() => {
    setAddCustomer(get(formState, 'add_customer'))
    setOrderID(get(formState, 'orderId'))
    setReference(get(formState, 'customerReference'))
    setEmail(get(formState, 'customerEmail'))
    setFirstName(get(formState, 'customerFirstName'))
    setLastName(get(formState, 'customerLastName'))
    setAddress(get(formState, 'customerAddress'))
    setCity(get(formState, 'customerCity'))
    setStateProvince(get(formState, 'customerState'))
    setCountry(get(formState, 'customerCountry'))
    setZipcode(get(formState, 'customerZipcode'))
    setPhone(get(formState, 'customerPhoneNumber'))
    setOrganization(get(formState, 'customerOrganization'))
    setExistingCustomer(get(formState, 'existingCustomer'))
  }, [formState])

  if (orderID) {
    return (
      <div className='CustomerStep'>
        <ExistingOrderDetails formState={formState} handleForward={handleForward} />
      </div>
    )
  }

  const validateOrderID = async () => {
    setLoading(true)
    let doesExist = false
    try {
      const ordersList = await getOrders(companyID, 0, orderId, 'store_id')
      const existingOrdersCount = get(ordersList, 'data.count')
      if (existingOrdersCount > 0) {
        const existingOrder = get(ordersList, 'data.results[0]')
        setExistingOrderID(get(existingOrder, 'id'))
        setDirty(false)
        doesExist = true
      }
      setLoading(false)
    } catch (err) {
      setLoading(false)
      sendErrorReport(err, 'Cannot check existing orders list')
    }

    if (doesExist) {
      setExistingOrderPopupDisplay(true)
    }
    return doesExist
  }

  const validateCustomerEmail = async val => {
    setEmailLoading(true)
    let errors

    try {
      errors = await validation.debouncedValidateNewUserEmail(val, companyID)
      setEmailLoading(false)
      setEmailError(errors)
    } catch (err) {
      setEmailLoading(false)
      sendErrorReport(err, 'Cannot validate customer email', { value: val })
    }

    if (errors) {
      return false
    }
    return true
  }

  const validateValue = async (val, cb) => {
    setLoading(true)
    let errors

    try {
      errors = await validation.validateRequiredValue(val)
      setLoading(false)
      cb(errors)
    } catch (err) {
      setLoading(false)
      sendErrorReport(err, 'Cannot validate customer field', { value: val })
    }

    if (errors) {
      return false
    }

    return true
  }

  const handleExistingCustomerChange = val => {
    setDirty(true)
    setExistingCustomer(val)
    setExistingCustomerError('')
  }

  const resetCustomerState = () => {
    // clean customer inner state
    setExistingCustomer(null)
    setExistingCustomerError('')
    setReference('')
    setReferenceError('')
    setEmail('')
    setEmailLoading(false)
    setEmailError('')
    setFirstName('')
    setFirstNameError('')
    setLastName('')
    setLastNameError('')
    setLastName('')
    setLastNameError('')
    setAddress('')
    setAddressError('')
    setCity('')
    setCityError('')
    setStateProvince('')
    setStateProvinceError('')
    setCountry('')
    setCountryError('')
    setZipcode('')
    setZipcodeError('')
    setPhone('')
    setPhoneError('')
    setOrganization('')
    setOrganizationError('')
  }

  const handleCustomerTypeChange = val => {
    setDirty(true)
    setSelectedCustomerType(val)
    resetCustomerState()
  }

  const handleEmailChange = val => {
    setDirty(true)
    setEmail(val)
    validateCustomerEmail(val)
  }

  const customNewCustomerValidation = async (overrideValidation = []) => {
    const emailValid = overrideValidation.includes('email')
      ? await validateValue(email, setEmailError)
      : true
    const firstNameValid = overrideValidation.includes('firstname')
      ? await validateValue(firstName, setFirstNameError)
      : true
    const lastNameValid = overrideValidation.includes('lastname')
      ? await validateValue(lastName, setLastNameError)
      : true
    const addressValid = overrideValidation.includes('address')
      ? await validateValue(address, setAddressError)
      : true
    const cityValid = overrideValidation.includes('city')
      ? await validateValue(city, setCityError)
      : true
    const stateValid = overrideValidation.includes('state')
      ? await validateValue(stateProvince, setStateProvince)
      : true
    const countryValid = overrideValidation.includes('country')
      ? await validateValue(country, setCountryError)
      : true
    const zipcodeValid = overrideValidation.includes('zipcode')
      ? await validateValue(zipcode, setZipcodeError)
      : true
    const phoneValid = overrideValidation.includes('phone')
      ? await validateValue(phone, setPhoneError)
      : true
    const organizationValid = overrideValidation.includes('organization')
      ? await validateValue(organization, setOrganizationError)
      : true
    const referenceValid = overrideValidation.includes('reference')
      ? await validateValue(reference, setReferenceError)
      : true

    return (
      !!emailValid &&
      !!firstNameValid &&
      !!lastNameValid &&
      !!phoneValid &&
      !!addressValid &&
      !!organizationValid &&
      !!stateValid &&
      !!zipcodeValid &&
      !!cityValid &&
      !!countryValid &&
      !!referenceValid
    )
  }

  const validateNewCustomer = () => {
    const overrideValidation = getCompanyConfigFieldValue(companyID, 'customerRequiredFields')

    if (!overrideValidation) {
      return validateCustomerEmail(email)
    }

    return customNewCustomerValidation(overrideValidation)
  }

  const validateExistingCustomer = async () => {
    setLoading(true)

    if (!existingCustomer) {
      const errors = await validation.validateExistingUserEmail(null)
      setExistingCustomerError(errors)
      setLoading(false)

      if (errors) {
        return false
      }
    }

    const overrideValidation = getCompanyConfigFieldValue(companyID, 'customerRequiredFields') || []
    const shouldValidateEmail = overrideValidation.includes('email')

    if (!overrideValidation || shouldValidateEmail) {
      const errors = await validation.validateExistingUserEmail(
        get(existingCustomer, 'email') || null
      )
      setExistingCustomerError(errors)
      setLoading(false)

      if (errors) {
        return false
      }
    }

    return true
  }

  const validateCustomer = () => {
    if (orderID) {
      return true
    }

    if (!addCustomer) {
      return true
    }

    if (selectedCustomerType === 'customer-existing') {
      return validateExistingCustomer()
    }

    return validateNewCustomer()
  }

  const validateAssignManager = () => {
    if (!addCustomer && isManager) {
      setManagerError(errorMsg.customerRequiredAssignManager)
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isCustomerValid = await validateCustomer()
    const isAssignManagerValid = validateAssignManager()
    return !!isCustomerValid && !!isAssignManagerValid
  }

  const doesFormExist = async () => {
    if (!orderId) {
      return false
    }
    const isExisting = await validateOrderID()
    return isExisting
  }

  const handleExistingFormConfirm = () => {
    history.push(`/${companyID}/licenses/issue-licenses/${existingOrderID}`)
  }

  const handleFormSubmit = async e => {
    e.preventDefault()

    if (loading) {
      return false
    }

    const isExistingOrder = await doesFormExist()
    if (isExistingOrder) {
      return false
    }

    const isValid = await isFormValid()
    if (!isValid) {
      Notification('error', __('Please enter correct information and try again'))
      return false
    }

    setLoading(true)

    const data = {
      add_customer: addCustomer,
      orderId,
      orderReference,
      customerReference: reference,
      customerFirstName: firstName,
      customerLastName: lastName,
      customerEmail: email,
      customerAddress: address,
      customerCity: city,
      customerState: stateProvince,
      customerCountry: country,
      customerZipcode: zipcode,
      customerPhoneNumber: phone,
      customerOrganization: organization,
      is_manager: isManager,
      selectedCustomerType,
      existingCustomer: selectedCustomerType === 'customer-existing' ? existingCustomer : null
    }

    return handleSubmit(data)
  }

  return (
    <div className='CustomerStep'>
      <form className='CustomerStep-form' id='customerDataForm' onSubmit={handleFormSubmit}>
        <div className='order-details'>
          <div className='form-row'>
            <div className='form-column'>
              <Label text={__('Order ID')} inputId='customer-order-id' />
              <TextInput
                id='customer-order-id'
                handleChange={val => {
                  setDirty(true)
                  setOrderID(val)
                }}
                value={orderId}
              />
            </div>
            <div className='form-column'>
              <Label text={__('Order reference')} inputId='order-reference' />
              <TextInput
                id='order-reference'
                handleChange={val => {
                  setDirty(true)
                  setOrderReference(val)
                }}
                value={orderReference}
              />
            </div>
          </div>
          <div className='form-row is-customer-manager'>
            <Checkbox
              label={__('Assign the customer as a License Manager for the Order')}
              inputId='is-license-manager'
              checked={isManager}
              handleChange={val => {
                setDirty(true)
                setManagerError('')
                setIsManager(val)
              }}
            />
            <InputErrorMessage text={isManagerError} />
          </div>
          <div className='form-row add-customer'>
            <Checkbox
              label={__('Add customer to the order')}
              inputId='add-customer'
              checked={addCustomer}
              handleChange={val => {
                setDirty(true)
                resetCustomerState()
                setManagerError('')
                setAddCustomer(val)
              }}
            />
          </div>
        </div>
        {addCustomer && (
          <div className='customer-details'>
            <h4>{__('Customer details')}</h4>
            <div className='form-row'>
              <div className='form-column radio-btns'>
                <RadioBtn
                  name='customer-select'
                  inputId='customer-existing'
                  label={__('Existing customer')}
                  value='customer-existing'
                  checked={selectedCustomerType === 'customer-existing'}
                  handleChange={val => handleCustomerTypeChange(val)}
                />
                <RadioBtn
                  name='customer-select'
                  inputId='customer-new'
                  label={__('New customer')}
                  value='customer-new'
                  checked={selectedCustomerType === 'customer-new'}
                  handleChange={val => handleCustomerTypeChange(val)}
                />
              </div>
            </div>
            <div className='customer-details-input-fields'>
              {selectedCustomerType === 'customer-existing' && (
                <div className='existing-customer-selector'>
                  {!getCompanyConfigFieldValue(companyID, 'existingCustomerFilter') ? (
                    <div className='existing-customer'>
                      <div className='search-selector'>
                        <Selector
                          handleChange={val => setSearchBy(val)}
                          options={[
                            {
                              label: __('Search by email'),
                              value: 'email__icontains'
                            },
                            { label: __('Search by name'), value: 'name' },
                            {
                              label: __('Search by company name'),
                              value: 'company_name__icontains'
                            },
                            {
                              label: __('Search by customer reference'),
                              value: 'reference__icontains'
                            }
                          ]}
                          value={searchBy}
                        />
                      </div>
                      <div className='customer-selector'>
                        <AsyncSelector
                          isClearable
                          valueKey='id'
                          labelKey='email'
                          getOptionLabel={o => {
                            if (searchBy === 'name') {
                              let label
                              if (o.first_name && o.last_name) {
                                label = `${o.first_name} ${o.last_name}`
                              } else if (o.email) {
                                label = o.email
                              } else if (o.company_name) {
                                label = o.company_name
                              } else if (o.reference) {
                                label = o.reference
                              } else {
                                label = o.id
                              }
                              return label
                            }

                            let label
                            switch (searchBy) {
                              case 'email__icontains':
                                label = o.email
                                break
                              case 'reference__icontains':
                                // Show both reference and email
                                label = `${o.reference || ''} (${o.email || 'No email'})`
                                break
                              case 'company_name__icontains':
                                label = `${o.company_name || ''} (${o.email || 'No email'})`
                                break
                              default:
                                label = o.email
                            }

                            // Add additional info if primary field is empty
                            if (!label) {
                              if (o.first_name && o.last_name) {
                                label = `${o.first_name} ${o.last_name}`
                              } else if (o.email) {
                                label = o.email
                              } else if (o.reference) {
                                label = o.reference
                              } else if (o.company_name) {
                                label = o.company_name
                              } else {
                                label = o.id
                              }
                            }

                            return label
                          }}
                          id='customer-existing-select'
                          placeholder={__('Select existing customer')}
                          fetchOptions={val =>
                            api.get(
                              `/api/v1/customers/?company=${companyID}&limit=50&order_by=email&${searchBy}=${val}`
                            )
                          }
                          fetchInitialOptions={() =>
                            api.get(
                              `/api/v1/customers/?company=${companyID}&limit=50&order_by=email`
                            )
                          }
                          handleChange={val => handleExistingCustomerChange(val)}
                          value={get(existingCustomer, 'id') || ''}
                        />
                      </div>
                    </div>
                  ) : (
                    <div className='existing-customer-selector'>
                      <ExistingCustomerSelector
                        id='customer-existing-select'
                        companyID={companyID}
                        filter={getCompanyConfigFieldValue(companyID, 'existingCustomerFilter')}
                        handleChange={val => handleExistingCustomerChange(val)}
                      />
                    </div>
                  )}
                  <InputErrorMessage text={existingCustomerError} />
                </div>
              )}
              {selectedCustomerType === 'customer-new' && (
                <div className='new-customer-form'>
                  <div className='form-row'>
                    <div className='form-column wide'>
                      <Label text={__('Email')} inputId='customer-order-email' />
                      <TextInput
                        id='customer-order-email'
                        handleChange={handleEmailChange}
                        value={email}
                        error={emailError}
                        loading={emailLoading}
                        type='email'
                      />
                    </div>
                    <div className='form-column wide'>
                      <Label text={__('Customer reference')} inputId='customer-reference' />
                      <TextInput
                        id='customer-reference'
                        handleChange={val => {
                          setDirty(true)
                          setReference(val)
                        }}
                        value={reference}
                        error={referenceError}
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-column'>
                      <Label text={__('First name')} inputId='customer-order-firstname' />
                      <TextInput
                        id='customer-order-firstname'
                        handleChange={val => {
                          setDirty(true)
                          setFirstName(val)
                          setFirstNameError('')
                        }}
                        value={firstName}
                        error={firstNameError}
                      />
                    </div>
                    <div className='form-column'>
                      <Label text={__('Last name')} inputId='customer-order-lastname' />
                      <TextInput
                        id='customer-order-lastname'
                        handleChange={val => {
                          setDirty(true)
                          setLastName(val)
                          setLastNameError('')
                        }}
                        value={lastName}
                        error={lastNameError}
                      />
                    </div>
                    <div className='form-column'>
                      <Label text={__('Address')} inputId='customer-order-address' />
                      <TextInput
                        id='customer-order-address'
                        handleChange={val => {
                          setDirty(true)
                          setAddress(val)
                          setAddressError('')
                        }}
                        value={address}
                        error={addressError}
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-column'>
                      <Label text={__('City')} inputId='customer-order-city' />
                      <TextInput
                        id='customer-order-city'
                        handleChange={val => {
                          setDirty(true)
                          setCity(val)
                          setCityError('')
                        }}
                        value={city}
                        error={cityError}
                      />
                    </div>
                    <div className='form-column'>
                      <Label text={__('State / Province')} inputId='customer-order-state' />
                      <TextInput
                        id='customer-order-state'
                        handleChange={val => {
                          setDirty(true)
                          setStateProvince(val)
                          setStateProvinceError('')
                        }}
                        value={stateProvince}
                        error={stateProvinceError}
                      />
                    </div>
                    <div className='form-column'>
                      <Label text={__('Country')} inputId='customer-order-country' />
                      <TextInput
                        id='customer-order-country'
                        handleChange={val => {
                          setDirty(true)
                          setCountry(val)
                          setCountryError('')
                        }}
                        value={country}
                        error={countryError}
                      />
                    </div>
                  </div>
                  <div className='form-row'>
                    <div className='form-column'>
                      <Label text={__('Zipcode / Postcode')} inputId='customer-order-zipcode' />
                      <TextInput
                        id='customer-order-zipcode'
                        handleChange={val => {
                          setDirty(true)
                          setZipcode(val)
                          setZipcodeError('')
                        }}
                        value={zipcode}
                        error={zipcodeError}
                      />
                    </div>
                    <div className='form-column'>
                      <Label text={__('Phone number')} inputId='customer-order-phone' />
                      <PhoneNumberInput
                        handleChange={val => {
                          setDirty(true)
                          setPhone(val)
                          setPhoneError('')
                        }}
                        value={phone}
                        error={phoneError}
                      />
                    </div>
                    <div className='form-column'>
                      <Label text={__('Company Name')} inputId='customer-order-organization' />
                      <TextInput
                        id='customer-order-organization'
                        handleChange={val => {
                          setDirty(true)
                          setOrganization(val)
                          setOrganizationError('')
                        }}
                        value={organization}
                        error={organizationError}
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </form>
      <div className='CustomerStep-actions'>
        <Button
          theme='info'
          size='lg'
          onClick={handleFormSubmit}
          disabled={loading || emailLoading}
        >
          {__('Next')}
        </Button>
      </div>
      {isExistingOrderPopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setExistingOrderPopupDisplay(false)
            setExistingOrderID()
          }}
          confirmCb={handleExistingFormConfirm}
          title={__(
            'Order with this ID already exists. Do you want to issue licenses for this existing order?'
          )}
          confirmText={__('Confirm')}
          theme='error'
        />
      )}
    </div>
  )
}

CustomerStep.propTypes = {
  companyID: PropTypes.number.isRequired,
  formState: PropTypes.object.isRequired,
  handleForward: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  orderID: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setDirty: PropTypes.func.isRequired
}

CustomerStep.defaultProps = {
  orderID: null
}

export default CustomerStep
