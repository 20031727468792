import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { DirtyFormAlert, Label, Modal, Notification, Selector } from 'shared/components'
import {
  sendErrorReport,
  mapPlatformUserLabelsToSelector,
  mapPermissionTemplatesToSelector
} from 'shared/helpers'
import { assignTemplateToLabel } from 'src/account/actions'

const AssignTemplateForm = ({ closeCb, confirmCb }) => {
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const userPermissionsTemplates = useSelector(state =>
    get(state, 'company.userPermissionsTemplates')
  )
  const companyUserLabels = useSelector(state => get(state, 'company.userLabels'))
  const labelsOptions = mapPlatformUserLabelsToSelector(companyUserLabels)
  const templatesOptions = mapPermissionTemplatesToSelector(userPermissionsTemplates)

  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false)
  const [selectedLabel, setSelectedLabel] = useState(get(labelsOptions, '[0].value'))
  const [selectedTemplate, setSelectedTemplate] = useState(get(templatesOptions, '[0].value'))

  const handleSubmit = async () => {
    if (!dirty) {
      closeCb()
      return false
    }
    if (isLoading) {
      return false
    }

    setLoading(true)

    const data = {
      template_id: selectedTemplate
    }
    assignTemplateToLabel(companyID, selectedLabel, data)
      .then(() => {
        Notification(
          'success',
          __('Changes saved successfully'),
          __('Permissions from template assigned to users with selected label')
        )
        confirmCb()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit custom field on product', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      title={__('Assign template to users with selected label')}
      size='sm'
    >
      <div className='AssignTemplateForm'>
        <div>
          <Label inputId='permission-templates-selector' text={__('Select template')} />
          <Selector
            options={templatesOptions}
            value={selectedTemplate}
            handleChange={val => {
              setDirty(true)
              setSelectedTemplate(val)
            }}
          />
        </div>
        <div>
          <Label inputId='user-label-selector' text={__('User label')} />
          <Selector
            options={labelsOptions}
            value={selectedLabel}
            handleChange={val => {
              setDirty(true)
              setSelectedLabel(val)
            }}
          />
        </div>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </Modal>
  )
}

AssignTemplateForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired
}

AssignTemplateForm.defaultProps = {
  template: null
}

export default AssignTemplateForm
