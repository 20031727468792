import api from 'shared/api'

export const updateUserData = (id, data, companyID) =>
  api.patch(`/api/v1/users/${id}/?company=${companyID}`, data)

export const disable2FA = userId => api.patch(`/api/v1/users/${userId}/`, { twofa_required: false })

export const setupOTP = () => api.post('/api/v1/auth/require-configure-otp/')

export const removeOTP = () => api.post('/api/v1/auth/remove-otp/')
