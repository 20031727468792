import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  IconSearch,
  List,
  Page,
  Selector,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TextInput,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components'
import { displayValue, sendErrorReport, checkUserPermission } from 'shared/helpers'
import { searchType, userPermissions } from 'shared/constants'
import { getLicenseManagers } from 'src/order/actions'
import OrderManagerForm from 'src/order/OrderContainer/components/OrderManagers/components/OrderManagerForm'
import DefaultPermissions from './DefaultPermissions'

const UPLicenseManagers = () => {
  const canManageOrders = checkUserPermission(userPermissions.orders_write)
  const history = useHistory()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const manPermissions = useSelector(state => get(state, 'company.managerPermissions'))
  const managerPermissions = manPermissions.filter(p => {
    // TODO return when implement those two
    if (p.code === 'create_license_policy') {
      return false
    }
    if (p.code === 'show_license_usage_report') {
      return false
    }
    return true
  })

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState('')
  const [activeQuery, setActiveQuery] = useState('')
  const [searchBy, setSearchBy] = useState(searchType.trueEmail)
  const [sort, setSort] = useState([
    {
      desc: true,
      id: 'true_email'
    }
  ])
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [page, setPage] = useState(0)
  const [totalPages, setTotalPages] = useState(0)
  const [isAddManagerDisplayed, setAddManagerDisplayed] = useState(false)

  const fetchLicenseManagers = (tablePage, searchQuery, tableSearchType, tableSort, tableRows) => {
    setLoading(true)
    getLicenseManagers(companyID, tablePage, searchQuery, tableSearchType, tableSort, tableRows)
      .then(res => {
        const managersList = get(res, 'data.results') || []
        const pages = Math.ceil(get(res, 'data.count') / rowsPerPage)
        setData(managersList)
        setTotalPages(pages)
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch license managers list')
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchLicenseManagers(page, activeQuery, searchBy, sort, rowsPerPage)
  }, [])

  const handleSearchClear = () => {
    setActiveQuery('')
    setQuery('')
    fetchLicenseManagers(page, '', searchBy, sort, rowsPerPage)
  }

  const handleSearchSubmit = e => {
    e.preventDefault()
    setActiveQuery(query)
    setPage(0)
    fetchLicenseManagers(0, query, searchBy, sort, rowsPerPage)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
    fetchLicenseManagers(newPage, activeQuery, searchBy, sort, rowsPerPage)
  }

  const handleSortChange = newSorted => {
    setSort(newSorted)
    setPage(0)
    fetchLicenseManagers(0, activeQuery, searchBy, newSorted, rowsPerPage)
  }

  const handlePageSizeChange = (newPageSize, newPage) => {
    setRowsPerPage(newPageSize)
    setPage(newPage)
    fetchLicenseManagers(newPage, activeQuery, searchBy, sort, newPageSize)
  }

  const redirectToManagerPage = rowData => {
    const managerID = get(rowData, 'original.id')
    history.push(`/${companyID}/orders/license-managers/${managerID}`)
  }

  const handleAddOrderManagerClick = cb => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.orders_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <Page title={__('License managers')}>
      <Tabs>
        <TabsHeader>
          <Tab>{__('Managers list')}</Tab>
          <Tab>{__('Default permissions')}</Tab>
        </TabsHeader>
        <TabContent>
          <div className='UPLicenseManagers license-managers'>
            <div className='list-header'>
              <div>
                <div className='search-selector'>
                  <Selector
                    handleChange={val => setSearchBy(val)}
                    options={[
                      {
                        label: __('Search by email'),
                        value: searchType.trueEmail
                      },
                      {
                        label: __('Search by first name'),
                        value: searchType.firstName
                      },
                      {
                        label: __('Search by last name'),
                        value: searchType.lastName
                      },
                      {
                        label: __('Search by phone number'),
                        value: searchType.phoneNumber
                      }
                    ]}
                    value={searchBy}
                  />
                </div>
                <form onSubmit={handleSearchSubmit}>
                  <TextInput handleChange={val => setQuery(val)} value={query} />
                  {activeQuery && (
                    <button type='button' onClick={handleSearchClear} disabled={loading}>
                      &times;
                    </button>
                  )}
                  <Button type='submit' theme='info' disabled={loading}>
                    <IconSearch fill='#fff' />
                  </Button>
                </form>
              </div>
              <div>
                <Button
                  theme='info'
                  onClick={() => handleAddOrderManagerClick(setAddManagerDisplayed)}
                >
                  {__('Add license manager')}
                </Button>
              </div>
            </div>
            <List
              clickable
              columns={[
                {
                  accessor: 'true_email',
                  Header: __('Email'),
                  Cell: cellData => displayValue(cellData.value)
                },
                {
                  accessor: 'first_name',
                  Header: __('First name'),
                  Cell: cellData => displayValue(cellData.value)
                },
                {
                  accessor: 'last_name',
                  Header: __('Last name'),
                  Cell: cellData => displayValue(cellData.value)
                },
                {
                  accessor: 'phone_number',
                  Header: __('Phone number'),
                  Cell: cellData => displayValue(cellData.value)
                }
              ]}
              data={data}
              handleClick={rowData => redirectToManagerPage(rowData)}
              minRows={1}
              loading={loading}
              defaultSorted={sort}
              manual
              page={page}
              pages={totalPages}
              onPageChange={handlePageChange}
              onSortedChange={handleSortChange}
              showPagination={totalPages > 1}
              showPageSizeOptions
              pageSize={rowsPerPage}
              onPageSizeChange={(pageSize, pageIndex) => handlePageSizeChange(pageSize, pageIndex)}
            />
          </div>
        </TabContent>
        <TabContent>
          <DefaultPermissions companyID={companyID} permissionsList={managerPermissions} />
        </TabContent>
      </Tabs>
      {isAddManagerDisplayed && (
        <OrderManagerForm
          companyID={companyID}
          closeCb={() => setAddManagerDisplayed(false)}
          confirmCb={list => {
            const managerID = get(list, '[0].id')
            if (managerID) {
              history.push(`/${companyID}/orders/license-managers/${managerID}`)
            } else {
              setAddManagerDisplayed(false)
            }
          }}
        />
      )}
    </Page>
  )
}

export default UPLicenseManagers
