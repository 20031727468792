import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { Page, Tab, Tabs, TabContent, TabsHeader } from 'shared/components'
import UsersList from './UsersList'
import UserLabels from './UserLabels'

const PlatformUsers = () => {
  const location = useLocation()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const queryParams = get(location, 'search')
    if (!queryParams) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1)
    }
  }, [location])

  return (
    <Page title={__('All Users')}>
      <Tabs
        defaultFocus
        selectedIndex={selectedTab}
        onSelect={tabIndex => {
          history.push({
            search: `?st=${tabIndex}`
          })
          setSelectedTab(tabIndex)
        }}
      >
        <TabsHeader>
          <Tab>{__('Users')}</Tab>
          <Tab>{__('User labels')}</Tab>
        </TabsHeader>
        <TabContent>
          <UsersList />
        </TabContent>
        <TabContent>
          <UserLabels />
        </TabContent>
      </Tabs>
    </Page>
  )
}

export default PlatformUsers
