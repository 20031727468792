import React from 'react'
import PropTypes from 'prop-types'

const TrialNotice = ({ theme, trial }) => (
  <div className={`PageNotice PageNotice-theme--${theme} TrialNotice`}>
    <div className='PageNotice-body'>
      {`${__('You have')} ${trial.remaining_days} ${
        trial.remaining_days > 1 ? __('days') : __('day')
      } ${__('remaining in your trial')}.`}
    </div>
  </div>
)

TrialNotice.propTypes = {
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error']),
  trial: PropTypes.object.isRequired
}

TrialNotice.defaultProps = {
  theme: 'error'
}

export default TrialNotice
