import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { ChromePicker } from 'react-color'
import { Dropdown, Notification, Tooltip } from 'shared/components'
import { defaultDisabledFeatureMessage } from 'shared/constants'
import './styles.scss'

const ColorPicker = ({
  initialColor,
  label,
  onColorSelect,
  disabled,
  featureAvailable,
  featureEnabled,
  notAvailableMessage,
  notEnabledMessage
}) => {
  const pickerRef = useRef()
  const [isPickerDisplayed, setPickerDisplay] = useState(false)
  const [color, setColor] = useState(initialColor)

  const handleColorSelect = selectedColor => {
    const hexValue = get(selectedColor, 'hex') || ''
    setColor(hexValue)
    onColorSelect(hexValue)
  }

  const handleFeatureDisabledClick = () => {
    Notification('error', __(defaultDisabledFeatureMessage), __(notEnabledMessage))
  }

  const handlePickerDisplay = () => {
    setPickerDisplay(!isPickerDisplayed)
    onColorSelect(color)
  }

  if (featureAvailable) {
    return (
      <div className='ColorPicker'>
        <button
          className='color-select'
          type='button'
          // onClick={handlePickerDisplay}
          onClick={featureEnabled ? handlePickerDisplay : handleFeatureDisabledClick}
          disabled={disabled}
        >
          <div className='color-container' style={{ backgroundColor: initialColor }} />
          <div className='label-text'>{label}</div>
        </button>
        {isPickerDisplayed && (
          <Dropdown ref={pickerRef} close={handlePickerDisplay}>
            <ChromePicker color={color} onChange={handleColorSelect} disableAlpha />
          </Dropdown>
        )}
      </div>
    )
  }

  return (
    <Tooltip
      active={!featureAvailable}
      content={
        <div className='feature-unavailable-tooltip'>
          {__(notAvailableMessage)}
          &nbsp;
          <>
            {__('To compare different plan types click')}
            <a
              style={{ marginLeft: '3px' }}
              href='https://licensespring.com/pricing'
              target='_blank'
              rel='noopener noreferrer'
            >
              {__('here')}
            </a>
          </>
        </div>
      }
    >
      <div className='ColorPicker'>
        <button className='color-select' type='button' onClick={() => {}} disabled={disabled}>
          <div className='color-container' style={{ backgroundColor: initialColor }} />
          <div className='label-text'>{label}</div>
        </button>
      </div>
    </Tooltip>
  )
}

ColorPicker.propTypes = {
  initialColor: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.string,
  onColorSelect: PropTypes.func,
  featureAvailable: PropTypes.bool,
  featureEnabled: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

ColorPicker.defaultProps = {
  disabled: false,
  initialColor: '#949494',
  label: '#949494',
  onColorSelect: () => {},
  featureAvailable: true,
  featureEnabled: true,
  notAvailableMessage: 'This feature is not available in your plan type.',
  notEnabledMessage: ''
}

export default ColorPicker
