import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { abbreviateNumber } from 'js-abbreviation-number'
import {
  AreaChart,
  Area,
  ReferenceLine,
  Label,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer
} from 'recharts'
import { Button, ChartTooltip, ContentLoader, NoGraphData, Notification } from 'shared/components'
import { APIdateFormat } from 'shared/constants'
import {
  formatAnalyticsData,
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource
} from 'shared/helpers'
import {
  getHourlyLicenseConsumptionAnalytics,
  getDailyLicenseConsumptionAnalytics,
  exportLicenseConsumptionAnalytics
} from 'src/license/actions'
import LicenseConsumptionEventsList from '../LicenseConsumptionEventsList'
import './styles.scss'

// ts__lt, ts__lte, ts__gt, ts__gte, action__in=consumption_add,consumption_reset, order_by=ts, license__in | feature__in, ts__date__gte
const LicenseConsumptionChart = ({
  license,
  companyID,
  dateFrom,
  dateTo,
  height,
  label,
  params,
  title,
  subtitle
}) => {
  const allowUnlimited = get(license, 'allow_unlimited_consumptions') || false
  const maxConsumption = get(license, 'max_consumptions') || 0
  const maxOverages = get(license, 'max_overages') || 0

  const licenseID = get(license, 'id')
  const [isLoading, setLoading] = useState(false)
  const [isExportLoading, setExportLoading] = useState(false)
  const [data, setData] = useState(null)
  const [isDaily, setDaily] = useState(true)
  const [showEventsList, setEventsListDisplay] = useState(false)

  const getEndpointParams = () => {
    const from = dateFrom.format(APIdateFormat)
    const to = dateTo.format(APIdateFormat)
    const duration = moment.duration(dateTo.diff(dateFrom)).asDays()
    const gteParam = `&ts__date__gte=${from}`
    const lteParam = `&ts__date__lte=${to}`
    const objectParam = `&license__in=${licenseID}`

    return {
      from,
      to,
      duration,
      gteParam,
      lteParam,
      objectParam
    }
  }

  const fetchDailyData = parameters => {
    getDailyLicenseConsumptionAnalytics(parameters)
      .then(res => {
        const events = get(res, 'data') || []
        setData(events)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const fetchHourlyData = parameters => {
    getHourlyLicenseConsumptionAnalytics(parameters)
      .then(res => {
        const events = get(res, 'data') || []
        setData(events)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  const getData = useCallback(() => {
    const { duration, gteParam, lteParam, objectParam } = getEndpointParams()

    const shouldDisplayDaily = duration > 7
    setLoading(true)
    setData([])
    setDaily(shouldDisplayDaily)

    const endpointParams = `?company=${companyID}${gteParam}${lteParam}&order_by=ts${objectParam}${params}`

    if (shouldDisplayDaily) {
      fetchDailyData(endpointParams)
    } else {
      fetchHourlyData(endpointParams)
    }
  }, [companyID, dateFrom, dateTo])

  useEffect(() => {
    getData()
  }, [getData])

  const handleDataExport = () => {
    const { gteParam, lteParam, objectParam } = getEndpointParams()

    const endpointParams = `?company=${companyID}${gteParam}${lteParam}&order_by=ts${objectParam}${params}`

    setExportLoading(true)
    exportLicenseConsumptionAnalytics(endpointParams)
      .then(response => {
        const filename = filenameFromHeadersData(response.headers)
        downloadResource(response, filename)
        setExportLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot export data', data)
        Notification('error', __('Export data failed'))
        setExportLoading(false)
      })
  }

  if (isLoading) {
    return (
      <div
        className='LicenseConsumptionChart BaseChart loading'
        style={{ height: `${height}px`, maxHeight: `${height}px` }}
      >
        <ContentLoader text={`${label} ${__('loading')}`} />
      </div>
    )
  }

  if (!data || !data.length) {
    return (
      <div className='LicenseConsumptionChart'>
        <NoGraphData
          maxHeight={height}
          minHeight={height}
          text={__('No consumptions data')}
          description={__('Data is refreshed every 24 hours')}
          label={label}
          subtitle={subtitle}
        />
      </div>
    )
  }

  const overageLimit = maxConsumption + maxOverages
  const unifiedDateTimeData = formatAnalyticsData(data, isDaily)

  const formattedData = unifiedDateTimeData.map(fd => {
    const diff = fd.total_consumptions - maxConsumption
    const cons = diff > 0 ? maxConsumption : fd.total_consumptions
    const over = diff > 0 ? diff : 0
    return {
      ...fd,
      cons,
      over
    }
  })

  const showOverageLimit = maxOverages !== null && maxOverages !== 0

  return (
    <div className='LicenseConsumptionChart BaseChart'>
      {title && (
        <>
          <div className='LicenseConsumptionChart-heading BaseChart-heading'>
            <div className='title'>{title}</div>
            {subtitle && <div className='subtitle'>{subtitle}</div>}
          </div>
          <div className='LicenseConsumptionChart-actions'>
            <Button size='sm' onClick={() => setEventsListDisplay(true)}>
              {__('View consumption events')}
            </Button>
            <Button
              size='sm'
              onClick={handleDataExport}
              loading={isExportLoading}
              disabled={isExportLoading}
            >
              {__('Export')}
            </Button>
          </div>
        </>
      )}
      <div className='LicenseConsumptionChart-chart'>
        <ResponsiveContainer width='100%' height={height}>
          <AreaChart
            data={formattedData}
            margin={{
              top: 15,
              right: 20,
              bottom: 5,
              left: -20
            }}
          >
            <defs>
              <linearGradient id={`consumptions-fill-${licenseID}`} x1='0' y1='0' x2='0' y2='1'>
                <stop offset='15%' stopColor='#13b0fc' stopOpacity={0.6} />
                <stop offset='95%' stopColor='#13b0fc' stopOpacity={0.1} />
              </linearGradient>
              <linearGradient id={`overages-fill-${licenseID}`} x1='0' y1='0' x2='0' y2='1'>
                <stop offset='15%' stopColor='#ee5253' stopOpacity={0.6} />
                <stop offset='95%' stopColor='#ee5253' stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <Area
              connectNulls
              name={label}
              type='step'
              fillOpacity={1}
              stackId='a'
              dataKey='cons'
              fill={`url(#consumptions-fill-${licenseID})`}
            />
            <Area
              connectNulls
              name={label}
              type='step'
              fillOpacity={1}
              stackId='a'
              dataKey='over'
              fill={`url(#overages-fill-${licenseID})`}
            />
            {!allowUnlimited && (
              <ReferenceLine y={maxConsumption} stroke='#13b0fc' strokeDasharray='3 3'>
                <Label value={__('Max consumptions')} position='insideTopRight' />
              </ReferenceLine>
            )}
            {showOverageLimit && !allowUnlimited && (
              <ReferenceLine y={overageLimit} stroke='#ee5253' strokeDasharray='3 3'>
                <Label value={__('Max overage limit')} position='insideTopRight' />
              </ReferenceLine>
            )}
            {formattedData
              .filter(fd => fd.action === 'consumption_reset')
              .map(fd => (
                <ReferenceLine x={fd.ts} label='' stroke='#419a848a' strokeWidth={5} key={fd.ts}>
                  <Label value={__('Reset')} position='top' />
                </ReferenceLine>
              ))}
            <YAxis
              stroke='#07364c'
              tickFormatter={v => abbreviateNumber(v, 1)}
              tick={{ fontSize: 10 }}
              // domain={['dataMin', 'dataMax']}
              domain={[0, showOverageLimit ? overageLimit : 'dataMax']}
            />
            <XAxis
              stroke='#07364c'
              dataKey='ts'
              type='number'
              domain={['dataMin', 'dataMax']}
              interval='preserveStartEnd'
              scale='time'
              tickFormatter={ts =>
                isDaily ? moment(ts).format('MM/DD/YYYY') : moment(ts).format('MM/DD/YYYY HH:mm')
              }
              tick={{ fontSize: 10 }}
            />
            <Tooltip
              content={<ChartTooltip isDaily={isDaily} tooltipLabel={label} isConsumption />}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
      {showEventsList && (
        <LicenseConsumptionEventsList
          companyID={companyID}
          closeCb={() => setEventsListDisplay(false)}
          title={title}
          subtitle={subtitle}
          isOpen={showEventsList}
          params={getEndpointParams()}
        />
      )}
    </div>
  )
}

LicenseConsumptionChart.propTypes = {
  companyID: PropTypes.number.isRequired,
  license: PropTypes.object.isRequired,
  dateFrom: PropTypes.object.isRequired,
  dateTo: PropTypes.object.isRequired,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  params: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string
}

LicenseConsumptionChart.defaultProps = {
  height: 300,
  label: '',
  params: '',
  title: '',
  subtitle: ''
}

export default LicenseConsumptionChart
