import React, { useState, useEffect, useCallback, useRef } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  Button,
  ContentLoader,
  DirtyFormAlert,
  Label,
  Notice,
  Selector,
  SlidePane
} from 'shared/components'
import { mapProductsToSelector, sendErrorReport, formatPolicies } from 'shared/helpers'
import { getProductsInBundle } from 'src/product/actions'
import PolicySelector from './PolicySelector'
import './styles.scss'

const SelectBundleForm = ({ closeCb, handleSubmit, isOpen, bundleToEdit, title, width }) => {
  const isBundleChanged = useRef(false)
  const products = useSelector(state => get(state, 'products.list'))
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const bundles = products.filter(p => p.is_bundle)
  const bundleOptions = mapProductsToSelector(bundles)

  const initialSelectedPolicies = bundleToEdit ? get(bundleToEdit, 'selected_bundle_policies') : []

  const [isDirty, setDirty] = useState(false)
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false)
  const [selectedBundle, setSelectedBundle] = useState(
    get(bundleToEdit, 'bundle') || bundleOptions[0]
  )
  const [productsInBundleLoading, setProductsInBundleLoading] = useState(true)
  const [productsInBundle, setProductsInBundle] = useState([])
  const [selectedProductPolicies, setSelectedProductPolicies] = useState(initialSelectedPolicies)
  const [archivedProducts, setArchivedProducts] = useState([])
  const [bundleError, setBundleError] = useState(null)
  const [showArchivedDetails, setShowArchivedDetails] = useState(false)

  const selectedBundleID = get(selectedBundle, 'data.id')

  const setInitialState = (items = []) => {
    const productsList = []
    const policiesList = []
    const archivedProductsList = []

    // Reset states for new bundle selection
    setBundleError(null)
    setShowArchivedDetails(false)

    items.forEach(i => {
      const pr = products.find(p => p.id === i.id)

      if (!pr) {
        // Product not found in active products list - must be archived
        // Store full product info from the bundle response
        archivedProductsList.push({
          id: i.id,
          name: i.product_name || i.name || `Product ID: ${i.id}` // Fallback for name
        })
        return
      }

      const policies = get(pr, 'license_templates') || []
      const mappedPolicies = formatPolicies(policies)
      pr.license_templates = mappedPolicies
      productsList.push(pr)
      policiesList.push(mappedPolicies[0])
    })

    // Set archived products state
    setArchivedProducts(archivedProductsList)

    // Set error state based on archived products
    if (archivedProductsList.length === items.length) {
      setBundleError(
        'All products from this bundle were archived, no active products in selected bundle'
      )
    } else if (archivedProductsList.length > 0) {
      setBundleError('Some products from this bundle were archived')
    }

    // Only set products and policies if there are active products
    if (productsList.length > 0) {
      setProductsInBundle(productsList)
      if (!bundleToEdit || isBundleChanged.current) {
        setSelectedProductPolicies(policiesList)
      }
    } else {
      // Clear previous products if all are archived
      setProductsInBundle([])
      setSelectedProductPolicies([])
    }

    setProductsInBundleLoading(false)
  }

  const handlePolicySelect = policy => {
    const policyProductID = get(policy, 'product')
    const filtered = selectedProductPolicies.filter(spp => spp.product !== policyProductID)
    setSelectedProductPolicies([...filtered, policy])
  }

  const getBundledProducts = useCallback(() => {
    setProductsInBundleLoading(true)
    getProductsInBundle(selectedBundleID, companyID)
      .then(res => {
        const data = get(res, 'data') || []
        setInitialState(data)
      })
      .catch(err => {
        setProductsInBundleLoading(false)
        sendErrorReport(err, 'Cannot get bundled products')
      })
  }, [selectedBundleID])

  useEffect(() => {
    getBundledProducts()
  }, [getBundledProducts, selectedBundle])

  const handleBundleSelect = val => {
    const selectedB = bundleOptions.find(b => get(b, 'data.product_name') === val)
    setDirty(true)
    isBundleChanged.current = true
    setSelectedBundle(selectedB)
  }

  const handleBundleSubmit = () => {
    handleSubmit({
      bundle: selectedBundle,
      selected_bundle_policies: selectedProductPolicies
    })
  }

  const handleClose = () => {
    if (isDirty) {
      setDirtyFormDisplay(true)
    } else {
      closeCb()
    }
  }

  const getTitle = () => (
    <div className='SelectBundleFormTitle'>
      <div className='title'>{title}</div>
      <div className='confirm-btn'>
        <Button
          theme='success'
          onClick={handleBundleSubmit}
          disabled={productsInBundleLoading || !productsInBundle.length}
        >
          {__('Confirm')}
        </Button>
      </div>
    </div>
  )

  const isBundleProductCreated = bundles && Array.isArray(bundles) && bundles.length > 0

  return (
    <SlidePane closeCb={handleClose} isOpen={isOpen} title={getTitle()} width={width}>
      <div className='SelectBundleForm'>
        <div className='section-row'>
          <Label inputId='bundle-select' text={__('Bundle Name')} />
          <Selector
            options={bundleOptions}
            handleChange={handleBundleSelect}
            value={get(selectedBundle, 'value')}
            inputId='bundle-select'
            disabled={productsInBundleLoading}
          />
        </div>

        {bundleError && (
          <Notice
            theme={
              archivedProducts.length === archivedProducts.length + productsInBundle.length
                ? 'error'
                : 'warning'
            }
            size='sm'
          >
            <div className='archived-products-notice'>
              <div className='archived-products-header'>
                <span>{bundleError}</span>
                <Button
                  theme='link'
                  size='sm'
                  onClick={() => setShowArchivedDetails(!showArchivedDetails)}
                >
                  {showArchivedDetails ? __('Hide details') : __('Show details')}
                </Button>
              </div>
              {showArchivedDetails && (
                <div className='archived-products-list'>
                  <div className='archived-products-subheader'>
                    {__('Archived products in this bundle:')}
                  </div>
                  <ul>
                    {archivedProducts.map(product => (
                      <li key={product.id}>
                        <span className='product-name'>{product.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </Notice>
        )}

        {!isBundleProductCreated && (
          <Notice theme='error' size='sm' title={__('Bundle products are not defined')}>
            <div className='bundle-prod-warning'>
              <span>{__('Configure product bundle before issuing licenses')}</span>
              <NavLink to={`/${companyID}/products`}>{__('here')}</NavLink>
            </div>
          </Notice>
        )}

        <div className='section-row'>
          {productsInBundleLoading ? (
            <div className='bundle-products-loading'>
              <ContentLoader />
            </div>
          ) : (
            <div className='bundle-products-list'>
              <div className='bundle-products-heading'>{__('Products in a bundle')}</div>
              {productsInBundle.map(p => (
                <PolicySelector
                  key={p.id}
                  product={p}
                  selectedPolicies={selectedProductPolicies}
                  handlePolicySelect={handlePolicySelect}
                />
              ))}
            </div>
          )}
        </div>

        {isDirtyFormDisplayed && (
          <DirtyFormAlert
            dirty={isDirty}
            closeAlert={() => setDirtyFormDisplay(false)}
            closeCb={closeCb}
          />
        )}
      </div>
    </SlidePane>
  )
}

SelectBundleForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  bundleToEdit: PropTypes.object,
  title: PropTypes.string.isRequired,
  width: PropTypes.string
}

SelectBundleForm.defaultProps = {
  bundleToEdit: null,
  width: '80%'
}

export default SelectBundleForm
