import api from 'shared/api'
import * as actionTypes from './actionTypes'

// analytics date range
export const updateAnalyticsDateRange = res => ({
  type: actionTypes.updateAnalyticsDateRange,
  payload: res
})

export const changeDateRange = details => dispatch => {
  dispatch(updateAnalyticsDateRange(details))
}

export const getUsedMonthlyCalls = companyID =>
  api.get(`/api/v1/activities-hourly/billing_period_limit?company=${companyID}`)

export const getAnalyticsStats = (companyID, gteParam, lteParam, dailyParam) =>
  api.get(
    `/api/v1/activities-hourly/actions_stats?company=${companyID}${gteParam}${lteParam}&order_by=ts${dailyParam}`
  )

export const getAnalyticsLicenseTopCallCount = (
  companyID,
  gteParam,
  lteParam,
  page,
  pageSize,
  // orderBy,
  productID,
  action,
  statusCode
) => {
  // const orderUrl = orderBy ? `&order_by=${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}` : '';
  const actionParam = action ? `&action__in=${action}` : ''
  const productParam = productID ? `&product__in=${productID}` : ''
  const statusParam = statusCode ? `&status_code__in=${statusCode}` : ''
  // const paramsList = `${orderUrl}${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`;
  const paramsList = `${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`
  return api.get(
    `/api/v1/activities-hourly/top_call_count_licenses/?company=${companyID}&limit=${pageSize ||
      20}&offset=${page ? page * pageSize : 0}${paramsList}`
  )
}

export const getAnalyticsOrdersTopCallCount = (
  companyID,
  gteParam,
  lteParam,
  page,
  pageSize,
  // orderBy,
  productID,
  action,
  statusCode
) => {
  // const orderUrl = orderBy ? `&order_by=${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}` : '';
  const actionParam = action ? `&action__in=${action}` : ''
  const productParam = productID ? `&product__in=${productID}` : ''
  const statusParam = statusCode ? `&status_code__in=${statusCode}` : ''
  // const paramsList = `${orderUrl}${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`;
  const paramsList = `${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`
  return api.get(
    `/api/v1/activities-hourly/top_call_count_orders/?company=${companyID}&limit=${pageSize ||
      20}&offset=${page ? page * pageSize : 0}${paramsList}`
  )
}

export const getAnalyticsCustomersTopCallCount = (
  companyID,
  gteParam,
  lteParam,
  page,
  pageSize,
  // orderBy,
  productID,
  action,
  statusCode
) => {
  // const orderUrl = orderBy ? `&order_by=${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}` : '';
  const actionParam = action ? `&action__in=${action}` : ''
  const productParam = productID ? `&product__in=${productID}` : ''
  const statusParam = statusCode ? `&status_code__in=${statusCode}` : ''
  // const paramsList = `${orderUrl}${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`;
  const paramsList = `${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`
  return api.get(
    `/api/v1/activities-hourly/top_call_count_customers/?company=${companyID}&limit=${pageSize ||
      20}&offset=${page ? page * pageSize : 0}${paramsList}`
  )
}

export const getMaxConsumptionsLicensesCount = (
  companyID,
  gteParam,
  lteParam,
  page,
  pageSize,
  // orderBy,
  productID
) => {
  // const orderUrl = orderBy ? `&order_by=${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}` : '';
  const productParam = productID ? `&product__in=${productID}` : ''
  // const paramsList = `${orderUrl}${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`;
  const textParam = '&text=Max consumptions reached'
  const paramsList = `${gteParam}${lteParam}${textParam}${productParam}`
  return api.get(
    `/api/v1/license-histories/over_count_limit/?company=${companyID}&limit=${pageSize ||
      20}&offset=${page ? page * pageSize : 0}${paramsList}`
  )
}

export const getMaxConsumptionsFeaturesCount = (
  companyID,
  gteParam,
  lteParam,
  page,
  pageSize,
  // orderBy,
  productID
) => {
  // const orderUrl = orderBy ? `&order_by=${orderBy.map(orderType => `${orderType.desc ? '-' : ''}${orderType.id},`)}` : '';
  const productParam = productID ? `&product__in=${productID}` : ''
  // const paramsList = `${orderUrl}${gteParam}${lteParam}${actionParam}${productParam}${statusParam}`;
  const textParam = '&text=Max feature consumptions reached'
  const paramsList = `${gteParam}${lteParam}${textParam}${productParam}`
  return api.get(
    `/api/v1/license-histories/lpf_over_count_limit/?company=${companyID}&limit=${pageSize ||
      20}&offset=${page ? page * pageSize : 0}${paramsList}`
  )
}
