import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  CheckboxSelector
} from 'shared/components'
import { stripeTaxIDs, errorMsg } from 'shared/constants'
import { sendErrorReport, getCustomerTaxOptions, mapTaxOptionsToSelector } from 'shared/helpers'
import { updateCustomerTax } from 'src/account/actions'
import { getCompanySilent } from 'src/company/actions'
import './styles.scss'

const TaxDetailsForm = ({ closeCb, companyID, customerDetails }) => {
  const dispatch = useDispatch()
  const taxOptions = mapTaxOptionsToSelector(stripeTaxIDs)
  const customerTaxes = get(customerDetails, 'taxes') || []
  const initialTaxOptions = getCustomerTaxOptions(customerTaxes)

  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormDisplay] = useState(false)
  const [taxItems, setTaxItems] = useState(initialTaxOptions)

  const updateState = (val, field, taxValue) => {
    const updatedState = taxItems.map(t => {
      const found = t.value === taxValue
      if (found) {
        return {
          ...t,
          error: '',
          [field]: val
        }
      }
      return t
    })
    setTaxItems(updatedState)
  }

  const isFormValid = () => {
    let isValid = true
    taxItems.forEach(t => {
      if (!get(t, 'number')) {
        updateState(errorMsg.required, 'error', get(t, 'value'))
        isValid = false
      }
    })
    return isValid
  }

  const handleSubmit = () => {
    const isValid = isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setLoading(true)
    const data = taxItems.map(ti => ({
      type: get(ti, 'code'),
      value: get(ti, 'number')
    }))

    updateCustomerTax(companyID, data)
      .then(() => {
        dispatch(getCompanySilent(companyID))
        closeCb()
        Notification('success', __('Tax details updated'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create new tax type', data)
        Notification(
          'error',
          __('Error occured'),
          __('Make sure that you have entered correct tax ID.')
        )
        setLoading(false)
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Change tax details')}
      disabled={isLoading}
      size='sm'
    >
      <div className='TaxDetailsForm'>
        <div className='tax-type-selector'>
          <Label text={__('Select tax types')} />
          <CheckboxSelector
            text={__('Selected tax types')}
            options={taxOptions}
            value={taxItems}
            onChangeCallback={val => setTaxItems(val)}
            // onMenuClose={() => { }}
            disabled={isLoading}
          />
        </div>
        <div className='tax-type-fields'>
          {taxItems.map(t => (
            <div className='row' key={get(t, 'value')}>
              <Label inputId={`${t.value}-input`} text={get(t, 'label')} />
              <TextInput
                disabled={isLoading}
                handleChange={val => {
                  setDirty(true)
                  updateState(val, 'number', get(t, 'value'))
                }}
                id={`${t.value}-input`}
                type='text'
                placeholder={get(t, 'example')}
                value={get(t, 'number')}
                error={get(t, 'error')}
              />
            </div>
          ))}
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

TaxDetailsForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  customerDetails: PropTypes.object.isRequired
}

export default TaxDetailsForm
