import en from './translations/en.json'

const dict = {
  en
}

class i18n {
  constructor(locale) {
    this.currentLocale = locale
    window.__ = word => this.translate(word) // eslint-disable-line no-undef, no-underscore-dangle
  }

  translate(word) {
    const translatedWord = dict[this.currentLocale] ? dict[this.currentLocale][word] : null
    return translatedWord || word
  }
}

export default i18n
