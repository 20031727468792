import React from 'react'
import PropTypes from 'prop-types'
import { ErrorIcon, SuccessIcon, RunningIcon, PendingIcon } from './icons'
import './styles.scss'

const ImportStatus = ({ status }) => {
  const components = {
    success: SuccessIcon,
    error: ErrorIcon,
    running: RunningIcon,
    pending: PendingIcon
  }

  const Component = components[status]
  return <Component text={status} />
}

ImportStatus.propTypes = {
  status: PropTypes.string
}

ImportStatus.defaultProps = {
  status: null
}

export default ImportStatus
