import { User } from 'oidc-client-ts'
import { generateCodeChallenge, generateCodeVerifier } from './codeChallenge'
import { oidcConfig } from './authConfig'

export const getUserFromStorage = () => {
  const storageName = `oidc.user:${oidcConfig.authority}:${oidcConfig.client_id}`
  const oidcStorage = window.localStorage.getItem(storageName)
  if (!oidcStorage) {
    return null
  }

  return User.fromStorageString(oidcStorage)
}

export const generateUpdatePasswordUrl = async () => {
  const verifier = generateCodeVerifier()
  const codeChallenge = await generateCodeChallenge(verifier)

  const url =
    `${oidcConfig.authority}/protocol/openid-connect/auth?` +
    `client_id=${oidcConfig.client_id}&redirect_uri=${window.location.origin}` +
    '&response_type=code' +
    `&code_challenge=${codeChallenge}` +
    '&code_challenge_method=S256' +
    '&kc_action=UPDATE_PASSWORD'

  return url
}
