import React from 'react'
import PropTypes from 'prop-types'
import { Tabs as Ts } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import './styles.scss'

const Tabs = ({ children, onSelect, ...rest }) => (
  <Ts className='Tabs' onSelect={onSelect} {...rest}>
    {children}
  </Ts>
)

Tabs.propTypes = {
  children: PropTypes.node.isRequired,
  onSelect: PropTypes.func
}

Tabs.defaultProps = {
  onSelect: undefined
}

export default Tabs
