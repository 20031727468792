import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'shared/components'
import './styles.scss'

const BusinessPlusPlanTypeBox = ({ isCurrent, handleCtaClick, isUpgrade }) => (
  <article className={`BusinessPlusPlanTypeBox PlanTypeBox ${isCurrent ? 'current' : ''}`}>
    <div className='PlanTypeBox-title'>{__('Business Plus')}</div>
    <p className='PlanTypeBox-description'>
      {__(
        'For Organizations that need user-based licensing, usage metering, and unlimited development environments'
      )}
    </p>
    <div className='PlanTypeBox-price standard'>750</div>
    <div className='PlanTypeBox-btn'>
      {isUpgrade ? (
        <Button onClick={handleCtaClick} theme='success' disabled={isCurrent} size='lg'>
          {__('Upgrade to Business Plus')}
        </Button>
      ) : (
        <a
          className='upgrade-link'
          href='https://licensespring.com/get-started/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {__('Downgrade to Business Plus')}
        </a>
      )}
    </div>
    <ul className='PlanTypeBox-features'>
      <li>
        <span>1,000,000</span>
        {__('monthly requests')}
      </li>
      <li>
        <span>10</span>
        {__('products')}
      </li>
      <li>
        <span>{__('Unlimited')}</span>
        {__('development environments')}
      </li>
      <li className='checkmark'>{__('Node-Locking')}</li>
      <li className='checkmark'>{__('Key or User-Based')}</li>
      <li className='checkmark'>{__('Offline Licensing')}</li>
      <li className='checkmark'>{__('Product Versioning')}</li>
      <li className='checkmark'>{__('User-Based with SSO')}</li>
      <li className='checkmark'>{__('Unlimited MAUs')}</li>
      <li className='checkmark'>{__('Custom Authentication')}</li>
      <li className='checkmark'>{__('Cloud Floating Licenses')}</li>
    </ul>
  </article>
)

BusinessPlusPlanTypeBox.propTypes = {
  handleCtaClick: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isUpgrade: PropTypes.bool.isRequired
}

export default BusinessPlusPlanTypeBox
