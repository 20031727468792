import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  DirtyFormAlert,
  Checkbox,
  Label,
  Modal,
  TextInput,
  Notification,
  NumberInput,
  Selector
} from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { createTaxRate } from 'src/billing/actions'
import './styles.scss'

const stripeTaxTypeOptions = [
  { label: 'Sales tax', value: 'Sales tax' },
  { label: 'VAT', value: 'VAT' },
  { label: 'GST', value: 'GST' },
  { label: 'Custom', value: 'Custom' }
]

const TaxRateForm = ({ closeCb, confirmCb, fetchTaxes, title }) => {
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [region, setRegion] = useState('')
  const [regionError, setRegionError] = useState('')
  const [customType, setCustomType] = useState('')
  const [customTypeError, setCustomTypeError] = useState('')
  const [percentage, setPercentage] = useState('')
  const [percentageError, setPercentageError] = useState('')
  const [type, setType] = useState(get(stripeTaxTypeOptions, '[0].value'))
  const [isInclusive, setIsInclusive] = useState(false)

  const validateTaxRegion = val => {
    if (!val) {
      setRegionError(__('This field is required'))
      return false
    }
    setRegionError('')
    return true
  }

  const validateCustomType = val => {
    if (type === 'Custom' && !val) {
      setCustomTypeError(__('This field is required'))
      return false
    }
    setCustomTypeError('')
    return true
  }

  const validatePercentage = val => {
    if (!val) {
      setPercentageError(__('This field is required'))
      return false
    }
    setPercentageError('')
    return true
  }

  const handleRegionChange = val => {
    setDirty(true)
    setRegion(val)
    validateTaxRegion(val)
  }

  const handleCustomTypeChange = val => {
    setDirty(true)
    setCustomType(val)
    validateCustomType(val)
  }

  const handleTypeChange = val => {
    setDirty(true)
    setType(val)
  }

  const handlePercentageChange = val => {
    setDirty(true)
    setPercentage(val)
    validatePercentage(val)
  }

  const handleInclusive = val => {
    setDirty(true)
    setIsInclusive(val)
  }

  const isFormValid = () => {
    const isRegionValid = validateTaxRegion(region)
    const isCustomTypeValid = validateCustomType(customType)
    const isPercentageValid = validatePercentage(percentage)
    return isRegionValid && isCustomTypeValid && isPercentageValid
  }

  const handleSubmit = () => {
    if (!isFormValid() || loading) {
      return false
    }

    setLoading(true)
    const data = {
      display_name: type,
      percentage: Number(percentage),
      jurisdiction: region,
      description: region,
      inclusive: isInclusive
    }

    createTaxRate(data, companyID)
      .then(() => {
        fetchTaxes()
        Notification('success', __('Tax rate created'))
        confirmCb()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create tax rate', data)
        Notification('error', __('Error occured'), __('We could not create the tax rate.'))
        setLoading(false)
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
      size='sm'
    >
      <div className='TaxRateForm'>
        <form className='TaxRateForm-form' onSubmit={handleSubmit}>
          <div>
            <Label text={__('Type')} inputId='type' />
            <Selector
              options={stripeTaxTypeOptions}
              value={type}
              isDisabled={loading}
              handleChange={handleTypeChange}
            />
            {type === 'Custom' && (
              <div className='custom-type-input'>
                <TextInput
                  id='type'
                  value={customType}
                  error={customTypeError}
                  handleChange={handleCustomTypeChange}
                  placeholder={__('Enter custom tax type name')}
                  disabled={loading}
                />
              </div>
            )}
          </div>
          <div>
            <Label text={__('Region')} inputId='region' />
            <TextInput
              id='region'
              value={region}
              error={regionError}
              handleChange={handleRegionChange}
              disabled={loading}
            />
          </div>
          <div>
            <Label text={__('Percentage')} inputId='percentage' />
            <NumberInput
              id='percentage'
              value={percentage}
              error={percentageError}
              handleChange={handlePercentageChange}
              min='1'
              max='100'
              disabled={loading}
            />
          </div>
          <div>
            <Checkbox
              label={__('Is inclusive')}
              handleChange={handleInclusive}
              checked={isInclusive}
              inputId='is-inclusive-checkbox'
            />
          </div>
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

TaxRateForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  fetchTaxes: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired
}

TaxRateForm.defaultProps = {
  pricingPlanToEdit: null
}

export default TaxRateForm
