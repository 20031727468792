/* eslint-disable react/prop-types */
import React from 'react'
import { get } from 'lodash'

function withClose(Component) {
  class ClickContainer extends React.Component {
    constructor() {
      super()
      this.handleClose = this.handleClose.bind(this)
    }

    componentDidMount() {
      document.addEventListener('click', this.handleClose)
    }

    componentWillUnmount() {
      document.removeEventListener('click', this.handleClose)
    }

    handleClose(e) {
      const { forwardedRef, close } = this.props
      if (forwardedRef.current) {
        const targetClassName = get(e, 'target.classList.value') || ''
        // handle outside click and strange daterangepicker bug
        if (forwardedRef.current.contains(e.target) || targetClassName.includes('CalendarDay')) {
          return
        }
      }
      close()
    }

    render() {
      const { forwardedRef, ...rest } = this.props
      return <Component forwardedRef={forwardedRef} {...rest} />
    }
  }

  return React.forwardRef((props, ref) => <ClickContainer {...props} forwardedRef={ref} />)
}

export default withClose
