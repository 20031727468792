import * as actionTypes from './actionTypes'

const initialState = {
  dateRange: {}
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.updateAnalyticsDateRange:
      return { ...state, dateRange: action.payload }
    default:
      return state
  }
}
