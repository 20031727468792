import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props}>
    <circle cx='18' cy='9' r='2' transform='rotate(90 18 9)' />
    <path d='M18 4L18 7' />
    <path d='M18 11L18 20' />
    <circle cx='6' cy='9' r='2' transform='rotate(90 6 9)' />
    <path d='M6 4L6 7' />
    <path d='M6 11L6 20' />
    <circle cx='12' cy='15' r='2' transform='rotate(90 12 15)' />
    <path d='M12 4L12 13' />
    <path d='M12 17L12 20' />
  </Base>
)
