import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  Button,
  ConfirmationPopup,
  ClipboardRow,
  DescriptionTable,
  IconClipboard,
  IconDelete,
  IconEdit,
  IconInfo,
  List,
  Notification,
  PermissionMissingNotificationTitle,
  Tooltip,
  Switcher
} from 'shared/components'
import {
  sendErrorReport,
  copyText,
  displayValue,
  checkUserPermission,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled
} from 'shared/helpers'
import { userPermissions, platformFeatures } from 'shared/constants'
import {
  fetchLicenseApiKeys,
  createNewLicenseApiKey,
  deleteLicenseApiKey,
  updateLicenseApiKey
} from 'src/account/actions'
import EditLicenseAPIKeyForm from '../EditLicenseAPIKeyForm'
import './styles.scss'

const LicenseAPI = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const products = useSelector(state => get(state, 'products.list') || [])
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')

  const [isLoading, setLoading] = useState(true)
  const [tableExpanded, setTableExpanded] = useState({})
  // license api keys
  const [licenseKeys, setLicenseKeys] = useState([])
  const [keyGenerateLoading, setKeyGenerateLoading] = useState(false)
  const [keyToDelete, setKeyToDelete] = useState(null)
  const [isKeyDeleteConfirmationDisplayed, setKeyDeleteConfirmationDisplayed] = useState(false)
  const [keyDeleteLoading, setKeyDeleteLoading] = useState(false)
  const [keyToDeactivate, setKeyToDeactivate] = useState(null)
  const [isDeactivateKeyIntentDisplayed, setDeactivateKeyIntentDisplay] = useState(false)
  const [isKeyDeactivateConfirmationDisplayed, setKeyDeactivateConfirmationDisplayed] = useState(
    false
  )
  const [keyDeactivateLoading, setKeyDeactivateLoading] = useState(false)
  const [isEditFormDisplayed, setEditFormDisplay] = useState(false)
  const [keyToEdit, setKeyToEdit] = useState(null)

  const getLicenseAPIKeys = useCallback(() => {
    fetchLicenseApiKeys(companyID)
      .then(res => {
        const data = get(res, 'data.results') || []
        setLicenseKeys(data)
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get license api keys')
        setLoading(false)
        Notification(
          'error',
          __('There was an error while getting your data'),
          __('Try again later')
        )
      })
  }, [companyID])

  useEffect(() => {
    getLicenseAPIKeys()
  }, [getLicenseAPIKeys])

  const handleFieldCopy = (val, desc) => {
    copyText(val)
    Notification('success', `${desc} ${__('copied to clipboard')}`)
  }

  const generateNewKey = () => {
    setKeyGenerateLoading(true)
    const data = { label: '', read_only: false, products: [] }

    createNewLicenseApiKey(companyID, data)
      .then(res => {
        setLicenseKeys([...licenseKeys, get(res, 'data')])
        setKeyGenerateLoading(false)
        Notification('success', __('New license API key generated'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot generate new license api key')
        setKeyGenerateLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const updateApiKey = (key, keyData, cb = () => {}) => {
    const keyID = get(key, 'id')

    updateLicenseApiKey(keyID, companyID, keyData)
      .then(() => {
        const updatedKeys = licenseKeys.map(lk => {
          if (lk.id === keyID) return { ...key, ...keyData }
          return lk
        })
        setLicenseKeys(updatedKeys)
        Notification('success', __('Changes saved successfully'))
        cb()
      })
      .catch(err => {
        cb()
        sendErrorReport(err, 'Cannot edit license api key', keyData)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleKeyDeactivate = () => {
    setKeyDeactivateLoading(true)

    updateApiKey(keyToDeactivate, { revoked: true }, () => {
      setDeactivateKeyIntentDisplay(false)
      setKeyDeactivateConfirmationDisplayed(false)
      setKeyDeactivateLoading(false)
      setKeyToDeactivate(null)
    })
  }

  const handleKeyDelete = () => {
    const keyID = get(keyToDelete, 'id')
    setKeyDeleteLoading(true)

    deleteLicenseApiKey(keyID, companyID)
      .then(() => {
        setKeyDeleteLoading(false)
        setKeyToDelete(null)
        setKeyDeleteConfirmationDisplayed(false)
        const updatedKeys = licenseKeys.filter(k => k.id !== keyID)
        setLicenseKeys(updatedKeys)
        Notification('success', __('License API key deleted successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete license api key')
        setKeyDeleteLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const displayProductsRow = (keyProducts = []) => {
    if (!keyProducts.length) {
      return __('All')
    }
    return (
      <div className='key-products-row'>
        {keyProducts.map(p => {
          const product = products.find(prod => prod.id === p)
          const productName = get(product, 'product_name')
          return <NavLink to={`/${companyID}/products/${p}`}>{displayValue(productName)}</NavLink>
        })}
      </div>
    )
  }

  return (
    <div className='LicenseAPI'>
      <div className='row'>
        <h3>{__('License API keys')}</h3>
        <Button
          size='sm'
          theme='info'
          loading={keyGenerateLoading}
          disabled={keyGenerateLoading || isLoading}
          onClick={() => handleManageSettingsClick(generateNewKey)}
          featureEnabled={isFeatureEnabled(platformFeatures.extra_multiple_api_keys)}
          featureAvailable={isFeatureAvailable(platformFeatures.extra_multiple_api_keys)}
          notEnabledMessage={getDisabledMessage()}
        >
          {__('Generate new key')}
        </Button>
        <div className='license-keys-list'>
          <List
            onExpandedChange={expanded => setTableExpanded(expanded)}
            expanded={tableExpanded}
            SubComponent={row => (
              <div className='SubComponent'>
                <DescriptionTable
                  details={[
                    {
                      label: __('Shared key'),
                      value: (
                        <ClipboardRow
                          value={get(row, 'original.shared_key')}
                          hideLabel
                          label={__('Shared key')}
                          id='shared-key-input'
                        />
                      )
                    },
                    {
                      label: __('Allowed products'),
                      value: displayProductsRow(get(row, 'original.products'))
                    }
                  ]}
                />
              </div>
            )}
            columns={[
              {
                expander: true,
                Header: __('Details'),
                headerClassName: 'text-center',
                width: 80,
                style: {
                  fontSize: 25,
                  padding: '0',
                  textAlign: 'center',
                  userSelect: 'none'
                }
              },
              {
                accessor: 'uuid',
                Header: __('API key (UUID)'),
                Cell: cellData => {
                  const key = get(cellData, 'value')
                  return (
                    <div className='key-container'>
                      <span>{key}</span>
                      <button
                        type='button'
                        className='clipboard-btn'
                        onClick={() => handleFieldCopy(key, __('License API key'))}
                      >
                        <IconClipboard width='21.5' height='20' viewBox='0 0 51.5 50' />
                      </button>
                    </div>
                  )
                },
                width: 350
              },
              {
                accessor: 'label',
                Header: __('Label'),
                Cell: cellData => displayValue(get(cellData, 'value'))
              },
              {
                // TODO -> in License API allow only on GET requests
                accessor: 'read_only',
                className: 'text-center',
                headerClassName: 'text-center',
                Header: () => (
                  <div className='descriptive-header'>
                    {__('Read only')}
                    <Tooltip
                      content={__(
                        'If read only is enabled then only GET requests are allowed with this key.'
                      )}
                      active
                    >
                      <span>
                        <IconInfo height='12px' width='12px' />
                      </span>
                    </Tooltip>
                  </div>
                ),
                Cell: cellData => (
                  <Switcher
                    size='sm'
                    checked={cellData.value}
                    handleChange={() =>
                      handleManageSettingsClick(() =>
                        updateApiKey(cellData.original, { read_only: !cellData.value })
                      )
                    }
                  />
                ),
                width: 120
              },
              {
                // TODO -> in License API do not allow requests with revoked keys, return error
                headerClassName: 'text-center',
                className: 'text-center',
                accessor: 'revoked',
                Header: () => (
                  <div className='descriptive-header'>
                    {__('Active')}
                    <Tooltip
                      content={__(
                        'If deactivated this key will be revoked. This cannot be undone, once revoked key cannot be used anymore.'
                      )}
                      active
                    >
                      <span>
                        <IconInfo height='12px' width='12px' />
                      </span>
                    </Tooltip>
                  </div>
                ),
                Cell: cellData => (
                  <Switcher
                    size='sm'
                    checked={!cellData.value}
                    handleChange={() =>
                      handleManageSettingsClick(() => {
                        setKeyToDeactivate(cellData.original)
                        setDeactivateKeyIntentDisplay(true)
                      })
                    }
                    disabled={cellData.value}
                  />
                ),
                width: 100
              },
              {
                Header: __('Edit'),
                headerClassName: 'text-center',
                className: 'text-center',
                id: 'edit',
                width: 80,
                sortable: false,
                Cell: rowData => (
                  <Button
                    className='edit-button'
                    onClick={() =>
                      handleManageSettingsClick(() => {
                        setKeyToEdit(rowData.original)
                        setEditFormDisplay(true)
                      })
                    }
                    type='button'
                  >
                    <IconEdit height='16px' width='16px' />
                  </Button>
                )
              },
              {
                Header: __('Delete'),
                headerClassName: 'text-center',
                className: 'text-center',
                id: 'delete',
                width: 80,
                sortable: false,
                show: false,
                Cell: rowData => (
                  <Button
                    className='edit-button'
                    onClick={() =>
                      handleManageSettingsClick(() => {
                        setKeyToDelete(rowData.original)
                        setKeyDeleteConfirmationDisplayed(true)
                      })
                    }
                    type='button'
                  >
                    <IconDelete height='16px' width='16px' color='#ee5253' />
                  </Button>
                )
              }
            ]}
            data={licenseKeys}
            minRows={1}
            pageSize={10}
            loading={isLoading}
            clickable={false}
          />
        </div>
      </div>
      {isEditFormDisplayed && (
        <EditLicenseAPIKeyForm
          apiKey={keyToEdit}
          closeCb={() => {
            setEditFormDisplay(false)
            setKeyToEdit(null)
          }}
          companyID={get(companyDetails, 'id')}
          refetchKeys={getLicenseAPIKeys}
        />
      )}
      {isKeyDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setKeyToDelete(null)
            setKeyDeleteConfirmationDisplayed(false)
          }}
          confirmCb={handleKeyDelete}
          title={`${__('Are you sure you want to delete this License API key')}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={keyDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {get(keyToDelete, 'key')}
          </span>
        </ConfirmationPopup>
      )}
      {isDeactivateKeyIntentDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeactivateKeyIntentDisplay(false)}
          confirmCb={() => setKeyDeactivateConfirmationDisplayed(true)}
          title={`${__('Are you sure you want to deactivate this License API key')}?`}
          confirmText={__('Deactivate')}
          theme='error'
          disabled={keyDeactivateLoading}
        >
          {__('This action cannot be undone!')}
        </ConfirmationPopup>
      )}
      {isKeyDeactivateConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setKeyToDeactivate(null)
            setKeyDeactivateConfirmationDisplayed(false)
          }}
          confirmCb={handleKeyDeactivate}
          title={`${__(
            'Please, confirm that you really want to deactivate this License API key'
          )}?`}
          confirmText={__('Deactivate')}
          theme='error'
          disabled={keyDeactivateLoading}
          warning
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {get(keyToDeactivate, 'uuid')}
          </span>
        </ConfirmationPopup>
      )}
    </div>
  )
}

export default LicenseAPI
