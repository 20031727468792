import React, { useState } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  ConfirmationPopup,
  IconReset,
  List,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components'
import {
  displayValue,
  getDefaultLicensePolicy,
  getDisabledMessage,
  isFeatureEnabled,
  isFeatureAvailable,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import { getProducts, archiveProduct } from 'src/product/actions'
import './styles.scss'

const ArchivedProducts = () => {
  const canManageProducts = checkUserPermission(userPermissions.products_write)
  const history = useHistory()
  const dispatch = useDispatch()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const archivedProducts = useSelector(state => get(state, 'products.archived') || [])

  const [isLoading, setLoading] = useState(false)
  const [productToReactivate, setProductToReactivate] = useState(null)
  const [isProductReactivationPopupDisplayed, setProductReactivationPopupDisplay] = useState(false)

  const handleProductReactivation = () => {
    const productID = get(productToReactivate, 'id')
    setLoading(true)
    const productData = { is_archived: false }
    archiveProduct(productID, productData, companyID)
      .then(() => {
        dispatch(getProducts(companyID))
        Notification('success', __('Product reactivated'))
        history.push(`/${companyID}/products/${productID}`)
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot reactivate product', {
          product_id: productID
        })
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleReactivateProductsClick = cb => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <div className='ArchivedProducts'>
      <List
        loading={isLoading}
        columns={[
          {
            accessor: 'product_name',
            Header: __('Product Name')
          },
          {
            accessor: 'short_code',
            Header: __('Product code'),
            maxWidth: 200
          },
          {
            id: 'default_license_template',
            Header: __('Default License Policy'),
            Cell: cellData => {
              const defaultPolicy = getDefaultLicensePolicy([], cellData.original)
              return displayValue(get(defaultPolicy, 'name'))
            },
            sortable: false
          },
          {
            accessor: 'authorization_method',
            Header: __('Authorization method'),
            Cell: cellData => (cellData.value === 'license-key' ? __('License key') : __('User'))
          },
          {
            Header: __('Reactivate'),
            headerClassName: 'text-center',
            className: 'text-center',
            accessor: 'id',
            sortable: false,
            width: 100,
            Cell: rowData => (
              <Button
                featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_product)}
                featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_product)}
                notEnabledMessage={getDisabledMessage()}
                className='reset-button'
                onClick={() =>
                  handleReactivateProductsClick(() => {
                    setProductToReactivate(get(rowData, 'original'))
                    setProductReactivationPopupDisplay(true)
                  })
                }
                type='button'
              >
                <IconReset
                  fill='none'
                  stroke='#000'
                  strokeWidth='2'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                />
              </Button>
            )
          }
        ]}
        data={archivedProducts}
        minRows={2}
        pageSize={20}
      />
      {isProductReactivationPopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setProductToReactivate(null)
            setProductReactivationPopupDisplay(false)
          }}
          confirmCb={handleProductReactivation}
          confirmText={__('Reactivate')}
          disabled={isLoading}
          theme='success'
          title={__('Are you sure you want to reactivate this product?')}
        >
          <div className='ProductReactivation-popup'>
            <div className='row'>
              <span className='label'>{`${__('Product name')}:`}</span>
              <span className='value'>{get(productToReactivate, 'product_name')}</span>
            </div>
            <div className='row'>
              <span className='label'>{`${__('Product code')}:`}</span>
              <span className='value'>{get(productToReactivate, 'short_code')}</span>
            </div>
          </div>
        </ConfirmationPopup>
      )}
    </div>
  )
}

export default ArchivedProducts
