import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const ProgressBar = ({ percentage, min, color }) => (
  <div className='ProgressBar'>
    <div
      className='ProgressBar-fill'
      style={{ width: `${Math.max(min, percentage)}%`, background: color }}
    />
  </div>
)

ProgressBar.propTypes = {
  min: PropTypes.number,
  percentage: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  color: PropTypes.string
}

ProgressBar.defaultProps = {
  min: 5,
  percentage: 0,
  color: '#2e86de'
}

export default ProgressBar
