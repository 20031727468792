import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  Button,
  DescriptionTable,
  ConfirmationPopup,
  IconDelete,
  IconEdit,
  PermissionMissingNotificationTitle,
  Notification,
  Switcher,
  Label
} from 'shared/components'
import {
  sendErrorReport,
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
  capitalizeFirstLetter
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import {
  getCustomerAccProvider,
  deleteCustomerAccProvider,
  updateCustomerAccProvider
} from 'src/customer/actions'
import CustomerAccountSSOProviderForm from '../CustomerAccountSSOProviderForm'
import CustomerAccountRedirectUriForm from '../CustomerAccountRedirectUriForm'
import './styles.scss'

const CustomerAccountSSO = ({ accountID, redirectUrl, refetchAccount }) => {
  const canManageCustomers = checkUserPermission(userPermissions.customers_write)
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')

  const [providerLoading, setProviderLoading] = useState(true)
  const [provider, setProvider] = useState(null)
  const [providerToDelete, setProviderToDelete] = useState(null)
  const [isProviderDeleteConfirmationDisplayed, setProviderDeleteConfirmationDisplayed] = useState(
    false
  )
  const [providerDeleteLoading, setProviderDeleteLoading] = useState(false)
  const [isFormDisplayed, setFormDisplay] = useState(false)
  const [isRedirectFormDisplayed, setRedirectFormDisplay] = useState(false)

  const getProvider = useCallback(() => {
    getCustomerAccProvider(accountID, companyID)
      .then(res => {
        const data = get(res, 'data') || []
        setProvider(data)
        setProviderLoading(false)
      })
      .catch(() => {
        setProviderLoading(false)
      })
  }, [companyID])

  useEffect(() => {
    getProvider()
  }, [getProvider])

  const handleProviderDelete = () => {
    setProviderDeleteLoading(true)
    deleteCustomerAccProvider(accountID, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        setProviderDeleteLoading(false)
        setProviderToDelete(null)
        setProviderDeleteConfirmationDisplayed(false)
        getProvider()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete customer account sso provider')
        setProviderDeleteLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleAccountVerificationUpdate = () => {
    setProviderLoading(true)
    const usingDefaultFlow = get(provider, 'firstBrokerLoginFlowAlias') === null
    const data = {
      use_alternative_login_flow: !!usingDefaultFlow
    }
    updateCustomerAccProvider(companyID, accountID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        getProvider()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update sso provider', provider)
        setProviderLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleManageCustomerSSOClick = cb => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.customers_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const getProviderLabel = () => {
    if (!provider) {
      return '-'
    }
    const providerID = capitalizeFirstLetter(provider.providerId)
    return providerID
  }

  const RedirectUriCell = () => (
    <div className='redirect-uri-cell'>
      <div>{redirectUrl || '-'}</div>
      {provider && (
        <Button size='sm' onClick={() => setRedirectFormDisplay(true)}>
          <IconEdit height='14px' width='14px' />
        </Button>
      )}
    </div>
  )

  return (
    <div className='CustomerAccountSSO'>
      <div className='row table-row'>
        <h3>{__('Single Sign On Provider')}</h3>
        {!provider ? (
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
            notEnabledMessage={getDisabledMessage()}
            size='sm'
            theme='info'
            disabled={providerLoading}
            onClick={() => handleManageCustomerSSOClick(setFormDisplay)}
          >
            {__('Add provider')}
          </Button>
        ) : (
          <>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
              featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
              notEnabledMessage={getDisabledMessage()}
              size='sm'
              theme='error'
              disabled={providerLoading}
              onClick={() => handleManageCustomerSSOClick(setProviderDeleteConfirmationDisplayed)}
            >
              <>
                <IconDelete height='16px' width='16px' color='#ee5253' />
                {__('Delete provider')}
              </>
            </Button>
          </>
        )}
        <div className='providers-list'>
          <DescriptionTable
            details={[
              {
                label: __('Name'),
                value: displayValue(get(provider, 'displayName'))
              },
              { label: __('Type'), value: getProviderLabel() },
              {
                label: __('Redirect URI'),
                value: <RedirectUriCell />
              }
            ]}
            loading={providerLoading}
          />
          <div className='account-verification-switcher' inputId='acc-verification-switcher'>
            <Switcher
              checked={!!get(provider, 'firstBrokerLoginFlowAlias')}
              handleChange={handleAccountVerificationUpdate}
              size='sm'
              disabled={providerLoading || !provider}
            />
            <Label
              inputId='acc-verification-switcher'
              text={__('Require email verification on first SSO login')}
            />
          </div>
        </div>
      </div>
      {isProviderDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setProviderToDelete(null)
            setProviderDeleteConfirmationDisplayed(false)
          }}
          confirmCb={handleProviderDelete}
          title={`${__('Are you sure you want to delete this SSO provider')}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={providerDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {get(providerToDelete, 'provider_name')}
          </span>
        </ConfirmationPopup>
      )}
      {isFormDisplayed && (
        <CustomerAccountSSOProviderForm
          accountID={accountID}
          companyID={companyID}
          refetchProvider={() => {
            getProvider()
            refetchAccount()
          }}
          closeCb={() => setFormDisplay(false)}
          redirectUrl={redirectUrl}
        />
      )}
      {isRedirectFormDisplayed && (
        <CustomerAccountRedirectUriForm
          accountID={accountID}
          companyID={companyID}
          confirmCb={() => {
            refetchAccount()
            setRedirectFormDisplay(false)
          }}
          closeCb={() => setRedirectFormDisplay(false)}
          redirectUrl={redirectUrl}
        />
      )}
    </div>
  )
}

CustomerAccountSSO.propTypes = {
  accountID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  refetchAccount: PropTypes.func.isRequired,
  redirectUrl: PropTypes.string
}

CustomerAccountSSO.defaultProps = {
  redirectUrl: ''
}

export default CustomerAccountSSO
