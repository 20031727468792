/* eslint-disable quote-props */
import React from 'react'
import PropTypes from 'prop-types'

const CardType = ({ type }) => {
  const cards = {
    amex: '/img/cardIcons/american-express.png',
    dinersclub: '/img/cardIcons/diners-club.png',
    discover: '/img/cardIcons/discover.png',
    jcb: '/img/cardIcons/jcb.png',
    mastercard: '/img/cardIcons/mastercard.png',
    unionpay: '/img/cardIcons/unionpay.png',
    visa: '/img/cardIcons/visa.png',
    Unknown: '/img/cardIcons/unknown.png'
  }

  return (
    <div className='CardType'>
      <img src={cards[type]} alt={`${type} logo`} />
    </div>
  )
}

CardType.propTypes = {
  type: PropTypes.string
}

CardType.defaultProps = {
  type: 'Unknown'
}

export default CardType
