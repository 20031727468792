import React from 'react'
import PropTypes from 'prop-types'
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'
import './styles.scss'

const Expander = ({ items, ...rest }) => {
  if (!items || !Array.isArray(items)) {
    return null
  }

  return (
    <Accordion className='accordion Expander' {...rest}>
      {items.map((i, index) => (
        <AccordionItem key={i.uuid || index} uuid={i.uuid} className='accordion__item ExpanderItem'>
          <AccordionItemHeading className='accordion__heading ExpanderItemHeading'>
            <AccordionItemButton className='accordion__button ExpanderItemButton'>
              {i.heading}
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel className='accordion__panel ExpanderItemPanel'>
            {i.body}
          </AccordionItemPanel>
        </AccordionItem>
      ))}
    </Accordion>
  )
}

Expander.propTypes = {
  items: PropTypes.array.isRequired
}

export default Expander
