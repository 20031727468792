import api from 'shared/api'
import { setSearchQueryParam, formatOrderBy } from 'shared/helpers'

export const getOrders = (
  companyId,
  page,
  query,
  queryType,
  orderBy,
  pageSize = 20,
  customerLabels = []
) => {
  const queryUrl = setSearchQueryParam(query, queryType)
  const orderUrl = formatOrderBy(orderBy)
  const customerLabelsFilter = customerLabels.length
    ? `&customer_label__in=${customerLabels.map(l => l.value).join(',')}`
    : ''
  const url = `/api/v1/orders/?company=${companyId}&limit=${pageSize}&offset=${
    page ? page * pageSize : 0
  }${queryUrl}${orderUrl}${customerLabelsFilter}`
  return api.get(url)
}

export const getOrder = (companyID, orderID) => {
  const url = `/api/v1/orders/${orderID}/?company=${companyID}`
  return api.get(url)
}

export const createOrderLegacy = data => api.post('/api/v1/products/create_order/', data)

export const createOrder = (companyID, data) =>
  api.post(`/api/v1/orders/create_order/?company=${companyID}`, data)

export const updateOrder = (orderID, companyID, data) =>
  api.patch(`/api/v1/orders/${orderID}/?company=${companyID}`, data)

export const deleteOrder = (orderId, companyId) =>
  api.delete(`/api/v1/orders/${orderId}/?company=${companyId}`)

export const exportOrderDetails = (orderID, companyID) =>
  api.get(`/api/v1/orders/${orderID}/export/?company=${companyID}`)

export const disableLicenses = (companyID, licenseIds) => {
  const url = `/api/v1/licenses/disable_bulk/?company=${companyID}`
  return api.post(url, licenseIds)
}

export const updateUIOptions = (userPrefsID, companyID, data) => {
  const url = `/api/v1/users/${userPrefsID}/?company=${companyID}`
  return api.patch(url, data)
}

export const getOrderManagers = (orderID, companyID) => {
  const url = `/api/v1/orders/${orderID}/managers/?company=${companyID}`
  return api.get(url)
}

export const createLicenseManager = (companyID, data) => {
  const url = `/api/v1/companies/${companyID}/add_license_manager`
  return api.post(url, data)
}

export const addOrderManager = (orderID, companyID, data) => {
  const url = `/api/v1/orders/${orderID}/add_manager/?company=${companyID}`
  return api.post(url, data)
}

export const removeOrderManager = (orderID, companyID, licenseUserID) => {
  const url = `/api/v1/orders/${orderID}/remove_manager/?company=${companyID}`
  return api.post(url, { license_user_id: licenseUserID })
}

export const getLicenseManagers = (
  companyId,
  page = 0,
  query,
  queryType,
  orderBy,
  pageSize = 20
) => {
  const queryUrl = query ? `&${queryType}__icontains=${query}` : ''
  const orderUrl = formatOrderBy(orderBy)
  const url = `/api/v1/license-users/?company=${companyId}&is_license_manager=1&limit=${pageSize}&offset=${
    page ? page * pageSize : 0
  }${queryUrl}${orderUrl}`
  return api.get(url)
}

export const getLicenseManager = (managerID, companyID) =>
  api.get(`/api/v1/license-users/${managerID}/?company=${companyID}`)

export const removeLicenseManager = (managerID, companyID) =>
  api.post(`/api/v1/license-users/${managerID}/remove_lm_permissions/?company=${companyID}`)

export const updateLicenseManagerPermissions = (managerID, companyID, data) =>
  api.post(`/api/v1/license-users/${managerID}/update_lm_permissions/?company=${companyID}`, data)

export const getDistributionActivities = (
  companyId,
  page = 0,
  orderBy,
  from,
  to,
  pageSize = 20
) => {
  const gteParam = `&ts__date__gte=${from}`
  const lteParam = `&ts__date__lte=${to}`
  const orderUrl = formatOrderBy(orderBy)
  const url = `/api/v1/distribution-activities/?company=${companyId}&limit=${pageSize}&offset=${
    page ? page * pageSize : 0
  }${orderUrl}${gteParam}${lteParam}`
  return api.get(url)
}

export const getDefaultPermissions = companyID =>
  api.get(`/api/v1/default-license-manager-permissions/?company=${companyID}`)

export const updateDefaultPermissions = (companyID, data) =>
  api.post(`/api/v1/companies/${companyID}/set_default_manager_permissions/`, data)

export const getLmLicensesCount = (userID, companyID) =>
  api.get(`/api/v1/license-users/${userID}/created_licenses_counts/?company=${companyID}`)
