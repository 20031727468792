import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  sendErrorReport,
  formatDate,
  displayValue,
  checkUserPermission,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled
} from 'shared/helpers'
import { defaultDateFormat, userPermissions, platformFeatures } from 'shared/constants'
import {
  Button,
  Page,
  PermissionMissingNotificationTitle,
  Notification,
  List,
  IconCheckmark,
  IconDisabled
} from 'shared/components'
import { getWebhookEndpoints } from 'src/account/actions'
import EndpointForm from '../EndpointForm'
import './styles.scss'

const WebhooksList = () => {
  const history = useHistory()
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const companyID = useSelector(state => get(state, 'company.details.id'))

  const [isLoading, setLoading] = useState(true)
  const [webhooks, setWebhooks] = useState([])
  const [webhookFormDisplay, setWebhookFormDisplay] = useState(false)

  const fetchWebhooks = useCallback(() => {
    getWebhookEndpoints(companyID)
      .then(data => {
        const list = get(data, 'data.results') || []
        setWebhooks(list)
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot fetch webhooks')
        setLoading(false)
      })
  }, [companyID])

  useEffect(() => {
    fetchWebhooks()
  }, [fetchWebhooks])

  const handleManageWebhooksClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const handleRedirect = rowData => {
    const webhookID = get(rowData, 'original.id')
    return history.push(`/${companyID}/account/webhooks/${webhookID}`)
  }

  return (
    <Page title={__('Webhooks')}>
      <div className='WebhooksList'>
        <div className='list-header'>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_webhooks)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_webhooks)}
            notEnabledMessage={getDisabledMessage()}
            size='sm'
            theme='info'
            onClick={() => handleManageWebhooksClick(setWebhookFormDisplay)}
          >
            {__('Add endpoint')}
          </Button>
        </div>
        <div className='WebhooksList-list'>
          <List
            columns={[
              {
                accessor: 'url',
                Header: __('Endpoint')
              },
              {
                accessor: 'description',
                Header: __('Description'),
                Cell: cellData => displayValue(cellData.value)
              },
              {
                accessor: 'created_at',
                Header: __('Created on'),
                Cell: cellData => formatDate(cellData.value, defaultDateFormat),
                width: 160
              },
              {
                accessor: 'updated_at',
                Header: __('Last updated'),
                Cell: cellData => formatDate(cellData.value, defaultDateFormat),
                width: 160
              },
              {
                accessor: 'is_enabled',
                Header: __('Enabled'),
                className: 'text-center',
                headerClassName: 'text-center',
                Cell: cellData => {
                  const isEnabled = get(cellData, 'value')
                  return isEnabled ? (
                    <IconCheckmark color='#10ac84' height='14px' />
                  ) : (
                    <IconDisabled color='red' height='14px' />
                  )
                },
                width: 100
              }
            ]}
            data={webhooks}
            loading={isLoading}
            minRows={2}
            noDataText={__('No webhooks added')}
            clickable
            handleClick={rowData => handleRedirect(rowData)}
          />
        </div>
        {webhookFormDisplay && (
          <EndpointForm closeCb={() => setWebhookFormDisplay(false)} confirmCb={() => {}} />
        )}
      </div>
    </Page>
  )
}

export default WebhooksList
