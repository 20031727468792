import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import './styles.scss'

const DashboardButton = ({ path, children }) => (
  <div className='DashboardButton'>
    <NavLink to={path}>{children}</NavLink>
  </div>
)

DashboardButton.propTypes = {
  path: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]).isRequired
}

export default DashboardButton
