import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  Checkbox,
  DirtyFormAlert,
  Label,
  NumberInput,
  Notification,
  TextInput,
  Modal
} from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { validateRequiredNumber, debouncedValidateRequiredNumber } from 'shared/validation'
import { getCompanyFeatures } from 'src/company/actions'
import { editFeature } from 'src/billing/actions'
import './styles.scss'

const EditCompanyFeatureForm = ({ closeCb, companyId, feature }) => {
  const dispatch = useDispatch()

  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [isUnlimited, setUnlimited] = useState(get(feature, 'value') === -1)
  const [featureValue, setFeatureValue] = useState(get(feature, 'value'))
  const [featureValueError, setFeatureValueError] = useState('')
  const [featureStatus, setFeatureStatus] = useState(get(feature, 'is_active'))

  const validateValue = async val => {
    const defaultValue = get(feature, 'value')
    if (isUnlimited || !defaultValue) {
      return true
    }

    setLoading(true)
    let errors
    try {
      errors = await validateRequiredNumber(val)
      setFeatureValueError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate feature form value', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isValueValid = await validateValue(featureValue)
    return isValueValid
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()

    if (!isValid || loading) {
      return false
    }

    setLoading(true)
    const fValue = isUnlimited ? -1 : featureValue
    const data = {
      key: get(feature, 'key'),
      value: fValue || null,
      is_active: featureStatus,
      company: companyId
    }

    editFeature(get(feature, 'id'), data, companyId)
      .then(() => {
        dispatch(getCompanyFeatures(companyId))
        closeCb()
        Notification('success', __('Company feature successfully updated'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update company feature', data)
        setLoading(false)
        Notification('error', __('Error occured'), __('We could not update the company feature.'))
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setIsDirtyFormAlertDisplayed(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Edit feature')}
      size='sm'
      disabled={loading}
    >
      <div className='EditCompanyFeatureForm'>
        <div>
          <Label text={__('Name')} inputId='feature-name' />
          <TextInput
            value={get(feature, 'label')}
            handleChange={() => {}}
            disabled
            id='feature-name'
          />
        </div>
        <div>
          <Label text={__('Key')} inputId='feature-key' />
          <TextInput
            value={get(feature, 'key')}
            handleChange={() => {}}
            disabled
            id='feature-key'
          />
        </div>
        {get(feature, 'value') && (
          <div>
            <Label text={__('Value')} inputId='feature-value' />
            <Checkbox
              label={__('Is unlimited')}
              handleChange={val => {
                setDirty(true)
                setUnlimited(val)
              }}
              checked={isUnlimited}
              inputId='feature-value-unlimited'
            />
            {!isUnlimited && (
              <NumberInput
                min='1'
                max='2147483647'
                id='feature-value'
                handleChange={val => {
                  setDirty(true)
                  setFeatureValue(val)
                  debouncedValidateRequiredNumber(val).then(err => setFeatureValueError(err))
                }}
                value={featureValue}
                error={featureValueError}
              />
            )}
          </div>
        )}
        <div>
          <Label text={__('Status')} inputId='feature-active' />
          <Checkbox
            label={__('Is active')}
            handleChange={val => {
              setDirty(true)
              setFeatureStatus(val)
            }}
            checked={featureStatus}
            inputId='feature-active'
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

EditCompanyFeatureForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyId: PropTypes.number.isRequired,
  feature: PropTypes.object.isRequired
}

export default EditCompanyFeatureForm
