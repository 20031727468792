import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import './styles.scss'

const ChartTooltip = ({ active, payload, label, isDaily, tooltipLabel, isConsumption }) => {
  if (active && payload && payload.length) {
    return (
      <div className='ChartTooltip'>
        <div className='ChartTooltip-date'>{`${
          isDaily ? moment(label).format('MM/DD/YYYY') : moment(label).format('MM/DD/YYYY HH:mm')
        }`}</div>
        {isConsumption ? (
          <div>
            <div>
              {`${isDaily ? 'Daily' : 'Hourly'} consumptions: ${get(
                payload,
                '[0].payload.consumptions'
              )}`}
            </div>
            <div>{`Total: ${get(payload, '[0].payload.total_consumptions')}`}</div>
            <div>{`Consumptions: ${get(payload, '[0].payload.cons')}`}</div>
            <div>{`Overages: ${get(payload, '[0].payload.over')}`}</div>
          </div>
        ) : (
          <div className='ChartTooltip-row'>{`${tooltipLabel}: ${get(
            payload,
            '[0].payload.total_call_count'
          )}`}</div>
        )}
      </div>
    )
  }

  return <div />
}

ChartTooltip.propTypes = {
  active: PropTypes.bool,
  isConsumption: PropTypes.bool,
  isDaily: PropTypes.bool,
  payload: PropTypes.array,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tooltipLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

ChartTooltip.defaultProps = {
  active: false,
  isDaily: false,
  isConsumption: false,
  payload: [],
  label: '',
  tooltipLabel: ''
}

export default ChartTooltip
