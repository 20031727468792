import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { components } from 'react-select'
import { IconLock, Tooltip } from 'shared/components'
import './styles.scss'

const Option = ({ isDisabled, isSelected, ...props }) => (
  <Tooltip active={isDisabled} content={get(props, 'data.disabledMessage')} position='topLeft'>
    <div
      className={`Option ${isDisabled ? 'Option--disabled' : ''} ${
        isSelected ? 'Option--selected' : ''
      }`}
    >
      {isDisabled && !get(props, 'data.isOptionAvailable') && (
        <span className='Option-LockIcon'>
          <IconLock height='20px' width='20px' />
        </span>
      )}
      <components.Option {...props} />
    </div>
  </Tooltip>
)

Option.propTypes = {
  isDisabled: PropTypes.bool,
  isSelected: PropTypes.bool
}

Option.defaultProps = {
  isDisabled: false,
  isSelected: false
}

export default Option
