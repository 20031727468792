import React, { useState } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  FileUploader,
  Label,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components'
import api from 'shared/api'
import { sendErrorReport, checkUserPermission } from 'shared/helpers'
import { userPermissions } from 'shared/constants'
import placeholderImg from 'shared/assets/image-placeholder.jpg'
import { getCompanySuccess } from 'src/company/actions'
import './styles.scss'

const LogoUpload = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const dispatch = useDispatch()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const existingLogo = useSelector(state => get(state, 'company.details.logo'))
  const validFileTypes = '.png, .jpg, .jpeg'

  const [uploading, setUploading] = useState(false)
  const [progress, setProgress] = useState(0)

  const handleWrongFileType = () => {
    Notification(
      'error',
      __('Wrong file type'),
      `${__('Only this file types can be uploaded')}: ${validFileTypes}`
    )
  }

  const handleSubmitClick = val => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }

    const file = get(val, 'file')
    setUploading(true)

    const data = new FormData()
    data.append('logo', file)

    api
      .post(`/api/v1/companies/${companyID}/upload_logo/`, data, {
        onUploadProgress: progressEvent => {
          if (progressEvent.lengthComputable) {
            const percentCompleted = Math.floor((progressEvent.loaded * 100) / progressEvent.total)
            setProgress(percentCompleted)
          }
        }
      })
      .then(res => {
        dispatch(getCompanySuccess(res.data))
        Notification('success', __('Changes saved successfully'), __('Company logo updated'))
        val.remove()
        setUploading(false)
      })
      .catch(err => {
        sendErrorReport(err)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        setUploading(false)
      })
    return true
  }

  const handleItemRemove = () => {
    setUploading(false)
    setProgress(0)
  }

  return (
    <div className='LogoUpload'>
      <div className='logo-upload-container'>
        <Label text={__('Company logo')} inputId='logo-upload' />
        <div className='logo-upload-wrapper'>
          <div className='existing-logo'>
            <img src={existingLogo || placeholderImg} alt='company logo' />
          </div>
          <div className='logo-uploader'>
            <FileUploader
              disabled={uploading}
              progress={progress}
              onSubmit={handleSubmitClick}
              onWrongFileTypeReject={handleWrongFileType}
              onItemRemove={handleItemRemove}
              label={__('Drag file or click to browse')}
              subLabel={`${__('Valid file types')}:`}
              validFileTypes={validFileTypes}
              submitButtonContent={__('Change logo')}
              maxFiles={1}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default LogoUpload
