import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  Button,
  ConfirmationPopup,
  HiddenPasswordField,
  List,
  PermissionMissingNotificationTitle,
  Notification
} from 'shared/components'
import {
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import { addOrderManager, removeOrderManager } from 'src/order/actions'
import { removeLicenseUser, setLicenseUserActivations } from 'src/license/actions'
import AssignLicenseUser from '../AssignLicenseUser'
import EditLicenseUser from '../EditLicenseUser'
import ChangeLicenseUserPassword from '../ChangeLicenseUserPassword'
import BatchLicenseAssign from '../BatchLicenseAssign'
import LicenseUserMaxActivations from '../LicenseUserMaxActivations'
import LicenseUsersActionMenu from './LicenseUsersActionMenu'
import './styles.scss'

const LicenseUsers = ({ companyID, license, refetchLicense }) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write)
  const orderID = get(license, 'order')
  const licenseID = get(license, 'id')
  const [isLoading, setLoading] = useState(false)
  const [showAssignLicenseUserForm, setAssignLicenseUserFormDisplay] = useState(false)
  const [showLicenseUserForm, setLicenseUserFormDisplay] = useState(false)
  const [showBatchLicenseUserForm, setBatchLicenseUserFormDisplay] = useState(false)
  const [licenseUserToEdit, setLicenseUserToEdit] = useState(null)
  const [showUserRemovePopup, setUserRemovePopupDisplay] = useState(false)
  const [showUserManagerPopup, setUserManagerPopupDisplay] = useState(false)
  const [showMaxActivationsPopup, setMaxActivationsPopupDisplay] = useState(false)
  const [showActivationsResetPopup, setActivationsResetPopupDisplay] = useState(false)
  const [showPasswordChangePopup, setPasswordChangePopupDisplay] = useState(false)

  const maxLicenseUsers = get(license, 'max_license_users')
  const isUnlimitedLicenseUsers = get(license, 'max_license_users') === 0
  const licenseUsers = get(license, 'license_users') || []
  const currentLicenseUsersCount = licenseUsers.length
  const maxLicenseUserStatus = `${currentLicenseUsersCount} / ${
    isUnlimitedLicenseUsers ? __('Unlimited') : maxLicenseUsers
  }`

  const handleRemoveLicenseUser = () => {
    const userToRemoveID = get(licenseUserToEdit, 'id')
    setLoading(true)

    removeLicenseUser(companyID, licenseID, userToRemoveID)
      .then(() => {
        refetchLicense()
        setLoading(false)
        setLicenseUserToEdit(null)
        setUserRemovePopupDisplay(false)
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Cannot remove license user')
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleManagerChangeSuccess = () => {
    refetchLicense()
    setUserManagerPopupDisplay(false)
    setLicenseUserToEdit(null)
    setLoading(false)
    Notification('success', __('Changes saved successfully'), '')
  }

  const setAsManager = () => {
    setLoading(true)
    const userEmail = get(licenseUserToEdit, 'true_email')
    addOrderManager(orderID, companyID, { email: userEmail })
      .then(() => handleManagerChangeSuccess())
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user as manager', { value: userEmail })
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const removeAsManager = () => {
    setLoading(true)
    const userID = get(licenseUserToEdit, 'id')
    removeOrderManager(orderID, companyID, userID)
      .then(() => handleManagerChangeSuccess())
      .catch(err => {
        sendErrorReport(err, 'Cannot remove license user as manager')
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const resetTotalActivations = () => {
    setLoading(true)
    const userID = get(licenseUserToEdit, 'id')
    const data = {
      [userID]: { reset_total_activations: true }
    }
    setLicenseUserActivations(licenseID, companyID, data)
      .then(() => {
        refetchLicense()
        setActivationsResetPopupDisplay(false)
        setLicenseUserToEdit(null)
        setLoading(false)
        Notification('success', __('Changes saved successfully'), '')
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user as manager')
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleManageUsersClick = cb => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    if (!isUnlimitedLicenseUsers && currentLicenseUsersCount >= Number(maxLicenseUsers)) {
      Notification(
        'error',
        __('Max license users number reached'),
        __('Remove some user or increase the max license users number')
      )
      return false
    }
    cb(true)
    return true
  }

  const isUserToEditManager = get(licenseUserToEdit, 'is_license_manager')

  return (
    <div className='LicenseUsers license-user-tab'>
      <div className='license-user-tab-actions'>
        <div className='left'>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageUsersClick(setAssignLicenseUserFormDisplay)}
            theme='info'
            size='sm'
          >
            {__('Assign license user')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            onClick={() => handleManageUsersClick(setBatchLicenseUserFormDisplay)}
            theme='default'
            size='sm'
          >
            {__('Batch license assign')}
          </Button>
        </div>
        <div className='max-license-users-count'>{`${__(
          'Available number of users'
        )}: ${maxLicenseUserStatus}`}</div>
      </div>
      <List
        columns={[
          {
            accessor: 'true_email',
            Header: __('Email'),
            Cell: cellData => displayValue(cellData.value),
            minWidth: 200
          },
          {
            accessor: 'first_name',
            Header: __('First name'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'last_name',
            Header: __('Last name'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'phone_number',
            Header: __('Phone number'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'max_activations',
            Header: __('Max activations'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => {
              const isUnlimited = get(rowData, 'original.allow_unlimited_activations')
              if (isUnlimited) {
                return __('Unlimited')
              }
              return displayValue(rowData.value)
            }
          },
          {
            accessor: 'total_activations',
            Header: __('Total activations'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'is_license_manager',
            Header: __('Is license manager'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => (cellData.value ? __('Yes') : __('No'))
          },
          {
            accessor: 'is_initial_password',
            Header: __('Initial password'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: cellData => {
              if (!cellData.value) {
                return __('N/A')
              }
              const initialPass = get(cellData, 'original.initial_password')
              return <HiddenPasswordField value={initialPass} fallback={__('N/A')} />
            },
            width: 120
          },
          {
            id: 'actions',
            className: 'select-container action-menu',
            Header: __('Actions'),
            headerClassName: 'text-center',
            Cell: rowData => (
              <LicenseUsersActionMenu
                user={get(rowData, 'original')}
                handleEditLicenseUser={() => {
                  setLicenseUserToEdit(rowData.original)
                  setLicenseUserFormDisplay(true)
                }}
                handlePasswordChange={() => {
                  setLicenseUserToEdit(rowData.original)
                  setPasswordChangePopupDisplay(true)
                }}
                handleManagerLicenseUser={() => {
                  setLicenseUserToEdit(rowData.original)
                  setUserManagerPopupDisplay(true)
                }}
                handleMaxActivationsChange={() => {
                  setLicenseUserToEdit(rowData.original)
                  setMaxActivationsPopupDisplay(true)
                }}
                handleResetTotalActivations={() => {
                  setLicenseUserToEdit(rowData.original)
                  setActivationsResetPopupDisplay(true)
                }}
                handleRemoveLicenseUser={() => {
                  setLicenseUserToEdit(rowData.original)
                  setUserRemovePopupDisplay(true)
                }}
              />
            ),
            width: 70
          }
        ]}
        data={licenseUsers}
        minRows={2}
        pageSize={20}
      />
      {showAssignLicenseUserForm && (
        <AssignLicenseUser
          closeCb={() => {
            setAssignLicenseUserFormDisplay(false)
          }}
          refetchLicense={refetchLicense}
          licenseID={get(license, 'id')}
          companyID={companyID}
          orderID={get(license, 'order')}
          licenseUsers={licenseUsers}
        />
      )}
      {showLicenseUserForm && (
        <EditLicenseUser
          closeCb={() => {
            setLicenseUserToEdit(null)
            setLicenseUserFormDisplay(false)
          }}
          user={licenseUserToEdit}
          userID={get(licenseUserToEdit, 'id') || null}
          refetchLicense={refetchLicense}
          companyID={companyID}
        />
      )}
      {showPasswordChangePopup && (
        <ChangeLicenseUserPassword
          closeCb={() => {
            setLicenseUserToEdit(null)
            setPasswordChangePopupDisplay(false)
          }}
          user={licenseUserToEdit}
          refetchLicense={refetchLicense}
          companyID={companyID}
        />
      )}
      {showMaxActivationsPopup && (
        <LicenseUserMaxActivations
          closeCb={() => {
            setLicenseUserToEdit(null)
            setMaxActivationsPopupDisplay(false)
          }}
          user={licenseUserToEdit}
          licenseID={licenseID}
          refetchLicense={refetchLicense}
          companyID={companyID}
        />
      )}
      {showBatchLicenseUserForm && (
        <BatchLicenseAssign
          closeCb={() => setBatchLicenseUserFormDisplay(false)}
          confirmCb={refetchLicense}
          license={license}
          companyID={companyID}
        />
      )}
      {showUserRemovePopup && (
        <ConfirmationPopup
          closeCb={() => {
            setLicenseUserToEdit(null)
            setUserRemovePopupDisplay(false)
          }}
          confirmCb={handleRemoveLicenseUser}
          title={__('Are you sure you want to remove this user from the license?')}
          confirmText={__('Remove')}
          theme='error'
          disabled={isLoading}
        >
          {get(licenseUserToEdit, 'true_email')}
        </ConfirmationPopup>
      )}
      {showUserManagerPopup && (
        <ConfirmationPopup
          closeCb={() => {
            setLicenseUserToEdit(null)
            setUserManagerPopupDisplay(false)
          }}
          confirmCb={() => {
            if (isUserToEditManager) {
              removeAsManager()
            } else {
              setAsManager()
            }
          }}
          title={
            isUserToEditManager
              ? __('Are you sure you want to remove this user as manager?')
              : __('Are you sure you want to set this user as manager?')
          }
          confirmText={isUserToEditManager ? __('Remove') : __('Confirm')}
          theme={isUserToEditManager ? 'error' : 'success'}
          disabled={isLoading}
        >
          {get(licenseUserToEdit, 'true_email')}
        </ConfirmationPopup>
      )}
      {showActivationsResetPopup && (
        <ConfirmationPopup
          closeCb={() => {
            setLicenseUserToEdit(null)
            setActivationsResetPopupDisplay(false)
          }}
          confirmCb={resetTotalActivations}
          title={__('Are you sure you want to reset this users total license activations count?')}
          confirmText={__('Reset')}
          theme='error'
          disabled={isLoading}
        >
          {get(licenseUserToEdit, 'true_email')}
        </ConfirmationPopup>
      )}
    </div>
  )
}

LicenseUsers.propTypes = {
  companyID: PropTypes.number.isRequired,
  license: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired
}

export default LicenseUsers
