import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import {
  sendErrorReport,
  displayValue,
  formatDate,
  isAllowedEventRecipient,
  hasCustomEventData,
  joinArrayItems,
  checkUserPermission
} from 'shared/helpers'
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconDelete,
  IconEdit,
  List,
  Notification,
  PermissionMissingNotificationTitle,
  Switcher
} from 'shared/components'
import { defaultDateTimeFormat, eventsConfig, userPermissions } from 'shared/constants'
import {
  getNotificationPolicies,
  updateNotificationPolicy,
  deleteNotificationPolicy
} from 'src/notifications/actions'
import NotificationPolicyForm from '../components/NotificationPolicyForm'

const getCompanyUsers = (userIDS, usersList) => {
  const users = []
  userIDS.forEach(uid => {
    const user = usersList.find(u => u.id === uid)
    if (user) {
      users.push(user)
    }
  })
  return users
}

const NotificationPolicies = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const dispatch = useDispatch()

  const products = useSelector(state => get(state, 'products.list'))
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const users = useSelector(state => get(state, 'users.list'))
  // const events = useSelector(state => get(state, 'notifications.events'));
  const isLoading = useSelector(state => get(state, 'notifications.policiesLoading'))
  const notificationPolicies = useSelector(state =>
    get(state, 'notifications.notification_policies')
  )

  const [tableExpanded, setTableExpanded] = useState({})
  const [switcherLoading, setSwitcherLoading] = useState(false)
  const [isFormDisplayed, setFormDisplay] = useState(false)
  const [policyToEdit, setPolicyToEdit] = useState(null)
  const [policyToDelete, setPolicyToDelete] = useState(null)
  const [isPolicyDeleteConfirmationDisplayed, setPolicyDeleteConfirmationDisplayed] = useState(
    false
  )
  const [policyDeleteLoading, setPolicyDeleteLoading] = useState(false)

  const fetchNotificationPolicies = useCallback(() => {
    dispatch(getNotificationPolicies(companyID))
  }, [companyID])

  useEffect(() => {
    fetchNotificationPolicies()
  }, [fetchNotificationPolicies])

  const handleIsActiveChange = async (policy, isActive) => {
    setSwitcherLoading(true)
    const data = {
      ...policy,
      event: get(policy, 'event.id'),
      is_active: !isActive
    }
    const policyID = get(policy, 'id')
    try {
      await updateNotificationPolicy(policyID, companyID, data)
      Notification('success', __('Changes saved successfully'), __('Notification policy updated'))
      fetchNotificationPolicies()
      setSwitcherLoading(false)
    } catch (err) {
      sendErrorReport(err, 'Cannot edit notification policy active status', data)
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
      setSwitcherLoading(false)
    }
  }

  const handlePolicyDelete = async () => {
    const policyID = get(policyToDelete, 'id')
    setPolicyDeleteLoading(true)
    try {
      await deleteNotificationPolicy(policyID, companyID)
      Notification('success', __('Changes saved successfully'), __('Notification policy deleted'))
      fetchNotificationPolicies()
      setPolicyDeleteLoading(false)
      setPolicyDeleteConfirmationDisplayed(false)
      setPolicyToDelete(null)
    } catch (err) {
      sendErrorReport(err, 'Cannot delete notification policy')
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
      setPolicyDeleteLoading(false)
    }
  }

  const handlePolicyEdit = val => {
    setPolicyToEdit(val)
    setFormDisplay(true)
  }

  const handleManageNotificationsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <div className='NotificationPolicies'>
      <div className='list-header'>
        <div>
          <Button
            onClick={() =>
              handleManageNotificationsClick(() => {
                // const eventsNum = events.length;
                // const policyNum = notificationPolicies.length;

                // if (eventsNum === policyNum) {
                //   Notification('error', __('Notification policies for all available events have been created'));
                //   return false;
                // }
                setFormDisplay(true)
                return true
              })
            }
            size='sm'
            theme='info'
            disabled={isLoading}
          >
            {__('Add notification policy')}
          </Button>
        </div>
      </div>
      <List
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'name',
            Header: __('Name'),
            Cell: cellData => displayValue(cellData.value)
          },
          {
            accessor: 'event.code',
            Header: __('Event'),
            Cell: cellData => {
              const eventData = eventsConfig[cellData.value]
              return get(eventData, 'label') || '-'
            }
          },
          {
            accessor: 'product',
            Header: __('Product'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => {
              const product = get(rowData, 'original.product')
              if (!product) return __('All products')

              const findProduct = products.find(p => p.id === product)
              if (!findProduct) return __('All products')

              const url = `/${companyID}/products/${findProduct.id}`
              return <NavLink to={url}>{findProduct.product_name}</NavLink>
            }
          },
          {
            accessor: 'is_active',
            Header: __('Is active'),
            className: 'text-center',
            headerClassName: 'text-center',
            Cell: rowData => (
              <Switcher
                checked={rowData.value}
                handleChange={() =>
                  handleManageNotificationsClick(() =>
                    handleIsActiveChange(rowData.original, rowData.value)
                  )
                }
                size='sm'
                disabled={switcherLoading}
              />
            ),
            maxWidth: 100
          },
          {
            className: 'text-center',
            id: 'edit',
            width: 60,
            sortable: false,
            Cell: rowData => (
              <Button
                className='edit-button'
                onClick={() =>
                  handleManageNotificationsClick(() => handlePolicyEdit(rowData.original))
                }
                type='button'
              >
                <IconEdit height='16px' width='16px' />
              </Button>
            )
          },
          {
            className: 'text-center',
            id: 'delete',
            width: 60,
            sortable: false,
            Cell: rowData => (
              <Button
                className='edit-button'
                onClick={() =>
                  handleManageNotificationsClick(() => {
                    setPolicyToDelete(rowData.original)
                    setPolicyDeleteConfirmationDisplayed(true)
                    return true
                  })
                }
                type='button'
              >
                <IconDelete height='16px' width='16px' color='#ee5253' />
              </Button>
            ),
            maxWidth: 50
          }
        ]}
        data={notificationPolicies}
        minRows={2}
        pageSize={20}
        loading={isLoading}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => {
          const event = get(row, 'original.event')
          const eventData = { data: event }
          return (
            <div className='SubComponent'>
              <DescriptionTable
                details={[
                  {
                    label: __('Created on'),
                    value: formatDate(get(row, 'original.created_at'), defaultDateTimeFormat)
                  },
                  {
                    label: isAllowedEventRecipient(eventData, 'customer')
                      ? __('Notify customer')
                      : null,
                    value: get(row, 'original.notify_customer') ? __('Yes') : __('No')
                  },
                  {
                    label: isAllowedEventRecipient(eventData, 'license_users')
                      ? __('Notify license users')
                      : null,
                    value: get(row, 'original.notify_license_users') ? __('Yes') : __('No')
                  },
                  {
                    label: isAllowedEventRecipient(eventData, 'license_managers')
                      ? __('Notify license managers')
                      : null,
                    value: get(row, 'original.notify_license_managers') ? __('Yes') : __('No')
                  },
                  {
                    label: isAllowedEventRecipient(eventData, 'company_users')
                      ? __('Notify company users')
                      : null,
                    value: joinArrayItems(
                      getCompanyUsers(get(row, 'original.notify_users' || []), users),
                      'email'
                    )
                  },
                  {
                    label: isAllowedEventRecipient(eventData, 'custom_emails')
                      ? __('Notify custom users')
                      : null,
                    value: joinArrayItems(get(row, 'original.notify_custom_emails' || []), 'email')
                  },
                  {
                    label: hasCustomEventData(eventData, 'check_days') ? __('Check days') : null,
                    value: get(row, 'original.data.check_days')
                      ? get(row, 'original.data.check_days').join(',')
                      : ''
                  },
                  {
                    label: hasCustomEventData(eventData, 'users_left')
                      ? __('User slots remaining')
                      : null,
                    value: get(row, 'original.data.users_left')
                      ? get(row, 'original.data.users_left').join(',')
                      : ''
                  }
                ]}
              />
            </div>
          )
        }}
      />
      {isFormDisplayed && (
        <NotificationPolicyForm
          closeCb={() => {
            setFormDisplay(false)
            setPolicyToEdit(null)
          }}
          notificationPolicy={policyToEdit}
          notificationPolicies={notificationPolicies}
          companyID={companyID}
          refetchData={fetchNotificationPolicies}
        />
      )}
      {isPolicyDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setPolicyToDelete(null)
            setPolicyDeleteConfirmationDisplayed(false)
          }}
          confirmCb={handlePolicyDelete}
          title={`${__('Are you sure you want to delete this notification policy')}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={policyDeleteLoading}
        >
          <span style={{ fontSize: '14px', wordBreak: 'break-all' }}>
            {get(eventsConfig, `${get(policyToDelete, 'event.code')}.label`)}
          </span>
        </ConfirmationPopup>
      )}
    </div>
  )
}

export default NotificationPolicies
