import React from 'react'
import PropTypes from 'prop-types'
import { ReactComponent as NoDataLogo } from './no_data.svg'
import './styles.scss'

const NoGraphData = ({
  text,
  description,
  label,
  subtitle,
  maxHeight,
  minHeight,
  iconHeight,
  iconWidth
}) => (
  <div className='NoGraphData' style={{ minHeight: `${minHeight}px`, maxHeight: `${maxHeight}px` }}>
    {label && (
      <div className='NoGraphData-label'>
        <div className='title'>{label}</div>
        {subtitle && <div className='subtitle'>{subtitle}</div>}
      </div>
    )}
    <div className='NoGraphData-inner'>
      <NoDataLogo width={`${iconWidth}px`} height={`${iconHeight}px`} />
      {text && <div className='text'>{text}</div>}
      {description && <div className='description'>{description}</div>}
    </div>
  </div>
)

NoGraphData.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  description: PropTypes.string,
  minHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  iconWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

NoGraphData.defaultProps = {
  label: '',
  subtitle: '',
  description: '',
  text: '',
  minHeight: 300,
  maxHeight: 300,
  iconHeight: 100,
  iconWidth: 100
}

export default NoGraphData
