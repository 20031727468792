import React from 'react'
import PropTypes from 'prop-types'
import { get, last } from 'lodash'
import { DescriptionTable, JsonView, StatusCard, Subtitle } from 'shared/components'
import { displayValue, formatDate, isFeatureEnabled } from 'shared/helpers'
import { platformFeatures } from 'shared/constants'
import PlatformAuditLog from 'src/account/AuditLog/PlatformAuditLog'
import './styles.scss'

const ProductDetails = ({ product, versions }) => {
  const productLicensePolicies = get(product, 'license_templates') || []
  const defaultPolicy = productLicensePolicies.find(p => p.is_default)
  const productStatus = get(product, 'active') ? 'success' : 'error'
  const productStatusText = get(product, 'active') ? __('Active') : __('Disabled')

  const isFloatingCloudFeatureEnabled = isFeatureEnabled(platformFeatures.model_floating_cloud)
  const isFleoatingOnPremisesFeatureEnabled = isFeatureEnabled(
    platformFeatures.model_floating_on_premises
  )
  const isAnyFloatingFeatureEnabled =
    isFloatingCloudFeatureEnabled || isFleoatingOnPremisesFeatureEnabled

  return (
    <div className='ProductDetails'>
      <DescriptionTable
        details={[
          {
            label: __('Product code'),
            value: displayValue(get(product, 'short_code'))
          },
          {
            label: __('Created on'),
            value: formatDate(get(product, 'created_at'))
          },
          {
            label: __('Status'),
            value: <StatusCard text={productStatusText} status={productStatus} />
          },
          {
            label: __('Number of licenses'),
            value: displayValue(get(product, 'licenses_count'))
          },
          {
            label: __('Default License Policy'),
            value: displayValue(get(defaultPolicy, 'name'))
          },
          {
            label: __('Authorization method'),
            value: displayValue(get(product, 'authorization_method'))
          },
          {
            label: get(product, 'allow_trial') ? __('Allow trial') : null,
            value: get(product, 'allow_trial') ? __('Yes') : __('No')
          },
          {
            label: isAnyFloatingFeatureEnabled ? __('Floating timeout') : null,
            value: `${displayValue(get(product, 'floating_timeout'))} min`
          },
          {
            label: __('Latest Version'),
            value: displayValue(get(last(versions), 'version'))
          },
          {
            label: __('Metadata'),
            value: <JsonView value={get(product, 'metadata')} name='metadata' />
          }
        ]}
      />
      <div className='Logs'>
        <Subtitle text={__('Logs')} />
        <PlatformAuditLog product={product} />
      </div>
    </div>
  )
}

ProductDetails.propTypes = {
  product: PropTypes.object.isRequired,
  versions: PropTypes.array
}

ProductDetails.defaultProps = {
  versions: []
}

export default ProductDetails
