import React, { useEffect, useState, useCallback, useRef } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink, useParams, useHistory, useLocation } from 'react-router-dom'
import {
  copyText,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers'
import {
  Button,
  ConfirmationPopup,
  ContentLoader,
  IconClipboard,
  NotFound,
  Notice,
  Notification,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  PermissionMissingNotificationTitle
} from 'shared/components'
import { platformFeatures, userPermissions } from 'shared/constants'
import { updateUserPreferences } from 'src/company/actions'
import {
  deleteLicense,
  fetchLicense,
  resetLicense,
  disableLicense,
  enableLicense
} from 'src/license/actions'
import { getLicensePolicy } from 'src/product/actions'
import EditLicenseForm from '../EditLicenseForm'
import BundleLicenseContainer from '../BundleLicenseContainer'
import {
  CustomBreadcrumbs,
  LicenseDetailsTable,
  CustomFields,
  ProductFeatures,
  LicenseUsers,
  LicenseAnalytics,
  DevicesList,
  BlacklistedDevicesList,
  UsageReport,
  LicenseOAuth
} from './components'
import './styles.scss'

const LicenseContainer = () => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write)
  const params = useParams()
  const { licenseId } = params
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const fetchDevicesRef = useRef(0)
  const fetchBlacklistedRef = useRef(0)

  const companyID = useSelector(state => get(state, 'company.details.id'))
  const products = useSelector(state => get(state, 'products.list') || [])
  const uiOptions = useSelector(state => get(state, 'company.userPreferences.ui_options'))
  const userPrefsId = useSelector(state => get(state, 'company.userPreferences.id'))

  const [selectedTab, setSelectedTab] = useState(0)
  const [isLoading, setLoading] = useState(true)
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [license, setLicense] = useState(null)
  const [isEnableLicenseConfirmationDisplayed, setEnableLicenseConfirmationDisplay] = useState(
    false
  )
  const [isResetLicenseConfirmationDisplayed, setResetLicenseConfirmationDisplay] = useState(false)
  const [showEditLicenseForm, setEditLicenseFormDisplay] = useState(false)

  // license delete
  const [isDeleteLicenseIntentDisplayed, setDeleteLicenseIntentDisplay] = useState(false)
  const [isDeleteLicenseWarningDisplayed, setDeleteLicenseWarningDisplay] = useState(false)
  const [licenseDeleteLoading, setLicenseDeleteLoading] = useState(false)
  const hideOauthSettings = useSelector(state => get(state, 'company.details.hide_oauth_settings'))

  // is_air_gapped
  const [licensePolicy, setLicensePolicy] = useState(null)

  const refetchDevices = () => {
    fetchDevicesRef.current += 1
    return true
  }
  const refetchBlacklistedDevices = () => {
    fetchBlacklistedRef.current += 1
    return true
  }

  const fetchLicensePolicy = useCallback(policyID => {
    getLicensePolicy(policyID, companyID)
      .then(res => {
        setLicensePolicy(get(res, 'data'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get license template details')
      })
  }, [])

  const getLicense = useCallback(() => {
    fetchLicense(licenseId, companyID)
      .then(res => {
        const airGap = get(res, 'data.is_air_gapped')
        setLicense(get(res, 'data'))
        setLoading(false)

        if (airGap) {
          const policy = get(res, 'data.license_template')
          fetchLicensePolicy(policy)
        }
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get license details')
        setLoading(false)
        setNotFound(true)
      })
  }, [licenseId, companyID])

  const handleLicenseDelete = () => {
    setLicenseDeleteLoading(true)

    deleteLicense(licenseId, companyID)
      .then(() => {
        history.push(`/${companyID}/licenses`)
        Notification('success', __('License succesfully deleted'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete license')
        setLicenseDeleteLoading(false)
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
  }

  useEffect(() => {
    getLicense()
  }, [licenseId])

  useEffect(() => {
    const queryParams = get(location, 'search')
    if (!queryParams) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1)
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2)
    }
    if (queryParams.indexOf('st=3') >= 0) {
      setSelectedTab(3)
    }
    if (queryParams.indexOf('st=4') >= 0) {
      setSelectedTab(4)
    }
    if (queryParams.indexOf('st=5') >= 0) {
      setSelectedTab(5)
    }
    if (queryParams.indexOf('st=6') >= 0) {
      setSelectedTab(6)
    }
    if (queryParams.indexOf('st=7') >= 0) {
      setSelectedTab(7)
    }
  }, [location])

  if (isLoading) {
    return (
      <Page showBreadcrumbs={false}>
        <ContentLoader text={__('Getting license details')} />
      </Page>
    )
  }

  if (notFound) {
    return (
      <Page showBreadcrumbs={false}>
        <NotFound />
      </Page>
    )
  }

  const currentProduct = products.find(p => get(p, 'id') === get(license, 'product.id'))
  const isAuthUserBased = get(currentProduct, 'authorization_method') === 'user'
  const isAirGapped = get(license, 'is_air_gapped')
  const isBundleLicense = get(license, 'is_bundle')
  // const isHardwareKeyAuth = get(license, 'is_hardware_key_auth');

  const handleFieldCopy = (val, desc) => {
    copyText(val)
    Notification('success', `${desc} ${__('copied to clipboard')}`)
  }

  const createLicenseTitle = () => {
    const value = isAuthUserBased
      ? get(currentProduct, 'product_name') || ''
      : get(license, 'license_key')
    const label = isAuthUserBased ? __('License product') : __('License key')

    return (
      <div className='license-title-container'>
        {isAuthUserBased ? __('License for') : label}
        :&nbsp;
        <span>{value}</span>
        <button
          type='button'
          className='clipboard-btn'
          onClick={() => handleFieldCopy(value, label)}
        >
          <IconClipboard width='21.5' height='20' viewBox='0 0 51.5 50' />
        </button>
      </div>
    )
  }

  const createCustomerLink = () => {
    const customer = get(license, 'customer')
    const customerAccount = get(customer, 'customer_account')
    const customerID = get(customer, 'id')
    const customerDesc =
      get(customer, 'email') ||
      get(customerAccount, 'name') ||
      get(customer, 'company_name') ||
      `${get(customer, 'last_name')}, ${get(customer, 'first_name')}`

    if (!customer) {
      return null
    }

    return (
      <div>
        {__('Customer')}
        :&nbsp;
        <NavLink to={`/${companyID}/customers/${customerID}`}>{customerDesc}</NavLink>
      </div>
    )
  }

  const handleResetLicense = () => {
    setConfirmationLoading(true)

    resetLicense(licenseId, license, companyID)
      .then(() => {
        getLicense()
        setConfirmationLoading(false)
        setResetLicenseConfirmationDisplay(false)
        Notification('success', __('Changes saved successfully'), __('Your license has been reset'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot reset license')
        setConfirmationLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const patchEnabledStatus = () => {
    const currentStatus = get(license, 'enabled')
    setConfirmationLoading(true)
    const licenseData = { enabled: !currentStatus }

    if (currentStatus) {
      disableLicense(licenseId, companyID)
        .then(() => {
          setConfirmationLoading(false)
          getLicense()
          setEnableLicenseConfirmationDisplay(false)
          Notification(
            'success',
            __('Changes saved successfully'),
            !currentStatus ? __('License enabled') : __('License disabled')
          )
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot enable license', licenseData)
          setConfirmationLoading(false)
          Notification(
            'error',
            __('Your changes were not saved'),
            __('There was an error while saving your changes')
          )
        })
    } else {
      enableLicense(licenseId, companyID)
        .then(() => {
          setConfirmationLoading(false)
          getLicense()
          setEnableLicenseConfirmationDisplay(false)
          Notification(
            'success',
            __('Changes saved successfully'),
            !currentStatus ? __('License enabled') : __('License disabled')
          )
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot enable license', licenseData)
          setConfirmationLoading(false)
          Notification(
            'error',
            __('Your changes were not saved'),
            __('There was an error while saving your changes')
          )
        })
    }
  }

  const handleManageLicenseClick = cb => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  if (isBundleLicense) {
    return (
      <div className='LicenseContainer BundleLicense'>
        <Page
          showBreadcrumbs={false}
          customBreadcrumbs={
            <CustomBreadcrumbs
              companyId={companyID}
              order={{
                id: get(license, 'order'),
                store_id: get(license, 'order_store_id')
              }}
            />
          }
          title={createLicenseTitle()}
          description={createCustomerLink()}
        >
          <BundleLicenseContainer
            license={license}
            companyID={companyID}
            bundleProduct={currentProduct}
            refetchLicense={getLicense}
          />
        </Page>
      </div>
    )
  }

  return (
    <div className='LicenseContainer'>
      <Page
        showBreadcrumbs={false}
        customBreadcrumbs={
          <CustomBreadcrumbs
            companyId={companyID}
            order={{
              id: get(license, 'order'),
              store_id: get(license, 'order_store_id')
            }}
          />
        }
        title={createLicenseTitle()}
        description={createCustomerLink()}
      >
        <div className='list-header'>
          <div>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
              notEnabledMessage={getDisabledMessage()}
              theme='info'
              onClick={() => handleManageLicenseClick(setEditLicenseFormDisplay)}
              disabled={isAirGapped}
            >
              {__('Edit license')}
            </Button>
          </div>
          <div>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
              notEnabledMessage={getDisabledMessage()}
              theme='warning'
              onClick={() => handleManageLicenseClick(setResetLicenseConfirmationDisplay)}
            >
              {__('Reset license')}
            </Button>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
              notEnabledMessage={getDisabledMessage()}
              theme={get(license, 'enabled') ? 'error' : 'success'}
              onClick={() => handleManageLicenseClick(setEnableLicenseConfirmationDisplay)}
            >
              {get(license, 'enabled') ? __('Disable license') : __('Enable license')}
            </Button>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
              featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
              notEnabledMessage={getDisabledMessage()}
              theme='default'
              onClick={() => handleManageLicenseClick(setDeleteLicenseIntentDisplay)}
              disabled={isAirGapped}
            >
              {__('Delete license')}
            </Button>
          </div>
        </div>
        {isAirGapped && (
          <Notice theme='info' size='sm' title={__('Air-gapped licensing')}>
            <div>
              <div>
                {__(
                  'Air-gapped licenses are meant to be used in Zero Trust Network environments where usual offline licensing via file exchange is not possible.'
                )}
              </div>
              <div>{__('This license cannot be edited or deleted once it is created.')}</div>
              <div className='docs-link'>
                <p>{__('Check out the documentantion for air-gapped licensing')}</p>
                <a
                  href='https://docs.licensespring.com/sdks/tutorials/licensing-scenarios/air-gapped'
                  target='_blank'
                  rel='noopener noreferrer'
                >{`${__('here')}.`}</a>
              </div>
            </div>
          </Notice>
        )}
        {/* {isHardwareKeyAuth && (
          // TODO add link to docs
          <Notice theme="info" size="sm" title="Hardware key licensing">
            <div>
              <div className="docs-link">
                <p>{__('This license can only be activated and used with a hardware key. Check out the documentation')}</p>
                <a href="https://docs.licensespring.com/sdks/tutorials/licensing-scenarios/air-gapped" target="_blank" rel="noopener noreferrer">{`${__('here')}.`}</a>
              </div>
              <div>
                <Button onClick={() => setHwKeyProvisioning(true)}>{__('Provision the key certificate')}</Button>
              </div>
            </div>
          </Notice>
        )} */}
        <div className='LicenseContainer-tabs'>
          <Tabs
            defaultFocus
            selectedIndex={selectedTab}
            onSelect={tabIndex => {
              history.push({
                search: `?st=${tabIndex}`
              })
              setSelectedTab(tabIndex)
            }}
          >
            <TabsHeader>
              <Tab>{__('License details')}</Tab>
              <Tab>{__('Devices')}</Tab>
              <Tab>{__('Custom fields')}</Tab>
              <Tab>{__('Product features')}</Tab>
              {isAuthUserBased && <Tab>{__('Users')}</Tab>}
              <Tab>{__('Usage report')}</Tab>
              <Tab>{__('Analytics')}</Tab>
              {!hideOauthSettings && <Tab>{__('OAuth')}</Tab>}
            </TabsHeader>
            <TabContent>
              <LicenseDetailsTable
                license={license}
                companyID={companyID}
                isAuthUserBased={isAuthUserBased}
                currentProduct={currentProduct}
                licensePolicy={licensePolicy}
                refetchLicense={getLicense}
              />
            </TabContent>
            <TabContent>
              <div>
                <DevicesList
                  companyID={companyID}
                  license={license}
                  uiOptions={uiOptions}
                  refetchLicense={getLicense}
                  updateUserPreferences={data => dispatch(updateUserPreferences(data))}
                  userPrefsId={userPrefsId}
                  refetchBlacklistedDevices={refetchBlacklistedDevices}
                  shouldUpdateList={fetchDevicesRef.current}
                />
                <BlacklistedDevicesList
                  license={license}
                  companyID={companyID}
                  refetchDevices={refetchDevices}
                  refetchLicense={getLicense}
                  shouldUpdateList={fetchBlacklistedRef.current}
                />
              </div>
            </TabContent>
            <TabContent>
              <CustomFields
                license={license}
                currentProduct={currentProduct}
                companyID={companyID}
              />
            </TabContent>
            <TabContent>
              <ProductFeatures
                companyID={companyID}
                currentProduct={currentProduct}
                features={get(license, 'product_features') || []}
                license={license}
                refetchLicense={getLicense}
              />
            </TabContent>
            {isAuthUserBased && (
              <TabContent>
                <LicenseUsers companyID={companyID} license={license} refetchLicense={getLicense} />
              </TabContent>
            )}
            <TabContent>
              <UsageReport license={license} isAuthUserBased={isAuthUserBased} />
            </TabContent>
            <TabContent>
              <LicenseAnalytics license={license} />
            </TabContent>
            {!hideOauthSettings && (
              <TabContent>
                <LicenseOAuth licenseID={licenseId} companyID={companyID} />
              </TabContent>
            )}
          </Tabs>
        </div>
      </Page>
      {showEditLicenseForm && (
        <EditLicenseForm
          closeCb={() => setEditLicenseFormDisplay(!showEditLicenseForm)}
          isOpen={showEditLicenseForm}
          license={license}
          currentProduct={currentProduct}
          refetchLicense={getLicense}
        />
      )}
      {isResetLicenseConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setResetLicenseConfirmationDisplay(false)}
          confirmCb={handleResetLicense}
          title={__('Are you sure you want to reset your license?')}
          confirmText={__('Reset')}
          theme='warning'
          disabled={confirmationLoading}
        />
      )}
      {isEnableLicenseConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setEnableLicenseConfirmationDisplay(false)}
          confirmCb={patchEnabledStatus}
          title={__(
            `Are you sure you want to ${
              get(license, 'enabled') ? __('disable') : __('enable')
            } your license?`
          )}
          confirmText={get(license, 'enabled') ? __('Disable') : __('Enable')}
          theme={get(license, 'enabled') ? 'error' : 'success'}
          disabled={confirmationLoading}
        >
          {get(license, 'enabled')
            ? __('License will be disabled.')
            : __('License will be enabled.')}
        </ConfirmationPopup>
      )}
      {isDeleteLicenseIntentDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeleteLicenseIntentDisplay(false)}
          confirmCb={() => setDeleteLicenseWarningDisplay(true)}
          title={__('Are you sure you want to delete this license?')}
          confirmText={__('Delete')}
          theme='error'
          disabled={licenseDeleteLoading}
        >
          {__('This action cannot be undone!')}
        </ConfirmationPopup>
      )}
      {isDeleteLicenseWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setDeleteLicenseWarningDisplay(false)
            setDeleteLicenseIntentDisplay(false)
          }}
          confirmCb={handleLicenseDelete}
          title={__('Please, confirm that you really want to delete this license.')}
          confirmText={__('Delete')}
          theme='error'
          warning
          disabled={licenseDeleteLoading}
        />
      )}
    </div>
  )
}

export default LicenseContainer
