import React from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'
import { Button, IconDisabled, IconInfo } from 'shared/components'
import './styles.scss'

const ConfirmationPopup = ({
  confirmText,
  disabled,
  title,
  theme,
  children,
  confirmCb,
  cancelText,
  closeCb,
  warning,
  ...props
}) =>
  createPortal(
    <div className={`ConfirmationPopup ${warning ? 'ConfirmationPopup-warning' : ''}`} {...props}>
      <div className='ConfirmationPopup-inner'>
        <div className='ConfirmationPopup-header'>
          {warning && <IconInfo height='40px' width='40px' color='#ee5253' />}
          {title}
        </div>
        {children && <div className='ConfirmationPopup-body'>{children}</div>}
        <div className='ConfirmationPopup-footer'>
          <Button onClick={confirmCb} theme={theme} disabled={disabled}>
            {confirmText}
          </Button>
          <Button onClick={closeCb} theme='link' disabled={disabled}>
            {cancelText}
          </Button>
          <button
            type='button'
            className='ConfirmationPopup-dismiss'
            onClick={closeCb}
            disabled={disabled}
          >
            <IconDisabled height='16px' />
          </button>
        </div>
      </div>
    </div>,
    document.body
  )

ConfirmationPopup.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.array]),
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  disabled: PropTypes.bool,
  theme: PropTypes.oneOf(['default', 'info', 'warning', 'success', 'error', 'link']),
  title: PropTypes.string,
  warning: PropTypes.bool
}

ConfirmationPopup.defaultProps = {
  children: '',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  disabled: false,
  warning: false,
  theme: 'success',
  title: 'Are you sure?'
}

export default ConfirmationPopup
