import { find, get } from 'lodash'

export const getProductName = (id, products, fallback = '-') => {
  const product = find(products, { id })
  return get(product, 'product_name') || fallback
}

export const mapLicenses = (data, products) => {
  const licenses = []

  if (Array.isArray(data)) {
    data.forEach(d => {
      const licensesList = get(d, 'licenses') || []

      licensesList.forEach(l => {
        const license = {
          ...l,
          product: getProductName(get(d, 'product_id'), products)
        }
        licenses.push(license)
      })
    })
  }

  return licenses
}
