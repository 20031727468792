import React from 'react'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'

const TrialStandardNotice = ({ theme, company, trial, isAdmin }) => (
  <div className={`PageNotice PageNotice-theme--${theme} TrialStandardNotice`}>
    <div className='PageNotice-title'>
      {`${__('You have')} ${trial.remaining_days} ${
        trial.remaining_days > 1 ? __('days') : __('day')
      } ${__('remaining in your trial')}.`}
    </div>
    {isAdmin ? (
      <div className='PageNotice-body'>
        <NavLink to={`/${company}/account/billing`}>
          {__('Add a payment method to activate your plan.')}
        </NavLink>
      </div>
    ) : (
      <div className='PageNotice-body'>
        {__('Add a payment method to activate your plan.')}
        &nbsp;
        {__(
          'You must have admin rights in order to access this section. Please contact your account administrator to gain access.'
        )}
      </div>
    )}
  </div>
)

TrialStandardNotice.propTypes = {
  company: PropTypes.number.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error']),
  trial: PropTypes.object.isRequired
}

TrialStandardNotice.defaultProps = {
  theme: 'error'
}

export default TrialStandardNotice
