import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { IconInfo, Tooltip } from 'shared/components'
import './styles.scss'

const SelectedCustomFieldsTags = ({ cFields }) => {
  if (!Array.isArray(cFields) || !cFields.length) {
    return '-'
  }

  return (
    <div className='SelectedCustomFieldsTags'>
      {cFields.map(cf => (
        <div key={cf.value || cf.id} className='CFTag'>
          <Tooltip
            overlayClassName='cf-tags-tooltip'
            active
            content={
              <div className='cf-tags-tooltip-inner'>
                <div className='type'>
                  <span className='value'>
                    {get(cf, 'cf_value') || get(cf, 'value') || get(cf, 'default_value')}
                  </span>
                </div>
              </div>
            }
          >
            <div className='CFTag-inner'>
              <span>{get(cf, 'name') || get(cf, 'label')}</span>
              <IconInfo height='10px' width='10px' />
            </div>
          </Tooltip>
        </div>
      ))}
    </div>
  )
}

SelectedCustomFieldsTags.propTypes = {
  cFields: PropTypes.array
}

SelectedCustomFieldsTags.defaultProps = {
  cFields: []
}

export default SelectedCustomFieldsTags
