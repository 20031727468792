import React from 'react'
import PropTypes from 'prop-types'
import { InputErrorMessage } from 'shared/components'
import './styles.scss'

const TextArea = ({ disabled, error, handleChange, loading, rows, ...rest }) => (
  <div className='TextArea'>
    <textarea
      className={error ? 'has-error' : ''}
      disabled={disabled}
      rows={rows}
      onChange={e => handleChange(e.target.value, e)}
      {...rest}
    />
    <InputErrorMessage text={error} />
  </div>
)

TextArea.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  rows: PropTypes.string,
  loading: PropTypes.bool
}

TextArea.defaultProps = {
  disabled: false,
  error: '',
  rows: '2',
  loading: false
}

export default TextArea
