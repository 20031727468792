import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { NavLink as Link } from 'react-router-dom'
import { Notification } from 'shared/components'
import { defaultDisabledFeatureMessage } from 'shared/constants'

const NavLink = ({ children, disabled, disabledMessage, to, hide, ...props }) => {
  const handleClick = useCallback(
    e => {
      e.preventDefault(e)
      Notification('error', __(defaultDisabledFeatureMessage), __(disabledMessage))
    },
    [defaultDisabledFeatureMessage, disabledMessage]
  )

  if (hide) {
    return null
  }

  return (
    <Link // eslint-disable-line jsx-a11y/anchor-is-valid
      onClick={disabled ? handleClick : undefined}
      to={disabled ? '#' : to}
      {...props}
    >
      {children}
    </Link>
  )
}

NavLink.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  disabled: PropTypes.bool,
  disabledMessage: PropTypes.string,
  to: PropTypes.string,
  hide: PropTypes.bool
}

NavLink.defaultProps = {
  children: '',
  disabled: false,
  disabledMessage: '',
  to: '',
  hide: false
}

export default NavLink
