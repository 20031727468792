import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  sendErrorReport,
  displayValue,
  isFeatureAllowed,
  checkUserPermission
} from 'shared/helpers'
import {
  Button,
  DescriptionTable,
  Forbidden,
  IconSearch,
  List,
  Notification,
  Page,
  PermissionMissingNotificationTitle,
  Selector,
  TextInput
} from 'shared/components'
import {
  platformFeatures,
  searchType,
  initialCustomerAccountsListSort,
  userPermissions
} from 'shared/constants'
import { fetchCustomerAccounts } from 'src/customer/actions'
import CustomerAccountForm from '../components/CustomerAccountForm'

const CustomerAccountsList = () => {
  const canManageCustomers = checkUserPermission(userPermissions.customers_write)
  const history = useHistory()
  const companyID = useSelector(state => get(state, 'company.details.id'))

  const [isLoading, setLoading] = useState(true)
  const [customerAccounts, setCustomerAccounts] = useState([])
  // table state
  const [customerAccountsCount, setCustomerAccountsCount] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [currentSort, setSort] = useState(initialCustomerAccountsListSort)
  const [tableExpanded, setTableExpanded] = useState({})
  // search state
  const [query, setQuery] = useState('')
  const [activeQuery, setActiveQuery] = useState('')
  const [searchBy, setSearchBy] = useState(get(searchType, 'all'))
  // forms state
  const [isFormDisplayed, setFormDisplay] = useState(false)

  const getCustomerAccounts = useCallback(
    (
      tablePage = 0,
      activeQ = undefined,
      searchTypeSelect = undefined,
      sort = currentSort,
      rows = rowsPerPage
    ) => {
      setLoading(true)
      fetchCustomerAccounts(companyID, tablePage, activeQ, searchTypeSelect, sort, rows)
        .then(res => {
          setCustomerAccounts(get(res, 'data.results') || [])
          setCustomerAccountsCount(get(res, 'data.count'))
          setLoading(false)
        })
        .catch(err => {
          setLoading(false)
          sendErrorReport(err, 'Cannot fetch customer accounts list')
        })
    },
    [companyID]
  )

  useEffect(() => {
    getCustomerAccounts()
  }, [getCustomerAccounts])

  const handleSearchSubmit = e => {
    e.preventDefault()
    setActiveQuery(query)
    setPage(0)
    getCustomerAccounts(0, query, searchBy, currentSort, rowsPerPage)
  }

  const handleSearchClear = () => {
    setQuery('')
    setActiveQuery('')
    setPage(0)
    getCustomerAccounts(0, undefined, undefined, currentSort, rowsPerPage)
  }

  const handlePageChange = newPage => {
    setPage(newPage)
    getCustomerAccounts(newPage, activeQuery, searchBy, currentSort, rowsPerPage)
  }

  const handleSortChange = newSorted => {
    setSort(newSorted)
    getCustomerAccounts(0, activeQuery, searchBy, newSorted, rowsPerPage)
  }

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage)
    setRowsPerPage(newPageSize)
    getCustomerAccounts(newPage, activeQuery, searchBy, currentSort, newPageSize)
  }

  const redirectToCustomerPage = rowData => {
    const customerAccountID = get(rowData, 'original.id')
    history.push(`/${companyID}/customers/accounts/${customerAccountID}`)
  }

  const handleManageCustomerClick = cb => {
    if (!canManageCustomers) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.customers_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const isAllowed = isFeatureAllowed(platformFeatures.extra_account_based_licensing)
  if (!isAllowed) {
    return (
      <Page title={__('Customer accounts')}>
        <div className='CustomerAccountsList'>
          <Forbidden
            text={__('This feature is not available in your current plan type')}
            description={__('Contact sales if you wish to change this policy.')}
          />
        </div>
      </Page>
    )
  }

  return (
    <Page title={__('Customer accounts')}>
      <div className='CustomerAccountsList'>
        <div className='CustomerAccountsList-public'>
          <div className='list-header'>
            <div>
              <div className='search-selector'>
                <Selector
                  handleChange={val => setSearchBy(val)}
                  options={[
                    { label: __('Search by all'), value: searchType.all },
                    { label: __('Search by name'), value: searchType.name },
                    { label: __('Search by code'), value: searchType.code },
                    { label: __('Search by reference'), value: searchType.reference }
                  ]}
                  value={searchBy}
                />
              </div>
              <form onSubmit={handleSearchSubmit}>
                <TextInput handleChange={val => setQuery(val)} value={query} />
                {activeQuery && (
                  <button type='button' onClick={handleSearchClear} disabled={isLoading}>
                    &times;
                  </button>
                )}
                <Button type='submit' theme='info' disabled={isLoading}>
                  <IconSearch fill='#fff' />
                </Button>
              </form>
            </div>
            <div>
              <Button theme='info' onClick={() => handleManageCustomerClick(setFormDisplay)}>
                {__('Add new account')}
              </Button>
            </div>
          </div>
          <List
            clickable
            onExpandedChange={expanded => setTableExpanded(expanded)}
            expanded={tableExpanded}
            SubComponent={row => (
              <div className='SubComponent'>
                <DescriptionTable
                  details={[
                    {
                      label: __('Address'),
                      value: displayValue(get(row, 'original.address'))
                    },
                    {
                      label: __('Phone Number'),
                      value: displayValue(get(row, 'original.phone'))
                    },
                    {
                      label: __('Description'),
                      value: displayValue(get(row, 'original.description'))
                    }
                  ]}
                />
              </div>
            )}
            columns={[
              {
                expander: true,
                Header: __('Details'),
                headerClassName: 'text-center',
                width: 80,
                style: {
                  fontSize: 25,
                  padding: '0',
                  textAlign: 'center',
                  userSelect: 'none'
                }
              },
              {
                accessor: 'name',
                Header: __('Name'),
                Cell: cellInfo => displayValue(cellInfo.value)
              },
              {
                accessor: 'code',
                Header: __('Code'),
                Cell: cellInfo => displayValue(cellInfo.value)
              },
              {
                accessor: 'customers_count',
                Header: __('Number of customers'),
                Cell: cellInfo => displayValue(cellInfo.value)
              },
              {
                accessor: 'email',
                Header: __('Email'),
                Cell: cellInfo => displayValue(cellInfo.value)
              },
              {
                accessor: 'reference',
                Header: __('Reference'),
                Cell: cellInfo => displayValue(cellInfo.value)
              }
            ]}
            data={customerAccounts}
            defaultSorted={currentSort}
            loading={isLoading}
            manual
            minRows={Math.ceil(get(customerAccounts, 'length')) || 10}
            page={page}
            pages={Math.ceil(customerAccountsCount / rowsPerPage)}
            onPageChange={handlePageChange}
            onSortedChange={handleSortChange}
            showPagination
            handleClick={rowData => redirectToCustomerPage(rowData)}
            showPageSizeOptions
            pageSize={rowsPerPage}
            onPageSizeChange={(pageSize, pageIndex) => handlePageSizeChange(pageSize, pageIndex)}
          />
        </div>
        {isFormDisplayed && (
          <CustomerAccountForm
            companyID={companyID}
            customerAccount={null}
            closeCb={() => setFormDisplay(false)}
            confirmCb={() => {
              setFormDisplay(false)
              getCustomerAccounts(page, query, searchBy, currentSort, rowsPerPage)
            }}
          />
        )}
      </div>
    </Page>
  )
}

export default CustomerAccountsList
