import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'
import { NavLink } from 'react-router-dom'
import { get } from 'lodash'
import {
  sendErrorReport,
  formatDate,
  displayValue,
  capitalizeFirstLetter,
  isFeatureEnabled,
  isFeatureAvailable,
  mapPlatformUserLabelsToSelector
} from 'shared/helpers'
import {
  defaultDateTimeFormat,
  platformFeatures,
  initialAuditLogFilter,
  eventsConfig
} from 'shared/constants'
import {
  Label,
  CheckboxSelector,
  DateRangePicker,
  DescriptionTable,
  List,
  SimpleFilterTag,
  IconUser,
  IconAPI
} from 'shared/components'
import { updateUserPreferences } from 'src/company/actions'
import { updateUIOptions } from 'src/license/actions'
import { getPlatformAuditLogs } from 'src/account/actions'
import './styles.scss'

const PlatformAuditLog = ({ license, order, customer, product, showDatePicker }) => {
  const dispatch = useDispatch()
  const isAuditLogEnabled =
    isFeatureEnabled(platformFeatures.extra_audit_log) &&
    isFeatureAvailable(platformFeatures.extra_audit_log)
  const APIdateFormat = 'YYYY-MM-DD'
  const defaultFrom = moment().subtract(30, 'days')
  const defaultTo = moment()

  const uiOptions = useSelector(state => get(state, 'company.userPreferences.ui_options'))
  const userPrefsID = useSelector(state => get(state, 'company.userPreferences.id'))
  const companyUserLabels = useSelector(state => get(state, 'company.userLabels'))
  const labelsOptions = mapPlatformUserLabelsToSelector(companyUserLabels)

  const companyID = useSelector(state => get(state, 'company.details.id'))
  const licenseParams = license ? `&license=${get(license, 'id')}` : ''
  const orderParams = order ? `&order=${get(order, 'id')}` : ''
  const customerParams = customer ? `&customer=${get(customer, 'id')}` : ''
  const productParams = product ? `&product=${get(product, 'id')}` : ''
  const params = `${licenseParams}${orderParams}${customerParams}${productParams}`

  // table state
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])
  const [dataCount, setDataCount] = useState(0)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [currentSort, setSort] = useState([
    {
      desc: true,
      id: 'ts'
    }
  ])
  const [tableExpanded, setTableExpanded] = useState({})
  const [timeRange, setTimeRange] = useState({
    from: defaultFrom,
    to: defaultTo
  })
  // filters
  const [isFiltersDirty, setFiltersDirty] = useState(false)
  const [selectedFilters, setSelectedFilters] = useState(
    get(uiOptions, 'list_filters.auditlog') || initialAuditLogFilter
  )
  const [selectedLabelFilters, setSelectedLabelFilters] = useState(
    get(uiOptions, 'list_filters.auditlog.labels') || initialAuditLogFilter.labels
  )

  const fetchData = useCallback(
    (tablePage = 0, sort = currentSort, rows = rowsPerPage, filters = selectedFilters) => {
      if (isAuditLogEnabled) {
        const from = moment(timeRange.from).format(APIdateFormat)
        const to = moment(timeRange.to).format(APIdateFormat)
        const range = showDatePicker ? { from, to } : {}
        setLoading(true)
        getPlatformAuditLogs(companyID, tablePage, sort, rows, params, range, filters)
          .then(res => {
            setData(get(res, 'data.results') || [])
            setDataCount(get(res, 'data.count'))
            setLoading(false)
          })
          .catch(err => {
            setLoading(false)
            sendErrorReport(err, 'Cannot fetch audit log list')
          })
      } else {
        setLoading(false)
      }
    },
    [isAuditLogEnabled, companyID, params, timeRange]
  )

  const patchUiFeatures = (
    newSorted = undefined,
    newPageSize = undefined,
    newFilters = undefined
  ) => {
    const sortObject = newSorted || currentSort
    const listPageSize = newPageSize || rowsPerPage
    const filters = newFilters || get(uiOptions, 'list_filters.auditlog') || {}

    if ((!newSorted && !newPageSize && !newFilters) || !userPrefsID) {
      return false
    }

    const listSort = get(uiOptions, 'list_sort') || {}
    const listSize = get(uiOptions, 'list_size') || {}
    const listFilter = get(uiOptions, 'list_filters') || {}

    listSort.auditlog = sortObject
    listSize.auditlog = listPageSize
    listFilter.auditlog = filters

    const ui = {
      ...uiOptions,
      list_sort: listSort,
      list_size: listSize,
      list_filters: listFilter
    }
    const uiOptionsData = { ui_options: JSON.stringify(ui) }

    updateUIOptions(userPrefsID, companyID, uiOptionsData)
      .then(res => {
        dispatch(updateUserPreferences(res.data))
      })
      .catch(err => sendErrorReport(err, 'Ui options update failed', data))
    return true
  }

  const handlePageChange = newPage => {
    setPage(newPage)
    fetchData(newPage, currentSort, rowsPerPage)
  }

  const handleSortChange = newSorted => {
    setSort(newSorted)
    fetchData(0, newSorted, rowsPerPage)
    patchUiFeatures(newSorted)
  }

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage)
    setRowsPerPage(newPageSize)
    fetchData(newPage, currentSort, newPageSize)
    patchUiFeatures(undefined, newPageSize)
  }

  const handleLabelsSelect = (newLabelFilters = undefined) => {
    if (!selectedLabelFilters || !isFiltersDirty) {
      return false
    }
    const newFilters = {
      labels: newLabelFilters || selectedLabelFilters
    }
    setSelectedFilters(newFilters)
    fetchData(0, currentSort, rowsPerPage, newFilters)
    patchUiFeatures(undefined, undefined, newFilters)
    return true
  }

  useEffect(() => {
    fetchData()
  }, [fetchData])

  const getLink = (object, item, label) => {
    let url = ''
    switch (object) {
      case 'product':
        url = `/${companyID}/products/${get(item, 'id')}`
        break
      case 'customer':
        url = `/${companyID}/customers/${get(item, 'id')}`
        break
      case 'order':
        url = `/${companyID}/orders/${get(item, 'id')}`
        break
      case 'license':
        url = `/${companyID}/orders/${get(item, 'order.id')}/${get(item, 'id')}`
        break
      case 'device':
        url = `/${companyID}/orders/${get(item, 'license.order.id')}/${get(item, 'license.id')}`
        break
      case 'license_user':
        url = `/${companyID}/orders/${get(item, 'order.id')}/${get(item, 'id')}`
        break
      case 'platform_user':
        url = `/${companyID}/account/users/${get(item, 'id')}`
        break
      case 'user_permission_template':
        url = `/${companyID}/account/permissions`
        break
      case 'notification_policy':
        url = `/${companyID}/account/notifications`
        break
      default:
        url = ''
    }
    return <NavLink to={url}>{label}</NavLink>
  }

  return (
    <div className='PlatformAuditLog'>
      <div className='filter-section'>
        <Label inputId='customer-labels-filter' text={__('User labels')} />
        <CheckboxSelector
          text={__('Selected labels')}
          options={labelsOptions}
          value={selectedLabelFilters}
          onChangeCallback={d => {
            if (d.length !== selectedLabelFilters.length) {
              setFiltersDirty(true)
            }
            setSelectedLabelFilters(d)
          }}
          onMenuClose={handleLabelsSelect}
          isDisabled={isLoading}
        />
      </div>
      <div className='Filters-list'>
        {selectedLabelFilters.map(slt => (
          <SimpleFilterTag
            key={slt.value}
            value={slt.value}
            label={capitalizeFirstLetter(slt.label)}
            customFilterSetter={() => {
              setFiltersDirty(true)
              const newUserLabelsFilter = selectedLabelFilters.filter(s => s.value !== slt.value)
              setSelectedLabelFilters(newUserLabelsFilter)
              handleLabelsSelect(newUserLabelsFilter)
            }}
            isDisabled={isLoading}
          />
        ))}
      </div>
      {showDatePicker && (
        <DateRangePicker
          handleChange={dates => {
            setTimeRange({
              from: dates[0],
              to: dates[1]
            })
          }}
          initialStartDate={defaultFrom}
          initialEndDate={defaultTo}
          disabled={isLoading || !isAuditLogEnabled}
        />
      )}
      <List
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'ts',
            Header: __('Date'),
            Cell: cellInfo => formatDate(cellInfo.value, defaultDateTimeFormat),
            width: 200
          },
          {
            accessor: 'action',
            Header: __('Action'),
            className: '',
            Cell: cellInfo => {
              const { value } = cellInfo
              const formattedValue = value.split('_').join(' ')
              return capitalizeFirstLetter(formattedValue)
            }
          },
          {
            accessor: 'user',
            Header: __('User'),
            Cell: rowData => {
              const { value } = rowData
              if (value) {
                // actor is user
                return (
                  <div>
                    <IconUser />
                    <span className='log-actor'>
                      {displayValue(get(rowData, 'original.data.user.email'))}
                    </span>
                  </div>
                )
              }

              const isMgmtApiKey = get(rowData, 'original.company_api_key_prefix')
              if (isMgmtApiKey) {
                // actor is management api key
                const label = get(rowData, 'original.data.company_api_key.label')
                const labelString = label ? `${label} -` : ''
                const prefix = get(rowData, 'original.data.company_api_key.prefix')
                const prefixString = prefix ? `${prefix}...` : ''

                return (
                  <div>
                    <IconAPI />
                    <span className='log-actor'>{`${labelString} - ${prefixString}`}</span>
                  </div>
                )
              }

              return '-'
            }
          }
        ]}
        data={data}
        page={page}
        pages={Math.ceil(dataCount / rowsPerPage)}
        loading={isLoading}
        manual
        minRows={get(data, 'length') || 2}
        showPagination={dataCount > 5}
        onPageChange={handlePageChange}
        onSortedChange={handleSortChange}
        defaultSorted={currentSort}
        clickable={false}
        showPageSizeOptions
        pageSize={rowsPerPage}
        onPageSizeChange={(pageSize, pageIndex) => handlePageSizeChange(pageSize, pageIndex)}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        noDataText={
          isAuditLogEnabled
            ? __('Search returned 0 results')
            : __('This feature is not available in your current plan type')
        }
        SubComponent={row => (
          <div className='SubComponent'>
            <DescriptionTable
              details={[
                {
                  label: get(row, 'original.data.product') ? __('Product') : null,
                  value: getLink(
                    'product',
                    get(row, 'original.data.product'),
                    get(row, 'original.data.product.product_name')
                  )
                },
                {
                  label: get(row, 'original.data.customer') ? __('Customer') : null,
                  value: getLink(
                    'customer',
                    get(row, 'original.data.customer'),
                    get(row, 'original.data.customer.email')
                  )
                },
                {
                  label: get(row, 'original.data.order') ? __('Order') : null,
                  value: getLink(
                    'order',
                    get(row, 'original.data.order'),
                    get(row, 'original.data.order.store_id')
                  )
                },
                {
                  label: get(row, 'original.data.license') ? __('License') : null,
                  value: getLink(
                    'license',
                    get(row, 'original.data.license'),
                    get(row, 'original.data.license.id')
                  )
                },
                {
                  label: get(row, 'original.data.license_product_feature') ? __('License') : null,
                  value: getLink(
                    'license',
                    get(row, 'original.data.license_product_feature.license'),
                    get(row, 'original.data.license_product_feature.license.id')
                  )
                },
                {
                  label: get(row, 'original.data.license_custom_field') ? __('License') : null,
                  value: getLink(
                    'license',
                    get(row, 'original.data.license_custom_field.license'),
                    get(row, 'original.data.license_custom_field.license.id')
                  )
                },
                {
                  label: get(row, 'original.data.device') ? __('Device') : null,
                  value: getLink(
                    'device',
                    get(row, 'original.data.device'),
                    get(row, 'original.data.device.hardware_id')
                  )
                },
                {
                  label: get(row, 'original.data.license_user') ? __('License user') : null,
                  value: getLink(
                    'license_user',
                    get(row, 'original.data.license_user'),
                    get(row, 'original.data.license_user.email')
                  )
                },
                {
                  label: get(row, 'original.data.platform_user') ? __('Platform user') : null,
                  value: getLink(
                    'platform_user',
                    get(row, 'original.data.platform_user'),
                    get(row, 'original.data.platform_user.email')
                  )
                },
                {
                  label: get(row, 'original.data.user_permission_template')
                    ? __('Permissions template')
                    : null,
                  value: getLink(
                    'user_permission_template',
                    get(row, 'original.data.user_permission_template'),
                    get(row, 'original.data.user_permission_template.name')
                  )
                },
                {
                  label: get(row, 'original.data.notification_policy')
                    ? __('Notification policy')
                    : null,
                  value: getLink(
                    'notification_policy',
                    get(row, 'original.data.notification_policy'),
                    get(
                      eventsConfig,
                      `${get(row, 'original.data.notification_policy.event.code')}.label`
                    )
                  )
                }
              ]}
            />
          </div>
        )}
      />
    </div>
  )
}

PlatformAuditLog.propTypes = {
  license: PropTypes.object,
  order: PropTypes.object,
  customer: PropTypes.object,
  product: PropTypes.object,
  showDatePicker: PropTypes.bool
}

PlatformAuditLog.defaultProps = {
  license: null,
  order: null,
  customer: null,
  product: null,
  showDatePicker: false
}

export default PlatformAuditLog
