import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  Button,
  Notice,
  DescriptionTable,
  Notification,
  IconCheckmark,
  IconDisabled
} from 'shared/components'
import { deactivateSalesforceIntegration } from 'src/account/actions'
import { maskString, sendErrorReport } from 'shared/helpers'
import SalesforceInit from './SalesforceInit'
import { ReactComponent as SalesforceLogo } from './salesforce-logo.svg'

const Salesforce = ({ integrations }) => {
  const salesforceIntegration = integrations.find(i => i.code === 'salesforce')
  const isActive = get(salesforceIntegration, 'is_active')
  const isWizardConfigured = get(salesforceIntegration, 'is_verified')

  const [isFormDisplayed, setFormDisplay] = useState(false)
  const [isDeactivating, setDeactivating] = useState(false)

  const handleDeactivate = () => {
    const baseUrl = window.appConfig.integrationServiceBaseUrl
    setDeactivating(true)

    const data = {
      licenseSpringApiKey: get(salesforceIntegration, 'data.licenseSpringApiKey')
    }
    deactivateSalesforceIntegration(baseUrl, data)
      .then(() => {
        window.location.reload()
      })
      .catch(err => {
        setDeactivating(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        sendErrorReport(err)
      })
  }

  if (!isActive) {
    return (
      <div className='Integrations'>
        <div className='row'>
          <SalesforceLogo width='100px' height='60px' />
          <div className='docs-link'>
            <p>{__('Find integration documentation')}</p>
            <a
              href='https://docs.licensespring.com/integrations/salesforce'
              target='_blank'
              rel='noopener noreferrer'
            >
              {__('here')}
            </a>
          </div>
          <Notice size='sm' theme='default' title={__('Integration has not been activated.')} />
          <Button theme='info' size='sm' onClick={() => setFormDisplay(true)}>
            {__('Activate')}
          </Button>
        </div>
        {isFormDisplayed && (
          <SalesforceInit
            closeCb={() => setFormDisplay(false)}
            salesforceIntegration={salesforceIntegration}
          />
        )}
      </div>
    )
  }

  return (
    <div className='Integrations'>
      <div className='row'>
        <SalesforceLogo width='100px' height='60px' />
        <div className='docs-link'>
          <p>{__('Find integration documentation')}</p>
          <a
            href='https://docs.licensespring.com/integrations/salesforce'
            target='_blank'
            rel='noopener noreferrer'
          >
            {__('here')}
          </a>
        </div>
        <Notice size='sm' theme='success' title={__('Integration has been activated.')} />
        <DescriptionTable
          details={[
            {
              label: __('Consumer Key'),
              value: maskString(get(salesforceIntegration, 'data.salesForceClientID'))
            },
            {
              label: __('Consumer Secret'),
              value: maskString(get(salesforceIntegration, 'data.salesForceClientSecret'))
            },
            {
              label: __('Salesforce Domain'),
              value: get(salesforceIntegration, 'data.salesForceBaseUrl')
            },
            {
              label: __('Management API key'),
              value: maskString(get(salesforceIntegration, 'data.licenseSpringApiKey'))
            },
            {
              label: __('Salesforce Integration Wizard active'),
              value: isWizardConfigured ? (
                <IconCheckmark color='#10ac84' height='14px' />
              ) : (
                <IconDisabled color='red' height='14px' />
              )
            }
          ]}
        />
        <Button
          theme='error'
          size='sm'
          loading={isDeactivating}
          disabled={isDeactivating}
          onClick={handleDeactivate}
        >
          {__('Deactivate')}
        </Button>
      </div>
    </div>
  )
}

Salesforce.propTypes = {
  integrations: PropTypes.array
}

Salesforce.defaultProps = {
  integrations: []
}

export default Salesforce
