import React, { useState, useEffect, useCallback } from 'react'
import { NavLink, useParams, useLocation, useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import {
  ContentLoader,
  IconClipboard,
  Notification,
  NotFound,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader
} from 'shared/components'
import { copyText } from 'shared/helpers'
import { getOrder } from 'src/order/actions'
import { CustomerDetails, LicensesList, OrderDetails, OrderManagers } from './components'
import './styles.scss'

const OrderContainer = () => {
  const location = useLocation()
  const history = useHistory()
  const { orderId } = useParams()
  const companyID = useSelector(state => get(state, 'company.details.id'))

  const [isLoading, setLoading] = useState(true)
  const [notFound, setNotFound] = useState(false)
  const [order, setOrder] = useState(null)
  const [selectedTab, setSelectedTab] = useState(0)

  useEffect(() => {
    const queryParams = get(location, 'search')
    if (!queryParams) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1)
    }
    if (queryParams.indexOf('st=2') >= 0) {
      setSelectedTab(2)
    }
    if (queryParams.indexOf('st=3') >= 0) {
      setSelectedTab(3)
    }
  }, [location])

  const fetchOrder = useCallback(() => {
    getOrder(companyID, orderId)
      .then(res => {
        setOrder(get(res, 'data'))
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
        setNotFound(true)
      })
  }, [companyID, orderId])

  useEffect(() => {
    fetchOrder()
  }, [fetchOrder])

  const handleFieldCopy = (val, desc) => {
    copyText(val)
    Notification('success', `${desc} ${__('copied to clipboard')}`)
  }

  const createOrderTitle = orderID => {
    const label = __('Order ID')
    return (
      <div className='order-title-container'>
        {__('Order')}
        &nbsp;-&nbsp;
        <span>{orderID}</span>
        <button
          type='button'
          className='clipboard-btn'
          onClick={() => handleFieldCopy(orderID, label)}
        >
          <IconClipboard width='21.5' height='20' viewBox='0 0 51.5 50' />
        </button>
      </div>
    )
  }

  if (isLoading) {
    return (
      <Page>
        <ContentLoader text={__('Getting order details')} />
      </Page>
    )
  }

  if (notFound) {
    return (
      <Page>
        <NotFound />
      </Page>
    )
  }

  return (
    <div className='OrderContainer'>
      <Page
        description={
          <NavLink to={`/${companyID}/customers/${get(order, 'customer.id')}`}>
            {get(order, 'customer.email') ||
              get(order, 'customer.company_name') ||
              get(order, 'customer.reference')}
          </NavLink>
        }
        title={createOrderTitle(get(order, 'store_id'))}
      >
        <div className='OrderContainer-tabs'>
          <Tabs
            defaultFocus
            selectedIndex={selectedTab}
            onSelect={tabIndex => {
              history.push({
                search: `?st=${tabIndex}`
              })
              setSelectedTab(tabIndex)
            }}
          >
            <TabsHeader>
              <Tab>{__('Order details')}</Tab>
              <Tab>{__('Customer')}</Tab>
              <Tab>{__('Licenses')}</Tab>
              <Tab>{__('License managers')}</Tab>
            </TabsHeader>
            <TabContent>
              <OrderDetails order={order} companyID={companyID} refetchOrder={fetchOrder} />
            </TabContent>
            <TabContent>
              <CustomerDetails order={order} refetchOrder={fetchOrder} companyID={companyID} />
            </TabContent>
            <TabContent>
              <LicensesList order={order} refetchOrder={fetchOrder} />
            </TabContent>
            <TabContent>
              <OrderManagers order={order} companyID={companyID} />
            </TabContent>
          </Tabs>
        </div>
      </Page>
    </div>
  )
}

export default OrderContainer
