import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport } from 'shared/helpers'
import {
  ColorPicker,
  DirtyFormAlert,
  Modal,
  Notification,
  Label,
  TextInput
} from 'shared/components'
import { validateRequiredValue, debouncedValidateRequiredValue } from 'shared/validation'
import { editUserLabel } from 'src/account/actions'

const EditUserLabel = ({ companyID, closeCb, labelToEdit, updateUserLabel }) => {
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [labelName, setLabelName] = useState(get(labelToEdit, 'name'))
  const [labelNameError, setLabelNameError] = useState('')
  const [selectedColor, setSelectedColor] = useState(get(labelToEdit, 'color'))

  // validation methods
  const validateValue = async (val, cb) => {
    setLoading(true)
    let errors

    try {
      errors = await validateRequiredValue(val)
      setLoading(false)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate new customer label name', { value: val })
      setLoading(false)
    }

    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isLabelNameValid = await validateValue(labelName, setLabelNameError)
    return isLabelNameValid
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()
    if (loading || !isValid) {
      return false
    }

    setLoading(true)
    const labelID = get(labelToEdit, 'id')
    const data = {
      name: labelName,
      color: selectedColor
    }

    editUserLabel(labelID, companyID, data)
      .then(res => {
        const updatedLabel = get(res, 'data')
        updateUserLabel(updatedLabel)
        closeCb()
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit customer details', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size='sm'
      title={__('Edit user label')}
    >
      <div className='EditUserLabel'>
        <div>
          <Label text={__('New label name')} inputId='new-label-name' />
          <TextInput
            disabled={loading}
            handleChange={val => {
              setDirty(true)
              setLabelName(val)
              debouncedValidateRequiredValue(val).then(err => setLabelNameError(err))
            }}
            id='new-label-name'
            value={labelName}
            error={labelNameError}
          />
        </div>
        <div>
          <Label text={__('Label color')} inputId='new-label-color' />
          <ColorPicker
            initialColor={selectedColor}
            onColorSelect={val => setSelectedColor(val)}
            label={selectedColor}
          />
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

EditUserLabel.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  labelToEdit: PropTypes.object.isRequired,
  updateUserLabel: PropTypes.func.isRequired
}

export default EditUserLabel
