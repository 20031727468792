import React, { useEffect, useState } from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import {
  Button,
  FilterSelect,
  IconCheckmark,
  IconDisabled,
  IconFilter,
  IconSearch,
  List,
  Notification,
  Page,
  PermissionMissingNotificationTitle,
  Selector,
  SimpleFilterTag,
  StatusCard,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  TextInput
} from 'shared/components'
import { platformFeatures, searchType, userPermissions } from 'shared/constants'
import {
  displayValue,
  getDisabledMessage,
  getDefaultLicensePolicy,
  getProductNumber,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission
} from 'shared/helpers'
import { checkCompanyConfigField } from 'shared/companyConfig'
// import { getCompanySilent } from 'src/company/actions';
import { getAllProducts } from '../actions'
import { searchProducts } from './helpers'
import { authFilterMethods, statusFilterFields, trialFilterFields } from './constants'
import { getFilteredList } from './filters'
import ArchivedProducts from './ArchivedProducts'
import { ProductBundleForm } from '../Forms'
import './styles.scss'

const ProductList = () => {
  const canManageProducts = checkUserPermission(userPermissions.products_write)
  const history = useHistory()
  const dispatch = useDispatch()
  const currentCompany = useSelector(state => get(state, 'company.details.id'))
  const list = useSelector(state => get(state, 'products.list'))
  const loading = useSelector(state => get(state, 'products.loading'))
  const activeProducts = list.filter(p => !p.is_archived)

  const [productsList, setProductsList] = useState(activeProducts)
  const [query, setQuery] = useState('')
  const [activeQuery, setActiveQuery] = useState('')
  const [searchBy, setSearchBy] = useState(searchType.productName)
  const [showFilters, setFiltersDisplay] = useState(false)
  // filter state
  const [selectedAuthFilterMethod, setSelectedAuthFilterMethod] = useState(authFilterMethods[0])
  const [selectedTrialFilter, setSelectedTrialFilter] = useState(trialFilterFields[0])
  const [selectedFilterStatus, setSelectedFilterStatus] = useState(statusFilterFields[0])

  const [showBundleForm, setBundleFormDisplay] = useState(false)

  const runFilters = products => {
    const filters = {
      selectedAuthFilterMethod,
      selectedTrialFilter,
      selectedFilterStatus
    }
    return getFilteredList(products, filters)
  }

  const handleSearchSubmit = () => {
    setActiveQuery(query)
    const searchedProducts = searchProducts(activeProducts, searchBy, query)
    const filteredProducts = runFilters(searchedProducts)
    setProductsList(filteredProducts)
  }

  const handleSearchClear = () => {
    setQuery('')
    setActiveQuery('')
    setProductsList(activeProducts)
    const filteredProducts = runFilters(activeProducts)
    setProductsList(filteredProducts)
  }

  const handleFiltersClear = () => {
    setSelectedAuthFilterMethod(authFilterMethods[0])
    setSelectedTrialFilter(trialFilterFields[0])
    setSelectedFilterStatus(statusFilterFields[0])
    setProductsList(activeProducts)
  }

  useEffect(() => {
    dispatch(getAllProducts(currentCompany))
  }, [currentCompany])

  // filter the products on filter change, update dependency array when adding new filters
  useEffect(() => {
    handleSearchSubmit()
  }, [selectedAuthFilterMethod, selectedTrialFilter, selectedFilterStatus])

  const handleManageProductsClick = cb => {
    if (!canManageProducts) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.products_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <Page title={__('Product List')}>
      <Tabs forceRenderTabPanel>
        <TabsHeader>
          <Tab>{__('Active products')}</Tab>
          <Tab>{__('Archived products')}</Tab>
        </TabsHeader>
        <TabContent>
          <div className='ActiveProducts-tab'>
            <div className='list-header'>
              <div>
                <div className='search-selector'>
                  <Selector
                    handleChange={val => setSearchBy(val)}
                    options={[
                      {
                        label: __('Search by name'),
                        value: searchType.productName
                      },
                      {
                        label: __('Search by product code'),
                        value: searchType.productCode
                      }
                    ]}
                    value={searchBy}
                  />
                </div>
                <form
                  onSubmit={e => {
                    e.preventDefault()
                    handleSearchSubmit()
                  }}
                >
                  <TextInput
                    handleChange={val => setQuery(val)}
                    value={query}
                    placeholder={__('Search')}
                  />
                  {activeQuery && (
                    <button type='button' onClick={handleSearchClear} disabled={loading}>
                      &times;
                    </button>
                  )}
                  <Button type='submit' theme='info' disabled={loading}>
                    <IconSearch fill='#fff' />
                  </Button>
                </form>
              </div>
              <div>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.extra_product_bundle)}
                  featureAvailable={isFeatureAvailable(platformFeatures.extra_product_bundle)}
                  notEnabledMessage={getDisabledMessage()}
                  onClick={() => handleManageProductsClick(setBundleFormDisplay)}
                >
                  {__('Add new bundle')}
                </Button>
                <Button
                  featureEnabled={isFeatureEnabled(platformFeatures.usage_products)}
                  featureAvailable={isFeatureAvailable(
                    platformFeatures.usage_products,
                    getProductNumber()
                  )}
                  notAvailableMessage='You have reached the maximum amount of products allowed in your plan.'
                  ctaText={__('Upgrade to add more products.')}
                  notEnabledMessage={getDisabledMessage()}
                  onClick={() => history.push(`/${currentCompany}/products/new-product`)}
                  theme='info'
                >
                  {__('Add new Product')}
                </Button>
              </div>
            </div>
            <div className='ProductList-filters'>
              <button
                type='button'
                className='AdvancedSearch-btn'
                onClick={() => {
                  setFiltersDisplay(!showFilters)
                }}
              >
                <IconFilter stroke='#2e86de' strokeWidth={2} fill='none' viewBox='0 0 24 24' />
                {__('Search filters')}
              </button>
              <div className={`AdvancedSearch-wrapper ${showFilters ? '' : 'hide'}`}>
                <div className={`AdvancedSearch ${showFilters ? '' : 'hide'}`}>
                  <div className='AdvancedSearch-fields'>
                    <FilterSelect
                      filter={selectedAuthFilterMethod}
                      filterArray={authFilterMethods}
                      filterSetter={setSelectedAuthFilterMethod}
                      label={__('Authorization method')}
                      labelID='auth-method-filter'
                    />
                    <FilterSelect
                      filter={selectedTrialFilter}
                      filterArray={trialFilterFields}
                      filterSetter={setSelectedTrialFilter}
                      label={__('Allow trial')}
                      labelID='allow_trial-filter'
                    />
                    <FilterSelect
                      filter={selectedFilterStatus}
                      filterArray={statusFilterFields}
                      filterSetter={setSelectedFilterStatus}
                      label={__('Status')}
                      labelID='status-filter'
                    />
                    <div className='AdvancedSearch-actions'>
                      <Button
                        theme='error'
                        size='sm'
                        disabled={loading}
                        onClick={handleFiltersClear}
                      >
                        {__('Clear filters')}
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='Filters-list'>
                <SimpleFilterTag
                  label={selectedAuthFilterMethod.label}
                  value={selectedAuthFilterMethod.value}
                  filtersArray={authFilterMethods}
                  filterSetter={setSelectedAuthFilterMethod}
                />
                <SimpleFilterTag
                  value={selectedTrialFilter.value}
                  label={selectedTrialFilter.value}
                  filtersArray={trialFilterFields}
                  filterSetter={setSelectedTrialFilter}
                />
                <SimpleFilterTag
                  value={selectedFilterStatus.value}
                  label={selectedFilterStatus.label}
                  filtersArray={statusFilterFields}
                  filterSetter={setSelectedFilterStatus}
                />
              </div>
            </div>
            <List
              clickable
              loading={loading}
              columns={[
                {
                  accessor: 'product_name',
                  Header: __('Product Name')
                },
                {
                  accessor: 'short_code',
                  Header: __('Product code'),
                  maxWidth: 200
                },
                {
                  id: 'default_license_template',
                  Header: __('Default License Policy'),
                  Cell: cellData => {
                    const defaultPolicy = getDefaultLicensePolicy([], cellData.original)
                    return displayValue(get(defaultPolicy, 'name'))
                  },
                  sortable: false
                },
                {
                  accessor: 'authorization_method',
                  Header: __('Authorization method'),
                  Cell: cellData =>
                    cellData.value === 'license-key' ? __('License key') : __('User')
                },
                {
                  accessor: 'allow_trial',
                  Header: __('Allow trial'),
                  className: 'text-center',
                  headerClassName: 'text-center',
                  Cell: cellData =>
                    cellData.value ? (
                      <IconCheckmark color='#10ac84' height='14px' />
                    ) : (
                      <IconDisabled color='red' height='14px' />
                    ),
                  width: 100,
                  show: checkCompanyConfigField(currentCompany, 'isTrial')
                },
                {
                  accessor: 'is_bundle',
                  Header: __('Is bundle'),
                  className: 'text-center',
                  headerClassName: 'text-center',
                  Cell: cellData => (cellData.value ? __('Yes') : __('No')),
                  width: 100,
                  show: isFeatureAvailable(platformFeatures.extra_product_bundle)
                },
                {
                  accessor: 'active',
                  Header: __('Status'),
                  className: 'text-center',
                  headerClassName: 'text-center',
                  Cell: cellData => (
                    <StatusCard
                      status={cellData.value ? 'success' : 'error'}
                      text={cellData.value ? __('Active') : __('Disabled')}
                    />
                  ),
                  width: 80
                }
              ]}
              data={productsList}
              handleClick={rowData =>
                history.push(`/${currentCompany}/products/${rowData.original.id}`)
              }
              minRows={2}
              pageSize={20}
            />
          </div>
        </TabContent>
        <TabContent>
          <ArchivedProducts />
        </TabContent>
      </Tabs>
      {showBundleForm && (
        <ProductBundleForm
          closeCb={() => setBundleFormDisplay(false)}
          refreshState={() => dispatch(getAllProducts(currentCompany))}
          bundle={null}
          companyID={currentCompany}
        />
      )}
    </Page>
  )
}

export default ProductList
