import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  IconDelete,
  Notification,
  PermissionMissingNotificationTitle,
  Switcher,
  Label
} from 'shared/components'
import {
  sendErrorReport,
  displayValue,
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  checkUserPermission,
  capitalizeFirstLetter
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import {
  getKeycloakSSOProvider,
  deleteKeycloakSSOProvider,
  updateKeycloakSSOProvider
} from 'src/account/actions'
import AddKeycloakSSOProviderForm from '../AddKeycloakSSOProviderForm'
import './styles.scss'

const SsoSettings = () => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)

  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')

  const [providerLoading, setProviderLoading] = useState(true)
  const [provider, setProvider] = useState(null)
  const [isProviderDeleteConfirmationDisplayed, setProviderDeleteConfirmationDisplayed] = useState(
    false
  )
  const [providerDeleteLoading, setProviderDeleteLoading] = useState(false)
  const [isFormDisplayed, setFormDisplay] = useState(false)

  const getProvider = useCallback(() => {
    getKeycloakSSOProvider(companyID)
      .then(res => {
        const data = get(res, 'data')
        setProvider(data)
        setProviderLoading(false)
      })
      .catch(() => {
        setProvider(null)
        setProviderLoading(false)
      })
  }, [companyID])

  useEffect(() => {
    getProvider()
  }, [getProvider])

  const handleProviderDelete = () => {
    setProviderDeleteLoading(true)
    deleteKeycloakSSOProvider(companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        setProviderDeleteLoading(false)
        setProviderDeleteConfirmationDisplayed(false)
        setProviderLoading(true)
        getProvider()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete company sso provider')
        setProviderDeleteLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleUserCreateUpdate = () => {
    setProviderLoading(true)
    const usingDefaultFlow = get(provider, 'firstBrokerLoginFlowAlias') === null
    const data = {
      use_alternative_login_flow: !!usingDefaultFlow
    }
    updateKeycloakSSOProvider(companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        getProvider()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update sso provider', provider)
        setProviderLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  const getProviderLabel = () => {
    if (!provider) {
      return ''
    }
    const providerID = capitalizeFirstLetter(provider.providerId)
    return providerID
  }

  return (
    <div className='SsoSettings'>
      <div className='row table-row'>
        <h3>{__('Single Sign On Provider')}</h3>
        {!provider ? (
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
            featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
            notEnabledMessage={getDisabledMessage()}
            size='sm'
            theme='info'
            disabled={providerLoading}
            onClick={() => handleManageSettingsClick(setFormDisplay)}
          >
            {__('Add provider')}
          </Button>
        ) : (
          <>
            <Button
              featureEnabled={isFeatureEnabled(platformFeatures.extra_single_sign_on)}
              featureAvailable={isFeatureAvailable(platformFeatures.extra_single_sign_on)}
              notEnabledMessage={getDisabledMessage()}
              size='sm'
              theme='error'
              disabled={providerLoading}
              onClick={() => handleManageSettingsClick(setProviderDeleteConfirmationDisplayed)}
            >
              <>
                <IconDelete height='16px' width='16px' color='#ee5253' />
                {__('Delete provider')}
              </>
            </Button>
          </>
        )}
        <div className='providers-list'>
          <DescriptionTable
            details={[
              { label: __('Name'), value: displayValue(get(provider, 'displayName')) },
              { label: __('Type'), value: getProviderLabel() }
            ]}
            loading={providerLoading}
          />
          <div className='create-user-switcher' inputId='create-user-switcher'>
            <Switcher
              checked={!!get(provider, 'firstBrokerLoginFlowAlias')}
              handleChange={handleUserCreateUpdate}
              size='sm'
              disabled={providerLoading || !provider}
            />
            <Label
              inputId='create-user-switcher'
              text={__('Create new platform user if it does not exist already')}
            />
          </div>
        </div>
      </div>
      {isProviderDeleteConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setProviderDeleteConfirmationDisplayed(false)
          }}
          confirmCb={handleProviderDelete}
          title={`${__('Are you sure you want to delete this SSO provider')}?`}
          confirmText={__('Delete')}
          theme='error'
          disabled={providerDeleteLoading}
        >
          <div>
            <div>
              <span style={{ fontSize: '14px', fontWeight: 500, wordBreak: 'break-all' }}>
                {get(provider, 'displayName')}
              </span>
            </div>
            <div style={{ fontSize: '14px' }}>
              {__(
                'You will be logged out from all LicenseSpring accounts associated with this provider.'
              )}
            </div>
          </div>
        </ConfirmationPopup>
      )}
      {isFormDisplayed && (
        <AddKeycloakSSOProviderForm
          companyID={companyID}
          refetchProvider={getProvider}
          closeCb={() => setFormDisplay(false)}
        />
      )}
    </div>
  )
}

export default SsoSettings
