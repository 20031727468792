import React from 'react'
import PropTypes from 'prop-types'
import { Tooltip } from 'shared/components'
import './styles.scss'

const PlanTypeButton = ({
  disabled,
  disabledMessage,
  error,
  handleChange,
  inputId,
  planType,
  planTypeDesc,
  value,
  ...rest
}) => (
  <Tooltip active={disabled} content={disabledMessage}>
    <div className='PlanTypeButton'>
      <div className={`PlanTypeButton-input PlanTypeButton-${disabled ? 'disabled' : 'enabled'}`}>
        <input
          type='radio'
          name='plan-type'
          className={error ? 'has-error' : ''}
          id={inputId}
          value={value}
          onChange={e => handleChange(e.target.value, e)}
          disabled={disabled}
          {...rest}
        />
        <label htmlFor={inputId} className='content'>
          <div className='plan-type-name'>{planType}</div>
          <div className='plan-type-desc'>{planTypeDesc}</div>
        </label>
      </div>
    </div>
  </Tooltip>
)

PlanTypeButton.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.string,
  disabledMessage: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  inputId: PropTypes.string.isRequired,
  planType: PropTypes.string.isRequired,
  planTypeDesc: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired
}

PlanTypeButton.defaultProps = {
  error: '',
  disabled: '',
  disabledMessage: ''
}

export default PlanTypeButton
