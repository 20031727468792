import React from 'react'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { Page, Tab, Tabs, TabContent, TabsHeader } from 'shared/components'
import { getCompanySilent } from 'src/company/actions'
import PlanTypeContainer from '../components/PlanTypeContainer'
import EnterpriseSubscription from '../components/EnterpriseSubscription'
import StandardSubscription from '../components/StandardSubscription'
import StandardTrial from '../components/StandardTrial'
import EnterpriseTrial from '../components/EnterpriseTrial'
import CompanyFeatures from '../components/CompanyFeatures'
import Settings from '../components/Settings'

const CompanySettings = () => {
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const planType = get(companyDetails, 'plan_type')
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true'
  const dispatch = useDispatch()
  const hasSubscription = get(companyDetails, 'stripe_has_subscription')
  const companyID = get(companyDetails, 'id')

  const companyFeatures = useSelector(state => get(state, 'company.features'))

  return (
    <Page title={__('Company settings')}>
      <div className='CompanySettings'>
        <Tabs>
          <TabsHeader>
            {!isSelfHosted && <Tab>{__('Billing')}</Tab>}
            <Tab>{__('Features')}</Tab>
            <Tab>{__('Settings')}</Tab>
          </TabsHeader>
          {!isSelfHosted && (
            <TabContent>
              <div className='CompanySettings-billing'>
                <PlanTypeContainer
                  companyDetails={companyDetails}
                  hasSubscription={hasSubscription}
                />
                {planType === 'enterprise' && !hasSubscription && (
                  <EnterpriseTrial companyDetails={companyDetails} />
                )}
                {planType === 'enterprise' && (
                  <EnterpriseSubscription
                    dispatchCompanyChange={() => dispatch(getCompanySilent(companyID))}
                  />
                )}
                {planType !== 'free' && planType !== 'enterprise' && hasSubscription && (
                  <StandardSubscription
                    dispatchCompanyChange={() => dispatch(getCompanySilent(companyID))}
                  />
                )}
                {planType !== 'free' && planType !== 'enterprise' && !hasSubscription && (
                  <StandardTrial />
                )}
              </div>
            </TabContent>
          )}
          <TabContent>
            <CompanyFeatures companyDetails={companyDetails} features={companyFeatures} />
          </TabContent>
          <TabContent>
            <Settings companyDetails={companyDetails} />
          </TabContent>
        </Tabs>
      </div>
    </Page>
  )
}

export default CompanySettings
