import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { NavLink } from 'react-router-dom'
import { getUpcomingInvoiceDate } from 'shared/helpers'

const calculateDaysInterval = paymentDate => {
  if (!paymentDate) {
    return false
  }

  const interval = moment(paymentDate).diff(moment(), 'days') || 0

  if (!interval || interval > 30) {
    return false
  }

  return true
}

const NoPaymentMethod = ({ theme, company, companyDetails, isAdmin }) => {
  const paymentDate = getUpcomingInvoiceDate(companyDetails)
  const is30DaysInterval = calculateDaysInterval(paymentDate)

  if (!paymentDate || !is30DaysInterval) {
    return null
  }

  return (
    <div className={`PageNotice PageNotice-theme--${theme} NoPaymentMethod`}>
      {isAdmin ? (
        <div className='PageNotice-body'>
          {__('Your payment method is missing, please add it')}
          &nbsp;
          <NavLink to={`/${company}/account/billing`}>{`${__('here')}.`}</NavLink>
        </div>
      ) : (
        <div className='PageNotice-body'>
          {`${__('Your payment method is missing, please add it')}.`}
          &nbsp;
          {__(
            'You must have admin rights in order to access this section. Please contact your account administrator to gain access.'
          )}
        </div>
      )}
    </div>
  )
}

NoPaymentMethod.propTypes = {
  company: PropTypes.number.isRequired,
  companyDetails: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  theme: PropTypes.oneOf(['success', 'warning', 'info', 'error'])
}

NoPaymentMethod.defaultProps = {
  theme: 'error'
}

export default NoPaymentMethod
