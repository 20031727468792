import { get } from 'lodash'

export const mapCustomerLabelsToSelector = labels =>
  labels.map(l => ({
    value: l.id,
    label: l.label,
    data: l
  }))

export const getAvailableLabels = (labels, customer) => {
  const customerLabels = get(customer, 'labels') || []
  const result = labels.filter(l => {
    const isAlreadyUsed = customerLabels.find(cl => cl.label_id === l.id)
    if (!isAlreadyUsed) {
      return true
    }
    return false
  })
  return result
}
