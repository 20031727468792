import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props}>
    <svg id='Layer_1' data-name='Layer 1' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 50 50'>
      <path d='M12.7,2A4.27,4.27,0,0,0,8.42,6.28V43.72A4.27,4.27,0,0,0,12.7,48H37.3a4.27,4.27,0,0,0,4.28-4.28V6.28A4.27,4.27,0,0,0,37.3,2ZM10.56,6.28H39.44v35.3H10.56ZM25,43.19a1.61,1.61,0,1,1-1.6,1.6A1.59,1.59,0,0,1,25,43.19Z' />
    </svg>
  </Base>
)
