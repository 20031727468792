import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  DirtyFormAlert,
  Label,
  InputErrorMessage,
  Modal,
  MultiEmailInput,
  Notification
} from 'shared/components'
import { errorMsg } from 'shared/constants'
import { assignLicenseUsers } from './actions'
import './styles.scss'

const BatchLicenseAssign = ({ closeCb, confirmCb, license, companyID }) => {
  const licenseID = get(license, 'id')
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [loading, setLoading] = useState(false)
  const [licenseUsers, setLicenseUsers] = useState([])

  const maxLicenseUsers = get(license, 'max_license_users')
  const users = get(license, 'license_users') || []
  const currentLicenseUsersCount = users.length
  const isUnlimitedLicenseUsers = get(license, 'max_license_users') === 0
  const unassignedLicenseCount = isUnlimitedLicenseUsers
    ? 0
    : Number(maxLicenseUsers) - currentLicenseUsersCount
  const maxLicenseUserStatus = `${currentLicenseUsersCount} / ${
    isUnlimitedLicenseUsers ? __('Unlimited') : maxLicenseUsers
  }`

  const handleSubmit = e => {
    e.preventDefault()

    if (licenseUsers.value) {
      setLicenseUsers({
        ...licenseUsers,
        validationError: `${get(errorMsg, 'unsubmittedEmail')} (${get(licenseUsers, 'value')})`
      })
      return false
    }

    if (!get(licenseUsers, 'emails.length')) {
      return false
    }

    if (!isUnlimitedLicenseUsers && unassignedLicenseCount < get(licenseUsers, 'emails.length')) {
      return Notification(
        'error',
        __('Check license users count.'),
        __('Number of submitted license user emails is bigger than unassigned licenses count.')
      )
    }

    setLoading(true)
    const emails = get(licenseUsers, 'emails')
    const userEmailsList = emails.map(em => ({ email: em, is_manager: false }))
    const data = { emails: userEmailsList }

    assignLicenseUsers(licenseID, companyID, data)
      .then(() => {
        confirmCb()
        Notification(
          'success',
          __('Changes saved successfully'),
          __('License users have been assigned')
        )
        closeCb()
      })
      .catch(() => {
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })

    return true
  }

  const handleEmailSubmit = val => {
    setDirty(true)
    setLicenseUsers(val)
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      title={__('Batch license assign')}
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={loading}
      size='lg'
    >
      <div className='BatchLicenseAssign'>
        <div className='BatchLicenseAssign-main'>
          <div className='unassigned-count'>
            {__('Available number of users:')}
            &nbsp;
            <span>{maxLicenseUserStatus}</span>
          </div>
          <div>
            {unassignedLicenseCount !== 0 || isUnlimitedLicenseUsers ? (
              <>
                <Label text={__('User emails')} inputId='emails-input' />
                <MultiEmailInput
                  emails={get(licenseUsers, 'emails')}
                  onEmailSubmit={val => handleEmailSubmit(val)}
                  disabled={loading}
                />
                <InputErrorMessage text={get(licenseUsers, 'validationError') || ''} />
              </>
            ) : (
              <div className='warning-notice'>{`${__('All license users are assigned.')}`}</div>
            )}
          </div>
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

BatchLicenseAssign.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  license: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired
}

export default BatchLicenseAssign
