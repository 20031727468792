/* eslint-disable react/destructuring-assignment */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import Select, { components } from 'react-select'
import { Tooltip } from 'shared/components'
import { MdClose } from 'react-icons/md'
import './styles.scss'

const Option = props => (
  <div>
    <components.Option {...props}>
      <input
        id={`${props.label}-input`}
        className='checkbox-option'
        type='checkbox'
        checked={props.isSelected}
        onChange={() => null}
      />{' '}
      <label>{props.label}</label>
    </components.Option>
  </div>
)

const SelectedPill = ({ label, onRemove }) => (
  <div className='selected-pill'>
    <span className='pill-label'>{label}</span>
    <button type='button' onClick={onRemove} className='pill-remove' aria-label={`Remove ${label}`}>
      <MdClose />
    </button>
  </div>
)

const SearchableCheckbox = ({
  disabled,
  options,
  onChangeCallback,
  onMenuClose,
  text,
  notAvailableMessage,
  notEnabledMessage,
  featureAvailable,
  featureEnabled,
  value,
  ...otherProps
}) => {
  const handleRemoveOption = optionToRemove => {
    const updatedValue = Array.isArray(value)
      ? value.filter(option => option.value !== optionToRemove.value)
      : []
    onChangeCallback(updatedValue)
  }

  const renderContent = () => (
    <div className='selector-wrapper'>
      <Select
        closeMenuOnSelect={false}
        className='selector-container'
        classNamePrefix='selector-inner'
        placeholder={'Select product features' || text}
        isMulti
        isSearchable
        isClearable={false}
        isDisabled={disabled}
        components={{ Option }}
        options={options}
        hideSelectedOptions={false}
        backspaceRemovesValue={false}
        onMenuClose={onMenuClose}
        onChange={e => onChangeCallback(e)}
        value={value}
        {...otherProps}
      />
      {Array.isArray(value) && value.length > 0 && (
        <div className='selected-pills'>
          {value.map(option => (
            <SelectedPill
              key={option.value}
              label={option.label}
              onRemove={() => handleRemoveOption(option)}
            />
          ))}
        </div>
      )}
    </div>
  )

  if (!featureAvailable) {
    return (
      <Tooltip
        active={!featureAvailable}
        content={<div className='feature-unavailable-tooltip'>{notAvailableMessage}</div>}
      >
        <div className='CheckboxSelector Selector'>{renderContent()}</div>
      </Tooltip>
    )
  }

  return <div className='CheckboxSelector Selector'>{renderContent()}</div>
}

SearchableCheckbox.propTypes = {
  options: PropTypes.array.isRequired,
  onChangeCallback: PropTypes.func.isRequired,
  onMenuClose: PropTypes.func.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  featureAvailable: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  featureEnabled: PropTypes.bool,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

SearchableCheckbox.defaultProps = {
  text: '',
  disabled: false,
  featureAvailable: true,
  notAvailableMessage: 'This feature is not available in your plan type.',
  featureEnabled: true,
  notEnabledMessage: ''
}

export default SearchableCheckbox
