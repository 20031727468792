import React from 'react'
import { Button, Subtitle } from 'shared/components'
import icon from '../sunk-ship.svg'
import './styles.scss'

const GlobalError = () => (
  <div className='GlobalError'>
    <div className='wrapper'>
      <img src={icon} alt='Not found' height='100px' />
      <Subtitle text={__('An error occured')} />
      <p>
        {__('Our tech team has been notified, to restart browsing please click on a button below')}
      </p>
      <Button
        onClick={() => {
          window.location = '/'
        }}
        theme='info'
      >
        {__('Restart browsing')}
      </Button>
    </div>
  </div>
)

export default GlobalError
