import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get, sortBy } from 'lodash'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { sendErrorReport } from 'shared/helpers'
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  TextArea,
  CheckboxSelector
} from 'shared/components'
import { validateLink, debouncedValidateLink } from 'shared/validation'
import {
  createWebhookEndpoint,
  updateWebhookEndpoint,
  setWebhookEndpointEvents
} from 'src/account/actions'
import './styles.scss'

const formatWebhookEvents = events => {
  if (!events) {
    return []
  }
  const list = events.map(event => ({ label: event.code, value: event.id }))
  const sortedList = sortBy(list, 'label')
  return sortedList
}

const EndpointForm = ({ closeCb, confirmCb, endpoint }) => {
  const history = useHistory()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const webhookEvents = useSelector(state => get(state, 'notifications.webhookEvents'))
  const eventsList = formatWebhookEvents(webhookEvents)

  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const [url, setUrl] = useState({
    value: get(endpoint, 'url') || '',
    error: ''
  })
  const [description, setDescription] = useState({
    value: get(endpoint, 'description') || '',
    error: ''
  })
  const [selectedEvents, setSelectedEvents] = useState(formatWebhookEvents(get(endpoint, 'events')))

  const validateUrlValue = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateLink(val)
      cb(prev => ({ ...prev, error: errors }))
    } catch (err) {
      sendErrorReport(err, 'Cannot validate endpoint form value', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isUrlValid = await validateUrlValue(url.value, setUrl)
    return isUrlValid
  }

  const addSelectedEvents = endpointID => {
    const eventIDs = selectedEvents.map(event => event.value)
    const data = {
      event_ids: eventIDs
    }

    setWebhookEndpointEvents(endpointID, companyID, data)
      .then(() => {
        confirmCb()
        if (!endpoint) {
          history.push(`/${companyID}/account/webhooks/${endpointID}`)
        }
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set webhook endpoint events', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        setLoading(false)
      })
  }

  const updateExistingEndpoint = data => {
    const endpointID = get(endpoint, 'id')
    updateWebhookEndpoint(endpointID, companyID, data)
      .then(() => addSelectedEvents(endpointID))
      .catch(err => {
        sendErrorReport(err, 'Cannot update webhook endpoint', data)
        setLoading(false)
      })
  }

  const createNewEndpoint = data => {
    createWebhookEndpoint(companyID, data)
      .then(res => {
        const endpointID = get(res, 'data.id')
        return addSelectedEvents(endpointID)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create webhook endpoint', data)
        setLoading(false)
      })
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()
    if (isLoading || !isValid) {
      return false
    }

    setLoading(true)
    const data = {
      url: url.value,
      description: description.value
    }

    if (endpoint) {
      updateExistingEndpoint(data)
    } else {
      createNewEndpoint(data)
    }
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Add endpoint')}
      disabled={isLoading}
    >
      <div className='EndpointForm'>
        <form onSubmit={handleSubmit}>
          <div className='EndpointForm-input-row'>
            <Label text={__('Endpoint')} inputId='endpoint-name' />
            <TextInput
              id='endpoint-firstname'
              value={get(url, 'value')}
              error={get(url, 'error')}
              placeholder='https://'
              handleChange={val => {
                setDirty(true)
                setUrl(prev => ({ ...prev, value: val }))
                debouncedValidateLink(val).then(err => setUrl(prev => ({ ...prev, error: err })))
              }}
            />
          </div>
          <div className='EndpointForm-input-row'>
            <Label text={__('Description')} inputId='endpoint-description' />
            <TextArea
              id='endpoint-description'
              rows='4'
              value={get(description, 'value')}
              error={get(description, 'error')}
              placeholder={__('An optional description of what this webhook endpoint is used for')}
              handleChange={val => {
                setDirty(true)
                setDescription(prev => ({ ...prev, value: val }))
              }}
            />
          </div>
          <div className='EndpointForm-input-row events'>
            <Label text={__('Events')} />
            <CheckboxSelector
              text={__('Selected endpoint events')}
              options={eventsList}
              value={selectedEvents}
              onChangeCallback={val => setSelectedEvents(val)}
              // onMenuClose={() => { }}
            />
          </div>
        </form>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setDirtyFormAlertDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

EndpointForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  endpoint: PropTypes.object
}

EndpointForm.defaultProps = {
  endpoint: null
}

export default EndpointForm
