import React from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import {
  Notice,
  Notification,
  PermissionMissingNotificationTitle,
  Page,
  Subtitle
} from 'shared/components'
import { checkTrial, checkUserPermission } from 'shared/helpers'
import { userPermissions } from 'shared/constants'
import FreePlanTypeBox from './components/FreePlanTypeBox'
import BusinessStarterPlanTypeBox from './components/BusinessStarterPlanTypeBox'
import BusinessPlusPlanTypeBox from './components/BusinessPlusPlanTypeBox'
import EnterprisePlanTypeBox from './components/EnterprisePlanTypeBox'
import './styles.scss'

const PlanTypeContainer = () => {
  const canManageBilling = checkUserPermission(userPermissions.billing_write)
  const history = useHistory()
  const planType = useSelector(state => get(state, 'company.details.plan_type'))
  const companyId = useSelector(state => get(state, 'company.details.id'))
  const planTypeTiers = {
    free: 1,
    standard: 2,
    business_starter: 3,
    business_plus: 4,
    enterprise: 5
  }
  const currentPlanTier = planTypeTiers[planType]

  const routeToUpgradePage = planToUpgradeTo => {
    history.push({
      pathname: `/${companyId}/account/plan-type/upgrade`,
      state: { planType: planToUpgradeTo }
    })
  }

  const trial = checkTrial()
  const formatPlanTypeString = () => {
    if (!planType) {
      return ''
    }
    return `${planType.charAt(0).toUpperCase()}${planType.slice(1)}`
  }

  const handleManageBillingClick = cb => {
    if (!canManageBilling) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.billing_write} />,
        __('Contact your account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <Page title={`${__('Plan type')}: ${formatPlanTypeString()}`}>
      <div className='PlanTypeContainer'>
        <div className='PlanTypeContainer-heading'>
          <Subtitle text={__('Edit your plan type')} />
          <div className='description'>
            {__('To compare different plan types in more detail click')}
            <a
              style={{ marginLeft: '3px' }}
              href='https://licensespring.com/pricing'
              target='_blank'
              rel='noopener noreferrer'
            >
              {__('here')}
            </a>
          </div>
        </div>
        {get(trial, 'isTrial') && (
          <div className='PlanTypeContainer-notice'>
            <Notice size='sm' theme='warning'>
              <>
                {__('Remaining trial days')}: &nbsp;
                {get(trial, 'remaining_days')}
              </>
            </Notice>
          </div>
        )}
        <div className='PlanTypeContainer-form'>
          <div className='form-row'>
            {currentPlanTier === 1 && (
              <FreePlanTypeBox isCurrent={planType === 'free'} handleCtaClick={() => {}} />
            )}

            {currentPlanTier <= 3 && (
              <BusinessStarterPlanTypeBox
                isUpgrade={planTypeTiers[planType] <= planTypeTiers['business_starter']}
                isCurrent={planType === 'business_starter'}
                handleCtaClick={() =>
                  handleManageBillingClick(() => routeToUpgradePage('business_starter'))
                }
              />
            )}

            {currentPlanTier <= 4 && (
              <BusinessPlusPlanTypeBox
                isUpgrade={planTypeTiers[planType] <= planTypeTiers['business_plus']}
                isCurrent={planType === 'business_plus'}
                handleCtaClick={() =>
                  handleManageBillingClick(() => routeToUpgradePage('business_plus'))
                }
              />
            )}

            <EnterprisePlanTypeBox isCurrent={planType === 'enterprise'} />
          </div>
        </div>
      </div>
    </Page>
  )
}

export default PlanTypeContainer
