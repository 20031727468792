import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { isValidDate } from 'shared/helpers'
import {
  emailRegex,
  errorMsg,
  validationTimeDefault,
  validationTimeLong,
  productFeatureTypes
} from 'shared/constants'
import api from 'shared/api'

export const validateRequiredValue = val =>
  new Promise(resolve => {
    let error = ''
    if (!val) {
      error = errorMsg.required
    }
    resolve(error)
  })

export const validateRequiredNumber = val =>
  new Promise(resolve => {
    let error = ''
    if (!val || Number(val) <= 0) {
      error = errorMsg.required
    }

    resolve(error)
  })

export const validateProductMaxOverages = val =>
  new Promise(resolve => {
    let error = ''

    if (val === '0' || val === 0) {
      // max overages can be set to 0 - unlimited
      resolve(error)
    }

    if (!val || Number(val) <= 0) {
      error = errorMsg.required
      resolve(error)
    }

    resolve(error)
  })

export const validateLicenseUsers = (emails = [], num) =>
  new Promise(resolve => {
    let error = ''

    if (!emails || !emails.length) {
      resolve(error)
    }

    if (emails.length > Number(num)) {
      error = errorMsg.tooManyEmails
      resolve(error)
    }

    resolve(error)
  })

export const validateNewUserEmail = (val, companyId) =>
  new Promise(resolve => {
    let error = ''
    const url = `/api/v1/customers/?company=${companyId}&email=${encodeURIComponent(val)}`

    api.get(url).then(res => {
      if (!val) {
        error = errorMsg.required
        resolve(error)
      }

      if (!emailRegex.test(val)) {
        error = errorMsg.invalidEmail
        resolve(error)
      }

      if (res.data.count !== 0) {
        error = errorMsg.emailDuplicate
        resolve(error)
      }

      resolve(error)
    })
  })

export const validateDate = val =>
  new Promise(resolve => {
    let error = ''
    if (!val) {
      error = errorMsg.required
      resolve(error)
    }
    if (!isValidDate(val)) {
      error = errorMsg.dateYYYYMMDDformat
      resolve(error)
    }
    resolve(error)
  })

export const validateFeaturesMaxConsumptions = features =>
  new Promise(resolve => {
    let error = ''

    features.forEach(f => {
      if (f.feature_type === productFeatureTypes.consumption && f.max_consumption <= 0) {
        error = errorMsg.consumptionBiggerThanZero
      }
    })

    resolve(error)
  })

// eslint-disable-next-line no-unused-vars
export const validateFloatingUsers = (val, maxActivation = 1, isFloatingCloud) =>
  new Promise(resolve => {
    let error = ''
    if (!val || Number(val) <= 0) {
      error = errorMsg.required
    }

    // if ((Number(val) > Number(maxActivation)) && isFloatingCloud) {
    //   error = errorMsg.maxSimultaneousUsers;
    // }

    resolve(error)
  })

export const validateExistingUserEmail = val =>
  new Promise(resolve => {
    let error = ''

    if (!val) {
      error = errorMsg.selectCustomer
      resolve(error)
    }

    if (!emailRegex.test(val)) {
      error = errorMsg.invalidEmail
      resolve(error)
    }

    resolve(error)
  })

export const debouncedValidateRequiredValue = AwesomeDebouncePromise(
  validateRequiredValue,
  validationTimeDefault
)

export const debouncedValidateRequiredNumber = AwesomeDebouncePromise(
  validateRequiredNumber,
  validationTimeDefault
)

export const debouncedValidateProductMaxOverages = AwesomeDebouncePromise(
  validateProductMaxOverages,
  validationTimeDefault
)

export const debouncedValidateLicenseUsers = AwesomeDebouncePromise(
  validateLicenseUsers,
  validationTimeDefault
)

export const debouncedValidateDate = AwesomeDebouncePromise(validateDate, validationTimeDefault)

export const debouncedValidateFeaturesMaxConsumptions = AwesomeDebouncePromise(
  validateFeaturesMaxConsumptions,
  validationTimeDefault
)

export const debouncedValidateFloatingUsers = AwesomeDebouncePromise(
  validateFloatingUsers,
  validationTimeDefault
)

export const debouncedValidateNewUserEmail = AwesomeDebouncePromise(
  validateNewUserEmail,
  validationTimeLong
)
