import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  DirtyFormAlert,
  InputErrorMessage,
  Label,
  Modal,
  Notification,
  TextInput,
  TextArea,
  Selector
} from 'shared/components'
import { mapCountriesToSelector, sendErrorReport } from 'shared/helpers'
import { validateRequiredValue, validateEmail, debouncedValidateEmail } from 'shared/validation'
import { countriesList } from 'shared/countriesAlpha2Code'
import { changeBillingDetails } from 'src/account/actions'

const BillingDetailsForm = ({ closeCb, companyID, customerDetails, refreshCustomer }) => {
  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(false)
  const [name, setName] = useState(get(customerDetails, 'name') || '')
  const [email, setEmail] = useState(get(customerDetails, 'email'))
  const [emailError, setEmailError] = useState('')
  const [description, setDescription] = useState(get(customerDetails, 'description') || '')
  // address
  const countryOptions = mapCountriesToSelector(countriesList)
  const initialCountryOption = countryOptions.find(
    c => get(c, 'data.name') === get(customerDetails, 'address.country')
  )

  const [address1, setAddress1] = useState(get(customerDetails, 'address.line1') || '')
  const [address2, setAddress2] = useState(get(customerDetails, 'address.line2') || '')
  const [postalCode, setPostalCode] = useState(get(customerDetails, 'address.postal_code') || '')
  const [city, setCity] = useState(get(customerDetails, 'address.city') || '')
  const [state, setState] = useState(get(customerDetails, 'address.state') || '')
  const [country, setCountry] = useState(initialCountryOption || '')
  const [countryError, setCountryError] = useState('')

  const validateBillingEmail = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateEmail(val)
      cb(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate billing details email', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateCountry = async () => {
    let errors
    try {
      errors = await validateRequiredValue(get(country, 'value'))
      setCountryError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate billing details country value', {
        val: get(country, 'value')
      })
    }
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isEmailValid = await validateBillingEmail(email, setEmailError)
    const isCountryValid = await validateCountry()
    return isEmailValid && isCountryValid
  }

  const handleSubmit = async () => {
    const isValid = await isFormValid()
    if (loading || !isValid) {
      return false
    }

    setLoading(true)
    const data = {
      email,
      name,
      description,
      address: {
        city,
        country: get(country, 'value'),
        line1: address1,
        line2: address2,
        postal_code: postalCode,
        state
      }
    }

    changeBillingDetails(companyID, data)
      .then(res => {
        const newCustomerDetails = get(res, 'data.customer_details') || null
        refreshCustomer(newCustomerDetails)
        Notification('success', __('Changes saved successfully'))
        closeCb()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit customer details', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setIsDirtyFormAlertDisplayed(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Change billing details')}
      disabled={loading}
      size='lg'
    >
      <form className='BillingDetailsForm' onSubmit={handleSubmit}>
        <div className='form-inner'>
          <div className='left'>
            <div className='form-row'>
              <Label inputId='name-input' text={__('Name')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setName(val)
                }}
                id='name-input'
                type='text'
                value={name}
              />
            </div>
            <div className='form-row'>
              <Label inputId='email-input' text={__('Email')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setEmail(val)
                  debouncedValidateEmail(val).then(err => setEmailError(err))
                }}
                id='email-input'
                type='email'
                value={email}
                error={emailError}
              />
            </div>
            <div className='form-row'>
              <Label inputId='description-input' text={__('Description')} />
              <TextArea
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setDescription(val)
                }}
                id='description-input'
                type='description'
                value={description}
                rows='4'
              />
            </div>
          </div>
          <div className='divider active' />
          <div className='right'>
            <div className='form-row'>
              <Label inputId='line1-input' text={__('Address line 1')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setAddress1(val)
                }}
                id='line1-input'
                type='text'
                value={address1}
              />
            </div>
            <div className='form-row'>
              <Label inputId='line2-input' text={__('Address line 2')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setAddress2(val)
                }}
                id='line2-input'
                type='text'
                value={address2}
              />
            </div>
            <div className='form-row'>
              <Label inputId='postal-code-input' text={__('Postal code')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setPostalCode(val)
                }}
                id='postal-code-input'
                type='text'
                value={postalCode}
              />
            </div>
            <div className='form-row'>
              <Label inputId='city-input' text={__('City')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setCity(val)
                }}
                id='city-input'
                type='text'
                value={city}
              />
            </div>
            <div className='form-row'>
              <Label inputId='state-input' text={__('State')} />
              <TextInput
                disabled={loading}
                handleChange={val => {
                  setDirty(true)
                  setState(val)
                }}
                id='state-input'
                type='text'
                value={state}
              />
            </div>
            <div className='form-row'>
              <Label text={__('Country')} inputId='country-input' />
              <Selector
                options={countryOptions}
                isDisabled={loading}
                value={get(country, 'value')}
                id='upgrade-country'
                handleChange={val => {
                  const selected = countryOptions.find(option => option.value === val)
                  setDirty(true)
                  setCountry(selected)
                  setCountryError('')
                }}
              />
              <InputErrorMessage text={countryError} />
            </div>
          </div>
        </div>
      </form>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

BillingDetailsForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  customerDetails: PropTypes.object.isRequired,
  refreshCustomer: PropTypes.func.isRequired
}

export default BillingDetailsForm
