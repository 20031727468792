import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { Label, Selector } from 'shared/components'
import './styles.scss'

const FilterSelect = ({ filter, filterArray, filterSetter, label, labelID, isDisabled }) => (
  <div className='FilterSelect filter-section'>
    <Label inputId={labelID} text={label} />
    <Selector
      options={filterArray}
      value={get(filter, 'value')}
      handleChange={val => {
        const selected = filterArray.find(option => option.value === val)
        filterSetter(selected)
      }}
      blurInputOnSelect
      isDisabled={isDisabled}
    />
  </div>
)

FilterSelect.propTypes = {
  filter: PropTypes.object.isRequired,
  filterArray: PropTypes.array.isRequired,
  filterSetter: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  labelID: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool
}

FilterSelect.defaultProps = {
  isDisabled: false
}

export default FilterSelect
