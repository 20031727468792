import routes from './routes'

export const createBreadcrumbs = match => {
  const paths = match.path.split('/').filter(p => !!p)
  const urls = match.url.split('/').filter(u => !!u)
  const mappedUrls = urls.map((part, i) => {
    const cleanPart = paths[i].replace(/[()?]/g, '')
    if (cleanPart === ':companyId') {
      return {
        label: 'Home',
        path: `/${match.params.companyId}`
      }
    }

    if (cleanPart.indexOf(':') !== -1) {
      const dynamicRoute = routes[cleanPart]
      const paramName = cleanPart.replace(':', '')
      const paramValue = match.params[paramName]
      return {
        label: `${dynamicRoute}: ${paramValue}`,
        path: `/${paramValue}`
      }
    }
    if (cleanPart === 'profile') {
      return {
        label: 'My profile',
        path: cleanPart
      }
    }
    const staticRoute = routes[cleanPart]
    return {
      label: staticRoute,
      path: `/${cleanPart}`
    }
  })
  return mappedUrls
}
