import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Raven from 'raven-js'
import { GlobalError } from 'shared/components'

class ErrorBoundary extends Component {
  constructor() {
    super()

    this.state = {
      hasError: false
    }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  componentDidCatch(error, errorInfo) {
    Raven.captureException(error, { extra: errorInfo })
  }

  render() {
    const { hasError } = this.state
    const { children } = this.props

    if (hasError) {
      return <GlobalError />
    }

    return <>{children}</>
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired
}

export default ErrorBoundary
