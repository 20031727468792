import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { get } from 'lodash'
import {
  Button,
  Notification,
  PermissionMissingNotificationTitle,
  OAuthCredentials,
  OAuthModals
} from 'shared/components'
import { checkUserPermission, isFeatureEnabled } from 'shared/helpers'
import { userPermissions, platformFeatures } from 'shared/constants'
import { useOAuth } from 'shared/hooks'
import {
  getOauthCompanyClient,
  createOauthCompanyClient,
  deleteOauthCompanyClient,
  rotateOauthCompanyClientSecret,
  createOauthCompanyClientSecret,
  patchOauthCompanyDefaultData
} from 'src/account/actions'
import { getCompany } from 'src/company/actions'
import './styles.scss'

const getHumanReadableTime = seconds => {
  if (!seconds) return null

  const totalSeconds = parseInt(seconds, 10)
  const days = Math.floor(totalSeconds / (24 * 60 * 60))
  const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((totalSeconds % (60 * 60)) / 60)
  const remainingSeconds = totalSeconds % 60

  if (days > 0) {
    return `${days} ${days === 1 ? 'day' : 'days'}`
  }
  if (hours > 0) {
    return `${hours} ${hours === 1 ? 'hour' : 'hours'}`
  }
  if (minutes > 0) {
    return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`
  }
  return `${remainingSeconds} ${remainingSeconds === 1 ? 'second' : 'seconds'}`
}

const CompanyOauth = () => {
  const dispatch = useDispatch()
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')
  const defaultRotationSettings = get(companyDetails, 'oauth_clients_data')
  const isOauthEnabled = isFeatureEnabled(platformFeatures.extra_oauth)
  const [isInitModalOpen, setInitModalOpen] = useState(false)
  const [usePerpetualForInit, setUsePerpetualForInit] = useState(false)

  const getDefaultSettingsText = () => {
    if (!defaultRotationSettings) return null

    const expirationTime = getHumanReadableTime(
      get(defaultRotationSettings, 'secret_expiration_period')
    )
    const rotationTime = getHumanReadableTime(
      get(defaultRotationSettings, 'secret_rotated_expiration_period')
    )

    if (!expirationTime || !rotationTime) return null

    return `Default rotation settings: ${expirationTime} for secret expiration, ${rotationTime} for rotated secret expiration.`
  }

  // State for default settings modal
  const [isDefaultSettingsModalOpen, setDefaultSettingsModalOpen] = useState(false)

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb()
    return true
  }

  const {
    isLoading,
    loadingCreateClient,
    oAuthClient,
    isRotateSecretModalOpen,
    setRotateSecretModalOpen,
    isRotateConfirmationOpen,
    setRotateConfirmationOpen,
    isRefreshConfirmationOpen,
    setRefreshConfirmationOpen,
    isDeleteConfirmationOpen,
    setDeleteConfirmationOpen,
    rotateSecretData,
    setRotateSecretData,
    getOauthClient,
    createNewOauthClient,
    handleDeleteOauthClient,
    handleRefreshSecret,
    handleRotateSecret
  } = useOAuth({
    entityId: companyID,
    getClient: getOauthCompanyClient,
    createClient: createOauthCompanyClient,
    deleteClient: deleteOauthCompanyClient,
    rotateSecret: rotateOauthCompanyClientSecret,
    refreshSecret: createOauthCompanyClientSecret
  })

  // Initialize rotation data with defaults when opening the modal
  const handleRotateClick = () => {
    setRotateSecretModalOpen(true)
  }

  const handleRefreshClick = () => {
    setRefreshConfirmationOpen(true)
  }

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true)
  }

  const handleDefaultSettingsSave = async () => {
    try {
      await patchOauthCompanyDefaultData(companyID, {
        oauth_clients_data: rotateSecretData
      })
      await dispatch(getCompany(companyID))
      setDefaultSettingsModalOpen(false)
      Notification('success', __('Default rotation settings updated successfully'))
    } catch (error) {
      Notification('error', __('Failed to update default rotation settings'))
    }
  }

  const handleCreateNewOauthClient = async () => {
    await createNewOauthClient(usePerpetualForInit)
    setInitModalOpen(false)
  }

  return (
    <div className='OAuth'>
      {!isOauthEnabled && (
        <div className='row'>
          <h3>{__('Company OAuth Configuration')}</h3>
          <p>
            {__('This feature is not available for your account. Contact sales to enable oAuth.')}
          </p>
        </div>
      )}
      {isOauthEnabled && (
        <div className='row'>
          <h3>{__('Company OAuth Configuration')}</h3>
          <div className='inline-row'>
            <Button
              size='sm'
              theme='info'
              onClick={() => handleManageSettingsClick(() => setDefaultSettingsModalOpen(true))}
              disabled={isLoading}
            >
              {__('Set Default Rotation Settings')}
            </Button>
            <p className='oauth-rotation-info'>{getDefaultSettingsText()}</p>
          </div>
          {!oAuthClient ? (
            <Button
              size='sm'
              theme='info'
              loading={loadingCreateClient}
              disabled={loadingCreateClient || isLoading}
              onClick={() => handleManageSettingsClick(() => setInitModalOpen(true))}
            >
              {__('Initialize OAuth')}
            </Button>
          ) : (
            <OAuthCredentials
              oAuthClient={oAuthClient}
              isLoading={isLoading}
              handleManageSettingsClick={handleManageSettingsClick}
              onRotateClick={handleRotateClick}
              onRefreshClick={handleRefreshClick}
              onDeleteClick={handleDeleteClick}
              setRefreshConfirmationOpen={setRefreshConfirmationOpen}
              setRotateConfirmationOpen={setRotateConfirmationOpen}
              setDeleteConfirmationOpen={setDeleteConfirmationOpen}
            />
          )}
        </div>
      )}

      <OAuthModals
        isLoading={isLoading}
        isDeleteConfirmationOpen={isDeleteConfirmationOpen}
        setDeleteConfirmationOpen={setDeleteConfirmationOpen}
        handleDeleteOauthClient={handleDeleteOauthClient}
        isRotateConfirmationOpen={isRotateConfirmationOpen}
        setRotateConfirmationOpen={setRotateConfirmationOpen}
        isRefreshConfirmationOpen={isRefreshConfirmationOpen}
        setRefreshConfirmationOpen={setRefreshConfirmationOpen}
        isRotateSecretModalOpen={isRotateSecretModalOpen}
        setRotateSecretModalOpen={setRotateSecretModalOpen}
        handleRotateSecret={handleRotateSecret}
        handleRefreshSecret={handleRefreshSecret}
        getOauthClient={getOauthClient}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
      />

      {/* Default Settings Modal */}
      <OAuthModals
        isLoading={isLoading}
        isRotateSecretModalOpen={isDefaultSettingsModalOpen}
        setRotateSecretModalOpen={setDefaultSettingsModalOpen}
        handleRotateSecret={handleDefaultSettingsSave}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
        modalTitle={__('Set Default Rotation Settings')}
        confirmButtonText={__('Save Defaults')}
        confirmButtonTheme='info'
        showInitCheckbox={isInitModalOpen}
        closeInitModal={() => setInitModalOpen(false)}
        usePerpetual={usePerpetualForInit}
        onUsePerpetualChange={setUsePerpetualForInit}
        handleCreateNewOauthClient={handleCreateNewOauthClient}
      />
    </div>
  )
}

export default CompanyOauth
