// coupons actions
export const getCouponsInit = 'GET_COUPONS_INIT'
export const getCouponsSuccess = 'GET_COUPONS_SUCCESS'
export const getCouponsError = 'GET_COUPONS_ERROR'

// pricing plans actions
export const getPricingPlansInit = 'GET_PRICING_PLANS_INIT'
export const getPricingPlansSuccess = 'GET_PRICING_PLANS_SUCCESS'
export const getPricingPlansError = 'GET_PRICING_PLANS_ERROR'

// pricing plans actions
export const getStandardPricingPlansInit = 'GET_STANDARD_PRICING_PLANS_INIT'
export const getStandardPricingPlansSuccess = 'GET_STANDARD_PRICING_PLANS_SUCCESS'
export const getStandardPricingPlansError = 'GET_STANDARD_PRICING_PLANS_ERROR'

// tax rates actions
export const getTaxRatesInit = 'GET_TAX_RATES_INIT'
export const getTaxRatesSuccess = 'GET_TAX_RATES_SUCCESS'
export const getTaxRatesError = 'GET_TAX_RATES_ERROR'
