import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { ProgressBar, Notice } from 'shared/components'
import { sendErrorReport, getMonthlyRateLimit } from 'shared/helpers'
import { getUsedMonthlyCalls } from 'src/analytics/actions'
import './styles.scss'

const getColor = perc => {
  if (perc > 95) {
    return '#ee5253'
  }
  if (perc > 75) {
    return '#ff9f43'
  }

  return '#2e86de'
}

const MonthlyRateLimitBar = React.memo(({ activeCompany }) => {
  const monthlyRateLimit = getMonthlyRateLimit()
  const [usedMonthlyCalls, setUsedMonthlyCalls] = useState(null)

  useEffect(() => {
    getUsedMonthlyCalls(activeCompany)
      .then(res => {
        const data = get(res, 'data.call_count__sum') || 0
        setUsedMonthlyCalls(data)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get billing period limit')
      })
  }, [])

  if (!usedMonthlyCalls && usedMonthlyCalls !== 0) return null

  const percentage = (usedMonthlyCalls / monthlyRateLimit) * 100

  return (
    <div className='MonthlyRateLimitBar'>
      <div className='MonthlyRateLimitBar-details'>
        <div className='percentage'>{`${__('Used monthly rate limit for')} ${moment().format(
          'MMMM'
        )}: ${percentage.toFixed(2)}%`}</div>
        <div className='limit'>{monthlyRateLimit}</div>
      </div>
      <ProgressBar
        min={1}
        percentage={percentage > 100 ? 100 : percentage.toFixed(2)}
        color={getColor(percentage)}
      />
      {percentage > 100 && (
        <Notice theme='error' size='sm' title={__('You have reached your monthly rate limit')}>
          <>{__('Contact customer support to upgrade your monthly rate limit')}</>
        </Notice>
      )}
    </div>
  )
})

MonthlyRateLimitBar.propTypes = {
  activeCompany: PropTypes.number.isRequired
}

export default MonthlyRateLimitBar
