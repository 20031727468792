import React from 'react'
import PropTypes from 'prop-types'
import { slugify } from 'shared/helpers'
import './styles.scss'

const Subtitle = ({ text }) => (
  <h2 className='Subtitle' id={slugify(text)}>
    {text}
  </h2>
)

Subtitle.propTypes = {
  text: PropTypes.string.isRequired
}

export default Subtitle
