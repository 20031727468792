/* eslint-disable no-console */
import React from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import AirGap from './AirGap'
import LicenseAPI from './LicenseAPI'
import Oauth from './Oauth'
import ManagementAPI from './ManagementAPI'
import './styles.scss'

const ApiKeys = () => {
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const hideOauthSettings = companyDetails && companyDetails.hide_oauth_settings | false

  return (
    <div className='ApiKeys Settings-keys'>
      {!hideOauthSettings && <Oauth />}
      <LicenseAPI />
      <ManagementAPI />
      <AirGap />
    </div>
  )
}

export default ApiKeys
