// notifications
// events
export const getEventsInit = 'GET_EVENTS_INIT'
export const getEventsSuccess = 'GET_EVENTS_SUCCESS'
export const getEventsError = 'GET_EVENTS_ERROR'

// notification policies
export const getNotificationsInit = 'GET_NOTIFICATIONS_INIT'
export const getNotificationsSuccess = 'GET_NOTIFICATIONS_SUCCESS'
export const getNotificationsError = 'GET_NOTIFICATIONS_ERROR'

// webhook events
export const getWebhookEventsInit = 'GET_WEBHOOK_EVENTS_INIT'
export const getWebhookEventsSuccess = 'GET_WEBHOOK_EVENTS_SUCCESS'
export const getWebhookEventsError = 'GET_WEBHOOK_EVENTS_ERROR'
