import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { generatePass, sendErrorReport } from 'shared/helpers'
import { DirtyFormAlert, Label, Modal, Notification, TextInput } from 'shared/components'
import { errorMsg } from 'shared/constants'
import { validateRequiredValue, debouncedValidateRequiredValue } from 'shared/validation'
import { setLicenseUserPassword } from 'src/license/actions'
import './styles.scss'

const ChangeLicenseUserPassword = ({ closeCb, user, companyID, refetchLicense }) => {
  const userID = get(user, 'id')
  const [isLoading, setLoading] = useState(false)
  const [isDirty, setDirty] = useState(false)
  const [isDirtyFormDisplayed, setDirtyFormDisplay] = useState(false)
  const [password, setPassword] = useState(generatePass())
  const [passwordError, setPasswordError] = useState('')

  const validatePassword = async () => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredValue(password)
      if (password.length < 8) {
        errors = errorMsg.passCharNum
      }
      setPasswordError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate license user form password value', { value: password })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isPasswordValid = await validatePassword()
    return isPasswordValid
  }

  const setUserPassword = () => {
    const data = { password }
    setLicenseUserPassword(userID, companyID, data)
      .then(() => {
        refetchLicense()
        closeCb()
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user password')
        setLoading(false)
        Notification(
          'error',
          __('Password was not set'),
          __('There was an error while setting the license user password')
        )
      })
    return true
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setDirty(false)
    setLoading(true)
    setUserPassword()
    return true
  }

  const handleClose = () => {
    if (!isDirty) {
      return closeCb()
    }
    return setDirtyFormDisplay(true)
  }

  return (
    <Modal
      confirmCb={handleSubmit}
      closeCb={handleClose}
      disabled={isLoading}
      size='sm'
      title={__('Change password')}
    >
      <form className='ChangeLicenseUserPassword' onSubmit={handleSubmit}>
        <div className='form-inner'>
          <div className='form-row'>
            <Label text={__('Email')} inputId='email' />
            <TextInput
              id='email'
              value={get(user, 'true_email')}
              disabled
              handleChange={() => {}}
            />
          </div>
          <div className=' form-row password-container'>
            <Label text={__('Password')} inputId='password' />
            <TextInput
              id='password'
              value={password}
              error={passwordError}
              disabled={isLoading}
              handleChange={val => {
                setDirty(true)
                setPassword(val)
                debouncedValidateRequiredValue(val).then(err => setPasswordError(err))
              }}
            />
          </div>
        </div>
        <button type='submit' style={{ visibility: 'hidden' }} />
      </form>
      {isDirtyFormDisplayed && (
        <DirtyFormAlert
          dirty={isDirty}
          closeAlert={() => setDirtyFormDisplay(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

ChangeLicenseUserPassword.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyID: PropTypes.number.isRequired,
  user: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired
}

export default ChangeLicenseUserPassword
