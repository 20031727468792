import React, { useState, useEffect, useCallback } from 'react'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useParams, useHistory, Prompt } from 'react-router-dom'
import {
  Button,
  ConfirmationPopup,
  ContentLoader,
  DescriptionTable,
  HiddenPasswordField,
  NotFound,
  Page,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Label,
  CheckboxSelector,
  Notification,
  PermissionMissingNotificationTitle
} from 'shared/components'
import {
  sendErrorReport,
  displayValue,
  formatManagerPermissions,
  isFeatureAvailable,
  isFeatureEnabled,
  getDisabledMessage,
  checkUserPermission
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import {
  getLicenseManager,
  removeLicenseManager,
  updateLicenseManagerPermissions,
  getLmLicensesCount
} from 'src/order/actions'
import OrderManagerForm from 'src/order/OrderContainer/components/OrderManagers/components/OrderManagerForm'
import OrderManagerPasswordForm from 'src/order/OrderContainer/components/OrderManagers/components/OrderManagerPasswordForm'
import LicenseManagerReport from './LicenseManagerReport'
import { LicenseManagerProduct, LicenseManagerProductForm } from './components'
import './styles.scss'

const LicenseManagerPage = () => {
  const history = useHistory()
  const canManageOrders = checkUserPermission(userPermissions.orders_write)
  const { managerId } = useParams()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const manPermissions = useSelector(state => get(state, 'company.managerPermissions'))
  const allowsProductBundles =
    isFeatureEnabled(platformFeatures.extra_product_bundle) &&
    isFeatureAvailable(platformFeatures.extra_product_bundle)
  const allowsDistributionPortal =
    isFeatureEnabled(platformFeatures.extra_distribution_portal) &&
    isFeatureAvailable(platformFeatures.extra_distribution_portal)
  const managerPermissions = manPermissions.filter(p => {
    // TODO return when implement those two
    if (p.code === 'create_license_policy') {
      return false
    }
    if (p.code === 'show_license_usage_report') {
      return false
    }
    if (p.code === 'create_bundle_order') {
      if (!allowsProductBundles) {
        return false
      }
    }
    return true
  })

  const [isDirty, setDirty] = useState(false)
  const [isLoading, setLoading] = useState(true)
  const [isSubmitting, setSubmitting] = useState(false)
  const [notFound, setNotFound] = useState(false)
  const [manager, setManager] = useState(null)
  const [permissions, setPermissions] = useState([])
  const [counter, setCounter] = useState([])
  const [isProductAdd, setProductAdd] = useState(false)

  const [isEditManagerDisplayed, setEditManagerDisplayed] = useState(false)
  const [showManagerPasswordForm, setManagerPasswordForm] = useState(false)

  const [showDeleteManagerConfirmation, setDeleteManagerConfirmationDisplay] = useState(false)
  const [deletingManager, setDeletingManager] = useState(false)

  const getInitialPermissions = mlist => {
    const initial = []
    managerPermissions.forEach(mp => {
      const enabled = mlist.find(ml => ml.code === mp.code)
      if (enabled) {
        initial.push(enabled)
      }
    })
    return initial
  }

  const prefillEnabledPermissions = mlist => {
    const list = getInitialPermissions(mlist)
    setPermissions(formatManagerPermissions(list))
  }

  const fetchManager = useCallback(() => {
    getLicenseManager(managerId, companyID)
      .then(res => {
        const data = get(res, 'data')
        const mPermissions = get(data, 'permissions')
        setManager(data)
        prefillEnabledPermissions(mPermissions)
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get manager details')
        setLoading(false)
        setNotFound(true)
      })
  }, [companyID, managerId])

  const getCounter = useCallback(() => {
    getLmLicensesCount(managerId, companyID)
      .then(res => {
        const data = get(res, 'data')
        const counterData = get(data, 'created_licenses_counts')
        setCounter(counterData)
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot get manager details')
        setLoading(false)
        setNotFound(true)
      })
  }, [managerId, companyID])

  useEffect(() => {
    fetchManager()
    getCounter()
  }, [fetchManager, getCounter])

  const handleDeleteManager = () => {
    setDeletingManager(true)

    removeLicenseManager(managerId, companyID)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        history.push(`/${companyID}/orders/license-managers`)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete license manager')
        setDeletingManager(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleSubmit = () => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.orders_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }

    if (isSubmitting || !isDirty) {
      return false
    }

    const mmTemplates = get(manager, 'license_templates') || []
    const mProducts = get(manager, 'products') || []

    setSubmitting(true)
    const data = {
      permission_ids: permissions.map(p => p.id),
      license_template_ids: mmTemplates.map(t => t.id),
      products: mProducts.map(p => ({
        product_id: get(p, 'product.id'),
        num_of_licenses: p.num_of_licenses
      }))
    }

    updateLicenseManagerPermissions(managerId, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        fetchManager()
        setSubmitting(false)
        setDirty(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update manager permissions', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        setSubmitting(false)
      })
    return true
  }

  const displayInitialPassword = () => {
    const hasInitialPass = get(manager, 'is_initial_password')
    const initialPass = get(manager, 'initial_password')
    if (!hasInitialPass) {
      return __('N/A')
    }
    return <HiddenPasswordField value={initialPass} fallback={__('N/A')} />
  }

  const handleManagerActionClick = cb => {
    if (!canManageOrders) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.orders_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  if (isLoading) {
    return (
      <Page>
        <ContentLoader text={__('Getting license manager details')} />
      </Page>
    )
  }
  if (notFound) {
    return (
      <Page>
        <NotFound />
      </Page>
    )
  }

  const managerProducts = get(manager, 'products') || []

  return (
    <Page title={get(manager, 'true_email')}>
      <Prompt
        when={isDirty}
        message={__('You have unsaved changes. Are you sure you want to leave?')}
      />
      <div className='list-header'>
        <div>
          <Button theme='info' onClick={() => handleManagerActionClick(setEditManagerDisplayed)}>
            {__('Edit license manager')}
          </Button>
        </div>
        <div>
          <Button theme='default' onClick={() => handleManagerActionClick(setManagerPasswordForm)}>
            {__('Change password')}
          </Button>
          <Button
            theme='error'
            onClick={() => handleManagerActionClick(setDeleteManagerConfirmationDisplay)}
          >
            {__('Delete')}
          </Button>
        </div>
      </div>
      <div className='LicenseManagerPage'>
        <DescriptionTable
          details={[
            { label: __('Email'), value: displayValue(get(manager, 'true_email')) },
            { label: __('First Name'), value: displayValue(get(manager, 'first_name')) },
            { label: __('Last Name'), value: displayValue(get(manager, 'last_name')) },
            { label: __('Phone number'), value: displayValue(get(manager, 'phone_number')) },
            { label: __('Initial password'), value: displayInitialPassword() }
          ]}
        />
        <Tabs>
          <TabsHeader>
            <Tab>{__('Permissions')}</Tab>
            <Tab>{__('Report')}</Tab>
          </TabsHeader>
          <TabContent>
            <div className='permissions-tab'>
              <div className='LicenseManagerPage-permissions'>
                <div className='permission-actions'>
                  <div className='checkbox-selector'>
                    <Label text={__('Select enabled permissions')} />
                    <CheckboxSelector
                      featureEnabled={isFeatureEnabled(platformFeatures.extra_distribution_portal)}
                      featureAvailable={isFeatureAvailable(
                        platformFeatures.extra_distribution_portal
                      )}
                      notEnabledMessage={getDisabledMessage()}
                      text={__('Enabled permissions')}
                      options={managerPermissions}
                      value={permissions}
                      onChangeCallback={val => {
                        setDirty(true)
                        setPermissions(val)
                      }}
                      onMenuClose={() => {}}
                      disabled={isSubmitting}
                    />
                  </div>
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_distribution_portal)}
                    featureAvailable={isFeatureAvailable(
                      platformFeatures.extra_distribution_portal
                    )}
                    notEnabledMessage={getDisabledMessage()}
                    disabled={isSubmitting}
                    size='md'
                    theme='success'
                    onClick={handleSubmit}
                  >
                    {__('Save changes')}
                  </Button>
                </div>
                <DescriptionTable
                  details={permissions.map(p => ({ ...p, value: p.desc }))}
                  noDataText={__('Manager has no enabled permissions')}
                />
              </div>
              {allowsDistributionPortal && (
                <div className='LicenseManagerPage-products-list'>
                  <Label text={__('Product permissions')} />
                  <Button
                    featureEnabled={isFeatureEnabled(platformFeatures.extra_distribution_portal)}
                    featureAvailable={isFeatureAvailable(
                      platformFeatures.extra_distribution_portal
                    )}
                    notEnabledMessage={getDisabledMessage()}
                    disabled={isSubmitting}
                    theme='info'
                    size='sm'
                    onClick={() => setProductAdd(true)}
                  >
                    {__('Add product')}
                  </Button>
                  <ol>
                    {managerProducts.map(mp => (
                      <li key={get(mp, 'product.id')}>
                        <LicenseManagerProduct
                          product={mp}
                          productCounter={counter.find(
                            c => get(c, 'product.id') === get(mp, 'product.id')
                          )}
                          policies={get(manager, 'license_templates')}
                          manager={manager}
                          refetchManager={fetchManager}
                          refetchCounter={getCounter}
                        />
                      </li>
                    ))}
                  </ol>
                </div>
              )}
            </div>
          </TabContent>
          <TabContent>
            <LicenseManagerReport manager={manager} companyID={companyID} />
          </TabContent>
        </Tabs>
      </div>
      {isEditManagerDisplayed && (
        <OrderManagerForm
          companyID={companyID}
          closeCb={() => setEditManagerDisplayed(false)}
          confirmCb={() => {
            fetchManager()
            setEditManagerDisplayed(false)
          }}
          manager={manager}
        />
      )}
      {showManagerPasswordForm && (
        <OrderManagerPasswordForm
          companyID={companyID}
          closeCb={() => {
            setManagerPasswordForm(false)
          }}
          refetchManagers={fetchManager}
          manager={manager}
        />
      )}
      {isProductAdd && (
        <LicenseManagerProductForm
          closeCb={() => {
            setProductAdd(false)
          }}
          confirmCb={() => {
            fetchManager()
            getCounter()
            setProductAdd(false)
          }}
          manager={manager}
        />
      )}
      {showDeleteManagerConfirmation && (
        <ConfirmationPopup
          closeCb={() => setDeleteManagerConfirmationDisplay(false)}
          confirmCb={handleDeleteManager}
          title={__('Are you sure you want to delete this license manager?')}
          confirmText={__('Delete')}
          theme='error'
          disabled={deletingManager}
        >
          {__('License manager will be removed from any orders he is assigned to.')}
        </ConfirmationPopup>
      )}
    </Page>
  )
}

export default LicenseManagerPage
