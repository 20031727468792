import * as actionTypes from './actionTypes'

const initialState = {
  loadingCoupons: true,
  coupons: [],
  loadingPricingPlans: true,
  pricingPlans: [],
  loadingStandardPricingPlans: true,
  standardPricingPlans: [],
  loadingTaxRates: true,
  taxRates: []
}

export default function(state = initialState, action) {
  switch (action.type) {
    case actionTypes.getCouponsInit:
      return { ...state, loadingCoupons: true }
    case actionTypes.getCouponsSuccess:
      return { ...state, loadingCoupons: false, coupons: action.payload }
    case actionTypes.getCouponsError:
      return { ...state, loadingCoupons: false }
    case actionTypes.getPricingPlansInit:
      return { ...state, loadingPricingPlans: true }
    case actionTypes.getPricingPlansSuccess:
      return { ...state, loadingPricingPlans: false, pricingPlans: action.payload }
    case actionTypes.getPricingPlansError:
      return { ...state, loadingPricingPlans: false }
    case actionTypes.getStandardPricingPlansInit:
      return { ...state, loadingStandardPricingPlans: true }
    case actionTypes.getStandardPricingPlansSuccess:
      return { ...state, loadingStandardPricingPlans: false, standardPricingPlans: action.payload }
    case actionTypes.getStandardPricingPlansError:
      return { ...state, loadingStandardPricingPlans: false }
    case actionTypes.getTaxRatesInit:
      return { ...state, loadingTaxRates: true }
    case actionTypes.getTaxRatesSuccess:
      return { ...state, loadingTaxRates: false, taxRates: action.payload }
    case actionTypes.getTaxRatesError:
      return { ...state, loadingTaxRates: false }
    default:
      return state
  }
}
