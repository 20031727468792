import React from 'react'
import PropTypes from 'prop-types'
import { InputErrorMessage, Spinner } from 'shared/components'
import './styles.scss'

const TextInput = ({
  autoComplete,
  disabled,
  error,
  handleChange,
  loading,
  showErrorMsg,
  ...rest
}) => (
  <div className='TextInput'>
    <input
      className={error ? 'has-error' : ''}
      type='text'
      disabled={disabled}
      onChange={e => handleChange(e.target.value, e)}
      autoComplete={autoComplete ? 'on' : 'off'}
      {...rest}
    />
    {loading && <Spinner />}
    {showErrorMsg && <InputErrorMessage text={error} />}
  </div>
)

TextInput.propTypes = {
  autoComplete: PropTypes.bool,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  loading: PropTypes.bool,
  showErrorMsg: PropTypes.bool
}

TextInput.defaultProps = {
  autoComplete: true,
  disabled: false,
  error: '',
  loading: false,
  showErrorMsg: true
}

export default TextInput
