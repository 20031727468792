import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { userPermissions } from 'shared/constants'
import { PermissionCheck } from 'shared/components'
import WebhooksList from './WebhooksList'
import WebhookPage from './WebhookPage'

const WebhookContainer = () => (
  <Switch>
    <Route
      exact
      path='/:companyId/account/webhooks'
      component={PermissionCheck(WebhooksList, userPermissions.settings_read)}
    />
    <Route
      exact
      path='/:companyId/account/webhooks/:webhookId'
      component={PermissionCheck(WebhookPage, userPermissions.settings_read)}
    />
  </Switch>
)

export default WebhookContainer
