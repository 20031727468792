import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport } from 'shared/helpers'
import { errorMsg } from 'shared/constants'
import api from 'shared/api'
import {
  AsyncSelector,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  InputErrorMessage,
  TextInput,
  PhoneNumberInput,
  RadioBtn
} from 'shared/components'
import { validateEmail, debouncedValidateEmail } from 'shared/validation'
import { createCustomer, patchCustomer } from 'src/customer/actions'
import './styles.scss'

const AddCustomerToAccountForm = ({ closeForm, company, customerAccountID, updateCustomer }) => {
  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [email, setEmail] = useState({ value: '', error: '', loading: false })
  const [firstName, setFirstName] = useState({ value: '', error: '' })
  const [lastName, setLastName] = useState({ value: '', error: '' })
  const [phoneNumber, setPhoneNumber] = useState({ value: '', error: '' })
  const [address, setAddress] = useState({ value: '', error: '' })
  const [city, setCity] = useState({ value: '', error: '' })
  const [state, setStateValue] = useState({ value: '', error: '' })
  const [country, setCountry] = useState({ value: '', error: '' })
  const [zipCode, setZipCode] = useState({ value: '', error: '' })
  const [reference, setReference] = useState({ value: '', error: '' })
  const [selectedCustomerType, setSelectedCustomerType] = useState('customer-existing')
  const [existingCustomer, setExistingCustomer] = useState(null)
  const [existingCustomerError, setExistingCustomerError] = useState('')

  const handleCustomerTypeChange = val => {
    setDirty(true)
    setSelectedCustomerType(val)
    // clean customer inner state
    setExistingCustomer(null)
    setExistingCustomerError('')
    setEmail({ value: '', error: '', loading: false })
    setFirstName({ value: '', error: '' })
    setLastName({ value: '', error: '' })
    setPhoneNumber({ value: '', error: '' })
    setAddress({ value: '', error: '' })
    setCity({ value: '', error: '' })
    setCountry({ value: '', error: '' })
    setZipCode({ value: '', error: '' })
    setReference({ value: '', error: '' })
  }

  const handleExistingCustomerChange = val => {
    setDirty(true)
    setExistingCustomer(val)
    setExistingCustomerError('')
  }

  const handleEmailValidation = async () => {
    const isExistingSelected = selectedCustomerType === 'customer-existing'
    if (isExistingSelected) {
      if (!existingCustomer) {
        setExistingCustomerError(errorMsg.selectCustomer)
        return false
      }
      return true
    }
    setLoading(true)
    let errors
    try {
      errors = await validateEmail(get(email, 'value'))
      setEmail(prev => ({ ...prev, error: errors, loading: false }))
    } catch (err) {
      sendErrorReport(err, 'Cannot validate customer email', email)
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateFormData = async () => {
    const isValid = await handleEmailValidation()
    return isValid
  }

  const isFormValid = async () => {
    const isFormDataValid = await validateFormData()
    return isFormDataValid
  }

  const addCustomerToAccount = (newCustomerID = undefined) => {
    const id = newCustomerID || get(existingCustomer, 'id')

    if (!id) {
      setLoading(false)
      Notification(
        'error',
        __('Your changes were not saved'),
        __('There was an error while saving your changes')
      )
      return false
    }

    patchCustomer(id, company, { customer_account: customerAccountID })
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        updateCustomer(id)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer to account')
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const addCustomer = data => {
    const dataWithCompany = { ...data, company }
    createCustomer(company, dataWithCompany)
      .then(res => {
        const id = get(res, 'data.id')
        addCustomerToAccount(id)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleSubmit = async () => {
    if (!dirty) {
      closeForm()
      return false
    }
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setLoading(true)
    const data = {
      email: get(email, 'value'),
      first_name: get(firstName, 'value'),
      last_name: get(lastName, 'value'),
      address: get(address, 'value'),
      city: get(city, 'value'),
      state: get(state, 'value'),
      country: get(country, 'value'),
      postcode: get(zipCode, 'value'),
      phone: get(phoneNumber, 'value'),
      reference: get(reference, 'value')
    }

    const isExistingSelected = selectedCustomerType === 'customer-existing' && !!existingCustomer
    if (isExistingSelected) {
      await addCustomerToAccount()
    } else {
      await addCustomer(data)
    }
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeForm()
    }
    return setDirtyFormAlertDisplay(true)
  }

  const title = __('Add customer')

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading || email.loading}
      title={title}
      size='md'
    >
      <div className='AddCustomerToAccountForm'>
        <form onSubmit={handleSubmit}>
          <div className='form-column radio-btns'>
            <RadioBtn
              name='customer-select'
              inputId='customer-existing'
              label={__('Existing customer')}
              value='customer-existing'
              checked={selectedCustomerType === 'customer-existing'}
              handleChange={val => handleCustomerTypeChange(val)}
            />
            <RadioBtn
              name='customer-select'
              inputId='customer-new'
              label={__('New customer')}
              value='customer-new'
              checked={selectedCustomerType === 'customer-new'}
              handleChange={val => handleCustomerTypeChange(val)}
            />
          </div>
          {selectedCustomerType === 'customer-new' ? (
            <div className='form-inner'>
              <div className='left'>
                <div>
                  <Label text={__('Email')} inputId='customer-email' />
                  <TextInput
                    id='customer-email'
                    value={get(email, 'value')}
                    error={get(email, 'error')}
                    loading={get(email, 'loading')}
                    handleChange={val => {
                      setDirty(true)
                      setEmail(prev => ({ ...prev, value: val, loading: true }))
                      debouncedValidateEmail(val)
                        .then(err => setEmail(prev => ({ ...prev, error: err, loading: false })))
                        .catch(() => setEmail(prev => ({ ...prev, loading: false })))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('First Name')} inputId='customer-firstname' />
                  <TextInput
                    id='customer-firstname'
                    value={get(firstName, 'value')}
                    error={get(firstName, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setFirstName(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('Last Name')} inputId='customer-lastname' />
                  <TextInput
                    id='customer-lastname'
                    value={get(lastName, 'value')}
                    error={get(lastName, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setLastName(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('Address')} inputId='customer-address' />
                  <TextInput
                    id='customer-address'
                    value={get(address, 'value')}
                    error={get(address, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setAddress(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('City')} inputId='customer-city' />
                  <TextInput
                    id='customer-city'
                    value={get(city, 'value')}
                    error={get(city, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setCity(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
              </div>
              <div className='divider active' />
              <div className='right'>
                <div>
                  <Label text={__('State / Province')} inputId='customer-state' />
                  <TextInput
                    id='customer-state'
                    value={get(state, 'value')}
                    error={get(state, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setStateValue(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('Country')} inputId='customer-country' />
                  <TextInput
                    id='customer-country'
                    value={get(country, 'value')}
                    error={get(country, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setCountry(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('Zipcode / Postcode')} inputId='customer-zipcode' />
                  <TextInput
                    id='customer-zipcode'
                    value={get(zipCode, 'value')}
                    error={get(zipCode, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setZipCode(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('Phone Number')} inputId='customer-phonenumber' />
                  <PhoneNumberInput
                    value={get(phoneNumber, 'value')}
                    error={get(phoneNumber, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setPhoneNumber(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
                <div>
                  <Label text={__('Reference')} inputId='customer-reference' />
                  <TextInput
                    id='customer-reference'
                    value={get(reference, 'value')}
                    error={get(reference, 'error')}
                    handleChange={val => {
                      setDirty(true)
                      setReference(prev => ({ ...prev, value: val }))
                    }}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className='existing-customer-select'>
              <AsyncSelector
                isClearable
                valueKey='email'
                labelKey='email'
                id='customer-existing-select'
                placeholder={__('Select existing customer')}
                fetchOptions={val =>
                  api.get(
                    `/api/v1/customers/?company=${company}&limit=50&order_by=email&email__icontains=${val}`
                  )
                }
                fetchInitialOptions={() =>
                  api.get(`/api/v1/customers/?company=${company}&limit=50&order_by=email`)
                }
                handleChange={val => handleExistingCustomerChange(val)}
                value={get(existingCustomer, 'email') || ''}
                getOptionLabel={o => `${o.email || o.company_name}`}
              />
              <InputErrorMessage text={existingCustomerError} />
            </div>
          )}
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeForm}
          />
        )}
      </div>
    </Modal>
  )
}

AddCustomerToAccountForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
  customerAccountID: PropTypes.number.isRequired,
  updateCustomer: PropTypes.func.isRequired
}

export default AddCustomerToAccountForm
