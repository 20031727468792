import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { PermissionCheck } from 'shared/components'
import { userPermissions } from 'shared/constants'
import APIUsage from '../APIUsage'
import ProductsAnalytics from '../ProductsAnalytics'

const AnalyticsContainer = () => (
  <Switch>
    <Route
      exact
      path='/:companyId/analytics'
      component={PermissionCheck(APIUsage, userPermissions.analytics_read)}
    />
    <Route
      path='/:companyId/analytics/products'
      component={PermissionCheck(ProductsAnalytics, userPermissions.analytics_read)}
    />
  </Switch>
)

export default AnalyticsContainer
