import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import { Button, ConfirmationPopup, DescriptionTable, Notification } from 'shared/components'
import {
  sendErrorReport,
  capitalizeFirstLetter,
  formatAmountValue,
  mapCurrencySymbol,
  displayCouponName,
  displayTaxRate,
  getSelectedPricingPlan,
  getUpcomingInvoiceDate
} from 'shared/helpers'
import { changePlanType } from 'src/billing/actions'
import ScheduledUpdateContainer from '../ScheduledUpdateContainer'
import StandardSubscriptionForm from '../StandardSubscriptionForm'
import ScheduledUpdateForm from '../ScheduledUpdateForm'

const StandardSubscription = ({ dispatchCompanyChange }) => {
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyBilling = useSelector(state => get(state, 'billing'))
  const pricingPlans = get(companyBilling, 'pricingPlans') || []
  const coupons = get(companyBilling, 'coupons') || []
  const taxRates = get(companyBilling, 'taxRates') || []
  const [cancelSubscriptionLoading, setCancelSubscriptionLoading] = useState(false)
  const [isCancelSubscriptionWarningDisplayed, setIsCancelSubscriptionWarningDisplayed] = useState(
    false
  )
  const [isSubscriptionFormDisplayed, setSubscriptionFormDisplayed] = useState(false)
  const [isScheduledUpdateFormDisplayed, setScheduledUpdateFormDisplayed] = useState(false)

  const handleSubscriptionCancel = () => {
    const companyId = get(companyDetails, 'id')
    setCancelSubscriptionLoading(true)

    const data = { plan_type: 'free' }

    changePlanType(companyId, data)
      .then(() => {
        dispatchCompanyChange(companyId)
        Notification('success', __('Subscription successfully canceled'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot cancel subscription', data)
        Notification('error', __('Error occured'), __('We could not cancel the subscription.'))
        setCancelSubscriptionLoading(false)
      })
  }

  const couponName = displayCouponName(
    get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') ||
      get(companyDetails, 'payment_coupon_code'),
    coupons
  )
  const taxRate = displayTaxRate(get(companyDetails, 'stripe_tax_id'), taxRates)
  const taxAmount = formatAmountValue(get(companyDetails, 'upcoming_invoice.tax'))
  const pricingPlan = getSelectedPricingPlan(
    get(companyDetails, 'upcoming_invoice.plan_id'),
    pricingPlans
  )
  const nextPaymentDate = getUpcomingInvoiceDate(companyDetails)
  const scheduledUpdate = get(companyDetails, 'scheduled_update')

  return (
    <div className='StandardSubscription'>
      <div className='section-header'>{__('Subscription')}</div>
      <div className='section-body'>
        <div className='list-header'>
          <div>
            <Button size='sm' theme='info' onClick={() => setSubscriptionFormDisplayed(true)}>
              {__('Edit subscription')}
            </Button>
            <Button
              size='sm'
              theme='default'
              onClick={() => setScheduledUpdateFormDisplayed(true)}
              disabled={!!scheduledUpdate}
            >
              {__('Add scheduled update')}
            </Button>
          </div>
          <div>
            <Button
              size='sm'
              theme='error'
              disabled={cancelSubscriptionLoading}
              onClick={() => setIsCancelSubscriptionWarningDisplayed(true)}
            >
              {__('Cancel subscription')}
            </Button>
          </div>
        </div>
        <div>
          {!!scheduledUpdate && (
            <ScheduledUpdateContainer
              scheduledUpdate={scheduledUpdate}
              companyDetails={companyDetails}
              coupons={coupons}
              taxRates={taxRates}
              pricingPlans={pricingPlans}
              refreshCompany={dispatchCompanyChange}
            />
          )}
        </div>
        <DescriptionTable
          details={[
            {
              label: __('Pricing plan'),
              value: get(pricingPlan, 'nickname') || '-'
            },
            {
              label: __('Billing cycle'),
              value: capitalizeFirstLetter(get(pricingPlan, 'interval')) || '-'
            },
            {
              label: __('Amount'),
              value: formatAmountValue(get(pricingPlan, 'amount'))
            },
            {
              label: __('Currency'),
              value: mapCurrencySymbol(get(pricingPlan, 'currency')) || '-'
            },
            { label: __('Discount'), value: couponName },
            { label: __('Tax rate'), value: taxRate },
            { label: __('Tax amount'), value: taxAmount },
            {
              label: __('Total'),
              value: `${formatAmountValue(
                get(companyDetails, 'upcoming_invoice.total')
              )} ${mapCurrencySymbol(get(pricingPlan, 'currency')) || ''}`
            },
            {
              label: nextPaymentDate ? __('Next billing date') : null,
              value: moment(nextPaymentDate).format('YYYY-MM-DD')
            }
          ]}
        />
      </div>
      {isSubscriptionFormDisplayed && (
        <StandardSubscriptionForm
          closeCb={() => setSubscriptionFormDisplayed(false)}
          title={__('Edit subscription')}
        />
      )}
      {isScheduledUpdateFormDisplayed && (
        <ScheduledUpdateForm
          closeCb={() => setScheduledUpdateFormDisplayed(false)}
          companyDetails={companyDetails}
          title={__('Scheduled subscription update')}
          coupons={coupons}
          taxRates={taxRates}
          pricingPlans={pricingPlans}
        />
      )}
      {isCancelSubscriptionWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setCancelSubscriptionLoading(false)
            setIsCancelSubscriptionWarningDisplayed(false)
          }}
          confirmCb={() => handleSubscriptionCancel()}
          title={__('This action cannot be undone!')}
          confirmText={__('Confirm')}
          theme='error'
          warning
          disabled={cancelSubscriptionLoading}
        >
          <>
            {__('Please, confirm that you really want to cancel this subscription.')}
            <span>&nbsp;</span>
            {__(
              'This account plan type will be set to free and it will lose all the standard features.'
            )}
          </>
        </ConfirmationPopup>
      )}
    </div>
  )
}

StandardSubscription.propTypes = {
  dispatchCompanyChange: PropTypes.func.isRequired
}

export default StandardSubscription
