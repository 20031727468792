import React from 'react'
import PropTypes from 'prop-types'
import './styles.scss'

const WidgetsContainer = ({ children }) => <div className='WidgetsContainer'>{children}</div>

WidgetsContainer.propTypes = {
  children: PropTypes.node.isRequired
}

export default WidgetsContainer
