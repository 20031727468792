import React from 'react'
import PropTypes from 'prop-types'
import SlidingPane from 'react-sliding-pane'
import { IconDisabled } from 'shared/components'
import 'react-sliding-pane/dist/react-sliding-pane.css'
import './styles.scss'

const SlidePane = ({ children, closeCb, disabled, isOpen, subtitle, title, width }) => (
  <SlidingPane
    className='SlidePane'
    overlayClassName='SlidePane-overlay'
    isOpen={isOpen}
    title={title}
    subtitle={subtitle}
    onRequestClose={() => {
      if (disabled) {
        return
      }
      closeCb()
    }}
    closeIcon={<IconDisabled height='18px' width='18px' />}
    width={width}
  >
    {children}
  </SlidingPane>
)

SlidePane.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  closeCb: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isOpen: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  width: PropTypes.string
}

SlidePane.defaultProps = {
  disabled: false,
  isOpen: true,
  subtitle: '',
  title: '',
  width: '70%'
}

export default SlidePane
