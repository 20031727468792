import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport, parseMetadata, isFeatureEnabled } from 'shared/helpers'
import { platformFeatures } from 'shared/constants'
import {
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  TextInput,
  TextArea,
  PhoneNumberInput
} from 'shared/components'
import { getCompanyConfigFieldValue } from 'shared/companyConfig'
import {
  validateRequiredValue,
  validateCustomerEmail,
  debouncedValidateCustomerEmail,
  validateJSON,
  debouncedValidateJSON
} from 'shared/validation'
import { patchCustomer, createCustomer } from 'src/customer/actions'
import './styles.scss'

const CustomerForm = ({ closeForm, company, customer, updateCustomer }) => {
  const isMetadataFeatureEnabled = isFeatureEnabled(platformFeatures.extra_metadata)

  const initialEmail = get(customer, 'email')

  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [email, setEmail] = useState({
    value: get(customer, 'email') || '',
    error: '',
    loading: false
  })
  const [firstName, setFirstName] = useState({
    value: get(customer, 'first_name') || '',
    error: ''
  })
  const [lastName, setLastName] = useState({
    value: get(customer, 'last_name') || '',
    error: ''
  })
  const [phoneNumber, setPhoneNumber] = useState({
    value: get(customer, 'phone') || '',
    error: ''
  })
  const [address, setAddress] = useState({
    value: get(customer, 'address') || '',
    error: ''
  })
  const [city, setCity] = useState({
    value: get(customer, 'city') || '',
    error: ''
  })
  const [state, setStateValue] = useState({
    value: get(customer, 'state') || '',
    error: ''
  })
  const [country, setCountry] = useState({
    value: get(customer, 'country') || '',
    error: ''
  })
  const [zipCode, setZipCode] = useState({
    value: get(customer, 'postcode') || '',
    error: ''
  })
  const [companyName, setCompanyName] = useState({
    value: get(customer, 'company_name') || '',
    error: ''
  })
  const [reference, setReference] = useState({
    value: get(customer, 'reference') || '',
    error: ''
  })
  const [metadata, setMetadata] = useState(parseMetadata(get(customer, 'metadata')))
  const [metadataError, setMetadataError] = useState('')

  const validateValue = async (val, cb) => {
    setLoading(true)
    let errors
    try {
      errors = await validateRequiredValue(val)
      cb(prev => ({ ...prev, error: errors }))
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer form value', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const validateMetadata = async val => {
    setLoading(true)
    let errors
    try {
      errors = await validateJSON(metadata)
      setMetadataError(errors)
    } catch (err) {
      sendErrorReport(err, 'Cannot validate edit customer form value', { value: val })
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const handleEmailValidation = async () => {
    setLoading(true)
    let errors
    try {
      errors = await validateCustomerEmail(get(email, 'value'), company, get(customer, 'email'))
      setEmail(prev => ({ ...prev, error: errors, loading: false }))
    } catch (err) {
      sendErrorReport(err, 'Cannot validate customer email', email)
    }
    setLoading(false)
    if (errors) {
      return false
    }
    return true
  }

  const customValidation = async (overrideValidation = []) => {
    const emailValid = overrideValidation.includes('email') ? await handleEmailValidation() : true
    const firstNameValid = overrideValidation.includes('firstname')
      ? await validateValue(firstName.value, setFirstName)
      : true
    const lastNameValid = overrideValidation.includes('lastname')
      ? await validateValue(lastName.value, setLastName)
      : true
    const phoneValid = overrideValidation.includes('phone')
      ? await validateValue(phoneNumber.value, setPhoneNumber)
      : true
    const addressValid = overrideValidation.includes('address')
      ? await validateValue(address.value, setAddress)
      : true
    const cityValid = overrideValidation.includes('city')
      ? await validateValue(city.value, setCity)
      : true
    const stateValid = overrideValidation.includes('state')
      ? await validateValue(state.value, setStateValue)
      : true
    const countryValid = overrideValidation.includes('country')
      ? await validateValue(country.value, setCountry)
      : true
    const zipcodeValid = overrideValidation.includes('zipcode')
      ? await validateValue(zipCode.value, setZipCode)
      : true
    const companyNameValid = overrideValidation.includes('organization')
      ? await validateValue(companyName.value, setCompanyName)
      : true
    const referenceValid = overrideValidation.includes('reference')
      ? await validateValue(reference.value, setReference)
      : true
    const metadataValid = await validateMetadata()

    return (
      !!emailValid &&
      !!firstNameValid &&
      !!lastNameValid &&
      !!phoneValid &&
      !!addressValid &&
      !!companyNameValid &&
      !!stateValid &&
      !!zipcodeValid &&
      !!cityValid &&
      !!countryValid &&
      !!referenceValid &&
      !!metadataValid
    )
  }

  const validateFormData = async () => {
    let isValid
    const overrideValidation = getCompanyConfigFieldValue(company, 'customerRequiredFields')
    if (!overrideValidation) {
      isValid = await handleEmailValidation()
      return isValid
    }
    isValid = await customValidation(overrideValidation)
    return isValid
  }

  const isFormValid = async () => {
    const isFormDataValid = await validateFormData()
    return isFormDataValid
  }

  const editCustomer = (customerID, data) => {
    patchCustomer(customerID, company, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        updateCustomer()
        closeForm()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot edit customer details', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const addCustomer = data => {
    const dataWithCompany = { ...data, company }
    createCustomer(company, dataWithCompany)
      .then(res => {
        const id = get(res, 'data.id')
        updateCustomer(id)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot add customer', data)
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const handleSubmit = async () => {
    if (!dirty) {
      closeForm()
      return false
    }
    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setLoading(true)
    const customerID = get(customer, 'id')
    const isEmailChanged = initialEmail !== get(email, 'value')
    const data = {
      email: isEmailChanged ? get(email, 'value') : undefined,
      first_name: get(firstName, 'value'),
      last_name: get(lastName, 'value'),
      address: get(address, 'value'),
      city: get(city, 'value'),
      state: get(state, 'value'),
      country: get(country, 'value'),
      postcode: get(zipCode, 'value'),
      phone: get(phoneNumber, 'value') || '',
      company_name: get(companyName, 'value'),
      reference: get(reference, 'value'),
      metadata: metadata ? JSON.parse(metadata) : {}
    }

    if (customer) {
      editCustomer(customerID, data)
    } else {
      addCustomer(data)
    }
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeForm()
    }
    return setDirtyFormAlertDisplay(true)
  }

  const title = customer
    ? `${__('Edit customer')} - ${get(customer, 'email') ||
        get(customer, 'company_name') ||
        get(customer, 'reference')}`
    : __('Add customer')

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading || email.loading}
      title={title}
      size='lg'
    >
      <div className='CustomerForm'>
        <form onSubmit={handleSubmit}>
          <div className='form-inner'>
            <div className='left'>
              <div>
                <Label text={__('Email')} inputId='customer-email' />
                <TextInput
                  id='customer-email'
                  value={get(email, 'value')}
                  error={get(email, 'error')}
                  loading={get(email, 'loading')}
                  handleChange={val => {
                    setDirty(true)
                    setEmail(prev => ({ ...prev, value: val, loading: true }))
                    debouncedValidateCustomerEmail(val, company, get(customer, 'email'))
                      .then(err => setEmail(prev => ({ ...prev, error: err, loading: false })))
                      .catch(() => setEmail(prev => ({ ...prev, loading: false })))
                  }}
                />
              </div>
              <div>
                <Label text={__('First Name')} inputId='customer-firstname' />
                <TextInput
                  id='customer-firstname'
                  value={get(firstName, 'value')}
                  error={get(firstName, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setFirstName(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('Last Name')} inputId='customer-lastname' />
                <TextInput
                  id='customer-lastname'
                  value={get(lastName, 'value')}
                  error={get(lastName, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setLastName(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('Address')} inputId='customer-address' />
                <TextInput
                  id='customer-address'
                  value={get(address, 'value')}
                  error={get(address, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setAddress(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('City')} inputId='customer-city' />
                <TextInput
                  id='customer-city'
                  value={get(city, 'value')}
                  error={get(city, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setCity(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('State / Province')} inputId='customer-state' />
                <TextInput
                  id='customer-state'
                  value={get(state, 'value')}
                  error={get(state, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setStateValue(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
            </div>
            <div className='divider active' />
            <div className='right'>
              <div>
                <Label text={__('Country')} inputId='customer-country' />
                <TextInput
                  id='customer-country'
                  value={get(country, 'value')}
                  error={get(country, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setCountry(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('Zipcode / Postcode')} inputId='customer-zipcode' />
                <TextInput
                  id='customer-zipcode'
                  value={get(zipCode, 'value')}
                  error={get(zipCode, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setZipCode(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('Phone Number')} inputId='customer-phonenumber' />
                <PhoneNumberInput
                  value={get(phoneNumber, 'value')}
                  error={get(phoneNumber, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setPhoneNumber(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('Company Name')} inputId='customer-company' />
                <TextInput
                  id='customer-company'
                  value={get(companyName, 'value')}
                  error={get(companyName, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setCompanyName(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label text={__('Reference')} inputId='customer-reference' />
                <TextInput
                  id='customer-reference'
                  value={get(reference, 'value')}
                  error={get(reference, 'error')}
                  handleChange={val => {
                    setDirty(true)
                    setReference(prev => ({ ...prev, value: val }))
                  }}
                />
              </div>
              <div>
                <Label inputId='metadata-input' text={__('Metadata JSON')} />
                <TextArea
                  handleChange={val => {
                    setDirty(true)
                    setMetadata(val)
                    debouncedValidateJSON(val).then(err => setMetadataError(err))
                  }}
                  id='metadata-input'
                  type='metadata'
                  value={metadata}
                  error={metadataError}
                  rows='4'
                  disabled={!isMetadataFeatureEnabled}
                />
              </div>
            </div>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeForm}
          />
        )}
      </div>
    </Modal>
  )
}

CustomerForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  company: PropTypes.number.isRequired,
  customer: PropTypes.object,
  updateCustomer: PropTypes.func.isRequired
}

CustomerForm.defaultProps = {
  customer: null
}

export default CustomerForm
