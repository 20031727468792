import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  Notification,
  PermissionMissingNotificationTitle,
  OAuthCredentials,
  OAuthModals
} from 'shared/components'
import { checkUserPermission, isFeatureEnabled } from 'shared/helpers'
import { userPermissions, platformFeatures } from 'shared/constants'
import { useOAuth } from 'shared/hooks'
import {
  getOauthCustomerAccountClient,
  createOauthCustomerAccountClient,
  deleteOauthCustomerAccountClient,
  createOauthCustomerAccountClientSecret,
  rotateOauthCustomerAccountClientSecret
} from 'src/customer/actions'

const CustomerAccountOAuth = ({ accountID, companyID }) => {
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const isOauthEnabled = isFeatureEnabled(platformFeatures.extra_oauth)
  const [isInitModalOpen, setInitModalOpen] = useState(false)
  const [usePerpetualForInit, setUsePerpetualForInit] = useState(false)

  const handleManageSettingsClick = cb => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb()
    return true
  }

  const {
    isLoading,
    loadingCreateClient,
    oAuthClient,
    isRotateSecretModalOpen,
    setRotateSecretModalOpen,
    isRotateConfirmationOpen,
    setRotateConfirmationOpen,
    isRefreshConfirmationOpen,
    setRefreshConfirmationOpen,
    isDeleteConfirmationOpen,
    setDeleteConfirmationOpen,
    rotateSecretData,
    setRotateSecretData,
    getOauthClient,
    createNewOauthClient,
    handleDeleteOauthClient,
    handleRefreshSecret,
    handleRotateSecret
  } = useOAuth({
    entityId: accountID,
    companyID,
    getClient: getOauthCustomerAccountClient,
    createClient: createOauthCustomerAccountClient,
    deleteClient: deleteOauthCustomerAccountClient,
    refreshSecret: createOauthCustomerAccountClientSecret,
    rotateSecret: rotateOauthCustomerAccountClientSecret
  })

  const handleRotateClick = () => {
    setRotateSecretModalOpen(true)
  }

  const handleRefreshClick = () => {
    setRefreshConfirmationOpen(true)
  }

  const handleDeleteClick = () => {
    setDeleteConfirmationOpen(true)
  }

  const handleCreateNewOauthClient = async () => {
    await createNewOauthClient(usePerpetualForInit)
    setInitModalOpen(false)
  }

  return (
    <div className='OAuth'>
      {!isOauthEnabled && (
        <div className='row'>
          <h3>{__('Customer Account OAuth Configuration')}</h3>
          <p>
            {__('This feature is not available for this account. Contact sales to enable oAuth.')}
          </p>
        </div>
      )}
      {isOauthEnabled && (
        <div className='row'>
          <h3>{__('Customer Account OAuth Configuration')}</h3>
          {!oAuthClient ? (
            <Button
              size='sm'
              theme='info'
              loading={loadingCreateClient}
              disabled={loadingCreateClient || isLoading}
              onClick={() => handleManageSettingsClick(() => setInitModalOpen(true))}
            >
              {__('Initialize OAuth')}
            </Button>
          ) : (
            <OAuthCredentials
              oAuthClient={oAuthClient}
              isLoading={isLoading}
              handleManageSettingsClick={handleManageSettingsClick}
              onRotateClick={handleRotateClick}
              onRefreshClick={handleRefreshClick}
              onDeleteClick={handleDeleteClick}
              setRefreshConfirmationOpen={setRefreshConfirmationOpen}
              setRotateConfirmationOpen={setRotateConfirmationOpen}
              setDeleteConfirmationOpen={setDeleteConfirmationOpen}
            />
          )}
        </div>
      )}

      <OAuthModals
        isLoading={isLoading}
        isDeleteConfirmationOpen={isDeleteConfirmationOpen}
        setDeleteConfirmationOpen={setDeleteConfirmationOpen}
        handleDeleteOauthClient={handleDeleteOauthClient}
        isRotateConfirmationOpen={isRotateConfirmationOpen}
        setRotateConfirmationOpen={setRotateConfirmationOpen}
        isRefreshConfirmationOpen={isRefreshConfirmationOpen}
        setRefreshConfirmationOpen={setRefreshConfirmationOpen}
        isRotateSecretModalOpen={isRotateSecretModalOpen}
        setRotateSecretModalOpen={setRotateSecretModalOpen}
        handleRotateSecret={handleRotateSecret}
        handleRefreshSecret={handleRefreshSecret}
        getOauthClient={getOauthClient}
        rotateSecretData={rotateSecretData}
        setRotateSecretData={setRotateSecretData}
        showInitCheckbox={isInitModalOpen}
        closeInitModal={() => setInitModalOpen(false)}
        usePerpetual={usePerpetualForInit}
        onUsePerpetualChange={setUsePerpetualForInit}
        handleCreateNewOauthClient={handleCreateNewOauthClient}
      />
    </div>
  )
}
CustomerAccountOAuth.propTypes = {
  accountID: PropTypes.string.isRequired,
  companyID: PropTypes.string.isRequired
}

export default CustomerAccountOAuth
