import React from 'react'
import { Page, Tab, Tabs, TabContent, TabsHeader } from 'shared/components'
import ActiveCompaniesList from '../ActiveCompaniesList'
import InactiveCompaniesList from '../InactiveCompaniesList'
import './styles.scss'

const CompanyList = () => (
  <div className='CompanyList'>
    <Page showBreadcrumbs={false} title={__('Companies')}>
      <Tabs>
        <TabsHeader>
          <Tab>{__('Active companies')}</Tab>
          <Tab>{__('Inactive companies')}</Tab>
        </TabsHeader>
        <TabContent>
          <ActiveCompaniesList />
        </TabContent>
        <TabContent>
          <InactiveCompaniesList />
        </TabContent>
      </Tabs>
    </Page>
  </div>
)

export default CompanyList
