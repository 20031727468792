import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import {
  Button,
  ConfirmationPopup,
  DescriptionTable,
  Label,
  CheckboxSelector,
  Notification
} from 'shared/components'
import { sendErrorReport, formatDate } from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import { wipeData, patchCompany } from 'src/billing/actions'
import { getCompanySilent } from 'src/company/actions'
import DisableDateForm from '../DisableDateForm'
import './styles.scss'

const Settings = ({ companyDetails }) => {
  const companyID = get(companyDetails, 'id')
  const dispatch = useDispatch()

  const dataTypeOptions = [
    {
      value: 'customers',
      label: __('Customers')
    },
    {
      value: 'products',
      label: __('Products')
    },
    {
      value: 'orders',
      label: __('Orders')
    },
    {
      value: 'licenses',
      label: __('Licenses')
    }
  ]

  const [isWipeDataLoading, setWipeDataLoading] = useState(false)
  const [selectedDataTypes, setSelectedDataTypes] = useState([])
  const [showChangeDisableDate, setChangeDisableDateDisplay] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [showDisableDateRemovePopup, setDisableDateRemovePopupDisplay] = useState(false)

  const handleWipeDataClick = () => {
    if (!selectedDataTypes.length) {
      Notification('error', __('Select data types to wipe'))
      return false
    }

    setWipeDataLoading(true)

    const data = selectedDataTypes.map(s => s.value)
    wipeData(companyID, data)
      .then(() => {
        dispatch(getCompanySilent(companyID))
        setWipeDataLoading(false)
        Notification(
          'success',
          __('Selected company data wiped'),
          __('If there is a lot of data, please wait few minutes for process to finish')
        )
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot wipe company data')
        setWipeDataLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  const removeDisableDate = () => {
    setLoading(true)

    const data = { payment_skip_check_until: null }
    patchCompany(companyID, data)
      .then(() => {
        dispatch(getCompanySilent(companyID))
        setLoading(false)
        setDisableDateRemovePopupDisplay(false)
        Notification('success', __('Daily script disable date removed'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot remove disable daily script date.')
        setLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
    return true
  }

  return (
    <div className='Settings'>
      <section className='Settings-skip-script'>
        <div className='heading'>{__('Disable daily script')}</div>
        <div className='description'>
          {__('This will disable daily script check until selected date')}
        </div>
        <div className='actions'>
          <Button size='sm' theme='info' onClick={() => setChangeDisableDateDisplay(true)}>
            {__('Change disable date')}
          </Button>
          <Button size='sm' theme='warning' onClick={() => setDisableDateRemovePopupDisplay(true)}>
            {__('Clear disable date')}
          </Button>
        </div>
        <DescriptionTable
          details={[
            {
              label: __('Script disabled until:'),
              value: formatDate(
                get(companyDetails, 'payment_skip_check_until'),
                defaultDateFormat,
                'Not skipped'
              )
            }
          ]}
        />
      </section>
      <section className='Settings-data-wipe'>
        <div className='heading'>{__('Data wipe')}</div>
        <div className='description'>{__('This will wipe all data of the selected type')}</div>
        <div className='container'>
          <div className='data-type-selector'>
            <Label text={__('Select data types')} />
            <CheckboxSelector
              text={selectedDataTypes.map(s => s.label).join(', ')}
              options={dataTypeOptions}
              value={selectedDataTypes}
              onChangeCallback={val => setSelectedDataTypes(val)}
              onMenuClose={() => {}}
              disabled={isWipeDataLoading}
            />
          </div>
          <Button
            theme='error'
            onClick={handleWipeDataClick}
            disabled={isWipeDataLoading}
            loading={isWipeDataLoading}
          >
            {__('Wipe selected data')}
          </Button>
        </div>
      </section>
      {showChangeDisableDate && (
        <DisableDateForm
          closeCb={() => setChangeDisableDateDisplay(false)}
          company={companyDetails}
          refetchCompany={() => dispatch(getCompanySilent(companyID))}
        />
      )}
      {showDisableDateRemovePopup && (
        <ConfirmationPopup
          closeCb={() => {
            setDisableDateRemovePopupDisplay(false)
          }}
          confirmCb={removeDisableDate}
          title={__('Are you sure you want to remove the disable daily script date?')}
          confirmText={__('Remove')}
          theme='error'
          disabled={isLoading}
        />
      )}
    </div>
  )
}

Settings.propTypes = {
  companyDetails: PropTypes.object.isRequired
}

export default Settings
