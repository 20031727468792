import 'react-app-polyfill/ie9'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import Raven from 'raven-js'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import { AuthProvider } from 'react-oidc-context'
import { oidcConfig } from 'src/keycloak/authConfig'
import { getAppConfigVar } from 'shared/helpers'
import ErrorBoundary from './app/ErrorBoundary'
import App from './App'
import store from './store'
import './index.css'

const sentryDSN = getAppConfigVar('sentryDSN', '')

if (sentryDSN) {
  Raven.config(sentryDSN, {
    environment: process.env,
    version: 'v2'
  }).install()
}

render(
  <AuthProvider {...oidcConfig}>
    <Provider store={store}>
      <ErrorBoundary>
        <App />
      </ErrorBoundary>
    </Provider>
  </AuthProvider>,
  document.getElementById('root') // eslint-disable-line no-undef
)
