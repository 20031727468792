import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { Button, ConfirmationPopup, Notification, IconEdit, IconDelete } from 'shared/components'
import { sendErrorReport } from 'shared/helpers'
import { updateLicenseManagerPermissions } from 'src/order/actions'
import LicenseManagerProductForm from '../LicenseManagerProductForm'
import './styles.scss'

const LicenseManagerProduct = ({
  product,
  productCounter,
  policies,
  manager,
  refetchManager,
  refetchCounter
}) => {
  const companyID = useSelector(state => get(state, 'company.details.id'))

  const managerID = get(manager, 'id')
  const managerPermissions = get(manager, 'permissions') || []
  const managerProducts = get(manager, 'products') || []
  const managerPolicies = get(manager, 'license_templates') || []

  const [isEdit, setEdit] = useState(false)
  const [isDelete, setDelete] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const productPolicies = policies.filter(p => get(p, 'product.id') === get(product, 'product.id'))
  const maxLicenses = get(product, 'num_of_licenses')
  const maxLicensesValue = !maxLicenses ? __('Unlimited') : maxLicenses

  const getProductsWithoutDeleted = productIdToDelete => {
    const filteredList = managerProducts.filter(p => get(p, 'product.id') !== productIdToDelete)
    const mappedList = filteredList.map(p => ({
      product_id: get(p, 'product.id'),
      num_of_licenses: get(p, 'num_of_licenses')
    }))
    return mappedList
  }

  const getPoliciesWithoutDeletedProduct = productIdToDelete => {
    const filteredList = managerPolicies.filter(p => get(p, 'product.id') !== productIdToDelete)
    const mappedList = filteredList.map(p => p.id)
    return mappedList
  }

  const handleProductDelete = () => {
    const productIdToDelete = get(product, 'product.id')

    const data = {
      permission_ids: managerPermissions.map(p => p.id),
      products: getProductsWithoutDeleted(productIdToDelete),
      license_template_ids: getPoliciesWithoutDeletedProduct(productIdToDelete)
    }

    setLoading(true)
    updateLicenseManagerPermissions(managerID, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        setLoading(false)
        refetchManager()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update manager permissions', data)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
        setLoading(false)
      })
  }

  const getRemainingLicensesValue = () => {
    const numOfLicenses = get(productCounter, 'num_of_licenses')
    if (numOfLicenses === null) {
      return __('Unlimited')
    }
    const used = get(productCounter, 'created_licenses_count')
    const remaining = numOfLicenses - used
    return remaining
  }

  return (
    <div className='LicenseManagerProduct'>
      <div className='header'>
        <div className='product-name'>{get(product, 'product.product_name')}</div>
        <div className='actions'>
          <Button theme='default' size='sm' onClick={() => setEdit(true)}>
            <IconEdit height='16px' width='16px' />
          </Button>
          <Button theme='link' size='sm' onClick={() => setDelete(true)}>
            <IconDelete height='16px' width='16px' color='#ee5253' />
          </Button>
        </div>
      </div>
      <div className='details'>
        <div className='label'>{__('License policies')}</div>
        <div className='value'>{productPolicies.map(sp => sp.name).join(', ')}</div>
      </div>
      <div className='details'>
        <div className='label'>{__('Number of licenses')}</div>
        <div className='value'>{maxLicensesValue}</div>
      </div>
      <div className='details'>
        <div className='label'>{__('Issued licenses')}</div>
        <div className='value'>{get(productCounter, 'created_licenses_count')}</div>
      </div>
      <div className='details'>
        <div className='label'>{__('Remaining licenses')}</div>
        <div className='value'>{getRemainingLicensesValue()}</div>
      </div>
      {isDelete && (
        <ConfirmationPopup
          closeCb={() => {
            setDelete(false)
          }}
          confirmCb={handleProductDelete}
          confirmText={__('Confirm')}
          theme='error'
          disabled={isLoading}
        >
          {__(
            'Please, confirm that you want to remove this product from license manager permissions list.'
          )}
        </ConfirmationPopup>
      )}
      {isEdit && (
        <LicenseManagerProductForm
          closeCb={() => {
            setEdit(false)
          }}
          confirmCb={() => {
            refetchManager()
            refetchCounter()
            setEdit(false)
          }}
          manager={manager}
          product={product}
          productPolicies={productPolicies}
        />
      )}
    </div>
  )
}

LicenseManagerProduct.propTypes = {
  product: PropTypes.object,
  productCounter: PropTypes.object,
  policies: PropTypes.array,
  manager: PropTypes.object.isRequired,
  refetchManager: PropTypes.func.isRequired,
  refetchCounter: PropTypes.func.isRequired
}

LicenseManagerProduct.defaultProps = {
  product: {},
  productCounter: {},
  policies: []
}

export default LicenseManagerProduct
