import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'shared/components'
import './styles.scss'

const BusinessStarterPlanTypeBox = ({ isCurrent, handleCtaClick, isUpgrade }) => (
  <article className={`BusinessStarterPlanTypeBox PlanTypeBox ${isCurrent ? 'current' : ''}`}>
    <div className='PlanTypeBox-title'>{__('Business Starter')}</div>
    <p className='PlanTypeBox-description'>
      {__(
        'For ISVs, game developers, and organizations looking to protect their business with standard licensing models'
      )}
    </p>
    <div className='PlanTypeBox-price standard'>199</div>
    <div className='PlanTypeBox-btn'>
      {isUpgrade ? (
        <Button onClick={handleCtaClick} theme='success' disabled={isCurrent} size='lg'>
          {__('Upgrade to Business Starter')}
        </Button>
      ) : (
        <a
          className='upgrade-link'
          href='https://licensespring.com/get-started/'
          target='_blank'
          rel='noopener noreferrer'
        >
          {__('Downgrade to Business Starter')}
        </a>
      )}
    </div>
    <ul className='PlanTypeBox-features'>
      <li>
        <span>10,000</span>
        {__('monthly requests')}
      </li>
      <li>
        <span>3</span>
        {__('products')}
      </li>
      <li>
        <span>3</span>
        {__('development environments')}
      </li>
      <li className='checkmark'>{__('Node-Locking')}</li>
      <li className='checkmark'>{__('Key or User-Based')}</li>
      <li className='checkmark'>{__('Offline Licensing')}</li>
      <li className='checkmark'>{__('Product Versioning')}</li>
    </ul>
  </article>
)

BusinessStarterPlanTypeBox.propTypes = {
  handleCtaClick: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool.isRequired,
  isUpgrade: PropTypes.bool.isRequired
}

export default BusinessStarterPlanTypeBox
