import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { Button, ConfirmationPopup, DescriptionTable, Notification } from 'shared/components'
import {
  sendErrorReport,
  displayCouponName,
  displayTaxRate,
  getSelectedPricingPlan
} from 'shared/helpers'
import { deleteScheduledUpdate, rescheduleUpdate } from 'src/billing/actions'
import RescheduleUpdateForm from './components/RescheduleUpdateForm'
import './styles.scss'

const ScheduledUpdateContainer = ({
  scheduledUpdate,
  coupons,
  companyDetails,
  pricingPlans,
  taxRates,
  refreshCompany
}) => {
  const [isRescheduleUpdatePopupDisplayed, setRescheduleUpdatePopupDisplayed] = useState(false)
  const [isDeleteUpdatePopupDisplayed, setDeleteUpdatePopupDisplayed] = useState(false)
  const [deleteUpdateLoading, setDeleteUpdateLoading] = useState(false)
  const companyID = get(companyDetails, 'id')

  const handleUpdateDelete = () => {
    setDeleteUpdateLoading(true)

    deleteScheduledUpdate(companyID)
      .then(() => {
        setDeleteUpdateLoading(false)
        setDeleteUpdatePopupDisplayed(false)
        Notification('success', __('Scheduled update deleted successfully'))
        refreshCompany(companyID)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete scheduled update')
        setDeleteUpdateLoading(false)
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
  }

  const couponName = displayCouponName(get(scheduledUpdate, 'coupon'), coupons)
  const taxRate = displayTaxRate(get(scheduledUpdate, 'tax_rate'), taxRates)
  const pricingPlan = getSelectedPricingPlan(get(scheduledUpdate, 'plan'), pricingPlans)
  const startDate = get(scheduledUpdate, 'start_date')

  return (
    <div className='ScheduledUpdateContainer'>
      <div className='ScheduledUpdateContainer-heading'>{__('Scheduled update')}</div>
      <div className='ScheduledUpdateContainer-body'>
        <DescriptionTable
          details={[
            {
              label: __('Pricing plan'),
              value: get(pricingPlan, 'nickname') || '-'
            },
            { label: __('Discount'), value: couponName },
            { label: __('Tax rate'), value: taxRate },
            { label: __('Date'), value: moment(startDate).format('YYYY-MM-DD') }
          ]}
        />
      </div>
      <div className='ScheduledUpdateContainer-actions'>
        <Button size='sm' theme='default' onClick={() => setDeleteUpdatePopupDisplayed(true)}>
          {__('Delete update')}
        </Button>
        <Button size='sm' theme='default' onClick={() => setRescheduleUpdatePopupDisplayed(true)}>
          {__('Reschedule update')}
        </Button>
      </div>
      {isDeleteUpdatePopupDisplayed && (
        <ConfirmationPopup
          closeCb={() => setDeleteUpdatePopupDisplayed(false)}
          confirmCb={handleUpdateDelete}
          confirmText={__('Confirm')}
          theme='error'
          disabled={deleteUpdateLoading}
        >
          {__('Please, confirm that you really want to delete this subscription update.')}
        </ConfirmationPopup>
      )}
      {isRescheduleUpdatePopupDisplayed && (
        <RescheduleUpdateForm
          closeCb={() => setRescheduleUpdatePopupDisplayed(false)}
          confirmCb={() => {
            refreshCompany(companyID)
            setRescheduleUpdatePopupDisplayed(false)
          }}
          rescheduleUpdate={rescheduleUpdate}
          scheduledUpdate={scheduledUpdate}
          companyID={companyID}
        />
      )}
    </div>
  )
}

ScheduledUpdateContainer.propTypes = {
  scheduledUpdate: PropTypes.object,
  companyDetails: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  pricingPlans: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
  refreshCompany: PropTypes.func.isRequired
}

ScheduledUpdateContainer.defaultProps = {
  scheduledUpdate: null
}

export default ScheduledUpdateContainer
