import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { sendErrorReport, generatePass } from 'shared/helpers'
import { DirtyFormAlert, Label, Modal, Notification, TextInput } from 'shared/components'
import { setLicenseUserPassword } from 'src/customer/actions'
import { validatePassword, debouncedValidatePassword } from './validation'
import './styles.scss'

const CustomerPasswordForm = ({ closeForm, customer, companyID }) => {
  const [isLoading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setDirtyFormAlertDisplay] = useState(false)
  const [newPassword, setNewPassword] = useState(generatePass())
  const [newPasswordError, setNewPasswordError] = useState('')

  const handlePasswordValidation = async () => {
    setLoading(true)
    let errors
    try {
      errors = await validatePassword(newPassword)
      setLoading(false)
      setNewPasswordError(errors)
    } catch (err) {
      setLoading(false)
      sendErrorReport(err, 'Cannot validate new customer password', { value: newPassword })
    }
    if (errors) {
      return false
    }
    return true
  }

  const isFormValid = async () => {
    const isPasswordValid = await handlePasswordValidation()
    return isPasswordValid
  }

  const handleSubmit = async e => {
    e.preventDefault()
    const licenseUserId = get(customer, 'license_user')
    if (!licenseUserId) {
      Notification('error', __('Error occured'), __('Customer does not have license user id'))
      return false
    }

    const isValid = await isFormValid()
    if (!isValid || isLoading) {
      return false
    }

    setDirty(false)
    setLoading(true)
    const data = { password: newPassword }

    setLicenseUserPassword(licenseUserId, companyID, data)
      .then(() => {
        Notification('success', __('Changes saved successfully'))
        closeForm()
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot set license user password')
        setLoading(false)
        Notification(
          'error',
          __('Password was not set'),
          __('There was an error while setting the license user password')
        )
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeForm()
    }
    return setDirtyFormAlertDisplay(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      disabled={isLoading}
      size='sm'
      title={__('Customer password')}
    >
      <div className='CustomerPasswordForm'>
        <form onSubmit={handleSubmit}>
          <div className='password-container'>
            <Label text={__('New password')} inputId='password' />
            <TextInput
              id='password'
              value={newPassword}
              error={newPasswordError}
              handleChange={val => {
                setDirty(true)
                setNewPassword(val)
                debouncedValidatePassword(val).then(err => setNewPasswordError(err))
              }}
            />
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setDirtyFormAlertDisplay(false)}
            closeCb={closeForm}
          />
        )}
      </div>
    </Modal>
  )
}

CustomerPasswordForm.propTypes = {
  closeForm: PropTypes.func.isRequired,
  customer: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired
}

export default CustomerPasswordForm
