import React from 'react'
import ReactDOM from 'react-dom'
import { DirtyFormAlert } from 'shared/components'

const CustomPrompt = (message, callback, title) => {
  const container = document.createElement('div')
  container.setAttribute('custom-confirmation-navigation', '')
  document.body.appendChild(container)
  const closeModal = callbackState => {
    ReactDOM.unmountComponentAtNode(container)
    callback(callbackState)
  }
  ReactDOM.render(
    <DirtyFormAlert
      dirty
      closeAlert={() => closeModal(false)}
      closeCb={() => closeModal(true)}
      title={title}
    >
      {message}
    </DirtyFormAlert>,
    container
  )
}

export default CustomPrompt
