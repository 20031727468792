import React from 'react'
import Base from './Base'

export default props => (
  <Base {...props}>
    <rect x='1.9' y='23.9' width='13.2' height='23.1' />
    <rect x='18.4' y='3' width='13.2' height='44' />
    <rect x='34.9' y='14' width='13.2' height='33' />
  </Base>
)
