export const authFilterMethods = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'license-key',
    label: 'License key'
  },
  {
    value: 'user',
    label: 'User'
  }
]

export const trialFilterFields = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'Allow trial',
    label: 'Yes'
  },
  {
    value: "Don't allow trial",
    label: 'No'
  }
]

export const statusFilterFields = [
  {
    value: 'all',
    label: 'All'
  },
  {
    value: 'Active',
    label: 'Active'
  },
  {
    value: 'Disabled',
    label: 'Disabled'
  }
]
