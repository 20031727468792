import React from 'react'
import PropTypes from 'prop-types'
import { InputErrorMessage, IconInfo, Tooltip, Notification } from 'shared/components'
import { defaultDisabledFeatureMessage } from 'shared/constants'
import './styles.scss'

const Checkbox = ({
  disabled,
  description,
  featureAvailable,
  featureEnabled,
  notAvailableMessage,
  notEnabledMessage,
  handleChange,
  error,
  inputId,
  value,
  label,
  ...restProps
}) => {
  const handleInputChange = e => {
    handleChange(e.target.checked, e)
  }

  const handleFeatureDisabledChange = e => {
    e.preventDefault()
    Notification('error', __(defaultDisabledFeatureMessage), __(notEnabledMessage))
  }

  if (!featureAvailable) {
    return (
      <Tooltip
        active={!featureAvailable}
        content={<div className='feature-unavailable-tooltip'>{notAvailableMessage}</div>}
      >
        <div className='Checkbox Checkbox-disabled'>
          <label htmlFor={inputId}>
            <input
              type='checkbox'
              className={error ? 'has-error' : ''}
              id={inputId}
              checked={value}
              disabled
              onChange={() => {}}
              {...restProps}
            />
            {label}
          </label>
        </div>
      </Tooltip>
    )
  }

  return (
    <div className={`Checkbox ${disabled ? 'Checkbox-disabled' : ''}`}>
      <label htmlFor={inputId}>
        <input
          tabIndex={0}
          type='checkbox'
          className={error ? 'has-error' : ''}
          id={inputId}
          checked={value}
          disabled={disabled}
          onChange={featureEnabled ? handleInputChange : handleFeatureDisabledChange}
          {...restProps}
        />
        <>{label}</>
      </label>
      {description && (
        <Tooltip content={description} active>
          <span>
            <IconInfo height='12px' width='12px' />
          </span>
        </Tooltip>
      )}
      <InputErrorMessage text={error} />
    </div>
  )
}

Checkbox.propTypes = {
  disabled: PropTypes.bool,
  description: PropTypes.string,
  error: PropTypes.string,
  inputId: PropTypes.string.isRequired,
  value: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
  handleChange: PropTypes.func,
  featureAvailable: PropTypes.bool,
  notAvailableMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  featureEnabled: PropTypes.bool,
  notEnabledMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
}

Checkbox.defaultProps = {
  disabled: false,
  error: '',
  handleChange: () => {},
  featureAvailable: true,
  notAvailableMessage: '',
  description: '',
  featureEnabled: true,
  notEnabledMessage: '',
  value: false,
  label: ''
}

export default Checkbox
