import React, { useRef, useState } from 'react'
import { Dropdown } from 'shared/components'
import './styles.scss'

const HelpNavigation = () => {
  const popupRef = useRef()
  const [isMenuDisplayed, setMenuDisplay] = useState(false)
  return (
    <div className='HelpNavigation'>
      <div
        className='help-label'
        onClick={() => setMenuDisplay(!isMenuDisplayed)}
        role='presentation'
      >
        <span>{__('Support')}</span>
      </div>
      {isMenuDisplayed && (
        <Dropdown ref={popupRef} close={() => setMenuDisplay(!isMenuDisplayed)}>
          <ul className='links'>
            <li>
              <a
                className='li-content'
                href='https://licensespring.zendesk.com/hc/en-us/requests/new'
                rel='noopener noreferrer'
                target='_blank'
              >
                {__('Submit a Support Ticket')}
              </a>
            </li>
          </ul>
        </Dropdown>
      )}
    </div>
  )
}

export default HelpNavigation
