import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { getDisabledMessage, isFeatureEnabled, isFeatureAllowed, checkTrial } from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import {
  IconAnalytics,
  IconConfig,
  IconCustomers,
  IconHome,
  IconLicense,
  IconOrder,
  IconProduct,
  IconSettings,
  NavLink,
  NavLinkGroup,
  AuthorizedElement
} from 'shared/components'
import version from 'src/version'
import './styles.scss'

const formatPlanTypeString = plan => {
  if (!plan) {
    return ''
  }
  return `${plan.charAt(0).toUpperCase()}${plan.slice(1)}`
}

const getCompanyPlanNotice = company => {
  if (!company) {
    return null
  }

  const planType = get(company, 'plan_type')
  const connectedCompanies = get(company, 'connected_companies') || []
  const isMain = connectedCompanies.length > 0
  const isLinked = get(company, 'main')

  if (!planType) {
    return null
  }
  const trial = checkTrial()

  return (
    <div>
      <div className='plan-type'>{`${__('Plan type')}: ${formatPlanTypeString(planType)} ${
        trial.isTrial ? '(trial)' : ''
      }`}</div>
      {isMain && <div className='acc-type'>{__('Main account')}</div>}
      {isLinked && <div className='acc-type'>{__('Linked account')}</div>}
    </div>
  )
}

const CompanySidebar = ({ activeCompany, company }) => {
  const isSelfHosted = process.env.REACT_APP_SELF_HOSTED === 'true'
  return (
    <>
      <div className='Company-plan'>{getCompanyPlanNotice(company)}</div>
      <div className='CompanySidebar'>
        <NavLink
          activeClassName='NavLink-active'
          className='NavLink'
          exact
          to={`/${activeCompany}`}
        >
          <header>
            <div className='NavLink-icon'>
              <IconHome height='20px' width='20px' color='#777' />
            </div>
            <span className='NavLink-text'>{__('Dashboard')}</span>
          </header>
        </NavLink>
        <NavLinkGroup
          childLinks={[
            <NavLink
              key='/licenses'
              exact
              to={`/${activeCompany}/licenses`}
              activeClassName='NavSubLink'
              permission={userPermissions.licenses_read}
            >
              {__('All Licenses')}
            </NavLink>,
            <NavLink
              disabled={!isFeatureEnabled(platformFeatures.platform_add_license)}
              disabledMessage={getDisabledMessage()}
              key='/licenses/issue-licenses'
              to={`/${activeCompany}/licenses/issue-licenses`}
              activeClassName='NavSubLink'
              permission={userPermissions.orders_write}
            >
              {__('Issue Licenses')}
            </NavLink>,
            <NavLink
              hide={!isFeatureEnabled(platformFeatures.extra_hardware_token)}
              key='/licenses/hardware-keys'
              to={`/${activeCompany}/licenses/hardware-keys`}
              activeClassName='NavSubLink'
              permission={userPermissions.orders_write}
            >
              {__('Hardware keys')}
            </NavLink>
          ]}
          mainLink={
            <NavLink
              activeClassName='NavLink-active'
              className='NavLink'
              to={`/${activeCompany}/licenses`}
            >
              <header>
                <div className='NavLink-icon'>
                  <IconLicense height='20px' width='20px' color='#777' />
                </div>
                <span className='NavLink-text'>{__('Licenses')}</span>
              </header>
            </NavLink>
          }
          path={`/${activeCompany}/licenses`}
        />
        <NavLinkGroup
          childLinks={[
            <NavLink
              key='/orders'
              exact
              to={`/${activeCompany}/orders`}
              activeClassName='NavSubLink'
              permission={userPermissions.orders_read}
            >
              {__('All Orders')}
            </NavLink>,
            <NavLink
              key='/orders/license-managers'
              to={`/${activeCompany}/orders/license-managers`}
              activeClassName='NavSubLink'
              permission={userPermissions.orders_read}
            >
              {__('License managers')}
            </NavLink>
          ]}
          mainLink={
            <NavLink
              activeClassName='NavLink-active'
              className='NavLink'
              to={`/${activeCompany}/orders`}
            >
              <header>
                <div className='NavLink-icon'>
                  <IconOrder height='20px' width='20px' color='#777' />
                </div>
                <span className='NavLink-text'>{__('Orders')}</span>
              </header>
            </NavLink>
          }
          path={`/${activeCompany}/orders`}
        />
        <NavLinkGroup
          childLinks={[
            <NavLink
              key='/customers'
              exact
              to={`/${activeCompany}/customers`}
              activeClassName='NavSubLink'
              permission={userPermissions.customers_read}
            >
              {__('All Customers')}
            </NavLink>,
            <NavLink
              hide={!isFeatureAllowed(platformFeatures.extra_account_based_licensing)}
              key='/customers/accounts'
              to={`/${activeCompany}/customers/accounts`}
              activeClassName='NavSubLink'
              permission={userPermissions.customers_read}
            >
              {__('Customer accounts')}
            </NavLink>
          ]}
          mainLink={
            <NavLink
              activeClassName='NavLink-active'
              className='NavLink'
              to={`/${activeCompany}/customers`}
            >
              <header>
                <div className='NavLink-icon'>
                  <IconCustomers height='20px' width='20px' color='#777' />
                </div>
                <span className='NavLink-text'>{__('Customers')}</span>
              </header>
            </NavLink>
          }
          path={`/${activeCompany}/customers`}
        />
        <NavLinkGroup
          childLinks={[
            <NavLink
              key='/products'
              exact
              to={`/${activeCompany}/products`}
              activeClassName='NavSubLink'
              permission={userPermissions.products_read}
            >
              {__('All Products')}
            </NavLink>
          ]}
          mainLink={
            <NavLink
              activeClassName='NavLink-active'
              className='NavLink'
              to={`/${activeCompany}/products`}
            >
              <header>
                <div className='NavLink-icon'>
                  <IconProduct height='20px' width='20px' color='#777' />
                </div>
                <span className='NavLink-text'>{__('Products')}</span>
              </header>
            </NavLink>
          }
          path={`/${activeCompany}/products`}
        />
        <NavLinkGroup
          childLinks={[
            <NavLink
              permission={userPermissions.analytics_read}
              key='/analytics'
              exact
              to={`/${activeCompany}/analytics`}
              activeClassName='NavSubLink'
            >
              {__('API usage')}
            </NavLink>,
            <NavLink
              permission={userPermissions.analytics_read}
              key='/analytics/products'
              exact
              to={`/${activeCompany}/analytics/products`}
              activeClassName='NavSubLink'
            >
              {__('Products')}
            </NavLink>
          ]}
          mainLink={
            <NavLink
              activeClassName='NavLink-active'
              className='NavLink'
              to={`/${activeCompany}/analytics`}
            >
              <header>
                <div className='NavLink-icon'>
                  <IconAnalytics height='20px' width='20px' color='#777' />
                </div>
                <span className='NavLink-text'>{__('Analytics')}</span>
              </header>
            </NavLink>
          }
          path={`/${activeCompany}/analytics`}
        />
        <NavLinkGroup
          childLinks={[
            <NavLink
              permission={userPermissions.settings_read}
              key='/account/settings'
              exact
              to={`/${activeCompany}/account`}
              activeClassName='NavSubLink'
            >
              {__('Settings')}
            </NavLink>,
            <NavLink
              permission={userPermissions.billing_read}
              key='/account/billing'
              exact
              to={`/${activeCompany}/account/billing`}
              activeClassName='NavSubLink'
              hide={isSelfHosted}
            >
              {__('Billing')}
            </NavLink>,
            <NavLink
              permission={userPermissions.billing_read}
              key='/account/plan-type'
              to={`/${activeCompany}/account/plan-type`}
              activeClassName='NavSubLink'
              hide={isSelfHosted}
            >
              {__('Plan type')}
            </NavLink>,
            <NavLink
              permission={userPermissions.platform_users_read}
              key='/account/users'
              to={`/${activeCompany}/account/users`}
              activeClassName='NavSubLink'
            >
              {__('Users')}
            </NavLink>,
            <NavLink
              permission={userPermissions.platform_users_read}
              key='/account/permissions'
              to={`/${activeCompany}/account/permissions`}
              activeClassName='NavSubLink'
            >
              {__('Permissions')}
            </NavLink>,
            <NavLink
              permission={userPermissions.platform_users_read}
              key='/account/audit-log'
              to={`/${activeCompany}/account/audit-log`}
              activeClassName='NavSubLink'
            >
              {__('Audit log')}
            </NavLink>,
            <NavLink
              permission={userPermissions.settings_read}
              key='/account/user-portal-configuration'
              exact
              to={`/${activeCompany}/account/user-portal-configuration`}
              activeClassName='NavSubLink'
            >
              {__('Portal configuration')}
            </NavLink>,
            <NavLink
              permission={userPermissions.settings_read}
              key='/account/notifications'
              exact
              to={`/${activeCompany}/account/notifications`}
              activeClassName='NavSubLink'
            >
              {__('Notifications')}
            </NavLink>,
            <NavLink
              permission={userPermissions.settings_read}
              key='/account/webhooks'
              to={`/${activeCompany}/account/webhooks`}
              activeClassName='NavSubLink'
            >
              {__('Webhooks')}
            </NavLink>
          ]}
          mainLink={
            <NavLink
              activeClassName='NavLink-active'
              className='NavLink'
              to={`/${activeCompany}/account`}
            >
              <header>
                <div className='NavLink-icon'>
                  <IconSettings height='20px' width='20px' color='#777' />
                </div>
                <span className='NavLink-text'>{__('Settings')}</span>
              </header>
            </NavLink>
          }
          path={`/${activeCompany}/account`}
        />
        <AuthorizedElement level='staff'>
          <NavLinkGroup
            childLinks={[
              <NavLink
                key='/billing-configuration'
                exact
                to={`/${activeCompany}/billing-configuration`}
                activeClassName='NavSubLink'
              >
                {__('Company settings')}
              </NavLink>,
              <NavLink
                key='/billing-configuration/pricing-plans'
                to={`/${activeCompany}/billing-configuration/pricing-plans`}
                activeClassName='NavSubLink'
                hide={isSelfHosted}
              >
                {__('Pricing plans')}
              </NavLink>,
              <NavLink
                key='billing-configuration/coupons'
                exact
                to={`/${activeCompany}/billing-configuration/coupons`}
                activeClassName='NavSubLink'
                hide={isSelfHosted}
              >
                {__('Coupons')}
              </NavLink>,
              <NavLink
                key='billing-configuration/tax-rates'
                exact
                to={`/${activeCompany}/billing-configuration/tax-rates`}
                activeClassName='NavSubLink'
                hide={isSelfHosted}
              >
                {__('Tax rates')}
              </NavLink>
            ]}
            mainLink={
              <NavLink
                activeClassName='NavLink-active'
                className='NavLink'
                to={`/${activeCompany}/billing-configuration`}
              >
                <header>
                  <div className='NavLink-icon CompanyConfig'>
                    <IconConfig
                      width='20'
                      height='20'
                      viewBox='0 0 24 24'
                      fill='none'
                      stroke='#777'
                      strokeWidth='2'
                      strokeLinecap='square'
                      color='#777'
                    />
                  </div>
                  <span className='NavLink-text'>{__('Billing configuration')}</span>
                </header>
              </NavLink>
            }
            path={`/${activeCompany}/billing-configuration`}
          />
        </AuthorizedElement>
      </div>
      <div className='version-cont__sidebar'>
        <a
          href='https://docs.licensespring.com/docs/changelog'
          target='_blank'
          rel='noopener noreferrer'
        >
          <span className='label'>
            {__('Platform version:')}
            &nbsp;
          </span>
          {version}
        </a>
      </div>
    </>
  )
}

CompanySidebar.propTypes = {
  activeCompany: PropTypes.number.isRequired,
  company: PropTypes.object
}

CompanySidebar.defaultProps = {
  company: null
}

export default CompanySidebar
