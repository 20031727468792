import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Button, ConfirmationPopup, DescriptionTable, Notification } from 'shared/components'
import { capitalizeFirstLetter, displayValue, sendErrorReport } from 'shared/helpers'
import { patchCompany, patchCompanyAsDEV, deactivateCompany } from 'src/billing/actions'
import { getUserCompanies } from 'src/account/actions'
import ChangePlanTypeForm from '../ChangePlanTypeForm'
import { getCompanySilent } from '../../../../company/actions'

const PlanTypeContainer = ({ companyDetails, hasSubscription }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const isDEVCompany = get(companyDetails, 'trial_start') === null
  const isPaymentManual = get(companyDetails, 'is_payment_manual')
  const companyID = get(companyDetails, 'id')
  const companyName = get(companyDetails, 'name')

  const [loading, setLoading] = useState(false)
  const [isChangePlanTypeDisplayed, setChangePlanTypeDisplayed] = useState(false)
  const [isSetAsDEVDisplayed, setAsDEVDisplayed] = useState(false)
  const [isSetAsInactiveDisplayed, setAsInactiveDisplayed] = useState(false)
  const [isSetAsInactiveWarningDisplayed, setSetAsInactiveWarningDisplay] = useState(false)
  const [changePaymentModeLoading, setChangePaymentModeLoading] = useState(false)

  const setAsDev = () => {
    if (loading) {
      return false
    }
    setLoading(true)

    patchCompanyAsDEV(companyID)
      .then(() => {
        setLoading(false)
        setAsDEVDisplayed(false)
        dispatch(getCompanySilent(companyID))
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Settings company as dev has failed')
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
    return true
  }

  const handleManualPaymentChange = () => {
    if (changePaymentModeLoading) {
      return false
    }
    setChangePaymentModeLoading(true)

    const data = {
      is_payment_manual: !isPaymentManual
    }

    patchCompany(companyID, data)
      .then(() => {
        setLoading(false)
        setChangePaymentModeLoading(false)
        dispatch(getCompanySilent(companyID))
        Notification('success', __('Changes saved successfully'))
      })
      .catch(err => {
        setChangePaymentModeLoading(false)
        sendErrorReport(err, 'Changing company manual payment state has failed')
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
    return true
  }

  const setAsInactive = () => {
    setLoading(true)

    deactivateCompany(companyID)
      .then(() => {
        history.push('/')
        dispatch(getUserCompanies())
        Notification(
          'success',
          __('Changes saved successfully'),
          __(`${companyName} ${__('has been deactivated')}`)
        )
      })
      .catch(err => {
        setLoading(false)
        sendErrorReport(err, 'Deactivating company has failed')
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
  }

  return (
    <div className='PlanTypeContainer'>
      <div className='section-header'>{__('Plan type')}</div>
      <div className='section-body'>
        <div className='list-header'>
          <div>
            <Button size='sm' theme='info' onClick={() => setChangePlanTypeDisplayed(true)}>
              {__('Change plan type')}
            </Button>
          </div>
          <div>
            <Button
              size='sm'
              theme='default'
              onClick={handleManualPaymentChange}
              loading={changePaymentModeLoading}
            >
              {isPaymentManual ? __('Set to use credit card payment') : __('Enable manual payment')}
            </Button>
            <Button
              size='sm'
              theme='warning'
              onClick={() => setAsDEVDisplayed(true)}
              disabled={isDEVCompany}
            >
              {__('Set as DEV company')}
            </Button>
            <Button size='sm' theme='error' onClick={() => setAsInactiveDisplayed(true)}>
              {__('Deactivate company')}
            </Button>
          </div>
        </div>
        <DescriptionTable
          details={[
            {
              label: __('Plan type'),
              value: displayValue(capitalizeFirstLetter(get(companyDetails, 'plan_type')))
            },
            {
              label: __('Is subscription created'),
              value: hasSubscription ? __('Yes') : __('No')
            }
          ]}
        />
      </div>
      {isChangePlanTypeDisplayed && (
        <ChangePlanTypeForm
          closeCb={() => setChangePlanTypeDisplayed(false)}
          companyDetails={companyDetails}
          hasSubscription={hasSubscription}
        />
      )}
      {isSetAsDEVDisplayed && (
        <ConfirmationPopup
          closeCb={() => setAsDEVDisplayed(false)}
          confirmCb={setAsDev}
          confirmText={__('Confirm')}
          disabled={loading}
          theme='warning'
          title={__('Are you sure you want to set this company as a development account?')}
        >
          {__(
            'This will remove the trial days and trial start informations from this company account.'
          )}
        </ConfirmationPopup>
      )}
      {isSetAsInactiveDisplayed && (
        <ConfirmationPopup
          closeCb={() => setAsInactiveDisplayed(false)}
          confirmCb={() => setSetAsInactiveWarningDisplay(true)}
          confirmText={__('Confirm')}
          disabled={loading}
          theme='error'
          title={__('Are you sure you want to deactivate this company?')}
        />
      )}
      {isSetAsInactiveWarningDisplayed && (
        <ConfirmationPopup
          closeCb={() => {
            setSetAsInactiveWarningDisplay(false)
            setAsInactiveDisplayed(false)
          }}
          confirmCb={setAsInactive}
          title={__('All subscriptions will be canceled and company will be set as inactive')}
          confirmText={__('Deactivate')}
          theme='error'
          warning
          disabled={loading}
        >
          {__('Please, confirm that you really want to deactivate this company.')}
        </ConfirmationPopup>
      )}
    </div>
  )
}

PlanTypeContainer.propTypes = {
  companyDetails: PropTypes.object.isRequired,
  hasSubscription: PropTypes.bool.isRequired
}

export default PlanTypeContainer
