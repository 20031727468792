export const filterLicenseTypes = (overideList = [], options = []) => {
  if (!overideList.length) {
    return options
  }
  const filtered = []
  options.forEach(o => {
    if (overideList.includes(o.value)) {
      filtered.push(o)
    }
  })
  return filtered
}
