import React from 'react'
import PropTypes from 'prop-types'
import { InputErrorMessage, Spinner } from 'shared/components'
import './styles.scss'

const NumberInput = ({
  disabled,
  error,
  loading,
  min,
  max,
  showErrorMsg,
  handleChange,
  ...rest
}) => (
  <div className='NumberInput'>
    <input
      className={error ? 'has-error' : ''}
      type='number'
      min={min}
      max={max}
      onChange={e => handleChange(e.target.value, e)}
      disabled={disabled}
      {...rest}
    />
    {loading && <Spinner />}
    {showErrorMsg && <InputErrorMessage text={error} />}
  </div>
)

NumberInput.propTypes = {
  disabled: PropTypes.bool,
  error: PropTypes.string,
  handleChange: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  min: PropTypes.string.isRequired,
  max: PropTypes.string.isRequired,
  showErrorMsg: PropTypes.bool
}

NumberInput.defaultProps = {
  disabled: false,
  error: '',
  loading: false,
  showErrorMsg: true
}

export default NumberInput
