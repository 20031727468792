import React from 'react'
import PropTypes from 'prop-types'
import { DateInput, InputErrorMessage, Label, Selector, TextInput } from 'shared/components'
import './styles.scss'

const InstallationFileOptions = ({
  environmentOptions,
  productEnvironment,
  productEnvironmentError,
  handleProductEnvironmentChange,
  productVersion,
  productVersionError,
  handleProductVersionChange,
  productReleaseDate,
  productReleaseDateError,
  handleProductReleaseDateChange,
  productLinkToBinary,
  productLinkToBinaryError,
  handleProductLinkToBinaryChange,
  releaseNotesLink,
  releaseNotesLinkError,
  handleRelaseNotesLinkChange,
  eulaLink,
  eulaLinkError,
  handleEulaLinkChange,
  channel,
  handleChannelChange
}) => {
  return (
    <div className='InstallationFileOptions'>
      <div className='form-row'>
        <div className='form-column'>
          <Label text={__('Environment')} inputId='product-env' />
          <Selector
            options={environmentOptions}
            value={productEnvironment}
            handleChange={handleProductEnvironmentChange}
          />
          <InputErrorMessage text={productEnvironmentError} />
        </div>
        <div className='form-column'>
          <Label text={__('Initial Version')} inputId='product-version' />
          <TextInput
            id='product-version'
            value={productVersion}
            error={productVersionError}
            handleChange={handleProductVersionChange}
            placeholder={__('Eg. 1.0.717')}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-column'>
          <Label text={__('Release Date')} inputId='release' />
          <DateInput
            id='release'
            value={productReleaseDate}
            error={productReleaseDateError}
            handleChange={handleProductReleaseDateChange}
          />
        </div>
        <div className='form-column'>
          <Label text={__('Link to binary')} inputId='product-link' />
          <TextInput
            id='product-link'
            value={productLinkToBinary}
            error={productLinkToBinaryError}
            handleChange={handleProductLinkToBinaryChange}
            placeholder={__('Eg. https://someurl/file.zip')}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-column'>
          <Label text={__('Link to release notes')} inputId='release-notes-link' />
          <TextInput
            id='release-notes-link'
            value={releaseNotesLink}
            error={releaseNotesLinkError}
            handleChange={handleRelaseNotesLinkChange}
            placeholder={__('Eg. https://someurl/file.zip')}
          />
        </div>
        <div className='form-column'>
          <Label text={__('Link to EULA')} inputId='eula-link' />
          <TextInput
            id='eula-link'
            value={eulaLink}
            error={eulaLinkError}
            handleChange={handleEulaLinkChange}
            placeholder={__('Eg. https://someurl/file.zip')}
          />
        </div>
      </div>
      <div className='form-row'>
        <div className='form-column'>
          <Label text={__('Channel')} inputId='channel' />
          <TextInput
            id='channel'
            value={channel}
            handleChange={handleChannelChange}
            placeholder={__('Eg. prod, dev or similar')}
          />
        </div>
      </div>
    </div>
  )
}

InstallationFileOptions.propTypes = {
  environmentOptions: PropTypes.array.isRequired,
  productEnvironment: PropTypes.string,
  productEnvironmentError: PropTypes.string,
  handleProductEnvironmentChange: PropTypes.func.isRequired,
  productVersion: PropTypes.string,
  productVersionError: PropTypes.string,
  handleProductVersionChange: PropTypes.func.isRequired,
  productReleaseDate: PropTypes.string,
  productReleaseDateError: PropTypes.string,
  handleProductReleaseDateChange: PropTypes.func.isRequired,
  productLinkToBinary: PropTypes.string,
  productLinkToBinaryError: PropTypes.string,
  handleProductLinkToBinaryChange: PropTypes.func.isRequired,
  releaseNotesLink: PropTypes.string,
  releaseNotesLinkError: PropTypes.string,
  handleRelaseNotesLinkChange: PropTypes.func.isRequired,
  eulaLink: PropTypes.string,
  eulaLinkError: PropTypes.string,
  handleEulaLinkChange: PropTypes.func.isRequired,
  channel: PropTypes.string,
  handleChannelChange: PropTypes.func.isRequired
}

InstallationFileOptions.defaultProps = {
  productEnvironment: '',
  productEnvironmentError: '',
  productVersion: '',
  productVersionError: '',
  productReleaseDate: '',
  productReleaseDateError: '',
  productLinkToBinary: '',
  productLinkToBinaryError: '',
  releaseNotesLink: '',
  releaseNotesLinkError: '',
  eulaLink: '',
  eulaLinkError: '',
  channel: ''
}

export default InstallationFileOptions
