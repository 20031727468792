import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { DateInput, DirtyFormAlert, Label, Modal, Notification, RadioBtn } from 'shared/components'
import { sendErrorReport, getErrMsg } from 'shared/helpers'
import { rescheduleUpdate } from 'src/billing/actions'
import './styles.scss'

const RescheduleUpdateForm = ({ closeCb, confirmCb, scheduledUpdate, companyID }) => {
  const scheduleBillingDate = scheduledUpdate
    ? moment(get(scheduledUpdate, 'start_date')).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD')

  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(false)
  const [billingDate, setBillingDate] = useState(scheduleBillingDate)
  const [billingDateError, setBillingDateError] = useState('')
  const [selectedUpdateDateType, setSelectedUpdateDateType] = useState('end-of-current-period')

  // eslint-disable-next-line no-unused-vars
  const validateDate = () => {
    if (selectedUpdateDateType === 'end-of-current-period') {
      return true
    }
    if (!billingDate) {
      setBillingDateError(__('This field is required'))
      return false
    }
    if (moment(billingDate).isBefore(moment().subtract(1, 'days'))) {
      setBillingDateError(__('Only future dates can be selected'))
      return false
    }
    return true
  }

  const handleBillingDateSelect = val => {
    setDirty(true)
    if (moment(val).isBefore(moment().subtract(1, 'days'))) {
      setBillingDateError(__('Only future dates can be selected'))
    } else {
      setBillingDate(moment(val).format('YYYY-MM-DD'))
      setBillingDateError('')
    }
  }

  const isFormValid = () => {
    const isDateValid = validateDate()
    return isDateValid
  }

  const handleSubmit = () => {
    const isValid = isFormValid()

    if (!isValid || loading) {
      return false
    }

    setLoading(true)
    const data = {
      coupon: get(scheduledUpdate, 'coupon') || undefined,
      tax_rate: get(scheduledUpdate, 'tax_rate') || undefined,
      plan: get(scheduledUpdate, 'plan'),
      start_date:
        selectedUpdateDateType === 'end-of-current-period'
          ? undefined
          : moment(billingDate).format('YYYY-MM-DD')
    }

    rescheduleUpdate(companyID, data)
      .then(() => {
        setLoading(false)
        Notification('success', __('Scheduled update rescheduled successfully'))
        confirmCb()
      })
      .catch(err => {
        const errMsg = getErrMsg(err, 'error')
        sendErrorReport(err, 'Cannot reschedule scheduled update', data)
        setLoading(false)
        Notification('error', __('Error occured'), errMsg)
      })
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setIsDirtyFormAlertDisplayed(true)
  }

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={__('Reschedule subscription update')}
      disabled={loading}
      size='sm'
    >
      <div className='RescheduleUpdateForm'>
        <div className='scheduled-date-container'>
          <Label text={__('Scheduled update date:')} inputId='update-date' />
          <div>
            <RadioBtn
              name='update-date-select'
              inputId='end-of-current-period'
              label={`${__('End of the current period')} ${billingDate}`}
              value='end-of-current-period'
              checked={selectedUpdateDateType === 'end-of-current-period'}
              handleChange={val => {
                setDirty(true)
                setBillingDate(scheduleBillingDate)
                setSelectedUpdateDateType(val)
              }}
            />
          </div>
          <div>
            <RadioBtn
              name='update-date-select'
              inputId='custom-date'
              label={__('Custom date:')}
              value='custom-date'
              checked={selectedUpdateDateType === 'custom-date'}
              handleChange={val => {
                setDirty(true)
                setBillingDate(scheduleBillingDate)
                setSelectedUpdateDateType(val)
              }}
            />
          </div>
          <div>
            <DateInput
              handleChange={val => handleBillingDateSelect(val)}
              value={billingDate}
              error={billingDateError}
              id='update-date'
              disabled={selectedUpdateDateType === 'end-of-current-period'}
            />
          </div>
        </div>
      </div>
      {isDirtyFormAlertDisplayed && (
        <DirtyFormAlert
          dirty={dirty}
          closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
          closeCb={closeCb}
        />
      )}
    </Modal>
  )
}

RescheduleUpdateForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  confirmCb: PropTypes.func.isRequired,
  scheduledUpdate: PropTypes.object.isRequired,
  companyID: PropTypes.number.isRequired
}

export default RescheduleUpdateForm
