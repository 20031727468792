import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { Button, DescriptionTable, Notice } from 'shared/components'
import { checkTrial, displayValue, formatDate } from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import ChangeTrialForm from '../ChangeTrialForm'

const EnterpriseTrial = ({ companyDetails }) => {
  const [isTrialFormDisplayed, setIsTrialFormDisplayed] = useState(false)

  const trial = checkTrial()
  const isTrialExpired = get(trial, 'trial_expired')

  if (!trial.isTrial) {
    return null
  }

  return (
    <div className='EnterpriseTrial'>
      <div className='section-header'>{__('Trial')}</div>
      <div className='section-body'>
        <div>
          {isTrialExpired && (
            <Notice theme='error' size='sm'>
              {__('Trial period has expired.')}
            </Notice>
          )}
          <div className='list-header'>
            <div>
              <Button size='sm' theme='info' onClick={() => setIsTrialFormDisplayed(true)}>
                {isTrialExpired ? __('Add new trial period') : __('Change trial days')}
              </Button>
            </div>
          </div>
          <DescriptionTable
            details={[
              {
                label: __('Is trial active'),
                value: get(trial, 'isTrial') ? __('Yes') : __('No')
              },
              {
                label: __('Trial start'),
                value: formatDate(get(companyDetails, 'trial_start'), defaultDateFormat)
              },
              {
                label: __('Trial days'),
                value: displayValue(get(companyDetails, 'trial_days'))
              },
              {
                label: __('Trial end date'),
                value: moment(trial.trialEnd).format(defaultDateFormat)
              },
              {
                label: __('Remaining trial days'),
                value: isTrialExpired ? 0 : displayValue(get(trial, 'remaining_days'))
              }
            ]}
          />
        </div>
      </div>
      {isTrialFormDisplayed && (
        <ChangeTrialForm
          companyId={get(companyDetails, 'id')}
          extend={isTrialExpired}
          trialDays={get(companyDetails, 'trial_days')}
          passedTrialDays={get(trial, 'passed_trial_days')}
          trialStart={get(companyDetails, 'trial_start')}
          closeCb={() => setIsTrialFormDisplayed(false)}
          title={isTrialExpired ? __('Add new trial period') : __('Change trial days')}
        />
      )}
    </div>
  )
}

EnterpriseTrial.propTypes = {
  companyDetails: PropTypes.object.isRequired
}

export default EnterpriseTrial
