import React from 'react'
import { platformFeatures } from 'shared/constants'
import { isFeatureEnabled } from 'shared/helpers'
import { OfflinePortalData } from '../components'

const OfflinePortalPreferences = () => {
  const isOfflinePortalFeatureEnabled = isFeatureEnabled(platformFeatures.extra_offline_portal)
  return (
    <div className='OfflinePortalPreferences'>
      {isOfflinePortalFeatureEnabled ? (
        <OfflinePortalData />
      ) : (
        <div>
          {__('Offline portal feature is not enabled, upgrade your plan to access this feature.')}
        </div>
      )}
    </div>
  )
}

export default OfflinePortalPreferences
