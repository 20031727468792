import React, { useEffect, useCallback, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import {
  ConfirmationPopup,
  Page,
  Notification,
  PermissionMissingNotificationTitle,
  NotFound,
  ContentLoader,
  DescriptionTable,
  IconCheckmark,
  IconDisabled,
  IconResendMail,
  Button,
  List,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  JsonView,
  EventStatus,
  Forbidden
} from 'shared/components'
import { defaultDateTimeFormat, userPermissions, platformFeatures } from 'shared/constants'
import {
  sendErrorReport,
  displayValue,
  formatDate,
  checkUserPermission,
  isFeatureEnabled,
  isFeatureAvailable
} from 'shared/helpers'
import {
  getWebhookEndpoint,
  getWebhookEndpointHistory,
  updateWebhookEndpoint,
  deleteWebhookEndpoint,
  resendWebhookHistoryItem
} from 'src/account/actions'
import EndpointForm from '../EndpointForm'
import RolloverKeyForm from '../RolloverKeyForm'
import './styles.scss'

const WebhookPage = () => {
  const { webhookId } = useParams()
  const history = useHistory()
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const canManageSettings = checkUserPermission(userPermissions.settings_write)
  const areWebhooksEnabled =
    isFeatureEnabled(platformFeatures.extra_webhooks) &&
    isFeatureAvailable(platformFeatures.extra_webhooks)

  const [isLoading, setLoading] = useState(true)
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [webhook, setWebhook] = useState(null)
  const [isWebhookEditDisplayed, setWebhookEditDisplay] = useState(false)
  const [rolloverKey, setRolloverKey] = useState(null)
  const [isHistoryLoading, setHistoryLoading] = useState(true)

  // table state
  const [webhookHistory, setWebhookHistory] = useState([])
  const [historyCount, setHistoryCount] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(20)
  const [expandedTableRow, setExpandedTableRow] = useState({})

  const [showResendPopup, setResendPopupDisplay] = useState(false)
  const [eventToResend, setEventToResend] = useState(null)
  const [webhookEventResending, setWebhookEventResending] = useState(false)

  // license delete
  const [isDeleteWebhookIntentDisplayed, setDeleteWebhookIntentDisplay] = useState(false)
  const [isDeletewebhookWarningDisplayed, setDeleteWebhookWarningDisplay] = useState(false)
  const [webhookDeleteLoading, setWebhookDeleteLoading] = useState(false)

  const getWebhook = useCallback(() => {
    getWebhookEndpoint(webhookId, companyID)
      .then(res => {
        const webhookData = get(res, 'data')
        setWebhook(webhookData)
        setLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot load webhook details')
        setLoading(false)
      })
  }, [companyID, webhookId])

  const getWebhookHistory = useCallback(
    (tablePage = 0, rows = rowsPerPage) => {
      setHistoryLoading(true)
      getWebhookEndpointHistory(webhookId, companyID, tablePage, rows)
        .then(res => {
          const historyData = get(res, 'data.results') || []
          setHistoryCount(get(res, 'data.count'))
          setWebhookHistory(historyData)
          setHistoryLoading(false)
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot load webhook history')
          setHistoryLoading(false)
        })
    },
    [companyID, webhookId]
  )

  const handleWebhookDelete = () => {
    setWebhookDeleteLoading(true)

    deleteWebhookEndpoint(webhookId, companyID)
      .then(() => {
        history.push(`/${companyID}/account/webhooks`)
        Notification('success', __('Webhook endpoint succesfully deleted'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot delete webhook')
        setWebhookDeleteLoading(false)
        Notification('error', __('Error occured'), __('Your changes were not saved'))
      })
  }

  const patchEnabledStatus = () => {
    const currentStatus = get(webhook, 'is_enabled')
    const data = { is_enabled: !currentStatus }
    setConfirmationLoading(true)

    updateWebhookEndpoint(webhookId, companyID, data)
      .then(() => {
        setConfirmationLoading(false)
        getWebhook()
        Notification(
          'success',
          __('Changes saved successfully'),
          !currentStatus ? __('Webhook endpoint enabled') : __('Webhook endpoint disabled')
        )
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot change is_enable webhook', data)
        setConfirmationLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  useEffect(() => {
    getWebhook()
    getWebhookHistory()
  }, [getWebhook, getWebhookHistory])

  const handlePageChange = newPage => {
    setPage(newPage)
    getWebhookHistory(newPage, rowsPerPage)
  }

  const handlePageSizeChange = (newPageSize, newPage) => {
    setPage(newPage)
    setRowsPerPage(newPageSize)
    getWebhookHistory(newPage, newPageSize)
  }

  const handleManageWebhookClick = (cb, data = true) => {
    if (!canManageSettings) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.settings_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(data)
    return true
  }

  const handleEventResend = () => {
    setWebhookEventResending(true)

    const eventID = get(eventToResend, 'id')

    resendWebhookHistoryItem(eventID, companyID)
      .then(() => {
        Notification('success', __('Webhook event was resent successfully'))
        setResendPopupDisplay(false)
        setEventToResend(null)
        setWebhookEventResending(false)
        getWebhookHistory(page, rowsPerPage)
      })
      .catch(err => {
        sendErrorReport(err, 'Resending webhook event failed')
        setWebhookEventResending(false)
        Notification('error', __('Error occured'), __('Webhook event was not resent'))
      })
    return true
  }

  if (!areWebhooksEnabled) {
    return (
      <Page title={__('Webhooks')}>
        <div className='WebhookPage'>
          <Forbidden
            text={__('This feature is not available in your current plan type')}
            description={__('Contact sales if you wish to change this policy.')}
          />
        </div>
      </Page>
    )
  }

  if (isLoading) {
    return (
      <Page>
        <ContentLoader text={__('Getting endpoint details')} />
      </Page>
    )
  }
  if (!webhook) {
    return (
      <Page>
        <NotFound />
      </Page>
    )
  }

  const isEnabled = get(webhook, 'is_enabled')
  const endpointEvents = get(webhook, 'events') || []
  const endpointKeys = get(webhook, 'valid_keys') || []

  // TODO extract to separate components
  // TODO webhook history pagination manual
  return (
    <Page title={get(webhook, 'url')}>
      <div className='WebhookPage'>
        <div className='list-header'>
          <div>
            <Button
              onClick={() => handleManageWebhookClick(setWebhookEditDisplay)}
              theme='info'
              size='sm'
            >
              {__('Edit')}
            </Button>
          </div>
          <div>
            <Button
              onClick={() => handleManageWebhookClick(patchEnabledStatus)}
              theme={isEnabled ? 'error' : 'success'}
              loading={confirmationLoading}
              disabled={confirmationLoading}
              size='sm'
            >
              {isEnabled ? __('Disable') : __('Enable')}
            </Button>
            <Button
              onClick={() => handleManageWebhookClick(setDeleteWebhookIntentDisplay)}
              loading={webhookDeleteLoading}
              disabled={webhookDeleteLoading}
              theme='default'
              size='sm'
            >
              {__('Delete')}
            </Button>
          </div>
        </div>
        <div className='WebhookPage-details'>
          <DescriptionTable
            details={[
              { label: __('Endpoint'), value: displayValue(get(webhook, 'url')) },
              {
                label: __('Enabled'),
                value: isEnabled ? (
                  <IconCheckmark color='#10ac84' height='14px' />
                ) : (
                  <IconDisabled color='#aaa' height='14px' />
                )
              },
              { label: __('Created on'), value: formatDate(get(webhook, 'created_at')) },
              { label: __('Last updated'), value: formatDate(get(webhook, 'updated_at')) },
              { label: __('Description'), value: displayValue(get(webhook, 'description')) }
            ]}
          />
        </div>
        <Tabs>
          <TabsHeader>
            <Tab>{__('History')}</Tab>
            <Tab>{__('Events')}</Tab>
            <Tab>{__('Signing key')}</Tab>
          </TabsHeader>
          <TabContent>
            <List
              columns={[
                {
                  expander: true,
                  Header: __('Details'),
                  headerClassName: 'text-center',
                  width: 80,
                  style: {
                    fontSize: 25,
                    padding: '0',
                    textAlign: 'center',
                    userSelect: 'none'
                  }
                },
                {
                  accessor: 'data.event',
                  Header: __('Event')
                },
                {
                  accessor: 'ts',
                  Header: __('Date'),
                  Cell: cellInfo => formatDate(cellInfo.value, defaultDateTimeFormat)
                },
                {
                  accessor: 'response_code',
                  Header: __('Response Code'),
                  className: 'text-center',
                  width: 140,
                  Cell: rowData => {
                    const code = get(rowData, 'value')
                    const isError = code >= 400 && code < 599
                    const isSuccess = code >= 200 && code < 299
                    let status = code

                    if (isError) {
                      status = 'failed'
                    }
                    if (isSuccess) {
                      status = 'success'
                    }

                    return <EventStatus status={status} text={code.toString()} />
                  }
                },
                {
                  Header: __('Resend'),
                  headerClassName: 'text-center',
                  className: 'text-center',
                  id: 'edit',
                  width: 120,
                  sortable: false,
                  Cell: rowData => (
                    <Button
                      className='table-button'
                      onClick={() => {
                        const row = get(rowData, 'original')
                        setEventToResend(row)
                        setResendPopupDisplay(true)
                      }}
                      type='button'
                    >
                      <IconResendMail height={20} width={20} />
                    </Button>
                  )
                }
              ]}
              page={page}
              pages={Math.ceil(historyCount / rowsPerPage)}
              loading={isHistoryLoading}
              manual
              minRows={get(webhookHistory, 'length') || 10}
              showPagination={historyCount > 5}
              onPageChange={handlePageChange}
              showPageSizeOptions
              pageSize={rowsPerPage}
              onPageSizeChange={(pageSize, pageIndex) => handlePageSizeChange(pageSize, pageIndex)}
              data={webhookHistory}
              onExpandedChange={expanded => setExpandedTableRow(expanded)}
              expanded={expandedTableRow}
              SubComponent={row => (
                <div className='SubComponent'>
                  <div className='SubComponent-webhook-history'>
                    <div className='SubComponent-webhook-history-heading'>{__('Data')}</div>
                    <JsonView value={get(row, 'original.data.data')} name='data' />
                  </div>
                  <div className='SubComponent-webhook-history'>
                    <div className='SubComponent-webhook-history-heading'>
                      {__('Response body')}
                    </div>
                    <JsonView value={get(row, 'original.response_body')} name='response_body' />
                  </div>
                </div>
              )}
            />
          </TabContent>
          <TabContent>
            <div className='WebhookPage-events'>
              <div className='subheading'>{__('Selected events')}</div>
              <ul className='events-list'>
                {endpointEvents.map(event => (
                  <li className='event-item' key={event.id}>
                    {event.code}
                  </li>
                ))}
              </ul>
            </div>
          </TabContent>
          <TabContent>
            <div className='WebhookPage-keys'>
              <Button
                onClick={() => handleManageWebhookClick(setRolloverKey)}
                theme='default'
                size='sm'
              >
                {__('Key Rollover')}
              </Button>
              <List
                columns={[
                  {
                    accessor: 'key',
                    Header: __('Key')
                  },
                  {
                    accessor: 'valid_until',
                    Header: __('Valid until'),
                    Cell: cellData => formatDate(cellData.value, defaultDateTimeFormat)
                  }
                ]}
                data={endpointKeys}
                loading={isLoading}
                showPagination={false}
                minRows={1}
              />
            </div>
          </TabContent>
        </Tabs>
        {isWebhookEditDisplayed && (
          <EndpointForm
            closeCb={() => {
              setWebhookEditDisplay(false)
            }}
            confirmCb={() => {
              setWebhookEditDisplay(false)
              getWebhook()
            }}
            endpoint={webhook}
          />
        )}
        {rolloverKey && (
          <RolloverKeyForm
            closeCb={() => {
              setRolloverKey(null)
            }}
            confirmCb={() => {
              setRolloverKey(null)
              getWebhook()
            }}
            endpointID={Number(webhookId)}
            signingKeys={endpointKeys}
          />
        )}
        {isDeleteWebhookIntentDisplayed && (
          <ConfirmationPopup
            closeCb={() => setDeleteWebhookIntentDisplay(false)}
            confirmCb={() => setDeleteWebhookWarningDisplay(true)}
            title={__('Are you sure you want to delete this webhook endpoint?')}
            confirmText={__('Delete')}
            theme='error'
            disabled={webhookDeleteLoading}
          >
            {__('This action cannot be undone!')}
          </ConfirmationPopup>
        )}
        {isDeletewebhookWarningDisplayed && (
          <ConfirmationPopup
            closeCb={() => {
              setDeleteWebhookWarningDisplay(false)
              setDeleteWebhookIntentDisplay(false)
            }}
            confirmCb={handleWebhookDelete}
            title={__('Please, confirm that you really want to delete this webhook endpoint.')}
            confirmText={__('Delete')}
            theme='error'
            warning
            disabled={webhookDeleteLoading}
          />
        )}
        {showResendPopup && (
          <ConfirmationPopup
            closeCb={() => {
              setResendPopupDisplay(false)
              setEventToResend(null)
            }}
            confirmCb={handleEventResend}
            title={__('Are you sure you want to resend this webhook event?')}
            confirmText={__('Resend')}
            theme='success'
            disabled={webhookEventResending}
          />
        )}
      </div>
    </Page>
  )
}

export default WebhookPage
