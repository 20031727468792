import React, { useState, useEffect } from 'react'
import { get } from 'lodash'
import { Button, IconSearch, IconReset, List, TextInput } from 'shared/components'
import { displayValue, filterCompanies, formatDate } from 'shared/helpers'
import { getInactiveCompanies } from 'src/company/actions'
import ReactivateCompanyForm from '../ReactivateCompanyForm'

const InactiveCompaniesList = () => {
  const [companies, setCompanies] = useState([])
  const [loading, setLoading] = useState(true)
  const [query, setQuery] = useState('')
  const [showForm, setFormDisplay] = useState(false)
  const [companyToReactivate, setCompanyToReactivate] = useState(null)

  const getCompaniesList = () => {
    getInactiveCompanies()
      .then(res => {
        setCompanies(get(res, 'data.results') || [])
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    getCompaniesList()
  }, [])

  const filteredCompanies = filterCompanies(companies, query)
  const companiesNum = companies.length

  return (
    <div className='InactiveCompaniesList'>
      <div className='list-header'>
        <div>
          <form onSubmit={e => e.preventDefault()}>
            <TextInput
              handleChange={val => setQuery(val)}
              value={query}
              placeholder={__('Search companies')}
              disabled={loading}
            />
            {query && (
              <button type='button' onClick={() => setQuery('')} disabled={loading}>
                &times;
              </button>
            )}
            <Button type='submit' theme='info' disabled={loading}>
              <IconSearch fill='#fff' />
            </Button>
          </form>
        </div>
      </div>
      <List
        data={filteredCompanies}
        minRows={companiesNum > 10 ? 10 : 1}
        showPageJump={false}
        defaultPageSize={10}
        loading={loading}
        clickable={false}
        columns={[
          {
            accessor: 'name',
            Header: __('Company name'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'plan_type',
            Header: __('Plan type'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            accessor: 'last_login',
            Header: __('Last login'),
            Cell: cellInfo => formatDate(cellInfo.value)
          },
          {
            accessor: 'product_count',
            Header: __('Products'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100
          },
          {
            accessor: 'license_count',
            Header: __('Licenses'),
            Cell: cellInfo => displayValue(cellInfo.value),
            maxWidth: 100
          },
          {
            accessor: 'last_license',
            Header: __('Last license created'),
            Cell: cellInfo => formatDate(cellInfo.value)
          },
          {
            accessor: 'id',
            headerClassName: 'text-center',
            Header: __('Reactivate'),
            Cell: rowData => (
              <Button
                className='table-button'
                onClick={() => {
                  setCompanyToReactivate(rowData.original)
                  setFormDisplay(true)
                }}
                type='button'
              >
                <IconReset
                  fill='none'
                  stroke='#000'
                  strokeWidth='2'
                  width='18'
                  height='18'
                  viewBox='0 0 24 24'
                />
              </Button>
            )
          }
        ]}
      />
      {showForm && (
        <ReactivateCompanyForm
          closeCb={() => {
            setFormDisplay(false)
            setCompanyToReactivate(null)
          }}
          company={companyToReactivate}
        />
      )}
    </div>
  )
}

export default InactiveCompaniesList
