import React from 'react'
import Proptypes from 'prop-types'
import moment from 'moment'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import { DateRangePicker, Subtitle } from 'shared/components'
import { APIdateFormat } from 'shared/constants'
import { changeDateRange } from 'src/analytics/actions'
import './styles.scss'

const AnalyticsDatePicker = ({ title }) => {
  const defaultFrom = moment().startOf('month')
  const defaultTo = moment().endOf('month')

  const dispatch = useDispatch()
  const from = useSelector(state => get(state, 'analytics.dateRange.date_from'))
  const to = useSelector(state => get(state, 'analytics.dateRange.date_to'))

  const dateFrom = from ? moment(from) : defaultFrom
  const dateTo = to ? moment(to) : defaultTo

  const handleDateChange = dates => {
    dispatch(
      changeDateRange({
        date_from: dates[0].format(APIdateFormat),
        date_to: dates[1].format(APIdateFormat)
      })
    )
  }

  return (
    <div className='AnalyticsDatePicker'>
      <Subtitle text={title} />
      <DateRangePicker
        handleChange={handleDateChange}
        initialStartDate={dateFrom}
        initialEndDate={dateTo}
        minimumNights={0}
      />
    </div>
  )
}

AnalyticsDatePicker.propTypes = {
  title: Proptypes.string
}

AnalyticsDatePicker.defaultProps = {
  title: ''
}

export default AnalyticsDatePicker
