import React from 'react'
import PropTypes from 'prop-types'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { InputErrorMessage } from 'shared/components'
import './styles.scss'

const PhoneNumberInput = ({ disabled, error, handleChange, showErrorMsg, placeholder, value }) => (
  <div className='PhoneNumberInput'>
    <div className={`PhoneNumberInput-main ${error ? 'has-error' : ''}`}>
      <PhoneInput
        placeholder={placeholder}
        disabled={disabled}
        value={value}
        onChange={val => handleChange(val)}
      />
    </div>
    {showErrorMsg && <InputErrorMessage text={error} />}
  </div>
)

PhoneNumberInput.propTypes = {
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  showErrorMsg: PropTypes.bool
}

PhoneNumberInput.defaultProps = {
  disabled: false,
  error: '',
  placeholder: '',
  value: '',
  showErrorMsg: true
}

export default PhoneNumberInput
