import React, { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { get } from 'lodash'
import { platformFeatures } from 'shared/constants'
import { isFeatureEnabled } from 'shared/helpers'
import { Page, Tab, Tabs, TabContent, TabsHeader, Notice } from 'shared/components'
import PlatformAuditLog from './PlatformAuditLog'

const AuditLog = () => {
  const location = useLocation()
  const history = useHistory()
  const [selectedTab, setSelectedTab] = useState(0)
  const isAuditLogFeatureEnabled = isFeatureEnabled(platformFeatures.extra_audit_log)

  useEffect(() => {
    const queryParams = get(location, 'search')
    if (!queryParams) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=0') >= 0) {
      setSelectedTab(0)
    }
    if (queryParams.indexOf('st=1') >= 0) {
      setSelectedTab(1)
    }
  }, [location])

  return (
    <Page title={__('Audit log')}>
      {isAuditLogFeatureEnabled ? (
        <Tabs
          defaultFocus
          selectedIndex={selectedTab}
          onSelect={tabIndex => {
            history.push({
              search: `?st=${tabIndex}`
            })
            setSelectedTab(tabIndex)
          }}
        >
          <TabsHeader>
            <Tab>{__('Platform')}</Tab>
          </TabsHeader>
          <TabContent>
            <PlatformAuditLog showDatePicker />
          </TabContent>
        </Tabs>
      ) : (
        <div className='AuditLog'>
          <Notice size='sm'>
            {__('Audit Log feature is not enabled, upgrade your plan to access this feature.')}
          </Notice>
        </div>
      )}
    </Page>
  )
}

export default AuditLog
