import React from 'react'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import { checkUserPermission } from 'shared/helpers'
import './styles.scss'

const isRouteActive = path => document.location.pathname.startsWith(path)

const NavLinkGroup = ({ childLinks, mainLink, path }) => (
  <div className='NavLinkGroup'>
    {mainLink}
    {isRouteActive(path) && (
      <div className='NavLinkGroup-children'>
        {childLinks.map(link => {
          const permission = get(link, 'props.permission')
          const isAllowed = checkUserPermission(permission)
          if (permission && !isAllowed) {
            return null
          }
          if (!get(link, 'props.shouldhide')) {
            return link
          }
          return null
        })}
      </div>
    )}
  </div>
)

NavLinkGroup.propTypes = {
  childLinks: PropTypes.array.isRequired,
  mainLink: PropTypes.element.isRequired,
  path: PropTypes.string.isRequired
}

export default NavLinkGroup
