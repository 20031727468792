/* eslint-disable no-useless-escape */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { IconDisabled, InputErrorMessage } from 'shared/components'
import { emailRegex } from 'shared/constants'
import './styles.scss'

const MultiEmailInput = ({ emails, onEmailSubmit, isDisabled }) => {
  const [items, setItems] = useState(emails)
  const [value, setValue] = useState('')
  const [error, setError] = useState('')

  const isEmailInList = email => items.includes(email)

  const isEmailAddressValid = email => emailRegex.test(email)

  const isEmailValid = email => {
    let emailValidationError = ''

    if (isEmailInList(email)) {
      emailValidationError = `${email} has already been added.`
    }

    if (!isEmailAddressValid(email)) {
      emailValidationError = `${email} is not a valid email address.`
    }

    if (emailValidationError) {
      setError(emailValidationError)
      onEmailSubmit({
        emails: items,
        emailError: emailValidationError,
        value
      })
      return false
    }

    onEmailSubmit({
      emails: items,
      emailError: emailValidationError,
      value
    })
    return true
  }

  // eslint-disable-next-line consistent-return
  const handleKeyDown = evt => {
    if (['Enter', 'Tab', ','].includes(evt.key)) {
      evt.preventDefault()

      const emailValue = value.trim()

      if (value && isEmailValid(emailValue)) {
        const newEmailsList = [...items, emailValue]

        setItems(newEmailsList)
        setValue('')
        return onEmailSubmit({
          emails: newEmailsList,
          emailError: error,
          value: ''
        })
      }
    }
  }

  const handleChange = evt => {
    setValue(evt.target.value)
    setError('')
    onEmailSubmit({
      emails: items,
      emailError: '',
      value: evt.target.value
    })
  }

  const handleDelete = item => {
    const newItemsList = items.filter(i => i !== item)
    setItems(newItemsList)
    onEmailSubmit({
      emails: newItemsList,
      emailError: error,
      value
    })
  }

  const handlePaste = evt => {
    evt.preventDefault()

    const paste = evt.clipboardData.getData('text')
    const emailsFromPasteEvent = paste.match(
      /[a-zA-Z0-9!@#\$%\^\&*\)\(+=._-]+@[\w\d\.-]+\.[\w\d\.-]+/g
    )

    if (emailsFromPasteEvent) {
      const toBeAddedEmail = emailsFromPasteEvent.filter(email => !isEmailInList(email))
      const allEmails = [...items, ...toBeAddedEmail]
      setItems(allEmails)
      onEmailSubmit({
        emails: allEmails,
        emailError: error,
        value
      })
    }
  }

  return (
    <>
      <div
        className={`MultiEmailInput ${error && ' has-error'} ${isDisabled ? ' isDisabled' : ''}`}
      >
        <>
          {items.map(item => (
            <div className='tag-item' key={item}>
              {item}
              <button type='button' className='button' onClick={() => handleDelete(item)}>
                <IconDisabled color='#9f4141' height='14px' />
              </button>
            </div>
          ))}

          <input
            className='input'
            value={value}
            placeholder={__('Type or paste email addresses and press `Enter`...')}
            onKeyDown={handleKeyDown}
            onChange={handleChange}
            onPaste={handlePaste}
            disabled={isDisabled}
          />
        </>
        <InputErrorMessage text={error} />
      </div>
    </>
  )
}

MultiEmailInput.propTypes = {
  emails: PropTypes.array,
  onEmailSubmit: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool
}

MultiEmailInput.defaultProps = {
  emails: [],
  isDisabled: false
}

export default MultiEmailInput
