import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { get } from 'lodash'
import moment from 'moment'
import {
  Checkbox,
  ConfirmationPopup,
  DateInput,
  InputErrorMessage,
  DirtyFormAlert,
  Label,
  Modal,
  Notification,
  Selector
} from 'shared/components'
import {
  sendErrorReport,
  checkTrial,
  formatAmountValue,
  mapCurrencySymbol,
  getUpcomingInvoiceDate
} from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import { getCompanySilent } from 'src/company/actions'
import { createSubscription, editSubscription } from 'src/billing/actions'
import { getTotal, getBillingStartDate } from './helpers'
import './styles.scss'

const SubscriptionForm = ({
  closeCb,
  companyDetails,
  coupons,
  hasSubscription,
  pricingPlans,
  taxRates,
  title
}) => {
  const dispatch = useDispatch()

  const companyPricingPlan = get(companyDetails, 'upcoming_invoice.plan_id') || ''
  const companyCouponCode =
    get(companyDetails, 'upcoming_invoice.discounts[0].coupon.id') ||
    get(companyDetails, 'payment_coupon_code') ||
    ''
  const companyTaxRate = get(companyDetails, 'stripe_tax_id') || ''
  const invoiceBillingDate = getUpcomingInvoiceDate(companyDetails)
  const companyBillingDate = invoiceBillingDate
    ? moment(invoiceBillingDate).format('YYYY-MM-DD')
    : moment().format('YYYY-MM-DD')
  const isTrialSubscription = get(companyDetails, 'upcoming_invoice.in_trial') || ''
  const trial = checkTrial()

  const [loading, setLoading] = useState(false)
  const [dirty, setDirty] = useState(false)
  const [isDirtyFormAlertDisplayed, setIsDirtyFormAlertDisplayed] = useState(false)
  const [pricingPlan, setPricingPlan] = useState(companyPricingPlan)
  const [pricingPlanError, setPricingPlanError] = useState('')
  const [billingDate, setBillingDate] = useState(companyBillingDate)
  const [billingDateError, setBillingDateError] = useState('')
  const [hasDiscount, setHasDiscount] = useState(!!companyCouponCode)
  const [discount, setDiscount] = useState(companyCouponCode)
  const [discountError, setDiscountError] = useState('')
  const [hasTaxRate, setHasTaxRate] = useState(!!companyTaxRate)
  const [taxRate, setTaxRate] = useState(companyTaxRate)
  const [taxRateError, setTaxRateError] = useState('')
  const [billingStartDisabled, setBillingStartDisabled] = useState(false)
  const [billingOnTrialEnd, setBillingOnTrialEnd] = useState(false)
  const [displayWarningPopup, setWarningPopupDisplay] = useState(false)

  const handleBillingDateSelect = val => {
    setDirty(true)
    if (moment(val).isBefore(moment().subtract(1, 'days'))) {
      setBillingDateError(__('Only future dates can be selected'))
    } else {
      setBillingDate(moment(val).format('YYYY-MM-DD'))
      setBillingDateError('')
    }
  }

  const validatePlan = () => {
    if (!pricingPlan) {
      setPricingPlanError('This field is required')
      return false
    }
    return true
  }

  const validatePaymentMethod = () => {
    const isBillingDateToday = billingDate === moment().format('YYYY-MM-DD')
    const isCustomBillingDateToday = !billingOnTrialEnd && isBillingDateToday
    const isTrialEndToday =
      moment(trial.trialEnd).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
    const isBillingOnTrialEndToday = billingOnTrialEnd && isTrialEndToday
    const hasPaymentMethod = get(companyDetails, 'payment_card_id')

    if ((isCustomBillingDateToday || isBillingOnTrialEndToday) && !hasPaymentMethod) {
      Notification(
        'error',
        __('Missing payment method'),
        __('Subscription cannot be created if billing date is today and there is no payment method')
      )
      return false
    }
    return true
  }

  const validateDate = () => {
    if (!billingDate) {
      return false
    }
    if (moment(billingDate).isBefore(moment().subtract(1, 'days'))) {
      setBillingDateError(__('Only future dates can be selected'))
      return false
    }
    return true
  }

  const validateDiscount = () => {
    if (!hasDiscount) {
      return true
    }
    if (!discount) {
      setDiscountError('This field is required')
      return false
    }
    return true
  }

  const validateTaxRate = () => {
    if (!hasTaxRate) {
      return true
    }
    if (!taxRate) {
      setTaxRateError('This field is required')
      return false
    }
    return true
  }

  const isFormValid = () => {
    const isPlanValid = validatePlan()
    const isDateValid = validateDate()
    const isDiscountValid = validateDiscount()
    const isTaxRateValid = validateTaxRate()
    return isPlanValid && isDateValid && isDiscountValid && isTaxRateValid
  }

  const createNewSubscription = () => {
    setLoading(true)
    const data = {
      plan: pricingPlan,
      start_date: getBillingStartDate(trial, billingOnTrialEnd, billingDate),
      coupon: discount || undefined,
      tax_rate: taxRate || undefined
    }
    const companyId = get(companyDetails, 'id')

    createSubscription(companyId, data)
      .then(() => {
        dispatch(getCompanySilent(companyId))
        Notification('success', __('Subscription successfully created'))
        setWarningPopupDisplay(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot create subscription', data)
        Notification('error', __('Error occured'), __('We could not create the subscription.'))
        setLoading(false)
      })
  }

  const updateExistingSubscription = () => {
    setLoading(true)
    const data = {
      plan: !isTrialSubscription ? undefined : pricingPlan,
      start_date:
        !hasSubscription || isTrialSubscription
          ? getBillingStartDate(trial, billingOnTrialEnd, billingDate)
          : undefined,
      coupon: discount || '',
      tax_rate: taxRate || ''
    }
    const companyId = get(companyDetails, 'id')

    editSubscription(companyId, data)
      .then(() => {
        dispatch(getCompanySilent(companyId))
        Notification('success', __('Subscription successfully updated'))
        setWarningPopupDisplay(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot update subscription', data)
        Notification('error', __('Error occured'), __('We could not update the subscription.'))
        setLoading(false)
      })
  }

  const handleSubmit = () => {
    const isValid = isFormValid()
    if (!isValid || loading) {
      return false
    }
    const isPaymentMethodValid = validatePaymentMethod()
    if (!isPaymentMethodValid) {
      return false
    }
    // check here if billing date not on trial end date
    if (trial.isTrial && !billingOnTrialEnd) {
      setWarningPopupDisplay(true)
      return true
    }
    // this as callback to warning if billing date set before or after trial period end date
    if (hasSubscription) {
      updateExistingSubscription()
      return true
    }
    createNewSubscription()
    return true
  }

  const handleClose = () => {
    if (!dirty) {
      return closeCb()
    }
    return setIsDirtyFormAlertDisplayed(true)
  }

  const selectedPricingPlan = pricingPlans.find(p => p.id === pricingPlan)
  const selectedCoupon = coupons.find(c => c.id === discount)
  const selectedTaxRate = taxRates.find(t => t.id === taxRate)
  const totalPrice = getTotal(selectedPricingPlan, selectedCoupon, selectedTaxRate)

  return (
    <Modal
      closeCb={handleClose}
      confirmCb={handleSubmit}
      title={title}
      disabled={loading}
      size='sm'
    >
      <div className='SubscriptionForm'>
        <form className='SubscriptionForm-form' onSubmit={handleSubmit}>
          <div>
            <Label text={__('Pricing plan')} inputId='pricing-plan' />
            <Selector
              options={pricingPlans}
              value={pricingPlan}
              disabled={hasSubscription && !isTrialSubscription}
              handleChange={val => {
                setDirty(true)
                setPricingPlanError('')
                setPricingPlan(val)
              }}
              valueKey='id'
              getOptionLabel={o =>
                `${o.nickname} - ${formatAmountValue(o.amount)} ${mapCurrencySymbol(o.currency)} (${
                  o.interval
                })`
              }
              getOptionValue={o => o.id}
            />
            <InputErrorMessage text={pricingPlanError} />
          </div>
          {(!hasSubscription || isTrialSubscription) && (
            <div>
              <Label text={__('Billing start date')} inputId='billing-date' />
              <DateInput
                handleChange={val => handleBillingDateSelect(val)}
                value={billingDate}
                error={billingDateError}
                id='billing-date'
                disabled={billingOnTrialEnd}
              />
            </div>
          )}
          {trial.isTrial && (
            <div className='billing-trial-end'>
              <Checkbox
                label={__(
                  `${__('Start billing on trial period end')} - ${moment(trial.trialEnd).format(
                    defaultDateFormat
                  )}`
                )}
                handleChange={val => {
                  setDirty(true)
                  setBillingStartDisabled(!billingStartDisabled)
                  setBillingOnTrialEnd(val)
                }}
                checked={billingOnTrialEnd}
                inputId='billing-trial-end'
              />
            </div>
          )}
          <div>
            <Checkbox
              label={__('Discount')}
              handleChange={val => {
                setDirty(true)
                setDiscountError('')
                setDiscount('')
                setHasDiscount(val)
              }}
              checked={hasDiscount}
              inputId='discount'
            />
            {hasDiscount && (
              <div>
                <Selector
                  options={coupons}
                  value={discount}
                  handleChange={val => {
                    setDirty(true)
                    setDiscount(val)
                  }}
                  valueKey='id'
                  getOptionLabel={o => `${o.name}`}
                  getOptionValue={o => o.id}
                />
                <InputErrorMessage text={discountError} />
              </div>
            )}
          </div>
          <div className='tax-rate-wrapper'>
            <Checkbox
              label={__('Tax rate')}
              handleChange={val => {
                setDirty(true)
                setTaxRate('')
                setTaxRateError('')
                setHasTaxRate(val)
              }}
              checked={hasTaxRate}
              inputId='tax-rate'
            />
            {hasTaxRate && (
              <div>
                <Selector
                  options={taxRates}
                  value={taxRate}
                  handleChange={val => {
                    setDirty(true)
                    setTaxRate(val)
                  }}
                  valueKey='id'
                  getOptionLabel={o => `${o.display_name} ${o.jurisdiction} - ${o.percentage}%`}
                  getOptionValue={o => o.id}
                />
                <InputErrorMessage text={taxRateError} />
              </div>
            )}
          </div>
          <div className='total'>
            {`${__('Total')}:`}
            &nbsp;
            <span>{`${totalPrice} ${mapCurrencySymbol(
              get(selectedPricingPlan, 'currency') || ''
            )}`}</span>
          </div>
        </form>
        {isDirtyFormAlertDisplayed && (
          <DirtyFormAlert
            dirty={dirty}
            closeAlert={() => setIsDirtyFormAlertDisplayed(false)}
            closeCb={closeCb}
          />
        )}
        {displayWarningPopup && (
          <ConfirmationPopup
            closeCb={() => setWarningPopupDisplay(false)}
            confirmCb={() => {
              if (hasSubscription) {
                return updateExistingSubscription()
              }
              return createNewSubscription()
            }}
            title={__('Trial period will be changed!')}
            confirmText={__('Confirm')}
            theme='error'
            warning
            disabled={loading}
          >
            <>
              <div>{__('Trial period will be changed to end on date')}</div>
              <div>{moment(billingDate).format(defaultDateFormat)}</div>
            </>
          </ConfirmationPopup>
        )}
      </div>
    </Modal>
  )
}

SubscriptionForm.propTypes = {
  closeCb: PropTypes.func.isRequired,
  companyDetails: PropTypes.object.isRequired,
  coupons: PropTypes.array.isRequired,
  hasSubscription: PropTypes.bool.isRequired,
  pricingPlans: PropTypes.array.isRequired,
  taxRates: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired
}

export default SubscriptionForm
