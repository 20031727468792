import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useSelector, useDispatch } from 'react-redux'
import {
  Button,
  ConfirmationPopup,
  PermissionMissingNotificationTitle,
  Tab,
  Tabs,
  TabContent,
  TabsHeader,
  Notification
} from 'shared/components'
import {
  getDisabledMessage,
  isFeatureAvailable,
  isFeatureEnabled,
  sendErrorReport,
  checkUserPermission
} from 'shared/helpers'
import { platformFeatures, userPermissions } from 'shared/constants'
import { resetLicense, disableLicense, enableLicense } from 'src/license/actions'
import { updateUserPreferences } from 'src/company/actions'
import EditLicenseForm from '../../../EditLicenseForm'
import {
  LicenseDetailsTable,
  CustomFields,
  ProductFeatures,
  LicenseAnalytics,
  DevicesList,
  BlacklistedDevicesList,
  UsageReport
} from '../../../LicenseContainer/components'
import './styles.scss'

const BundleLicenseItem = ({ license, refetchLicense }) => {
  const canManageLicenses = checkUserPermission(userPermissions.licenses_write)
  const dispatch = useDispatch()
  const fetchDevicesRef = useRef(0)
  const fetchBlacklistedRef = useRef(0)

  const [showEditLicenseForm, setEditLicenseFormDisplay] = useState(false)
  const [confirmationLoading, setConfirmationLoading] = useState(false)
  const [isEnableLicenseConfirmationDisplayed, setEnableLicenseConfirmationDisplay] = useState(
    false
  )
  const [isResetLicenseConfirmationDisplayed, setResetLicenseConfirmationDisplay] = useState(false)

  const licenseID = get(license, 'id')
  const companyID = useSelector(state => get(state, 'company.details.id'))
  const products = useSelector(state => get(state, 'products.list') || [])
  const uiOptions = useSelector(state => get(state, 'company.userPreferences.ui_options'))
  const userPrefsId = useSelector(state => get(state, 'company.userPreferences.id'))

  const currentProduct = products.find(p => get(p, 'id') === get(license, 'product.id'))
  const isAuthUserBased = get(currentProduct, 'authorization_method') === 'user'

  const refetchDevices = () => {
    fetchDevicesRef.current += 1
    return true
  }
  const refetchBlacklistedDevices = () => {
    fetchBlacklistedRef.current += 1
    return true
  }

  const handleResetLicense = () => {
    setConfirmationLoading(true)

    resetLicense(licenseID, license, companyID)
      .then(() => {
        refetchLicense()
        setConfirmationLoading(false)
        setResetLicenseConfirmationDisplay(false)
        Notification('success', __('Changes saved successfully'), __('Your license has been reset'))
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot reset license')
        setConfirmationLoading(false)
        Notification(
          'error',
          __('Your changes were not saved'),
          __('There was an error while saving your changes')
        )
      })
  }

  const patchEnabledStatus = () => {
    const currentStatus = get(license, 'enabled')
    setConfirmationLoading(true)
    const licenseData = { enabled: !currentStatus }

    if (currentStatus) {
      disableLicense(licenseID, companyID)
        .then(() => {
          setConfirmationLoading(false)
          refetchLicense()
          setEnableLicenseConfirmationDisplay(false)
          Notification(
            'success',
            __('Changes saved successfully'),
            !currentStatus ? __('License enabled') : __('License disabled')
          )
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot enable license', licenseData)
          setConfirmationLoading(false)
          Notification(
            'error',
            __('Your changes were not saved'),
            __('There was an error while saving your changes')
          )
        })
    } else {
      enableLicense(licenseID, companyID)
        .then(() => {
          setConfirmationLoading(false)
          refetchLicense()
          setEnableLicenseConfirmationDisplay(false)
          Notification(
            'success',
            __('Changes saved successfully'),
            !currentStatus ? __('License enabled') : __('License disabled')
          )
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot enable license', licenseData)
          setConfirmationLoading(false)
          Notification(
            'error',
            __('Your changes were not saved'),
            __('There was an error while saving your changes')
          )
        })
    }
  }

  const handleManageLicenseClick = cb => {
    if (!canManageLicenses) {
      Notification(
        'error',
        <PermissionMissingNotificationTitle permission={userPermissions.licenses_write} />,
        __('Contact you account admin for support.')
      )
      return false
    }
    cb(true)
    return true
  }

  return (
    <div className='BundleLicenseItem LicenseContainer'>
      <div className='list-header'>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            theme='info'
            onClick={() => handleManageLicenseClick(setEditLicenseFormDisplay)}
          >
            {__('Edit license')}
          </Button>
        </div>
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            theme='warning'
            onClick={() => handleManageLicenseClick(setResetLicenseConfirmationDisplay)}
          >
            {__('Reset')}
          </Button>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.platform_edit_license)}
            featureAvailable={isFeatureAvailable(platformFeatures.platform_edit_license)}
            notEnabledMessage={getDisabledMessage()}
            theme={get(license, 'enabled') ? 'error' : 'success'}
            onClick={() => handleManageLicenseClick(setEnableLicenseConfirmationDisplay)}
          >
            {get(license, 'enabled') ? __('Disable') : __('Enable')}
          </Button>
        </div>
      </div>
      <div className='BundleLicenseItem-tabs LicenseContainer-tabs'>
        <Tabs>
          <TabsHeader>
            <Tab>{__('License details')}</Tab>
            <Tab>{__('Devices')}</Tab>
            <Tab>{__('Custom fields')}</Tab>
            <Tab>{__('Product features')}</Tab>
            <Tab>{__('Usage report')}</Tab>
            <Tab>{__('Analytics')}</Tab>
          </TabsHeader>
          <TabContent>
            <LicenseDetailsTable
              license={license}
              companyID={companyID}
              isAuthUserBased={isAuthUserBased}
              currentProduct={currentProduct}
              refetchLicense={refetchLicense}
            />
          </TabContent>
          <TabContent>
            <div>
              <DevicesList
                isBundle={true}
                companyID={companyID}
                license={license}
                uiOptions={uiOptions}
                refetchLicense={refetchLicense}
                updateUserPreferences={data => dispatch(updateUserPreferences(data))}
                userPrefsId={userPrefsId}
                refetchBlacklistedDevices={refetchBlacklistedDevices}
                shouldUpdateList={fetchDevicesRef.current}
              />
              <BlacklistedDevicesList
                license={license}
                companyID={companyID}
                refetchDevices={refetchDevices}
                refetchLicense={refetchLicense}
                shouldUpdateList={fetchBlacklistedRef.current}
              />
            </div>
          </TabContent>
          <TabContent>
            <CustomFields license={license} currentProduct={currentProduct} companyID={companyID} />
          </TabContent>
          <TabContent>
            <ProductFeatures
              companyID={companyID}
              currentProduct={currentProduct}
              features={get(license, 'product_features') || []}
              license={license}
              refetchLicense={refetchLicense}
            />
          </TabContent>
          <TabContent>
            <UsageReport license={license} isAuthUserBased={isAuthUserBased} />
          </TabContent>
          <TabContent>
            <LicenseAnalytics license={license} />
          </TabContent>
        </Tabs>
      </div>
      {showEditLicenseForm && (
        <EditLicenseForm
          closeCb={() => setEditLicenseFormDisplay(!showEditLicenseForm)}
          isOpen={showEditLicenseForm}
          license={license}
          currentProduct={currentProduct}
          refetchLicense={refetchLicense}
        />
      )}
      {isResetLicenseConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setResetLicenseConfirmationDisplay(false)}
          confirmCb={handleResetLicense}
          title={__('Are you sure you want to reset your license?')}
          confirmText={__('Reset')}
          theme='warning'
          disabled={confirmationLoading}
        />
      )}
      {isEnableLicenseConfirmationDisplayed && (
        <ConfirmationPopup
          closeCb={() => setEnableLicenseConfirmationDisplay(false)}
          confirmCb={patchEnabledStatus}
          title={__(
            `Are you sure you want to ${
              get(license, 'enabled') ? __('disable') : __('enable')
            } your license?`
          )}
          confirmText={get(license, 'enabled') ? __('Disable') : __('Enable')}
          theme={get(license, 'enabled') ? 'error' : 'success'}
          disabled={confirmationLoading}
        >
          {get(license, 'enabled')
            ? __('License and all active license devices will be disabled.')
            : __('License will be enabled.')}
        </ConfirmationPopup>
      )}
    </div>
  )
}

BundleLicenseItem.propTypes = {
  license: PropTypes.object.isRequired,
  refetchLicense: PropTypes.func.isRequired
}

export default BundleLicenseItem
