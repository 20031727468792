import React, { useEffect, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import moment from 'moment'
import { useSelector } from 'react-redux'
import ReactJson from 'react-json-view'
import {
  Button,
  DateRangePicker,
  DescriptionTable,
  DeviceIcon,
  IconExport,
  LevelIcon,
  List,
  Notice,
  Notification,
  StatusCard
} from 'shared/components'
import { defaultDateTimeFormat, platformFeatures } from 'shared/constants'
import {
  capitalizeFirstLetter,
  displayValue,
  formatDate,
  isJSON,
  sendErrorReport,
  filenameFromHeadersData,
  downloadResource,
  isFeatureAvailable,
  isFeatureEnabled,
  getDisabledMessage
} from 'shared/helpers'
import { getLicenseHistory, exportUsageReport } from 'src/license/actions'
import './styles.scss'

const UsageReport = ({ license, isAuthUserBased }) => {
  const APIdateFormat = 'YYYY-MM-DD'
  const defaultFrom = moment().subtract(30, 'days')
  const defaultTo = moment()
  const licenseID = get(license, 'id')
  const companyFeatures = useSelector(state => get(state, 'company.features'))
  const companyDetails = useSelector(state => get(state, 'company.details'))
  const companyID = get(companyDetails, 'id')
  const isExtraAnalyticsFeatureEnabled = isFeatureEnabled(platformFeatures.extra_analytics)
  const showDataDays = companyFeatures.find(
    cf => cf.key === platformFeatures.reporting_data_retention_show
  )
  const daysCount = get(showDataDays, 'value')

  const [isLoading, setLoading] = useState(true)
  const [exportLoading, setExportLoading] = useState(false)
  const [events, setEvents] = useState([])
  const [timeRange, setTimeRange] = useState({
    from: defaultFrom,
    to: defaultTo
  })
  // table state
  const [eventsCount, setEventsCount] = useState(null)
  const [page, setPage] = useState(0)
  const [currentSort, setCurrentSort] = useState([
    {
      desc: true,
      id: 'ts'
    }
  ])
  const [tableExpanded, setTableExpanded] = useState({})

  const fetchEvents = useCallback(
    (tablePage, tableSort, range) => {
      setLoading(true)
      const from = moment(range.from).format(APIdateFormat)
      const to = moment(range.to).format(APIdateFormat)
      getLicenseHistory(companyID, licenseID, tablePage, tableSort, { from, to })
        .then(res => {
          const count = get(res, 'data.count') || 0
          const data = get(res, 'data.results') || []
          setEvents(data)
          setEventsCount(count)
          setLoading(false)
        })
        .catch(err => {
          sendErrorReport(err, 'Cannot get license history')
          setLoading(false)
        })
    },
    [licenseID]
  )

  useEffect(() => {
    fetchEvents(page, currentSort, timeRange)
  }, [fetchEvents, page, currentSort, timeRange])

  const exportData = () => {
    setExportLoading(true)
    const data = {
      company: companyID,
      from: moment(timeRange.from).format(APIdateFormat),
      to: moment(timeRange.to).format(APIdateFormat),
      license: licenseID
    }

    exportUsageReport(data)
      .then(response => {
        const filename = filenameFromHeadersData(response.headers)
        downloadResource(response, filename)
        setExportLoading(false)
      })
      .catch(err => {
        sendErrorReport(err, 'Cannot export data', data)
        Notification('error', __('Export data failed'))
        setExportLoading(false)
      })
  }

  if (!isExtraAnalyticsFeatureEnabled) {
    return (
      <div className='UsageReport'>
        <Notice
          size='sm'
          title={__('License usage report is not available in your plan type.')}
          theme='error'
        >
          <div>{__('Upgrade your plan to use this feature.')}</div>
        </Notice>
      </div>
    )
  }

  return (
    <div className='UsageReport'>
      <div className='list-header'>
        <div />
        <div>
          <Button
            featureEnabled={isFeatureEnabled(platformFeatures.reporting_data_export)}
            featureAvailable={isFeatureAvailable(platformFeatures.reporting_data_export)}
            notAvailableMessage={__('Data export is not available in your plan type.')}
            ctaText={__('Upgrade to download your data exports.')}
            notEnabledMessage={getDisabledMessage()}
            style={{ padding: '0', width: '50px' }}
            theme='default'
            title={__('Export usage report')}
            onClick={exportData}
            disabled={isLoading || exportLoading}
            loading={exportLoading}
          >
            <IconExport height='14px' color='#555' />
          </Button>
          <DateRangePicker
            handleChange={dates => {
              setTimeRange({
                from: dates[0],
                to: dates[1]
              })
            }}
            initialStartDate={defaultFrom}
            initialEndDate={defaultTo}
            disabled={isLoading}
          />
        </div>
      </div>
      <Notice
        size='sm'
        title={`${__('License usage data is saved for')} ${daysCount} ${__('days')}.`}
      >
        {__('Contact sales if you wish to change this policy.')}
      </Notice>
      <List
        columns={[
          {
            expander: true,
            Header: __('Details'),
            headerClassName: 'text-center',
            width: 80,
            style: {
              fontSize: 25,
              padding: '0',
              textAlign: 'center',
              userSelect: 'none'
            }
          },
          {
            accessor: 'ts',
            Header: __('Date'),
            Cell: cellInfo => formatDate(cellInfo.value, defaultDateTimeFormat)
          },
          {
            accessor: 'text',
            Header: __('Description'),
            Cell: cellInfo => {
              const level = get(cellInfo, 'original.level') || 'info'
              const text = displayValue(capitalizeFirstLetter(cellInfo.value))
              return (
                <div className='description-cell'>
                  <LevelIcon level={level} />
                  <span>{text}</span>
                </div>
              )
            }
          },
          {
            accessor: 'source',
            Header: __('Source'),
            Cell: cellInfo => displayValue(capitalizeFirstLetter(cellInfo.value))
          },
          {
            id: 'sdk_version',
            Header: __('SDK build'),
            Cell: cellInfo => displayValue(cellInfo.value)
          },
          {
            id: 'license_user__true_email',
            Header: __('User'),
            Cell: cellInfo => {
              const userEmail = get(cellInfo, 'original.license_user.true_email')
              return displayValue(userEmail)
            },
            show: isAuthUserBased
          }
        ]}
        data={events}
        loading={isLoading}
        showPagination
        manual
        defaultSorted={currentSort}
        onPageChange={newPage => setPage(newPage)}
        onSortedChange={newSorted => setCurrentSort(newSorted)}
        page={page}
        pages={Math.ceil(eventsCount / 20)}
        minRows={Math.ceil(get(events, 'length')) || 2}
        onExpandedChange={expanded => setTableExpanded(expanded)}
        expanded={tableExpanded}
        SubComponent={row => {
          const hasUser = get(row, 'original.license_user')
          const hasDevice = get(row, 'original.device')
          const hasData = get(row, 'original.data')
          const isDeviceActive = get(row, 'original.device.device_active')
          return (
            <div className='SubComponent'>
              {isAuthUserBased && (
                <div className='user-info'>
                  <div className='subheading'>{__('User details')}</div>
                  {hasUser ? (
                    <DescriptionTable
                      details={[
                        {
                          label: __('Email'),
                          value: displayValue(get(row, 'original.license_user.true_email'))
                        },
                        {
                          label: __('First name'),
                          value: displayValue(get(row, 'original.license_user.first_name'))
                        },
                        {
                          label: __('Last name'),
                          value: displayValue(get(row, 'original.license_user.last_name'))
                        }
                      ]}
                    />
                  ) : (
                    <div className='empty-data-warning'>{__('No user data')}</div>
                  )}
                </div>
              )}
              <div className='device-info'>
                <div className='subheading'>{__('Device details')}</div>
                {hasDevice ? (
                  <DescriptionTable
                    details={[
                      {
                        label: __('Type'),
                        value: <DeviceIcon device={get(row, 'original.device.type')} />
                      },
                      {
                        label: __('First activated'),
                        value: formatDate(
                          get(row, 'original.device.time_activated'),
                          defaultDateTimeFormat
                        )
                      },
                      {
                        label: __('Last checked'),
                        value: formatDate(
                          get(row, 'original.device.last_check'),
                          defaultDateTimeFormat
                        )
                      },
                      {
                        label: __('Status'),
                        value: (
                          <StatusCard
                            status={isDeviceActive ? 'success' : 'error'}
                            text={isDeviceActive ? `${__('Active')}` : `${__('Inactive')}`}
                          />
                        )
                      },
                      { label: __('OS'), value: displayValue(get(row, 'original.device.os')) },
                      {
                        label: __('SDK build'),
                        value: displayValue(get(row, 'original.device.sdk_build_version'))
                      },
                      {
                        label: __('App version'),
                        value: displayValue(get(row, 'original.device.app_ver'))
                      },
                      {
                        label: __('Hostname'),
                        value: displayValue(get(row, 'original.device.hostname'))
                      },
                      {
                        label: __('External IP address'),
                        value: displayValue(get(row, 'original.device.external_ip'))
                      },
                      {
                        label: __('IP address'),
                        value: displayValue(get(row, 'original.device.ip'))
                      },
                      {
                        label: __('MAC address'),
                        value: displayValue(get(row, 'original.device.mac_address'))
                      },
                      {
                        label: __('Is virtual machine'),
                        value: get(row, 'original.device.is_vm') ? __('Yes') : __('No')
                      },
                      {
                        label: __('VM info'),
                        value: displayValue(get(row, 'original.device.vm_info'))
                      },
                      {
                        label: __('Hardware ID'),
                        value: displayValue(get(row, 'original.device.hardware_id'))
                      }
                    ]}
                  />
                ) : (
                  <div className='empty-data-warning'>{__('No device data')}</div>
                )}
              </div>
              {hasData && isJSON(get(row, 'original.data')) && (
                <div className='data-container'>
                  <div className='subheading'>{__('Additional data')}</div>
                  <ReactJson
                    src={JSON.parse(get(row, 'original.data'))}
                    name='event'
                    collapsed={1}
                    displayDataTypes={false}
                  />
                </div>
              )}
            </div>
          )
        }}
      />
    </div>
  )
}

UsageReport.propTypes = {
  license: PropTypes.object.isRequired,
  isAuthUserBased: PropTypes.bool.isRequired
}

export default UsageReport
