import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get, sortBy } from 'lodash'
import { Button, IconEdit, IconDelete, JsonView, FeatureTypePill } from 'shared/components'
import { capitalizeFirstLetter, formatDate } from 'shared/helpers'
import { defaultDateFormat } from 'shared/constants'
import ProductFeaturesEditorForm from './ProductFeaturesEditorForm'
import './styles.scss'

const ProductFeaturesEditor = ({ selectedFeatures, onFeaturesChange, disableFeatureExpiry }) => {
  const [featureToEdit, setFeatureToEdit] = useState(null)

  const handleFeatureRemove = feat => {
    const featCode = get(feat, 'code')
    const newList = selectedFeatures.filter(sf => get(sf, 'code') !== featCode)
    onFeaturesChange(newList)
  }

  const handleFeatureEdit = feat => {
    const featCode = get(feat, 'code')
    const newList = selectedFeatures.map(sf => {
      if (get(sf, 'code') === featCode) {
        return feat
      }
      return sf
    })
    setFeatureToEdit(null)
    onFeaturesChange(newList)
  }

  const sortedList = sortBy(selectedFeatures, 'label')

  return (
    <div className='ProductFeaturesEditor'>
      <ol>
        {sortedList.map(feat => {
          const isConsumption = get(feat, 'feature_type') === 'consumption'
          const hasUnlimitedConsumptions = get(feat, 'allow_unlimited_consumptions')
          const allowNegativeConsumptions = get(feat, 'allow_negative_consumptions')
          const allowOverages = get(feat, 'allow_overages')
          const resetConsumption = get(feat, 'reset_consumption')
          const isFloatingCloud = get(feat, 'is_floating_cloud')
          const isFloatingOffline = get(feat, 'is_floating')
          const isFloating = isFloatingCloud || isFloatingOffline

          return (
            <li className='feature-row' key={get(feat, 'id')}>
              <div className='header'>
                <div className='product-name'>
                  <div className='name'>{get(feat, 'name')}</div>
                  <div className='code'>{`[${get(feat, 'code')}]`}</div>
                  <FeatureTypePill type={get(feat, 'feature_type')} />
                  {isFloating && (
                    <div className='floating-pill'>
                      {isFloatingCloud ? __('Floating') : __('Offline floating feature')}
                    </div>
                  )}
                </div>
                <div className='actions'>
                  <Button theme='default' size='sm' onClick={() => setFeatureToEdit(feat)}>
                    <IconEdit height='16px' width='16px' />
                  </Button>
                  <Button theme='link' size='sm' onClick={() => handleFeatureRemove(feat)}>
                    <IconDelete height='16px' width='16px' color='#ee5253' />
                  </Button>
                </div>
              </div>
              {isConsumption && (
                <>
                  <div className='details'>
                    <div className='label'>{__('Max consumption')}</div>
                    <div className='value'>
                      {hasUnlimitedConsumptions ? __('Unlimited') : get(feat, 'max_consumption')}
                    </div>
                  </div>
                  <div className='details'>
                    <div className='label'>{__('Allow negative consumptions')}</div>
                    <div className='value'>{allowNegativeConsumptions ? __('Yes') : __('No')}</div>
                  </div>
                  {allowOverages && (
                    <div className='details'>
                      <div className='label'>{__('Max overages')}</div>
                      <div className='value'>{get(feat, 'max_overages')}</div>
                    </div>
                  )}
                  {resetConsumption && (
                    <div className='details'>
                      <div className='label'>{__('Consumption period')}</div>
                      <div className='value'>{`${capitalizeFirstLetter(
                        get(feat, 'consumption_period')
                      )} ${__('consumptions reset')}`}</div>
                    </div>
                  )}
                </>
              )}
              {isFloating && (
                <>
                  <div className='details'>
                    <div className='label'>{__('Max simultaneous users')}</div>
                    <div className='value'>{get(feat, 'floating_users')}</div>
                  </div>
                  <div className='details'>
                    <div className='label'>{__('Floating timeout')}</div>
                    <div className='value'>{`${get(feat, 'floating_timeout')} min`}</div>
                  </div>
                </>
              )}
              {!disableFeatureExpiry && (
                <div className='details'>
                  <div className='label'>{__('Expiry date')}</div>
                  <div className='value'>
                    {formatDate(get(feat, 'expiry_date'), defaultDateFormat, '-')}
                  </div>
                </div>
              )}
              <div className='details'>
                <div className='label'>{__('Metadata')}</div>
                <div className='value'>
                  <JsonView value={get(feat, 'metadata')} name='metadata' />
                </div>
              </div>
            </li>
          )
        })}
      </ol>
      {featureToEdit && (
        <ProductFeaturesEditorForm
          feature={featureToEdit}
          closeCb={() => setFeatureToEdit(null)}
          onConfirm={handleFeatureEdit}
        />
      )}
    </div>
  )
}

ProductFeaturesEditor.propTypes = {
  selectedFeatures: PropTypes.array,
  onFeaturesChange: PropTypes.func.isRequired,
  disableFeatureExpiry: PropTypes.bool
}

ProductFeaturesEditor.defaultProps = {
  selectedFeatures: [],
  disableFeatureExpiry: false
}

export default ProductFeaturesEditor
